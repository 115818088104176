import constants from '../utils/constants';

export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  '$timeout',
  '$q',
  'HelpersService',
  'FocusService',
  'FocusHighlightService',
  '$mdDialog',
  'MomentService',
  'IndividualInfoService',
  '$location',
  function IndividualController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
    $timeout,
    $q,
    HelpersService,
    FocusService,
    FocusHighlightService,
    $mdDialog,
    MomentService,
    IndividualInfoService,
    $location,
  ) {
    $scope.first_request = true;
    $rootScope.subMenuActive = 'basic-info';
    $scope.addPhysicians = () => {
      $scope.model.primaryPhysiciansCount++;
      $scope.model.primaryPhysicians.push({
        id: $scope.model.primaryPhysiciansCount,
        value: '',
        suffix: {},
        first_name: '',
        last_name: '',
      });
    };
    $scope.deletePhysicians = (i) => {
      $scope.model.primaryPhysicians.splice(i, 1);
      $scope.model.primaryPhysiciansCount++;
    };

    $scope.model = {
      formDataPC: { status: 'NO' },
      spouseEditable: true,
      sorting: {
        active: 'updated_at',
        name: '',
        email: '',
        updated_at: 'desc',
      },
      wizard: {
        activeTabClass: 'basic',
        back: () => {
          $scope.model.wizard.activeTabClass = 'basic';
          $timeout(() => {
            //$rootScope.gotoNextStep = false;
            $rootScope.gotoNextStep = undefined;
            $state.reload();
          }, 500);
        },
      },
      currentPrescriptionData: {},
      dropdown: {
        emailProviders: {},
        specialistSpeciality: {},
        physicianSuffix: {},
        drugNames: {},
        drugDosage: {
          dosages: {
            dataArray: [],
          },
        },
      },
      additionalPrescriptionsTakeData: [],
      prescriptionPacenetPA: false,
      prescriptionsTaken: false,
      spouseCurrentClient: false,
      primaryPhysiciansCount: 1,
      primaryPhysicians: [],
      selectedPhysiciansNew: [{ npi: 0, title: '-' }],
      additionalprescriptionsFields: [{ id: 1, value: '' }],
      approvedDateTime: '',
      aarpNumber: false,
      approvedInd: false,
      intakeDatePicker: false,
      appointmentDTPicker: false,
      leadDatePicker: false,
      spouseDOBPicker: false,
      effADatePicker: false,
      effBDatePicker: false,
      spouseEffADatePicker: false,
      spouseEffBDatePicker: false,
      disabilityPicker: false,
      deceased_date: false,
      spouseDisabilityPicker: false,
      counties: [],
      noSSN: true,

      prescriptionsTakeFields: [],
      prescriptionsTakeData: [],

      primaryPhysician: {
        suffix: { title: '' },
        first_name: '',
        last_name: '',
      },
      specialists: [],

      intakeSheetAdd: {
        arp_member: 'no',
      },
      addForm: {
        disability: '0',
        suffix_name: $rootScope.constants.dropdowns.suffix[0],
        no_email_address: false,
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
        lead_date: MomentService.formatDate(new Date(), 'YYYY-MM-DD'),
      },
      editForm: {
        disability: '0',
        suffix_name: $rootScope.constants.dropdowns.suffix[0],
        no_email_address: false,
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
      },
      files: {
        newFile: [],
        data: [],
      },
      spouseData: {},
      individualID: null,
      listingData: [],
      doctorsData: [],
      listingPerPage: 0,
      listingTotal: 0,
      paginationCurrent: 1,
      searchText: '',
      searchDoctor: '',
      doctorPerPage: 0,
      doctorTotal: 0,
      paginationCurrentDoctor: 1,
      listingFilter: 0,
    };

    if ($stateParams.type === 'listing' && $location.search().search) {
      $scope.model.searchText = $location.search().search;
    }

    function loadOfficeLocations() {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}agency-locations/get`, 'GET', {}, (response) => {
        $rootScope.constants.dropdowns.locations = response.data.map((a) => {
          const title = `${a.location} - ${a.zip}`;
          if ($rootScope.loggedInUserData.service_location_id === a.id) {
            $scope.model.addForm.location = {
              value: $rootScope.loggedInUserData.service_location_id,
              title: title,
            };
          }
          return { title: title, value: a.id };
        });
      });
    }
    $scope.spouseEditable = (i) => {
      $scope.model.spouseData = {};
      if (i.value === 'yes') {
        $scope.model.spouseEditable = true;
        if (typeof $scope.model.intakeSheetAdd.spouse_current_client_id !== 'undefined') {
          if (
            $scope.model.intakeSheetAdd.spouse_current_client_id.value !== 0 &&
            $scope.model.intakeSheetAdd.spouse_current_client_id.value !== '0'
          ) {
            $scope.populateSpouseData($scope.model.intakeSheetAdd.spouse_current_client_id);
          }
        }
      } else {
        $scope.model.spouseEditable = false;
      }
    };

    $scope.sortListing = (a) => {
      for (const i in $scope.model.sorting) {
        if (i !== 'active' && i !== a) {
          $scope.model.sorting[i] = '';
        }
      }

      if ($scope.model.sorting.active !== a) {
        $scope.model.sorting.active = a;
        $scope.model.sorting[a] = a;
      }

      if ($scope.model.sorting[a] === 'asc') {
        $scope.model.sorting[a] = 'desc';
      } else {
        $scope.model.sorting[a] = 'asc';
      }

      getIndividualResults(1, true);
    };
    /*SPOUSE SELECTION*/
    $scope.ssnCheckAndLoadData = (ssn, t) => {
      if (typeof ssn !== 'undefined' && ssn !== '') {
        const data = {};
        data.ssn = ssn;

        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/check-ssn`, 'POST', data, (response) => {
          if (response.data.status !== 200) {
            ConfirmBoxService.open(
              `Client(${response.data.individualData.social_security_no}) is already a member!`,
              `Do you want to edit this individual(${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name})?`,
              {
                Yes: () => {
                  window.location = `#/individuals/edit/${response.data.individualData.id}`;
                },
                No: () => {
                  $scope.model.addForm.social_security_no = '';
                  if (t) {
                    $scope.model.editForm.social_security_no = angular.copy(
                      $scope.model.clonedData.individualData.social_security_no,
                    );
                  }
                  /*else{
                   $state.reload();
                   }*/
                },
              },
            );
          }
          /*else{
         window.location = "#/individuals/addNew/"+ssn;

         }*/
        });
      }
    };
    $scope.checkSpouseSSNExistence = (ssn) => {
      if (typeof ssn !== 'undefined' && ssn !== '') {
        const data = {};
        data.ssn = ssn;
        data.notIn = $scope.model.editForm.id;
        if (data.ssn === $scope.model.editForm.social_security_no) {
          ConfirmBoxService.open('Error', "Individual's SSN & Spouse SSN are same!", {
            OK: () => {
              $scope.model.spouseData.social_security_no = '';
            },
          });
        } else if ($scope.model.intakeSheetAdd.spouse_current_client !== 'yes') {
          AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/check-ssn`, 'POST', data, (response) => {
            if (response.data.status !== 200) {
              ConfirmBoxService.open(
                `Client(${response.data.individualData.social_security_no}) is already a member!`,
                `Do you want to populate this individual's information(${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name})?`,
                {
                  Yes: () => {
                    $scope.populateSpouseData({
                      value: response.data.individualData.id,
                    });
                    $scope.model.intakeSheetAdd.spouse_current_client_id = {
                      value: response.data.individualData.id,
                      title: `${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name}`,
                    };
                    $scope.model.intakeSheetAdd.spouse_current_client = {
                      value: 'yes',
                      title: 'Yes',
                    };
                    $scope.onSelectSpouseCurrentClient({
                      value: 'yes',
                      title: 'Yes',
                    });
                  },
                  No: () => {
                    $scope.model.spouseData.social_security_no = '';
                  },
                },
              );
            }
          });
        }
      }
    };
    $scope.populateSpouseData = (v) => {
      if (v.value !== '' && typeof v.value !== 'undefined') {
        const data = {};
        data.spouse_id = v.value;
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/load-spouse-data/${data.spouse_id}`,
          'POST',
          data,
          (res) => {
            //$scope.model.spouseData = response.data.spouseData;
            if (typeof res.data.spouseData !== 'undefined') {
              $scope.model.spouseData = res.data.spouseData;
              $scope.model.spouseData.date_of_birth =
                res.data.spouseData.date_of_birth === 'null' || res.data.spouseData.date_of_birth == null
                  ? ''
                  : MomentService.convertTimeZone(res.data.spouseData.date_of_birth);
              if ($scope.model.spouseData.date_of_birth) {
                let birthday = MomentService.convertTimeZone($scope.model.spouseData.date_of_birth);
                birthday = MomentService.convertTimeZone(birthday);
                const ageDifMs = MomentService.getAgeDiffMs(birthday);
                const ageDate = MomentService.convertTimeZone(ageDifMs); // miliseconds from epoch
                const c = Math.abs(ageDate.getUTCFullYear() - 1970);
                $scope.model.spouseData.age = c;
              }
              $scope.model.spouseData.part_a_eff_date =
                $scope.model.spouseData.part_a_eff_date === 'null' || $scope.model.spouseData.part_a_eff_date == null
                  ? ''
                  : MomentService.convertTimeZone($scope.model.spouseData.part_a_eff_date);
              $scope.model.spouseData.part_b_eff_date =
                $scope.model.spouseData.part_b_eff_date === 'null' || $scope.model.spouseData.part_b_eff_date == null
                  ? ''
                  : MomentService.convertTimeZone($scope.model.spouseData.part_b_eff_date);
              $scope.model.spouseData.ss_disability_date =
                $scope.model.spouseData.ss_disability_date === 'null' ||
                $scope.model.spouseData.ss_disability_date == null
                  ? ''
                  : MomentService.convertTimeZone($scope.model.spouseData.ss_disability_date);
              $scope.model.spouseData.date_of_birth =
                $scope.model.spouseData.date_of_birth === 'null' || $scope.model.spouseData.date_of_birth == null
                  ? ''
                  : dateFormatterMDY($scope.model.spouseData.date_of_birth);
            } else {
              $scope.model.spouseData = {};
            }
          },
        );
      }
    };

    if ($rootScope.gotoNextStep === true) {
      $scope.model.wizard.activeTabClass = 'intake';
      $rootScope.gotoNextStep = false;
    }

    $scope.editPhoneNumber = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/phone-number-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addPhoneNumber = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/phone-number-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    $scope.openGoogleMap = (d, ev) => {
      $rootScope.gmapData = d;

      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/google-map-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addAddress = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/address-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.editDocters = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/docters-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.editAddress = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/address-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    function DialogController(scope, $mdDialog) {
      $scope.model.searchDoctor = '';
      scope.doctorsData = [];
      scope.newDoctorsData = {
        suffix: '',
        first_name: '',
        last_name: '',
        speciality: '',
        custom_doctor: true,
        individual_id: $scope.model.individualID,
        doctor_type: 'CRM',
      };
      scope.addDoctorManually = false;
      scope.saveNewDoctor = () => {
        if (scope.newDoctorsData.first_name === '' || scope.newDoctorsData.last_name === '') {
          alert('First name & last name is required!');
          return false;
        }
        ConfirmBoxService.open('confirmation', 'Add this doctor as a Primary Doctor or Specialist Doctor', {
          Add_Physician: () => {
            if (scope.is_primary_assigned === false) {
              scope.newDoctorsData.is_primary = 'yes';
              AuthService.sendRequest(
                `${$rootScope.api05DomainPath}individual/add-doctor`,
                'POST',
                scope.newDoctorsData,
                (response) => {
                  if (response.data.result.success === true) {
                    $scope.model.primaryPhysicians.push(response.data.result.doctorsData[0]);
                    $scope.model.selectedPhysiciansNew.push(response.data.result.doctorsData[0]);
                  }
                  alert(response.data.result.message);
                },
              );
            } else {
              alert('Primary Pysician is already added.');
            }
            scope.newDoctorsData = {
              suffix: '',
              first_name: '',
              last_name: '',
              speciality: '',
              custom_doctor: true,
              individual_id: $scope.model.individualID,
            };
          },
          Add_Specialist: () => {
            scope.newDoctorsData.is_primary = 'no';
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/add-doctor`,
              'POST',
              scope.newDoctorsData,
              (response) => {
                if (response.data.result.success === true) {
                  $scope.model.specialists.push(response.data.result.doctorsData[0]);
                  $scope.model.selectedPhysiciansNew.push(response.data.result.doctorsData[0]);
                }
                alert(response.data.result.message);
              },
            );
            scope.newDoctorsData = {
              suffix: '',
              first_name: '',
              last_name: '',
              speciality: '',
              custom_doctor: true,
              individual_id: $scope.model.individualID,
            };
          },
          Cancel: () => {},
        });
      };

      scope.googleMapInit = () => {
        const address = $rootScope.gmapData.address;
        const zip5 = $rootScope.gmapData.city.zip5;
        const city = $rootScope.gmapData.city.city;
        const state = $rootScope.gmapData.city.state;
        scope.gmap = {
          address: `${address} ${city}, ${state} ${zip5}`,
        };
        AuthService.XMLHttpRequest(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address} ${city}, ${zip5}&key=AIzaSyARi2o9Tyhk2FFpUdRuoQ-dhtcfYvH6_I8`,
          'get',
          {},
          (res) => {
            initMap(res);
          },
        );
        function initMap(r) {
          if (r.data.results) {
            const lat = r.data.results[0].geometry.location.lat;
            const lng = r.data.results[0].geometry.location.lng;
            const myLatLng = { lat: lat, lng: lng };
            const map = new google.maps.Map(document.getElementById('gmap'), {
              zoom: 14,
              center: myLatLng,
            });
            const _marker = new google.maps.Marker({
              position: myLatLng,
              map: map,
              title: 'Hello World!',
            });
          }
        }
      };
      scope.is_primary_assigned = false;
      scope.getStateCountyCity = (_t, k) => {
        if (typeof scope.multipleAddress[k].zip5 === 'undefined') {
          return false;
        }
        if (scope.multipleAddress[k].zip5.length <= 4) {
          return false;
        }
        const data = {};
        data.zip = angular.copy(scope.multipleAddress[k].zip5);
        AuthService.sendRequest(`${$rootScope.api05DomainPath}getAddressInfo`, 'POST', data, (response) => {
          if (response.data.status === 200) {
            scope.multipleAddressDataResponse = response.data.addressData[0];
            const state = angular.copy(scope.multipleAddressDataResponse);
            const county = angular.copy(scope.multipleAddressDataResponse);
            const city = angular.copy(response.data.cities[0]);
            scope.multipleAddress[k].state_fips = angular.copy(scope.multipleAddressDataResponse);
            scope.onStateChange(state, k);
            scope.multipleAddress[k].county_fips = county;
            scope.multipleAddress[k].city = city;

            /*if(response.data.cities.length != 0){
           scope.multipleAddress[k].city = angular.copy(response.data.cities[0].city);
           }*/

            scope.multipleAddress[k].counties = angular.copy(response.data.addressData);
            scope.multipleAddress[k].cities = angular.copy(response.data.cities);
            scope.model.counties = angular.copy(response.data.addressData);
          } else {
            scope.multipleAddress[k].county_fips = {};
            scope.multipleAddress[k].city = {};
            scope.multipleAddress[k].state_fips = {};
          }
        });

        return false;
      };
      scope.constants = {
        DB: angular.copy($rootScope.databaseData),
      };

      scope.isAddressModal = false;
      scope.multipleAddress = {};
      scope.primaryAddress = '';
      const addressTypes = ['Home', 'Business', 'Billing', 'Mailing', 'POA'];
      for (const i in addressTypes) {
        scope.multipleAddress[addressTypes[i]] = {
          type: addressTypes[i],
          state_fips: '',
          county_fips: '',
          counties: [],
          address_type: addressTypes[i],
          address_primary: addressTypes[i] === 'home_address' ? 'true' : false,
          address: '',
          address_2: '',
          zip5: '',
          zip4: '',
          city: '',
        };
        if (scope.multipleAddress[addressTypes[i]] === 'true') {
          scope.primaryAddress = addressTypes[i];
        }
      }

      scope.changePrimaryAddress = (k, _d) => {
        if (scope.primaryAddress !== '' && k !== scope.primaryAddress) {
          ConfirmBoxService.open(
            'Alert',
            `"${scope.primaryAddress}" is already selected as a primary address! Do you really want to change primary address from "${scope.primaryAddress}" to "${k}"?`,
            {
              Yes: () => {
                for (const i in scope.multipleAddress) {
                  const d = scope.multipleAddress[i];
                  if (d.address_type !== k) {
                    scope.multipleAddress[i].address_primary = false;
                  }
                }
              },
              NO: () => {
                for (const i in scope.multipleAddress) {
                  const d = scope.multipleAddress[i];
                  if (d.address_type !== scope.primaryAddress) {
                    scope.multipleAddress[i].address_primary = false;
                  } else {
                    scope.multipleAddress[i].address_primary = 'true';
                  }
                }
              },
            },
          );
        } else {
          scope.primaryAddress = k;
        }
      };

      scope.addressEditMode = () => {
        scope.isAddressModal = true;
        for (const i in scope.multipleAddress) {
          const addr = scope.multipleAddress[i];
          if (typeof $scope.model.individualAddress !== 'undefined') {
            if (typeof $scope.model.individualAddress[addr.type] !== 'undefined') {
              const addrCompare = $scope.model.individualAddress[addr.type];
              addrCompare.address_primary =
                addrCompare.address_primary === 'true' || addrCompare.address_primary === true ? 'true' : false;
              if (addrCompare.address_primary === 'true') {
                scope.primaryAddress = addr.type;
              }
              scope.multipleAddress[addr.type] = angular.copy(addrCompare);
            }
          }
        }
      };

      scope.openConfirmBox = (doctor) => {
        const params = {
          individual_id: $scope.model.individualID,
          hipaaspace_npi: doctor.npi,
        };
        ConfirmBoxService.open('confirmation', 'Add this doctor as a Primary Doctor or Specialist Doctor', {
          Add_Physician: () => {
            if (scope.is_primary_assigned === false) {
              params.is_primary = 'yes';
              AuthService.sendRequest(
                `${$rootScope.api05DomainPath}individual/add-doctor`,
                'POST',
                params,
                (response) => {
                  if (response.data.result.success === true) {
                    $scope.model.primaryPhysicians.push(doctor);
                    $scope.model.selectedPhysiciansNew.push(doctor);
                  }
                  alert(response.data.result.message);
                },
              );
            } else {
              alert('Primary Pysician is already added.');
            }
          },
          Add_Specialist: () => {
            params.is_primary = 'no';
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/add-doctor`,
              'POST',
              params,
              (response) => {
                if (response.data.result.success === true) {
                  $scope.model.specialists.push(doctor);
                  $scope.model.selectedPhysiciansNew.push(doctor);
                }
                alert(response.data.result.message);
              },
            );
          },
          Cancel: () => {},
        });
      };

      scope.pastePhone = (e, m) => {
        let phoneNumber = e.originalEvent.clipboardData.getData('text/plain');
        phoneNumber = phoneNumber ? phoneNumber.replace(/[^\d]/g, '') : null;
        if (phoneNumber.length !== 10 && phoneNumber.length !== 11) {
          scope.model.addForm[m] = '';
          alert('Invalid Phone Number');
          return false;
        }
        let ph = '';
        if (phoneNumber.length === 11) {
          ph = phoneNumber;
          ph = ph.substring(1, ph.length);
        }
        scope.model.addForm[m] = ph;
      };

      if ($stateParams.type === 'add') {
        scope.model = $scope.model;
        scope.model.addForm = $scope.model.addForm;
      } else {
        scope.model = $scope.model;
        scope.model.addForm = $scope.model.editForm;
      }

      scope.model.oldValues = angular.copy(scope.model.addForm);

      scope.hide = () => {
        $mdDialog.hide();
      };
      scope.cancel = () => {
        scope.model.addForm = angular.copy(scope.model.oldValues);
        $scope.model.addForm = angular.copy(scope.model.oldValues);
        $scope.model.editForm = angular.copy(scope.model.oldValues);
        $mdDialog.cancel();
      };
      scope.answer = (answer) => {
        if (scope.isAddressModal === true) {
          const d = {};
          for (const i in scope.multipleAddress) {
            const addr = scope.multipleAddress[i];
            addr.primary_address = addr.primary_address === 'true' ? 'true' : 'false';
            d[addr.type] = addr;
          }
          $scope.model.individualAddress = angular.copy(d);
        }
        $mdDialog.hide(answer);
      };
      scope.onStateChange = (i, k) => {
        scope.multipleAddress[k].county_fips = '';
        scope.multipleAddress[k].city = '';

        //$rootScope.constants.DB.counties = scope.constants.DB.counties;
        scope.model.counties = angular.copy(scope.constants.DB.counties[i.state_fips]);
        scope.multipleAddress[k].counties = angular.copy(scope.model.counties);

        /*var county_fips = angular.copy(scope.multipleAddress[k].counties[0]);
       scope.multipleAddress[k].county_fips = county_fips;

       $scope.assign_cities(county_fips,k);*/
      };
      scope.onCountryChange = (county, k) => {
        $scope.assign_cities(county, k);
      };

      $scope.assign_cities = (county, k) => {
        const params = { state_county_fips: county.state_county_fips };
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/get-cities`, 'POST', params, (response) => {
          scope.multipleAddress[k].cities = response.data.cities;

          //Select first one by default
          const city = angular.copy(scope.multipleAddress[k].cities[0]);
          scope.multipleAddress[k].city = city;
        });
      };
      scope.saveAddressInfo = () => {
        const d = {};
        for (const i in scope.multipleAddress) {
          const addr = scope.multipleAddress[i];
          addr.primary_address = addr.primary_address === 'true' ? 'true' : 'false';
          d[addr.type] = addr;
        }
        const data = {};
        data.addressDetail = angular.copy(d);
        $scope.model.individualAddress = data.addressDetail;
        if ($stateParams.type === 'add') {
          $mdDialog.hide('');
          return false;
        }

        data.individual_id = $rootScope.individualID;
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/updateAddress/${$rootScope.individualID}`,
          'POST',
          data,
          (_response) => {
            //$state.reload();
            scope.getAddressInformation(data.individual_id);
            $mdDialog.hide('');
          },
        );
      };

      scope.getAddressInformation = (id) => {
        $scope.model.addForm.firstStep = 'No';
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/get/${id}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.model.individualAddress = response.data.individualAddress;
          },
        );
      };

      $scope.validateAddressInfo = () => {
        if ($stateParams.type === 'add') {
          for (const addrType in scope.multipleAddress) {
            const addr = scope.multipleAddress[addrType];
            if (addr.address !== '' && addr.address_2 !== '' && addr.zip5 !== 'undefined' && addr.county_fips !== '') {
              return true;
            }
          }
        }
        return false;
      };


      //New Physician/Specialist work
      scope.searchDoctors = () => {
        getDoctorResults(1, 'search');
      };

      //Doctors pagination
      /*scope.pageChangedDoctor = function (newPage) {
      getDoctorResults(newPage, 'pagination');
    };*/

      $scope.$watch('model.paginationCurrentDoctor', (num) => {
        $scope.model.paginationCurrentDoctor = num;
        getDoctorResults(num, 'pagination');
      });

      function getDoctorResults(pageNumber, type) {
        const params = {
          individual_id: $scope.model.individualID,
          page: pageNumber,
          doctorTotal: $scope.model.doctorTotal,
        };

        if (type === 'search') {
          params.count_req = true;
        } else {
          params.count_req = false;
        }

        if ($scope.model.searchDoctor !== '') {
          params.search = $scope.model.searchDoctor;
          params.zip5 = $scope.model.zip5;
          params.state = $scope.model.state;
          params.city = $scope.model.city;

          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/search-doctor`,
            'POST',
            params,
            (response) => {
              if (type === 'search') {
                $scope.model.paginationCurrentDoctor = 1;
              }

              if (response.data.doctors_physicians.length > 0) {
                scope.doctorsData = response.data.doctors_physicians;
              } else {
                scope.doctorsData = [];
              }
              $scope.model.doctorTotal = response.data.doctors_physicians_count;
              $scope.model.doctorPerPage = response.data.doctorsPerPage;
            },
          );
        }
      }
    }

    $scope.addOccupation = (ev) => {
      $mdDialog
        .show({
          controller: OccupationController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/add-occupation-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    function OccupationController(scope, $mdDialog) {
      scope.model = {
        occupations: [],
        occupation_name: '',
      };

      scope.showRoutings = (b) => {
        for (const i in scope.model.occupations) {
          scope.model.occupations[i].status = undefined;
        }
        b.status = 1;
        scope.model.routings = b;
      };

      scope.saveNewOccupation = () => {
        const occupation = scope.model.occupation_name;
        scope.model.occupation_name = '';
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}occupations`,
          'POST',
          { occupation_name: occupation },
          (response) => {
            response.data.data.occupation.routing = [];
            scope.model.routings = response.data.data.occupation;
            loadOccupationsData();
          },
        );
      };

      scope.deleteOccupation = (b) => {
        ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}occupations/delete`,
              'POST',
              {
                id: b.id,
              },
              (_response) => {
                loadOccupationsData();
              },
            );
          },
          No: () => {},
        });
      };

      scope.cancel = () => {
        $mdDialog.cancel();
        $scope.loadAgencyData();
      };
      function loadOccupationsData() {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}occupations`, 'GET', {}, (response) => {
          scope.model.occupations = response.data.data.occupations;
        });
      }
      loadOccupationsData();
    }

    let _setTimeoutAddress;
    $scope.permanentDeleteIndividual = (i) => {
      const userData = AuthService.getAuthServiceData().userData;
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this individual!',
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = {};
            postData.password = model.password;
            postData.individualID = $scope.model.listingData[i].id;
            postData.email = userData.email;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/permanentDelete/${userData.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  $scope.model.listingData.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = 'Invalid Password!';
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    $scope.showDatePickerCalendar = (t) => {
      $scope.model[t] = true;
    };

    $scope.updateAdditionalPrescriptions = (i, t) => {
      if (t === 'presTaken') {
        $scope.model.prescriptionsTakeFields.splice(i, 1);
        $scope.model.prescriptionsTakeData.splice(i, 1);
        const val = Number($scope.model.intakeSheetAdd.prescriptions_taken.value) - 1;
        if (val === 0) {
          $scope.model.intakeSheetAdd.prescriptions_taken = {
            value: '',
            title: 'Not Applicable...',
          };
          //$scope.onPrescriptionSelected($scope.model.intakeSheetAdd.prescriptions_taken)
        } else {
          $scope.model.intakeSheetAdd.prescriptions_taken = {
            value: val,
            title: val,
          };
        }
      } else if (t === 'add') {
        $scope.model.additionalprescriptionsFields.push({
          id: $scope.model.additionalprescriptionsFields.length + 1,
        });
      } else {
        $scope.model.additionalprescriptionsFields.splice(i, 1);
        $scope.model.additionalPrescriptionsTakeData.splice(i, 1);
      }
    };

    $scope.onChangeDOB = (a, b, _ele) => {
      if (b == null || b === '' || typeof b === 'undefined' || b === 'Invalid Date') {
        return false;
      }
      b = b.replace(/-/g, '/'); // replaces all occurances of "-" with "/"
      const _dateObject = MomentService.convertTimeZone(b);
      const birthday = MomentService.convertTimeZone(b);
      const ageDifMs = MomentService.getAgeDiffMs(birthday); // miliseconds from epoch
      const ageDate = MomentService.convertTimeZone(ageDifMs);
      const c = Math.abs(ageDate.getUTCFullYear() - 1970);

      if (typeof b !== 'undefined' && b != null && b !== '' && b !== 'Invalid Date') {
        const splittedVal = b.split('/');
        const mm = splittedVal[0];
        const dd = splittedVal[1];
        const yyyy = splittedVal[2];
        if (Number(mm) > 12) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.addForm.date_of_birth = '';
            $scope.model.editForm.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
        if (Number(dd) > 31) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.addForm.date_of_birth = '';
            $scope.model.editForm.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
        if (Number(yyyy) > birthday.getFullYear()) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.addForm.date_of_birth = '';
            $scope.model.editForm.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
      }

      if (a) {
        const _monthNumber = birthday.getMonth() + 1;
        $scope.model.intakeSheetAdd.drug_id_password = `${
          (birthday.getMonth() + 1).toString().length === 1 ? `0${birthday.getMonth() + 1}` : birthday.getMonth() + 1
        }${birthday.getDate() > 9 ? birthday.getDate() : `0${birthday.getDate()}`}${birthday.getFullYear()}`;
        $scope.model.addForm.age = c;
        $scope.model.editForm.age = c;
      } else {
        $scope.model.spouseData.age = c;
      }
    };
    $scope.changeAARPMember = (v) => {
      if (v.value === 'yes') {
        $scope.model.aarpNumber = true;
      } else {
        $scope.model.aarpNumber = false;
      }
    };
    $scope.onSelectSpouseCurrentClient = (i) => {
      if (i.value !== '') {
        if (i.value === 'yes') {
          $scope.model.spouseCurrentClient = true;
        } else {
          $scope.model.spouseCurrentClient = false;
        }

        $scope.spouseEditable(i);
      }
    };
    $scope.onSelectPacenet = (i) => {
      if (i.value === 'PACENET of PA') {
        $scope.model.prescriptionPacenetPA = true;
      } else {
        $scope.model.prescriptionPacenetPA = false;
      }
    };
    $scope.onSelectPrescription = (i) => {
      populateCurrentPrescriptionData(i);
    };

    $scope.onPrescriptionSelected = (d) => {
      const count = d.value;
      if ($scope.model.intakeSheetAdd.prescriptions_taken.value === '') {
        $scope.model.prescriptionsTaken = false;
      } else {
        $scope.model.prescriptionsTakeFields = [];
        for (let i = 1; i <= count; i++) {
          $scope.model.prescriptionsTakeFields.push({ id: i });
        }
        $scope.model.prescriptionsTaken = true;
      }
    };
    $scope.onManagerApproval = (i) => {
      if (i.value === 'yes') {
        $scope.model.approvedInd = true;
      } else {
        $scope.model.approvedInd = false;
      }
    };

    $scope.loadAgencyData = () => {
      LoaderService.show();
      if ($stateParams.type === 'add') {
        if ($stateParams.ssn !== '') {
          $rootScope.gotoNextStep = undefined;
          $scope.model.addForm.social_security_no = $stateParams.ssn;
        }
        loadOfficeLocations();
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/getFormData`, 'POST', {}, (response) => {
          $scope.addAgencyForm(response);
          $scope.$watch('model.intakeSheetAdd.approved_by_manager', () => {
            if (typeof $scope.model.intakeSheetAdd.approved_by_manager !== 'undefined') {
              if ($scope.model.intakeSheetAdd.approved_by_manager.value === 'yes') {
                $scope.model.approvedInd = true;
              } else {
                $scope.model.approvedInd = false;
              }
            }
          });
        });
      } else if ($scope.model.individualID == null) {
        $rootScope.gotoNextStep = undefined;
        if ($stateParams.type === 'listingArchive') {
          getIndividualResults(1);
        } else {
          /*AuthService.sendRequest($rootScope.api05DomainPath + 'individual/getFormData', "POST", {}, function (response) {
          $scope.addAgencyForm(response);
        });*/
          getIndividualResults(1);
        }
      } else {
        if ($stateParams.type === 'view-relations-spouse') {
          $rootScope.subMenuActive = 'relations-spouse';
        }
        LoaderService.show();
        loadOfficeLocations();
        $scope.model.addForm.firstStep = $rootScope.gotoNextStep === false ? 'No' : 'Yes';
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/get/${$scope.model.individualID}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.addAgencyForm(response);
            $scope.editIndividual(response);
            LoaderService.show();

            //Store Individual info
            /*var merged_ind_add = angular.extend({}, response.data.individualData, response.data.individualAddress.Home);
            IndividualInfoService.setInfo(merged_ind_add);
            $scope.model.individual_info = merged_ind_add;*/

            $timeout(() => {
              const merged_ind_add = angular.extend(
                {},
                response.data.individualData,
                response.data.individualAddress.Home,
              );
              IndividualInfoService.setInfo(merged_ind_add);
              $scope.model.individual_info = merged_ind_add;
              LoaderService.hide();
            }, 2000);
          },
        );
      }
    };

    $scope.uploadFileMulti = (files) => {
      const form = new FormData();
      for (let i = 0; i < files.length; i++) {
        form.append('file[]', files[i]);
      }
      form.append('individual_id', $scope.model.editForm.id);
      form.append('category', 'idCardLicense');
      form.append('module_id', '1');
      AuthService.uploadFile(`${$rootScope.api05DomainPath}individual/file/upload`, form, (response) => {
        $scope.model.files.newFile = [];
        $scope.model.files.data.push(response.data);
      });
    };
    $scope.deleteFile = (f, index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this file?!', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/deleteAttachment/${$scope.model.editForm.id}`,
            'POST',
            { id: f.id, file_type: 'multiple' },
            (_response) => {
              $scope.model.files.data.splice(index, 1);
            },
          );
        },
        No: () => {},
      });
    };

    if ($stateParams.type !== 'add') {
      /*LOAD DATA*/
      if (typeof $stateParams.individualID !== 'undefined') {
        $scope.model.individualID = $stateParams.individualID;
        $scope.individualID = $stateParams.individualID;
        $rootScope.individualID = $stateParams.individualID;
      }
    }

    $scope.loadAgencyData();
    $scope.addAgencyForm = (response) => {
      LoaderService.hide();
      $rootScope.constants.DB = response.data;
      $rootScope.databaseData = response.data;

      if ($scope.model.addForm.agent_id === undefined) {
        for (const agent of $rootScope.databaseData.agentsData) {
          if (agent.id === $rootScope.loggedInUserData.id) {
            $scope.model.addForm.agent_id = agent;
          }
        }
      }

      if (response.data.hasOwnProperty('individualTypes') && response.data.individualTypes.length !== 0) {
        $scope.model.addForm.individual_type_id = response.data.individualTypes[0];
      }
    };
    $scope.onStateChange = (i) => {
      $scope.model.counties = $rootScope.constants.DB.counties[i.id];
    };
    $scope.openLeadDatepicker = () => {
      $scope.model.leadDatePicker = true;
    };
    $scope.setListing = (res) => {
      $scope.model.listingData = res.data.individualData;
      LoaderService.hide();
    };

    $scope.showHideSpouseSection = (v) => {
      if (v.value === 'Married') {
        $scope.model.showSpouseSection = true;
      } else {
        $scope.model.showSpouseSection = false;
      }
    };

    $scope.checkCostObjectExists = (costObject) => {
      if (typeof costObject !== 'undefined' && costObject !== '') {
        let o = costObject.toString();
        o = o.replace(/(\$)/, '');
        o = o.replace(/(\,)/g, '');
        return o;
      }
      return '';
    };

    $scope.submitAddForm = (closeForm, nextStep) => {
      if (!$scope.formValidated($scope.model.addForm)) {
        ConfirmBoxService.open('Error', 'Highlighted Input Fields Are Required!', {
          OK: () => {},
        });
        return false;
      }
      const userData = Object.assign({}, $scope.model.addForm);
      userData.no_email_address = userData.no_email_address === true ? 1 : 0;
      const dataObject = {
        userData: userData,
        intakeData: $scope.model.intakeSheetAdd,
        physicians: [],
        prescriptions: [],
        prescriptionsData: $scope.model.prescriptionsTakeData,
        addressDetail: $scope.model.individualAddress,
        additionalPrescriptionsData: $scope.model.additionalPrescriptionsTakeData,
      };
      dataObject.userData.date_of_birth = dateFormatterYMD(dataObject.userData.date_of_birth);
      dataObject.userData.lead_date = dateFormatterYMD(dataObject.userData.lead_date);
      // if (dataObject.userData.email === '' || $scope.model.dropdown.emailProviders.searchText === '') {
      //   dataObject.userData.email = '';
      // } else {
      //   dataObject.userData.email = `${dataObject.userData.email}@${$scope.model.dropdown.emailProviders.searchText}`;
      // }
      if (dataObject.userData.email === '') {
        dataObject.userData.email = '';
      }
      dataObject.userData = HelpersService.assignDropDownValues(dataObject.userData);

      dataObject.intakeData.current_cost = $scope.checkCostObjectExists(dataObject.intakeData.current_cost);
      dataObject.intakeData.spouse_current_cost = $scope.checkCostObjectExists(
        dataObject.intakeData.spouse_current_cost,
      );

      dataObject.intakeData.deceased_date = dateFormatterYMD(dataObject.intakeData.deceased_date);
      dataObject.intakeData.intake_date = dateFormatterYMD(dataObject.intakeData.intake_date);
      dataObject.intakeData.part_a_eff_date = dateFormatterYMD(dataObject.intakeData.part_a_eff_date);
      dataObject.intakeData.part_b_eff_date = dateFormatterYMD(dataObject.intakeData.part_b_eff_date);
      dataObject.intakeData.spouse_date_of_birth = dateFormatterYMD(dataObject.intakeData.spouse_date_of_birth);
      dataObject.intakeData.spouse_part_a_eff_date = dateFormatterYMD(dataObject.intakeData.spouse_part_a_eff_date);
      dataObject.intakeData.spouse_part_b_eff_date = dateFormatterYMD(dataObject.intakeData.spouse_part_b_eff_date);
      dataObject.intakeData.spouse_ss_disability_date = dateFormatterYMD(
        dataObject.intakeData.spouse_ss_disability_date,
      );
      dataObject.intakeData.ss_disability_date = dateFormatterYMD(dataObject.intakeData.ss_disability_date);
      if (dataObject.intakeData.current_prescription_drug_plan != null) {
        if (typeof dataObject.intakeData.current_prescription_drug_plan.value !== 'undefined') {
          dataObject.intakeData.current_prescription_drug_plan =
            dataObject.intakeData.current_prescription_drug_plan.value;
        }
      }
      dataObject.intakeData = HelpersService.assignDropDownValues(dataObject.intakeData);

      /*PHYSICIANS*/
      if ($scope.model.primaryPhysician != null && typeof $scope.model.primaryPhysician.first_name !== 'undefined') {
        if (typeof $scope.model.primaryPhysician === 'undefined') {
        } else if (
          typeof $scope.model.primaryPhysician.suffix === 'undefined' ||
          $scope.model.primaryPhysician.suffix !== ''
        ) {
          //return false;
        } else {
          dataObject.physicians.push(HelpersService.assignDropDownValues($scope.model.primaryPhysician, 'value'));
          if (dataObject.physicians[0].suffix !== '') {
            dataObject.physicians[0].suffix = HelpersService.ucfirst(dataObject.physicians[0].suffix);
          }
          dataObject.physicians[0].is_primary = 'yes';
          dataObject.physicians[0].individual_id = dataObject.userData.id;
        }
      }

      /*SPECIALISTS*/
      if ($scope.model.primaryPhysicians != null) {
        for (const i in $scope.model.primaryPhysicians) {
          if (
            typeof $scope.model.primaryPhysicians[i].first_name !== '' &&
            typeof $scope.model.primaryPhysicians[i].first_name !== 'undefined'
          ) {
            const d = HelpersService.assignDropDownValues($scope.model.primaryPhysicians[i], 'value');
            if (d.suffix === '' || typeof d.suffix === 'undefined') {
              //return false;
            } else {
              if (d.suffix !== '') {
                d.suffix = HelpersService.ucfirst(d.suffix);
              }
              d.is_primary = 'no';
              d.individual_id = dataObject.userData.id;
              dataObject.physicians.push(d);
            }
          }
        }
      }
      /*prescriptionsData*/

      if (dataObject.prescriptionsData != null) {
        for (const i in dataObject.prescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.prescriptionsData[i]);
          if (d.suffix === '' && typeof d.suffix === 'undefined') {
            break;
          }
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'no',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }
      if (dataObject.additionalPrescriptionsData != null) {
        for (const i in dataObject.additionalPrescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.additionalPrescriptionsData[i]);
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'yes',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }

      if (
        dataObject.intakeData.appointment_date_time !== '' &&
        typeof dataObject.intakeData.appointment_date_time !== 'undefined'
      ) {
        dataObject.intakeData.appointment_date_time = MomentService.convertTimeZone(
          dataObject.intakeData.appointment_date_time,
        );
        const dt = dataObject.intakeData.appointment_date_time;
        const newDateTime = `${dateFormatterYMD(dt)} ${dt.getHours()}:${dt.getMinutes()}:00`;
        dataObject.intakeData.appointment_date_time = newDateTime;
      }
      LoaderService.show();

      if (dataObject.userData.newsletter_subscription !== true) {
        dataObject.userData.newsletter_subscription = false;
      }

      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/add`, 'POST', dataObject, (response) => {
        if (closeForm === true) {
          $state.go('/individuals');
        } else {
          if (nextStep === true) {
            if (response.data.ab_error_status === true) {
              alert(response.data.ab_message);
            }

            //window.location = "#" + '/individuals/edit/' + response.data.individual_id;
            ConfirmBoxService.open('Alert', 'Information has been Addedd successfully!', {
              Allocation_Sheet: () => {
                window.location = `#/individuals/${response.data.individual_id}/allocation-sheet/view`;
              },
              Medical_Intake: () => {
                //$rootScope.gotoNextStep = true;
                window.location = `#/individuals/edit/${response.data.individual_id}`;
              },
            });
          } else {
            ConfirmBoxService.open('Alert', 'Information has been Addedd successfully!', {
              Allocation_Sheet: () => {
                window.location = `#/individuals/${response.data.individual_id}/allocation-sheet/view`;
              },
              Medical_Intake: () => {
                //$rootScope.gotoNextStep = true;
                window.location = `#/individuals/edit/${response.data.individual_id}`;
              },
            });
          }
        }
        LoaderService.hide();
      });
    };

    $scope.editIndividual = (res) => {
      if (res.data.hasOwnProperty('carriers') && res.data.carriers.length !== 0) {
        $rootScope.constants.DB.carriers = res.data.carriers;
      }
      if (res.data.hasOwnProperty('files') && res.data.files.length !== 0) {
        $scope.model.files.data = res.data.files;
      }

      if (res.data.hasOwnProperty('medical_specialties') && res.data.medical_specialties.length !== 0) {
        $rootScope.constants.DB.medical_specialties = res.data.medical_specialties;
      }
      if (res.data.hasOwnProperty('drugsInfo') && res.data.drugsInfo.length !== 0) {
        $rootScope.constants.dropdowns.drugsInfo.drugs = res.data.ndc_drugs;
      }

      $scope.model.individualAddress = res.data.individualAddress;
      $scope.model.editForm = res.data.individualData;
      $scope.model.clonedData = angular.copy(res.data);

      if (!$scope.model.editForm.suffix_name) {
        $scope.model.editForm.suffix_name = $rootScope.constants.dropdowns.suffix[0];
      }

      $scope.model.editForm.no_email_address = res.data.individualData.no_email_address === 1 ? true : false;

      $scope.model.editForm.disability = res.data.individualData.disability;

      //if($rootScope.gotoNextStep != false){}
      if ($scope.model.editForm.newsletter_subscription === 'true') {
        $scope.model.editForm.newsletter_subscription = true;
      }
      $scope.model.editForm.date_of_birth =
        res.data.individualData.date_of_birth === 'null' || res.data.individualData.date_of_birth == null
          ? ''
          : dateFormatterMDY(res.data.individualData.date_of_birth);
      $scope.model.editForm.lead_date =
        res.data.individualData.lead_date === 'null' || res.data.individualData.lead_date == null
          ? ''
          : MomentService.convertTimeZone(res.data.individualData.lead_date);

      $scope.showHideSpouseSection($scope.model.editForm.martial_status);

      if ($scope.model.editForm.date_of_birth !== '') {
        $scope.onChangeDOB(true, $scope.model.editForm.date_of_birth);
      }

      if ($scope.model.editForm.state_id) {
        $scope.onStateChange($scope.model.editForm.state_id);
      }
      $scope.model.intakeSheetAdd = res.data.individual_intake;
      $scope.onSelectSpouseCurrentClient({
        title: $scope.model.intakeSheetAdd.spouse_current_client,
        value: $scope.model.intakeSheetAdd.spouse_current_client,
      });

      /*EMAIL FORMATTING*/
      // if ($scope.model.editForm.email !== '') {
      //   const emailAddress = $scope.model.editForm.email;
      //   const attrs = emailAddress.split('@');
      //   $scope.model.dropdown.emailProviders.searchText = attrs[1];
      //   $scope.model.editForm.email = attrs[0];
      // }

      $scope.model.intakeSheetAdd.appointment_date_time =
        $scope.model.intakeSheetAdd.appointment_date_time === 'null' ||
        $scope.model.intakeSheetAdd.appointment_date_time == null ||
        $scope.model.intakeSheetAdd.appointment_date_time === ''
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.appointment_date_time);

      $scope.model.intakeSheetAdd.deceased_date =
        $scope.model.intakeSheetAdd.deceased_date === 'null' ||
        $scope.model.intakeSheetAdd.deceased_date == null ||
        $scope.model.intakeSheetAdd.deceased_date === '0000-00-00'
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.deceased_date);
      $scope.model.intakeSheetAdd.intake_date =
        $scope.model.intakeSheetAdd.intake_date === 'null' ||
        $scope.model.intakeSheetAdd.intake_date == null ||
        $scope.model.intakeSheetAdd.intake_date === ''
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.intake_date);
      $scope.model.intakeSheetAdd.part_a_eff_date =
        $scope.model.intakeSheetAdd.part_a_eff_date === 'null' || $scope.model.intakeSheetAdd.part_a_eff_date == null
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.part_a_eff_date);
      $scope.model.intakeSheetAdd.part_b_eff_date =
        $scope.model.intakeSheetAdd.part_b_eff_date === 'null' || $scope.model.intakeSheetAdd.part_b_eff_date == null
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.part_b_eff_date);
      $scope.model.intakeSheetAdd.ss_disability_date =
        $scope.model.intakeSheetAdd.ss_disability_date === 'null' ||
        $scope.model.intakeSheetAdd.ss_disability_date == null
          ? ''
          : MomentService.convertTimeZone($scope.model.intakeSheetAdd.ss_disability_date);

      /* $scope.model.intakeSheetAdd.spouse_part_a_eff_date = ($scope.model.intakeSheetAdd.spouse_part_a_eff_date == 'null' || $scope.model.intakeSheetAdd.spouse_part_a_eff_date == null) ? '' : MomentService.convertTimeZone($scope.model.intakeSheetAdd.spouse_part_a_eff_date);
     $scope.model.intakeSheetAdd.spouse_part_b_eff_date = ($scope.model.intakeSheetAdd.spouse_part_b_eff_date == 'null' || $scope.model.intakeSheetAdd.spouse_part_b_eff_date == null) ? '' : MomentService.convertTimeZone($scope.model.intakeSheetAdd.spouse_part_b_eff_date);
     $scope.model.intakeSheetAdd.spouse_ss_disability_date = ($scope.model.intakeSheetAdd.spouse_ss_disability_date == 'null' || $scope.model.intakeSheetAdd.spouse_ss_disability_date == null) ? '' : MomentService.convertTimeZone($scope.model.intakeSheetAdd.spouse_ss_disability_date);
     $scope.model.intakeSheetAdd.spouse_date_of_birth = ($scope.model.intakeSheetAdd.spouse_date_of_birth == 'null' || $scope.model.intakeSheetAdd.spouse_date_of_birth == null) ? '' : MomentService.convertTimeZone($scope.model.intakeSheetAdd.spouse_date_of_birth);

     $scope.model.intakeSheetAdd.spouse_age = $scope.onChangeDOB(false, $scope.model.intakeSheetAdd.spouse_date_of_birth);*/

      if (typeof res.data.spouseData !== 'undefined') {
        $scope.model.spouseData = res.data.spouseData;
        $scope.model.spouseData.date_of_birth =
          res.data.spouseData.date_of_birth === 'null' || res.data.spouseData.date_of_birth == null
            ? ''
            : dateFormatterMDY(res.data.spouseData.date_of_birth);
        if ($scope.model.spouseData.date_of_birth) {
          const birthday = MomentService.convertTimeZone($scope.model.spouseData.date_of_birth);
          const ageDifMs = MomentService.getAgeDiffMs(birthday); // miliseconds from epoch
          const ageDate = MomentService.convertTimeZone(ageDifMs);
          const c = Math.abs(ageDate.getUTCFullYear() - 1970);
          $scope.model.spouseData.age = c;
        }

        $scope.model.spouseData.part_a_eff_date =
          $scope.model.spouseData.part_a_eff_date === 'null' || $scope.model.spouseData.part_a_eff_date == null
            ? ''
            : MomentService.convertTimeZone($scope.model.spouseData.part_a_eff_date);
        $scope.model.spouseData.part_b_eff_date =
          $scope.model.spouseData.part_b_eff_date === 'null' || $scope.model.spouseData.part_b_eff_date == null
            ? ''
            : MomentService.convertTimeZone($scope.model.spouseData.part_b_eff_date);
        $scope.model.spouseData.ss_disability_date =
          $scope.model.spouseData.ss_disability_date === 'null' || $scope.model.spouseData.ss_disability_date == null
            ? ''
            : MomentService.convertTimeZone($scope.model.spouseData.ss_disability_date);

        $scope.model.spouseData.date_of_birth =
          $scope.model.spouseData.date_of_birth === 'null' || $scope.model.spouseData.date_of_birth == null
            ? ''
            : $scope.model.spouseData.date_of_birth;

        $scope.onSelectSpouseCurrentClient({ value: 'yes', title: 'Yes' });
      }

      if ($scope.model.intakeSheetAdd.spouse_current_client != null) {
        if (typeof $scope.model.intakeSheetAdd.spouse_current_client.value !== 'undefined') {
          if ($scope.model.intakeSheetAdd.spouse_current_client.value === 'yes') {
            $scope.model.spouseCurrentClient = true;
          }
        }
      }

      if ($scope.model.intakeSheetAdd.arp_member != null) {
        $scope.changeAARPMember($scope.model.intakeSheetAdd.arp_member);
      }
      if ($scope.model.intakeSheetAdd.additional_drug_help != null) {
        $scope.onSelectPacenet($scope.model.intakeSheetAdd.additional_drug_help);
      }
      if ($scope.model.intakeSheetAdd.prescriptions_taken != null) {
        $scope.onPrescriptionSelected($scope.model.intakeSheetAdd.prescriptions_taken);
      }
      /*        if ($scope.model.intakeSheetAdd.spouse_date_of_birth) {
     var birthday = $scope.model.intakeSheetAdd.spouse_date_of_birth;
     var ageDifMs = Date.now() - birthday.getTime();
     var ageDate = new Date(ageDifMs); // miliseconds from epoch
     var c = Math.abs(ageDate.getUTCFullYear() - 1970);
     $scope.model.intakeSheetAdd.spouse_age = c;
     }*/

      if ($scope.model.intakeSheetAdd.approved_by_manager != null) {
        if (
          $scope.model.approvedInd.manager_approval_date !== '' &&
          $scope.model.intakeSheetAdd.approved_by_manager.value === 'yes'
        ) {
          $scope.model.approvedDateTime = $scope.model.intakeSheetAdd.manager_approval_date;
          $scope.model.approvedInd = true;
        }
      }
      if ($scope.model.intakeSheetAdd.current_ins_co != null) {
        for (const i in $rootScope.constants.dropdowns.currentInsurance) {
          if ($rootScope.constants.dropdowns.currentInsurance[i].value === $scope.model.intakeSheetAdd.current_ins_co) {
            $scope.model.intakeSheetAdd.current_ins_co = $rootScope.constants.dropdowns.currentInsurance[i];
            break;
          }
        }
      }
      if ($scope.model.intakeSheetAdd.spouse_current_ins_co != null) {
        for (const i in $rootScope.constants.dropdowns.currentInsurance) {
          if (
            $rootScope.constants.dropdowns.currentInsurance[i].value ===
            $scope.model.intakeSheetAdd.spouse_current_ins_co
          ) {
            $scope.model.intakeSheetAdd.spouse_current_ins_co = $rootScope.constants.dropdowns.currentInsurance[i];
            break;
          }
        }
      }

      if ($scope.model.intakeSheetAdd.arp_member === 'yes') {
        $scope.model.aarpNumber = true;
      }

      if ($scope.model.intakeSheetAdd.current_prescription_drug_plan != null) {
        const _organization = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'organization',
          $rootScope.databaseData.prescription_drug_plan,
        );
        const _code = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'code',
          $rootScope.databaseData.prescription_drug_plan,
        );
        const _monthly_premium = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'monthly_premium',
          $rootScope.databaseData.prescription_drug_plan,
        );
        const title = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'title',
          $rootScope.databaseData.prescription_drug_plan,
        );
        const id = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'id',
          $rootScope.databaseData.prescription_drug_plan,
        );
        $scope.model.intakeSheetAdd.current_prescription_drug_plan = {
          value: Number(id),
          title: title,
        };
        populateCurrentPrescriptionData($scope.model.intakeSheetAdd.current_prescription_drug_plan);
      }

      if (res.data.hasOwnProperty('primary_physicians') && res.data.primary_physicians.length !== 0) {
        $scope.model.primaryPhysicians = res.data.primary_physicians;
      }
      if (res.data.hasOwnProperty('specialists') && res.data.specialists.length !== 0) {
        $scope.model.specialists = res.data.specialists;
      }
      //Merge primary physicans and specialists
      $scope.model.selectedPhysiciansNew = $scope.model.primaryPhysicians.concat($scope.model.specialists);
      $scope.model.selectedPhysiciansNew.push({ npi: '', title: '-' });
      $scope.model.selectedPhysiciansNew.reverse();

      if (res.data.hasOwnProperty('prescriptions') && res.data.prescriptions.length !== 0) {
        let presIndex = 0;
        let additionalPresIndex = 0;
        for (const i in res.data.prescriptions) {
          const p = res.data.prescriptions[i];

          if (p.is_additional_prescription === 'yes') {
            if (typeof $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] === 'undefined') {
              $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] = {};
            }
            if (additionalPresIndex === 0) {
              $scope.model.additionalprescriptionsFields = [];
            }
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].drug_name = p.drug_name;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].dosage = p.dosage;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].frequency = {
              value: p.frequency,
              title: p.frequency,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].quantity = p.quantity;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].prescribing_doctor = p.prescribing_doctor;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            additionalPresIndex++;
            $scope.model.additionalprescriptionsFields.push({
              id: additionalPresIndex,
              value: additionalPresIndex,
            });
          } else {
            if (typeof $scope.model.prescriptionsTakeData[presIndex] === 'undefined') {
              $scope.model.prescriptionsTakeData[presIndex] = {};
            }
            $scope.model.prescriptionsTakeData[presIndex].drug_name = {
              title: p.drug_name,
              value: p.drug_name,
            }; //p.drug_name;
            $scope.model.prescriptionsTakeData[presIndex].dosage = {
              title: p.dosage,
              value: p.dosage,
            };

            if (typeof $scope.model.dropdown.drugDosage.dosages.dataArray === 'undefined') {
              $scope.model.dropdown.drugDosage.dosages.dataArray = [];
            }
            $scope.model.dropdown.drugDosage.dosages.dataArray[presIndex] = p.dosageOptions;

            $scope.model.prescriptionsTakeData[presIndex].frequency = {
              value: p.frequency,
              title: p.frequency,
            };
            if (typeof p.rxcui !== 'undefined') {
              $scope.model.prescriptionsTakeData[presIndex].rxcui = p.rxcui;
            }

            $scope.model.prescriptionsTakeData[presIndex].quantity = p.quantity;
            $scope.model.prescriptionsTakeData[presIndex].prescribing_doctor = p.prescribing_doctor;
            $scope.model.prescriptionsTakeData[presIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.prescriptionsTakeData[presIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            presIndex++;
          }
        }
      }

      LoaderService.hide();
    };

    const populateCurrentPrescriptionData = (d) => {
      const organization = HelpersService.getValueByKey(
        d.value,
        'id',
        'organization',
        $rootScope.databaseData.prescription_drug_plan,
      );
      const code = HelpersService.getValueByKey(d.value, 'id', 'code', $rootScope.databaseData.prescription_drug_plan);
      const monthly_premium = HelpersService.getValueByKey(
        d.value,
        'id',
        'monthly_premium',
        $rootScope.databaseData.prescription_drug_plan,
      );
      const _title = HelpersService.getValueByKey(
        d.value,
        'id',
        'title',
        $rootScope.databaseData.prescription_drug_plan,
      );
      const _id = HelpersService.getValueByKey(d.value, 'id', 'id', $rootScope.databaseData.prescription_drug_plan);
      $scope.model.currentPrescriptionData.organization = organization;
      $scope.model.currentPrescriptionData.code = code;
      $scope.model.currentPrescriptionData.monthly_premium = monthly_premium;
    };

    const dateFormatterYMD = (date) => {
      if (date === '' || typeof date === 'undefined') {
        return '';
      }
      if (date === 'null' || date == null || date === '') {
        return false;
      }
      date = MomentService.convertTimeZone(date);

      return `${date.getFullYear()}-${
        Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }-${Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()}`;
    };

    const dateFormatterMDY = (d) => {
      if (d === '' || typeof d === 'undefined') {
        return '';
      }
      d = MomentService.convertTimeZone(d);
      const date = d; //new Date(d);
      if (d !== '' && d) {
        return `${Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
          Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()
        }-${date.getFullYear()}`;
      }
      return '';
    };

    MomentService;

    const _convertDateTimeZone = (date) => {
      const d = MomentService.convertTimeZone(date);
      return d;
    };

    $scope.emailFieldValidated = (email) => {
      // Simple regex for email validation
      const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return EMAIL_REGEXP.test(email);
    };

    $scope.fieldValidated = (v) => {
      let validated = true;
      if (v === '' || typeof v === 'undefined' || v == null) {
        validated = false;
      }
      return validated;
    };

    $scope.formValidated = (formData, isEditMode) => {
      let validated = true;
      const _intakeData = $scope.model.intakeSheetAdd;
      if (!$scope.fieldValidated(formData.social_security_no) && !$scope.model.noSSN) {
        FocusHighlightService.show('ssn');
        validated = false;
      } else {
        FocusHighlightService.hide('ssn');
      }

      if (!$scope.fieldValidated(formData.prefix_name)) {
        FocusHighlightService.show('prefix');
        validated = false;
      } else {
        FocusHighlightService.hide('prefix');
      }
      if (!formData.location || !formData.location.hasOwnProperty('value')) {
        FocusHighlightService.show('location');
        validated = false;
      } else {
        FocusHighlightService.hide('location');
      }
      if (!formData.agent_id || !formData.agent_id.hasOwnProperty('id')) {
        FocusHighlightService.show('agent');
        validated = false;
      } else {
        FocusHighlightService.hide('agent');
      }
      if (!formData.lead_id || !formData.lead_id.hasOwnProperty('id')) {
        FocusHighlightService.show('lead-source');
        validated = false;
      } else {
        FocusHighlightService.hide('lead-source');
      }

      if (!$scope.fieldValidated(formData.first_name)) {
        FocusHighlightService.show('first_name');
        validated = false;
      } else {
        FocusHighlightService.hide('first_name');
      }
      if (!$scope.fieldValidated(formData.middle_name)) {
        FocusHighlightService.show('middle_name');
        validated = false;
      } else {
        FocusHighlightService.hide('middle_name');
      }

      if (!$scope.fieldValidated(formData.last_name)) {
        FocusHighlightService.show('last_name');
        validated = false;
      } else {
        FocusHighlightService.hide('last_name');
      }

      if (!$scope.fieldValidated(formData.goes_by)) {
        FocusHighlightService.show('goes_by');
        validated = false;
      } else {
        FocusHighlightService.hide('goes_by');
      }

      if (!$scope.fieldValidated(formData.date_of_birth)) {
        FocusHighlightService.show('date_of_birth');
        validated = false;
      } else {
        FocusHighlightService.hide('date_of_birth');
      }

      if (isEditMode) {
        if (!formData.suffix_name || !formData.suffix_name.hasOwnProperty('value')) {
          FocusHighlightService.show('suffix_name');
          validated = false;
        } else {
          FocusHighlightService.hide('suffix_name');
        }

        if (!formData.martial_status || !formData.martial_status.hasOwnProperty('value')) {
          FocusHighlightService.show('martial_status');
          validated = false;
        } else {
          FocusHighlightService.hide('martial_status');
        }
      }

      if (!$scope.fieldValidated(formData.email) && !formData.no_email_address && !formData.newsletter_subscription) {
        FocusHighlightService.show('email');
        validated = false;
      } else {
        FocusHighlightService.hide('email');
      }
      // if (
      //   !$scope.fieldValidated($scope.model.dropdown.emailProviders.searchText) &&
      //   !formData.no_email_address &&
      //   !formData.newsletter_subscription
      // ) {
      //   FocusHighlightService.show('emailProviders');
      //   validated = false;
      // } else {
      //   FocusHighlightService.hide('emailProviders');
      // }

      if (
        !$scope.fieldValidated(formData.phone_home) &&
        !$scope.fieldValidated(formData.phone_business) &&
        !$scope.fieldValidated(formData.phone_cell) &&
        !$scope.fieldValidated(formData.phone_other)
      ) {
        FocusHighlightService.show('phone-section');
        validated = false;
      } else {
        FocusHighlightService.hide('phone-section');
      }

      return validated;
    };

    $scope.submitEditForm = (closeForm, nextStep) => {
      if (!$scope.formValidated($scope.model.editForm, true)) {
        ConfirmBoxService.open('Error', 'Highlighted Input Fields Are Required!', {
          OK: () => {},
        });
        return false;
      }

      const userData = Object.assign({}, $scope.model.editForm);
      userData.no_email_address = userData.no_email_address === true ? 1 : 0;
      const dataObject = {
        userData: userData,
        spouseData: $scope.model.spouseData,
        intakeData: $scope.model.intakeSheetAdd,
        addressDetail: $scope.model.individualAddress,
        physicians: [],
        prescriptions: [],
        prescriptionsData: $scope.model.prescriptionsTakeData,
        additionalPrescriptionsData: $scope.model.additionalPrescriptionsTakeData,
      };

      dataObject.intakeData.current_cost = $scope.checkCostObjectExists(dataObject.intakeData.current_cost);
      dataObject.intakeData.spouse_current_cost = $scope.checkCostObjectExists(
        dataObject.intakeData.spouse_current_cost,
      );

      dataObject.userData.date_of_birth = dateFormatterYMD(dataObject.userData.date_of_birth);
      dataObject.userData.lead_date = dateFormatterYMD(dataObject.userData.lead_date);
      // if (dataObject.userData.email === '' || $scope.model.dropdown.emailProviders.searchText === '') {
      //   dataObject.userData.email = '';
      // } else {
      //   dataObject.userData.email = `${dataObject.userData.email}@${$scope.model.dropdown.emailProviders.searchText}`;
      // }

      if (dataObject.userData.email === '') {
        dataObject.userData.email = '';
      }
      dataObject.userData = HelpersService.assignDropDownValues(dataObject.userData);

      dataObject.intakeData.deceased_date = dateFormatterYMD(dataObject.intakeData.deceased_date);
      dataObject.intakeData.intake_date = dateFormatterYMD(dataObject.intakeData.intake_date);
      dataObject.intakeData.part_a_eff_date = dateFormatterYMD(dataObject.intakeData.part_a_eff_date);
      dataObject.intakeData.part_b_eff_date = dateFormatterYMD(dataObject.intakeData.part_b_eff_date);
      dataObject.intakeData.ss_disability_date = dateFormatterYMD(dataObject.intakeData.ss_disability_date);

      /*SPOUSE*/
      dataObject.spouseData.date_of_birth = dateFormatterYMD(dataObject.spouseData.date_of_birth);
      dataObject.spouseData.part_a_eff_date = dateFormatterYMD(dataObject.spouseData.part_a_eff_date);
      dataObject.spouseData.part_b_eff_date = dateFormatterYMD(dataObject.spouseData.part_b_eff_date);
      dataObject.spouseData.ss_disability_date = dateFormatterYMD(dataObject.spouseData.ss_disability_date);
      dataObject.spouseData = HelpersService.assignDropDownValues(dataObject.spouseData);
      dataObject.spouseData.current_cost = $scope.checkCostObjectExists(dataObject.spouseData.current_cost);
      if (dataObject.intakeData.current_prescription_drug_plan != null) {
        if (typeof dataObject.intakeData.current_prescription_drug_plan.value !== 'undefined') {
          dataObject.intakeData.current_prescription_drug_plan =
            dataObject.intakeData.current_prescription_drug_plan.value;
        }
      }
      dataObject.intakeData = HelpersService.assignDropDownValues(dataObject.intakeData);

      /*PHYSICIANS*/
      if ($scope.model.primaryPhysician != null && typeof $scope.model.primaryPhysician.first_name !== 'undefined') {
        if (typeof $scope.model.primaryPhysician === 'undefined') {
        } else if (typeof $scope.model.primaryPhysician === 'undefined') {
          //return false;
        } else {
          dataObject.physicians.push(HelpersService.assignDropDownValues($scope.model.primaryPhysician, 'value'));
          dataObject.physicians[0].suffix = HelpersService.ucfirst(dataObject.physicians[0].suffix);
          dataObject.physicians[0].is_primary = 'yes';
          dataObject.physicians[0].individual_id = dataObject.userData.id;
        }
      }

      /*SPECIALISTS*/
      if ($scope.model.primaryPhysicians != null) {
        for (const i in $scope.model.primaryPhysicians) {
          if (
            typeof $scope.model.primaryPhysicians[i].first_name !== '' &&
            typeof $scope.model.primaryPhysicians[i].first_name !== 'undefined'
          ) {
            const d = HelpersService.assignDropDownValues($scope.model.primaryPhysicians[i], 'value');
            if (d.suffix === '' || typeof d.suffix === 'undefined') {
              //return false;
            } else {
              d.suffix = HelpersService.ucfirst(d.suffix);
              d.is_primary = 'no';
              d.individual_id = dataObject.userData.id;
              dataObject.physicians.push(d);
            }
          }
        }
      }
      /*prescriptionsData*/

      if (dataObject.prescriptionsData != null) {
        for (const i in dataObject.prescriptionsData) {
          let d = dataObject.prescriptionsData[i];
          d.prescribing_doctor = typeof d.prescribing_doctor !== 'undefined' ? d.prescribing_doctor.npi : '';
          d = HelpersService.assignDropDownValues(dataObject.prescriptionsData[i]);

          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'no',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }
      if (dataObject.additionalPrescriptionsData != null) {
        for (const i in dataObject.additionalPrescriptionsData) {
          let d = dataObject.additionalPrescriptionsData[i];
          d.prescribing_doctor = typeof d.prescribing_doctor !== 'undefined' ? d.prescribing_doctor.npi : '';
          d = HelpersService.assignDropDownValues(dataObject.additionalPrescriptionsData[i]);
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'yes',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }

      if ($scope.updateSpouseEmailIfSame(dataObject, closeForm, nextStep)) {
        return false;
      }

      LoaderService.show();
      if (
        dataObject.intakeData.appointment_date_time !== '' &&
        typeof dataObject.intakeData.appointment_date_time !== 'undefined'
      ) {
        dataObject.intakeData.appointment_date_time = MomentService.convertTimeZone(
          dataObject.intakeData.appointment_date_time,
        );
        dataObject.intakeData.appointment_date_time = MomentService.convertTimeZone(
          dataObject.intakeData.appointment_date_time,
        );
        const dt = dataObject.intakeData.appointment_date_time;
        const newDateTime = `${dateFormatterYMD(dt)} ${dt.getHours()}:${dt.getMinutes()}:00`;
        dataObject.intakeData.appointment_date_time = newDateTime;
      }

      if (dataObject.userData.newsletter_subscription !== true) {
        dataObject.userData.newsletter_subscription = false;
      }

      dataObject.formStep = $scope.model.wizard.activeTabClass === 'intake' ? 'Yes' : 'No';

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/update/${$scope.model.editForm.id}`,
        'POST',
        dataObject,
        (_response) => {
          if (closeForm === true) {
            $state.go('/individuals');
          } else {
            if (nextStep === true) {
              $scope.model.wizard.activeTabClass = 'intake';
              $timeout(() => {
                $rootScope.gotoNextStep = true;
                $state.reload();
              }, 500);
            } else {
              if ($scope.model.wizard.activeTabClass === 'intake') {
                $rootScope.gotoNextStep = true;
              }
              $state.reload();
              ConfirmBoxService.open('Alert', 'Information has been Updated successfully!', {
                OK: () => {},
              });
            }
          }
          LoaderService.hide();
        },
        () => {
          $state.go('/individuals');
        },
      );
    };
    $scope.deleteIndividual = (i) => {
      const userData = AuthService.getAuthServiceData().userData;
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this individual!',
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = {};
            postData.password = model.password;
            postData.individualID = $scope.model.listingData[i].id;
            postData.email = userData.email;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/delete/${userData.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  ConfirmBoxService.open('Success', 'The client information deleted successfully!', {
                    OK: () => {},
                  });
                  $scope.model.listingData.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = 'Invalid Password!';
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    const physiciansCheck = (c) => {
      let a = [];
      a = (() => $scope.model.primaryPhysicians.slice())();
      a.push($scope.model.primaryPhysician);

      for (const i in $scope.model.prescriptionsTakeData) {
        let notMatched = false;
        const selectedPhysicians = $scope.model.prescriptionsTakeData[i];
        const selectedPhysicianName = selectedPhysicians.prescribing_doctor.title;
        for (const k in a) {
          const physicianData = a[k];
          const physicianName = `${physicianData.suffix.title} ${physicianData.first_name} ${physicianData.last_name}`;
          if (physicianName === selectedPhysicianName) {
            notMatched = true;
          }
        }
        if (notMatched === false) {
          $scope.model.prescriptionsTakeData[i].prescribing_doctor = {
            title: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
            value: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
          };
        }
      }
      for (const i in $scope.model.additionalPrescriptionsTakeData) {
        let notMatched = false;
        const selectedPhysicians = $scope.model.additionalPrescriptionsTakeData[i];
        const selectedPhysicianName = selectedPhysicians.prescribing_doctor.title;
        for (const k in a) {
          const physicianData = a[k];
          const physicianName = `${physicianData.suffix.title} ${physicianData.first_name} ${physicianData.last_name}`;
          if (physicianName === selectedPhysicianName) {
            notMatched = true;
          }
        }
        if (notMatched === false) {
          $scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor = {
            title: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
            value: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
          };
        }
      }
    };
    $scope.changePrimaryPhysiciansCallBack = (a, _b, _c) => {
      physiciansCheck(a);
    };

    /*AUTO COMPLETE*/
    const selfObj = $scope;

    /*emailProviders*/

    selfObj.model.dropdown.emailProviders.simulateQuery = false;
    selfObj.model.dropdown.emailProviders.isDisabled = false;
    selfObj.model.dropdown.emailProviders.repos = (() => {
      const repos = $rootScope.constants.dropdowns.emailProviders;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.emailProviders.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.emailProviders.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.emailProviders.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.emailProviders.selectedItemChange = () => {};
    selfObj.model.dropdown.emailProviders.searchTextChange = (_a, _k) => {};

    /*specialistSpeciality*/
    selfObj.model.dropdown.specialistSpeciality.simulateQuery = false;
    selfObj.model.dropdown.specialistSpeciality.isDisabled = false;
    selfObj.model.dropdown.specialistSpeciality.repos = (() => {
      const repos = $rootScope.constants.dropdowns.specialistSpeciality;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.specialistSpeciality.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.specialistSpeciality.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.specialistSpeciality.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.specialistSpeciality.selectedItemChange = () => {};
    selfObj.model.dropdown.specialistSpeciality.searchTextChange = () => {};

    /*physicianSuffix*/
    selfObj.model.dropdown.physicianSuffix.simulateQuery = false;
    selfObj.model.dropdown.physicianSuffix.isDisabled = false;
    selfObj.model.dropdown.physicianSuffix.repos = (() => {
      const repos = $rootScope.constants.dropdowns.specialistSuffix;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.physicianSuffix.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.physicianSuffix.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.physicianSuffix.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.physicianSuffix.selectedItemChange = () => {};
    selfObj.model.dropdown.physicianSuffix.searchTextChange = () => {};

    /*DRUG NAMES*/
    selfObj.model.dropdown.drugNames.simulateQuery = false;
    selfObj.model.dropdown.drugNames.isDisabled = false;
    selfObj.model.dropdown.drugNames.repos = loadAll();
    selfObj.model.dropdown.drugNames.querySearch = querySearch;
    selfObj.model.dropdown.drugNames.selectedItemChange = selectedItemChange;
    selfObj.model.dropdown.drugNames.searchTextChange = (_a, k) => {
      if (typeof $scope.model.prescriptionsTakeData[k] === 'undefined') {
        $scope.model.prescriptionsTakeData[k] = { dosage: '' };
      } else if (typeof $scope.model.prescriptionsTakeData[k].dosage === 'undefined') {
        $scope.model.prescriptionsTakeData[k].dosage = '';
      } else {
        $scope.model.prescriptionsTakeData[k].dosage = '';
      }
    };
    const self = selfObj.model.dropdown.drugNames;

    /*DRUG DOSAGE*/
    selfObj.model.dropdown.drugDosage.simulateQuery = false;
    selfObj.model.dropdown.drugDosage.isDisabled = false;
    selfObj.model.dropdown.drugDosage.querySearch = (query, k) => {
      const results = query
        ? selfDrugDosage.dosages.dataArray[k].filter(createFilterFor(query))
        : selfDrugDosage.dosages.dataArray[k];
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.drugDosage.selectedItemChange = () => {};
    selfObj.model.dropdown.drugDosage.searchTextChange = searchTextChange;
    let selfDrugDosage = selfObj.model.dropdown.drugDosage;
    selfObj.model.dropdown.drugDosage.dosages = [];
    selfDrugDosage = selfObj.model.dropdown.drugDosage;

    function querySearch(query) {
      const results = query ? self.repos.filter(createFilterFor(query)) : self.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    }

    function searchTextChange(_text) {
      //$log.info('Text changed to ' + text);
    }

    function selectedItemChange(_d, _k) {
      return false;
    }

    function loadAll() {
      const repos = (() => {
        const d = $rootScope.constants.dropdowns.drugsInfo.drugs;
        return d;
      })();
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    }

    function createFilterFor(query) {
      const lowercaseQuery = angular.lowercase(query);
      return function filterFn(item) {
        return item.value.indexOf(lowercaseQuery) === 0;
      };
    }

    function loadAllDosage(a) {
      const repos = ((data) => {
        const d = $rootScope.constants.dropdowns.drugsInfo.dosage[data.id];
        const dArray = [];
        for (const k in d) {
          dArray.push({ title: d[k].title, id: d[k].value });
        }
        return dArray;
      })(a);
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    }

    /*UPDATE SPECIALIST UNDER PRESCRIPTION SECTION*/
    for (const i in $scope.model.primaryPhysician) {
      ((index) => {
        $scope.$watch(`model.primaryPhysician.${index}`, (_a, _b, _c) => {
          const _flag = false;
          if (
            $scope.model.primaryPhysician.suffix === '' ||
            typeof $scope.model.primaryPhysician.suffix === 'undefined'
          ) {
            //return false;
          } else {
            $rootScope.constants.dropdowns.selectedPhysicians[0] = {
              value: `${$scope.model.primaryPhysician.suffix.title} ${$scope.model.primaryPhysician.first_name} ${$scope.model.primaryPhysician.last_name}`,
              title: `${$scope.model.primaryPhysician.suffix.title} ${$scope.model.primaryPhysician.first_name} ${$scope.model.primaryPhysician.last_name}`,
            };
          }
        });
      })(i);
    }

    //Search Individuals in dropdown
    $scope.refreshIndividuals = (search) => {
      if (search !== '') {
        const params = { q: search, individual_id: $scope.model.individualID };
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/searchIndividuals`,
          'POST',
          params,
          (response) => {
            $rootScope.constants.DB.individuals = response.data.individuals;
          },
        );
      }
    };

    //Search Drugs in dropdown
    $scope.refreshDrugs = (search) => {
      if (search !== '' && search.length > 2) {
        const params = { q: search };
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/searchDrugs`, 'POST', params, (response) => {
          $rootScope.constants.dropdowns.drugsInfo.drugs = response.data.ndc_drugs;
        });
      }
    };

    //Pagination work for Individial Active and Archive
    $scope.pageChanged = (newPage) => {
      if ($scope.first_request !== true) {
        getIndividualResults(newPage);
      } else {
        $scope.first_request = false;
      }
    };

    //Search Individuals in Listing Page
    $scope.searchIndividual = () => {
      getIndividualResults(1);
      $scope.model.paginationCurrent = 1;
    };

    //Filter Individuals in Listing page
    $scope.change_filter = () => {
      getIndividualResults(1);
    };

    //Delete doctor
    $scope.deleteDoctor = (item, type) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this doctor?', {
        Yes: () => {
          let isDoctorAssign = false;
          const concat_prescriptions = $scope.model.prescriptionsTakeData.concat(
            $scope.model.additionalPrescriptionsTakeData,
          );
          for (const i in concat_prescriptions) {
            const data_c = concat_prescriptions[i];
            if (data_c.prescribing_doctor && data_c.prescribing_doctor.npi === item.npi) {
              isDoctorAssign = true;
              break;
            }
          }

          if (isDoctorAssign === false) {
            const params = {
              npi: item.npi,
              individual_id: $scope.model.individualID,
            };
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/delete-doctor`,
              'POST',
              params,
              (response) => {
                if (response.data.result.success === true) {
                  if (type === 'specialist') {
                    const index = $scope.model.specialists.indexOf(item);
                    $scope.model.specialists.splice(index, 1);
                  } else if (type === 'primary_physicians') {
                    const index = $scope.model.primaryPhysicians.indexOf(item);
                    $scope.model.primaryPhysicians.splice(index, 1);
                  }

                  const index = $scope.model.selectedPhysiciansNew.indexOf(item);
                  $scope.model.selectedPhysiciansNew.splice(index, 1);
                }
                alert(response.data.result.message);
              },
            );
          } else {
            alert(
              'Selected Doctor is already assigned on some of your prescriptions. Please remove from there in order to delete Doctor!',
            );
          }
        },
        No: () => {},
      });
    };

    //Edit Custom doctor
    const updateDoctorRef = (d, t) => {
      /*IS PRIMARY PHY.*/
      if (t === 'primary_physicians') {
        if ($scope.model.primaryPhysicians.length > 0) {
          if (
            $scope.model.primaryPhysicians[0].doctor_type === 'CRM' &&
            Number($scope.model.primaryPhysicians[0].hipaaspace_npi) === Number(d.hipaaspace_npi)
          ) {
            $scope.model.primaryPhysicians[0] = d;
          }
        }
      } else {
        if ($scope.model.specialists.length > 0) {
          for (const i in $scope.model.specialists) {
            if (
              $scope.model.specialists[i].doctor_type === 'CRM' &&
              Number($scope.model.specialists[i].hipaaspace_npi) === Number(d.hipaaspace_npi)
            ) {
              $scope.model.specialists[i] = d;
            }
          }
        }
      }
      /*Update Dropdown Options*/
      for (const i in $scope.model.selectedPhysiciansNew) {
        if (Number($scope.model.selectedPhysiciansNew[i].hipaaspace_npi) === Number(d.hipaaspace_npi)) {
          $scope.model.selectedPhysiciansNew[i].suffix = d.suffix;
          $scope.model.selectedPhysiciansNew[i].first_name = d.first_name;
          $scope.model.selectedPhysiciansNew[i].last_name = d.last_name;
          $scope.model.selectedPhysiciansNew[i].provider_first_name = d.first_name;
          $scope.model.selectedPhysiciansNew[i].provider_last_name_legal_vame = d.last_name;
          $scope.model.selectedPhysiciansNew[i].speciality = d.speciality;
          $scope.model.selectedPhysiciansNew[i].specialities = d.speciality;
          $scope.model.selectedPhysiciansNew[i].title = `${d.suffix} ${d.first_name} ${d.last_name}`;
        }
      }

      /*Update Prescription Selected Value*/
      for (const i in $scope.model.prescriptionsTakeData) {
        if (
          typeof $scope.model.prescriptionsTakeData[i].prescribing_doctor === 'undefined' ||
          $scope.model.prescriptionsTakeData[i].prescribing_doctor === ''
        ) {
          continue;
        }
        if (Number($scope.model.prescriptionsTakeData[i].prescribing_doctor.npi) === Number(d.hipaaspace_npi)) {
          $scope.model.prescriptionsTakeData[i].prescribing_doctor.title = `${d.suffix} ${d.first_name} ${d.last_name}`;
        }
      }
      /*Update Add. Prescription Selected Value*/
      for (const i in $scope.model.additionalPrescriptionsTakeData) {
        if (
          typeof $scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor === 'undefined' ||
          $scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor === ''
        ) {
          continue;
        }
        if (
          Number($scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor.npi) === Number(d.hipaaspace_npi)
        ) {
          $scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor.title =
            `${d.suffix} ${d.first_name} ${d.last_name}`;
        }
      }
    };
    $scope.editDoctorName = (d, t) => {
      ConfirmBoxService.form(
        'Edit Doctor',
        'Do you really want to update information?',
        {
          UPDATE: (model) => {
            const _suffix = model.suffix;
            const first_name = model.first_name;
            const last_name = model.last_name;
            const _speciality = model.speciality;
            if (first_name === '' || last_name === '') {
              alert('First Name & Last Name cannot be empty!');
              return false;
            }
            const params = HelpersService.mergeData([d, model]);
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/updateDoctorInfo/${params.individual_id}`,
              'POST',
              params,
              (response) => {
                if (response.data.result.status === 200) {
                  updateDoctorRef(params, t);
                }
              },
            );
          },
          CANCEL: () => {},
        },
        [
          {
            title: 'Suffix',
            placeholder: 'Suffix',
            modelName: 'suffix',
            type: 'text',
            modelValue: d.suffix,
          },
          {
            title: 'First Name',
            placeholder: 'First Name',
            modelName: 'first_name',
            type: 'text',
            modelValue: d.first_name,
          },
          {
            title: 'Last Name',
            placeholder: 'Last Name',
            modelName: 'last_name',
            type: 'text',
            modelValue: d.last_name,
          },
          {
            title: 'Speciality',
            placeholder: 'Speciality',
            modelName: 'speciality',
            type: 'text',
            modelValue: d.speciality,
          },
        ],
      );
    };

    $scope.apiCallVar = null;
    function getIndividualResults(pageNumber, _sorting) {
      const params = { page: pageNumber, filter: $scope.model.listingFilter };
      if ($stateParams.type === 'listingArchive') {
        params.status = 'Deleted';
      }

      // $stateParams['search'] = $scope.model.searchText;
      // $state.params['search'] = $scope.model.searchText;
      $location.search('search', $scope.model.searchText);

      if ($scope.model.searchText !== '') {
        params.search = $scope.model.searchText;
      }
      //if(sorting === true){
      const activeColumn = $scope.model.sorting.active;
      const ascDesc = $scope.model.sorting[$scope.model.sorting.active];
      params.column = activeColumn;
      params.columnOrder = ascDesc;
      //}

      $timeout.cancel($scope.apiCallVar);
      $scope.apiCallVar = $timeout(() => {
        APICallSearchIndividual(params);
      }, 500);
    }

    const APICallSearchIndividual = (params) => {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/get`, 'POST', params, (response) => {
        $scope.model.listingData = response.data.individualData;
        $scope.model.listingTotal = response.data.individualCount;
        $scope.model.listingPerPage = response.data.individualPerPage;
      });
    };

    /*CONTACTED FOR P&C POLICY*/
    $scope.contactedForPropertyCasualty = (d, i) => {
      $rootScope.selectedIndividualForPC = d;
      $rootScope.selectedIndividualIndexForPC = i;

      $mdDialog
        .show({
          controller: propertyAndCasualtyController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/contact-for-PC-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    function propertyAndCasualtyController(scope, $mdDialog) {
      const formData = angular.copy($rootScope.selectedIndividualForPC);
      scope.model = {
        formDataPC: $rootScope.selectedIndividualForPC,
      };
      scope.model.formDataPC.business_type = 'PC';
      if (formData.datetime == null) {
        scope.model.formDataPC.datetime = '';
      } else {
        scope.model.formDataPC.datetime = MomentService.convertTimeZone(formData.datetime);
      }
      scope.cancel = () => {
        $rootScope.selectedIndividualForPC = formData;
        $scope.model.listingData[$rootScope.selectedIndividualIndexForPC] = formData;
        $mdDialog.hide();
      };
      scope.save = () => {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/contacted/${scope.model.formDataPC.id}`,
          'POST',
          scope.model.formDataPC,
          (response) => {
            $scope.model.listingData[$rootScope.selectedIndividualIndexForPC].contacted = response.data.contacted;
            $scope.model.listingData[$rootScope.selectedIndividualIndexForPC].contacted_date = response.data.created_at;
            scope.model.formDataPC.contacted_date = response.data.created_at;
          },
        );
        $mdDialog.hide();
      };
    }

    $scope.updateSpouseEmailIfSame = (data, closeForm, nextStep) => {
      if ($scope.model.changeSpouseEmail) {
        $scope.model.changeSpouseEmail = false;
        return false;
      }
      if (
        $scope.model.clonedData.individualData.email === $scope.model.spouseData.email &&
        data.userData.email !== $scope.model.clonedData.individualData.email
      ) {
        ConfirmBoxService.open(
          'Confirmation',
          `Current email address (${$scope.model.clonedData.individualData.email}) used on this customer is being used in spouse account as well, do you want to update the new email address (${data.userData.email}) in spouse account as well?`,
          {
            Yes: () => {
              $scope.model.changeSpouseEmail = true;
              $scope.model.spouseData.update_email_address = 1;
              $scope.model.spouseData.email = data.userData.email;
              $scope.submitEditForm(closeForm, nextStep);
            },
            No: () => {
              $scope.model.changeSpouseEmail = true;
              $scope.submitEditForm(closeForm, nextStep);
            },
          },
        );
        return true;
      }
    };

    // log the individual id
    $scope.logIndividualId = (individualId) => {
      console.log('Individual ID: ', individualId);
    };
  },
];
