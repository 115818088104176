export default class clientSpecificHeaderMenuDirective {
  constructor($rootScope, AuthService) {
    this.restrict = 'EA'; // restrict the directive to be used as an element or attribute
    this.$rootScope = $rootScope;
    this.AuthService = AuthService;
  }

  link(scope, element, attrs) {
    attrs.$observe('clientSpecificHeaderMenuDirective', (value, oldValue) => {
      this.$rootScope.clientSpecificSubMenuFlag = value;
      this.$rootScope.clientSpecificSubMenu = {};
      this.AuthService.sendRequest(
        `${this.$rootScope.api05DomainPath}client/sub-menu/data/get`,
        'get',
        { client_id: value },
        (response) => {
          this.$rootScope.clientSpecificSubMenu = response.data;
        },
      );
    });
  }

  static factory($rootScope, AuthService) {
    const directive = new clientSpecificHeaderMenuDirective($rootScope, AuthService);
    return directive;
  }
}

// Dependency Injection
clientSpecificHeaderMenuDirective.factory.$inject = ['$rootScope', 'AuthService'];
