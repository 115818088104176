export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  function CarriersController($rootScope, $scope, AuthService, $stateParams, $state, LoaderService, ConfirmBoxService) {
    $scope.model = {
      coverageTypes: [],
      planCategories: [],
      selectedPlanCategories: [],
      lastSelectedCategories: [],

      companies: [],
      addForm: {
        phone: '',
        id: '',
      },
      editForm: {
        phone: '',
      },
      products: [[]],
      planTabsData: [],
      carrierID: null,
      listingData: [],
    };

    $scope.loadCarriersData = () => {
      LoaderService.show();
      if ($scope.model.carrierID == null) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}carriers/get`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.setListing(response);
          },
        );
      } else {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}carrier/get/${$scope.model.carrierID}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.editCarrier(response);
          },
        );
      }
    };

    if ($stateParams.type === 'add-coverage') {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}carrier/getCoverageTypes`, 'GET', {}, (response) => {
        if (response.status === 200) {
          $scope.model.coverageTypes = response.data.coverage;
          $scope.model.businessTypes = response.data.business;
        }
      });
    } else if ($stateParams.type !== 'add') {
      /*LOAD DATA*/
      if (typeof $stateParams.carrierID !== 'undefined') {
        $scope.model.carrierID = $stateParams.carrierID;
      }
      $scope.loadCarriersData();
    }

    $scope.submitAddForm = () => {
      LoaderService.show();
      AuthService.sendRequest(`${$rootScope.api05DomainPath}carrier/add`, 'POST', $scope.model.addForm, (response) => {
        window.location = `#/carrier/${response.data.carrierData.id}/edit`;
        LoaderService.hide();
      });
    };

    $scope.setListing = (res) => {
      $scope.model.listingData = res.data.carriersData;
      LoaderService.hide();
    };

    $scope.editCarrier = (res) => {
      $scope.model.editForm = res.data.carrierData;
      $scope.model.products = res.data.products;
      $scope.model.planCategories = res.data.planCategories;
      $scope.model.selectedPlanCategories = res.data.selectedPlans;
      $scope.model.companies = res.data.companyData;
      LoaderService.hide();
    };

    $scope.submitEditForm = (_res) => {
      LoaderService.show();
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}carrier/update/${$scope.model.editForm.id}`,
        'POST',
        $scope.model,
        (response) => {
          ConfirmBoxService.open('Alert', response.data.message, {
            OK: () => {},
          });
          $state.reload();
        },
      );
    };

    $scope.submitAddCoverageForm = () => {
      if (typeof $scope.model.addForm.business_type !== 'undefined') {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}carrier/coverage/add`,
          'POST',
          $scope.model.addForm,
          (response) => {
            if (response.status === 200) {
              $state.reload();
            }
          },
        );
      } else {
        ConfirmBoxService.open('Error', 'Business Type field is required!', {
          OK: () => {},
        });
      }
    };
    $scope.removeCoverageType = (i, d) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete COVERAGE TYPE?', {
        Yes: () => {
          AuthService.sendRequest(`${$rootScope.api05DomainPath}carrier/coverage/delete`, 'POST', d, (response) => {
            if (response.data.status !== 200) {
              ConfirmBoxService.open('Error', 'You cannot DELETE COVERAGE TYPE!', {
                OK: () => {},
              });
            } else {
              $scope.model.coverageTypes.splice(i, 1);
            }
          });
        },
        No: () => {},
      });
      console.log(d);
    };

    $scope.addProductPlan = (_i, _pData, cData) => {
      if (typeof $scope.model.products[cData.id] === 'undefined') {
        $scope.model.products[cData.id] = [];
      }

      if (cData.id === 5) {
        $scope.model.products[cData.id].push({
          id: 0,
          product_name: '',
          market_type: '',
        });
      } else if (cData.id !== 5) {
        $scope.model.products[cData.id].push({
          id: 0,
          product_name: '',
          monthly_eft_amount: '',
          code: '',
        });
      }
    };

    $scope.deleteProductPlan = (i, d, catData) => {
      const pName = d.product_name === '' ? 'this product?' : `'${d.product_name}'`;
      ConfirmBoxService.open('Confirmation', `Do you really want to delete ${pName}`, {
        Yes: () => {
          if (d.id === 0) {
            $scope.model.products[catData.id].splice(i, 1);
          } else {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}carrier/product/associated/${$scope.model.editForm.id}`,
              'POST',
              d,
              (response) => {
                if (response.data.status !== 200) {
                  ConfirmBoxService.open('Error', response.data.message, {
                    OK: () => {},
                  });
                } else {
                  ConfirmBoxService.open('Alert', 'Plan has been deleted successfully!', { OK: () => {} });
                  $scope.model.products[catData.id].splice(i, 1);
                }
              },
            );
          }
        },
        NO: () => {},
      });
    };

    $scope.updatePlanTabs = () => {
      const planTabsData = [];

      /*UPDATE PLANS TAB*/
      for (const i in $scope.model.selectedPlanCategories) {
        const planCategoryID = $scope.model.selectedPlanCategories[i];
        const planData = $scope.model.planCategories[planCategoryID];
        planTabsData.push(planData);
      }

      /*DELETING VALIDATION START*/
      for (const i in $scope.model.lastSelectedCategories) {
        const planCategoryID = $scope.model.lastSelectedCategories[i];
        const planData = $scope.model.planCategories[planCategoryID];

        if (typeof $scope.model.products[planCategoryID] === 'undefined') {
          $scope.model.products[planCategoryID] = [];
        }
        if (
          $scope.model.selectedPlanCategories.indexOf(planCategoryID) < 0 &&
          $scope.model.products[planCategoryID].length !== 0
        ) {
          ConfirmBoxService.open('Error', `You cannot remove '${planData.category_name}' category!`, {
            OK: () => {},
          });
          $scope.model.selectedPlanCategories = $scope.model.lastSelectedCategories;
          return false;
        }
      }
      /*DELETING VALIDATION END*/

      $scope.model.planTabsData = planTabsData;
      $scope.model.lastSelectedCategories = angular.copy($scope.model.selectedPlanCategories);
    };

    $scope.addCompany = () => {
      if (typeof $scope.model.addForm.company !== 'undefined' && $scope.model.addForm.company !== '') {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}carrier/company/add/${$scope.model.carrierID}`,
          'POST',
          {
            carrier_id: $scope.model.carrierID,
            company: $scope.model.addForm.company,
          },
          (response) => {
            if (response.data.status !== 200) {
              ConfirmBoxService.open('Error', 'Internal Server Error', {
                OK: () => {},
              });
            } else {
              $scope.model.companies.push(response.data.data);
            }
          },
        );

        $scope.model.addForm.company = '';
      }
    };
    $scope.deleteCarrierCompany = (i, d) => {
      if (d.id === 0) {
        ConfirmBoxService.open('Confirmation', 'Do You Really Want to Delete this Company?', {
          Yes: () => {
            $scope.model.companies.splice(i, 1);
          },
          No: () => {},
        });
      } else {
        ConfirmBoxService.open('ERROR', 'You Cannot Delete this Company!', {
          OK: () => {},
        });
      }
    };
  },
];
