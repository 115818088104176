import constants from '../utils/constants';
const accountRoutes = ($stateProvider) => {
  $stateProvider
    .state('/my-account', {
      url: '/my-account',
      params: {
        type: 'my-account',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/myAccount.html?v=${constants.randomNumber}`,
          controller: 'UserController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/my-account-password', {
      url: '/my-account-password',
      params: {
        type: 'my-account-password',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/changePassword.html?v=${constants.randomNumber}`,
          controller: 'UserController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    });
};

export default accountRoutes;
