export default class isCurrency {
  constructor() {
    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, element, attrs, ngModel) {
    if (!ngModel) {
      return false;
    }

    element.bind('change', () => {
      let val = ngModel.$viewValue.replace(/([a-zA-Z]+)/, '');
      val = val.replace(/^(\$)/, '');

      if (val == null || val === 'undefined' || val === 'null') {
        return '$';
      }

      if (val.indexOf('.') === -1) {
        val = `${val}.00`;
      }

      const x = val.split('.');
      let x1 = x[0];
      const x2 = x.length > 1 ? `.${x[1]}` : '';
      const rgx = /(\d+)(\d{3})/;

      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }

      ngModel.$setViewValue(`$${x1}${x2}`);
      ngModel.$render();
    });

    ngModel.$formatters.push((val) => {
      if (val == null || val === 'undefined' || val === 'null' || val === '') {
        return '$';
      }

      if (typeof val === 'number') {
        val = val.toString();
      }

      val = val.replace(/([a-zA-Z]+)/, '');
      val = val.replace(/^(\$)/, '');

      if (val == null || val === 'undefined' || val === 'null' || val === '') {
        return '$';
      }

      if (val.indexOf('.') === -1) {
        val = `${val}.00`;
      }

      const x = val.split('.');
      let x1 = x[0];
      const x2 = x.length > 1 ? `.${x[1]}` : '';
      const rgx = /(\d+)(\d{3})/;

      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }

      return `$${x1}${x2}`;
    });

    ngModel.$parsers.push((val) => val.replace(/^\$,/, ''));
  }

  static factory() {
    return new isCurrency();
  }
}

// Dependency Injection
isCurrency.factory.$inject = [];
