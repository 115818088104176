import constants from '../utils/constants';

export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  'ConfirmBoxService',
  'MomentService',
  'HelpersService',
  '$mdDialog',
  function IndividualIhsController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    ConfirmBoxService,
    MomentService,
    HelpersService,
    $mdDialog,
  ) {
    $rootScope.subMenuActive = 'ihs';
    $scope.model = {
      listing: [],
      individual: {},
      formData: {
        inhouse_summary: {
          appointment_date: null,
          individual_id: $stateParams.individualID,
          referred_by: '',
          bank_id: '',
          routing_number_id: '',
          bank_account_number: '',
          comments: '',
        },
        inhouse_current_insurance: {
          aging_into_medicare: 0,
          medicare_aep: 0,
          medicare_madp: 0,
          medicare_mov: 0,
          medicare_other: 0,
          insurance_provider: '',
          policy_number: '',
          pdp_coverage: 0,
          pdp_provider: '',
          pace: 0,
          pacenet: 0,
          carrier_to_be_cancelled: 0,
          contact_1: '',
          date_coverage_to_be_cancelled_1: null,
          cms_called_to_terminate: 0,
          date_cms_called_to_terminate: null,
          contact_2: '',
          office_location: '',
          date_coverage_to_be_cancelled_2: null,
          faxed_carrier_termination: 0,
          date_faxed_carrier_termination: null,
          saved_carrier_termination: 0,
          date_saved_carrier_termination: null,
          completed: 0,
          date_completed: null,
        },
        inhouse_medicare_supplement: {
          policy_number: '',
          carrier: '',
          plan: '',
          application_submitted: 0,
          date_application_submitted: null,
          initials: $rootScope.loggedInUserData.name,
          online: 0,
          date_online: null,
          faxed: 0,
          date_faxed: null,
          confirmation_saved_to_crm: 0,
          date_confirmation_saved_to_crm: null,
          policy_mediagap: 0,
          policy_madp: 0,
          plan_id: '',
          effective_date: null,
          premium: '',
          payment_method_eft: 0,
          payment_method_bill: 0,
          household_discount: 0,
          name: '',
          policy_no: '',
          open_enrollment: 0,
          guaranteed_issue: 0,
          sep: 0,
          underwritten: 0,
          ti_w: 0,
          date_ti_w: null,
        },
        pdp: {
          policy_number: '',
          carrier: '',
          plan: '',
          application_submitted: 0,
          date_application_submitted: null,
          initials: $rootScope.loggedInUserData.name,
          online: 0,
          date_online: null,
          faxed: 0,
          date_faxed: null,
          confirmation_saved_to_crm: 0,
          date_confirmation_saved_to_crm: null,
          plan_id: '',
          effective_date: null,
          premium: '',
          payment_method_eft: 0,
          payment_method_bill: 0,
          payment_method_coupon: 0,
          pace: 0,
          pacenet: 0,
          veteren: 0,
          tcrp: 0,
          orp: 0,
          ngp: 0,
          orpt: 0,
          sep: 0,
          sep_code: '',
        },
      },
    };

    function loadIndividualData(id, callback) {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/get/${id}`, 'POST', {}, (response) => {
        response.data.individualData.age = '';
        if (response.data.individualData.date_of_birth) {
          let birthday = MomentService.convertTimeZone(response.data.individualData.date_of_birth);
          birthday = MomentService.convertTimeZone(birthday);
          const ageDifMs = MomentService.getAgeDiffMs(birthday);
          const ageDate = MomentService.convertTimeZone(ageDifMs);
          const age = Math.abs(ageDate.getUTCFullYear() - 1970);
          response.data.individualData.age = age;
        }
        response.data.individualData.date_of_birth = MomentService.formatDate(
          response.data.individualData.date_of_birth,
          'MM/DD/YYYY',
        );
        response.data.individual_intake.part_a_eff_date = MomentService.formatDate(
          response.data.individual_intake.part_a_eff_date,
          'MM/DD/YYYY',
        );
        response.data.individual_intake.part_b_eff_date = MomentService.formatDate(
          response.data.individual_intake.part_b_eff_date,
          'MM/DD/YYYY',
        );
        $scope.model.individual = response.data;

        if (callback) {
          callback();
        }
      });
    }

    function bindIhsFormData(data) {
      if (data.length > 0) {
        const ihs = data[0];

        $scope.model.formData.inhouse_summary.appointment_date = HelpersService.convertTimeZone(ihs.appointment_date);
        $scope.model.formData.inhouse_summary.id = ihs.id;
        $scope.model.formData.inhouse_summary.bank_account_number = ihs.bank_account_number;
        $scope.model.formData.inhouse_summary.comments = ihs.comments;
        $scope.model.formData.inhouse_current_insurance = dateAndCheckBoxParser(ihs.current_insurance, [
          'date_completed',
          'date_coverage_to_be_cancelled_1',
          'date_coverage_to_be_cancelled_2',
          'date_faxed_carrier_termination',
          'date_saved_carrier_termination',
          'date_cms_called_to_terminate',
          'aging_into_medicare',
          'carrier_to_be_cancelled',
          'cms_called_to_terminate',
          'completed',
          'faxed_carrier_termination',
          'medicare_aep',
          'medicare_madp',
          'medicare_mov',
          'medicare_other',
          'pace',
          'pacenet',
          'pdp_coverage',
          'saved_carrier_termination',
        ]);
        $scope.model.formData.inhouse_medicare_supplement = dateAndCheckBoxParser(ihs.supplement, [
          'application_submitted',
          'confirmation_saved_to_crm',
          'faxed',
          'guaranteed_issue',
          'household_discount',
          'online',
          'open_enrollment',
          'payment_method_bill',
          'payment_method_eft',
          'policy_madp',
          'policy_mediagap',
          'sep',
          'ti_w',
          'underwritten',
          'date_application_submitted',
          'date_confirmation_saved_to_crm',
          'date_faxed',
          'date_online',
          'date_ti_w',
          'effective_date',
        ]);

        $scope.model.formData.pdp = dateAndCheckBoxParser(ihs.pdp, [
          'application_submitted',
          'confirmation_saved_to_crm',
          'faxed',
          'ngp',
          'online',
          'orp',
          'orpt',
          'pace',
          'pacenet',
          'payment_method_bill',
          'payment_method_coupon',
          'payment_method_eft',
          'sep',
          'tcrp',
          'veteren',
          'date_application_submitted',
          'date_confirmation_saved_to_crm',
          'date_faxed',
          'date_online',
          'effective_date',
        ]);

        $scope.model.individual.formData.carriers.splice(0, 0, {
          carrier_name: 'Select',
          id: '',
        });
        $scope.model.formData.inhouse_medicare_supplement.initials = $scope.model.formData.inhouse_medicare_supplement
          .initials
          ? $scope.model.formData.inhouse_medicare_supplement.initials
          : $rootScope.loggedInUserData.name;
        $scope.model.formData.pdp.initials = $scope.model.formData.pdp.initials
          ? $scope.model.formData.pdp.initials
          : $rootScope.loggedInUserData.name;

        const location = $scope.model.individual.formData.agency_locations.filter((a) =>
          $scope.model.formData.inhouse_current_insurance.office_location
            ? Number(a.value) === Number($scope.model.formData.inhouse_current_insurance.office_location)
            : '',
        );
        const carrier = $scope.model.individual.formData.carriers.filter((a) =>
          $scope.model.formData.inhouse_current_insurance.carrier_id
            ? Number(a.id) === Number($scope.model.formData.inhouse_current_insurance.carrier_id)
            : '',
        );
        const bank = $scope.model.individual.formData.banks.filter((a) =>
          ihs.bank_id ? Number(a.id) === Number(ihs.bank_id) : '',
        );
        if (bank.length > 0) {
          $scope.model.formData.inhouse_summary.bank_id = bank[0];
          const routing = bank[0].routing.filter((a) =>
            ihs.routing_number_id ? Number(a.id) === Number(ihs.routing_number_id) : '',
          );
          $scope.model.formData.inhouse_summary.routing_number_id = routing.length > 0 ? routing[0] : null;
        }

        if (location.length > 0) {
          $scope.model.formData.inhouse_current_insurance.office_location = location[0];
        }
        if (carrier.length > 0) {
          $scope.model.formData.inhouse_current_insurance.carrier_id = carrier[0];
        }
      }
    }

    function dateAndCheckBoxParser(data, checkboxes) {
      const fields = {};
      for (const i in data) {
        const val = data[i];
        fields[i] = val;
        if (checkboxes.indexOf(i) !== -1 && val === 1) fields[i] = true;
        if (
          checkboxes.indexOf(i) !== -1 &&
          typeof val === 'string' &&
          MomentService.convertTimeZone(val) !== 'Invalid Date'
        )
          fields[i] = MomentService.convertTimeZone(val);
      }
      return fields;
    }

    function loadListingData(id) {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/ihs`,
        'GET',
        { individual_id: $rootScope.individualID, id: id },
        (response) => {
          $scope.model.listing = response.data.data;
          if (id) bindIhsFormData(response.data.data);
        },
      );
    }

    if (typeof $stateParams.individualID !== 'undefined') {
      $rootScope.individualID = $stateParams.individualID;
      if ($stateParams.type !== 'listing') {
        loadIndividualData($rootScope.individualID, () => {
          if (typeof $stateParams.ihsID !== 'undefined') {
            loadListingData($stateParams.ihsID);
          }
        });
      } else {
        loadListingData();
      }
    }

    function getFormData() {
      const data = $scope.model.formData;
      const formData = {};
      for (const i in data) {
        formData[i] = {};
        for (const ii in data[i]) {
          let value = data[i][ii];
          if (typeof value === 'boolean') value = value === true ? 1 : 0;

          if (value instanceof Date) value = MomentService.formatDate(value, 'YYYY-MM-DD');

          formData[i][ii] = value;
        }
      }
      formData.inhouse_current_insurance.carrier_id = formData.inhouse_current_insurance.carrier_id
        ? formData.inhouse_current_insurance.carrier_id.id
        : '';
      formData.inhouse_current_insurance.office_location = formData.inhouse_current_insurance.office_location
        ? formData.inhouse_current_insurance.office_location.value
        : '';
      formData.inhouse_summary.routing_number_id = formData.inhouse_summary.routing_number_id
        ? formData.inhouse_summary.routing_number_id.id
        : '';
      formData.inhouse_summary.bank_id = formData.inhouse_summary.bank_id ? formData.inhouse_summary.bank_id.id : '';
      return formData;
    }

    $scope.saveIhsForm = () => {
      const data = getFormData();
      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/ihs/`, 'POST', data, (response) => {
        if (!data.inhouse_summary.id) {
          ConfirmBoxService.open('Message', 'In house summary has been created', {
            OK: () => {
              window.location = `#/individuals/${$rootScope.individualID}/ihs/${response.data.data.id}`;
              window.location.reload();
            },
          });
        } else {
          ConfirmBoxService.open('Message', 'In house summary  has been updated', {
            OK: () => {},
          });
        }
      });
    };

    $scope.statusUpdateDate = (slf, type, field) => {
      if (slf) {
        $scope.model.formData[type][field] = MomentService.convertTimeZone(new Date());
      } else {
        $scope.model.formData[type][field] = null;
      }
    };

    $scope.deleteIhs = (ihs, index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          $scope.model.listing.splice(index, 1);

          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/ihs/delete`,
            'POST',
            { id: ihs.id },
            (_response) => {},
          );
        },
        No: () => {},
      });
    };

    $scope.onSelectBank = (_item, _model) => {
      $scope.model.formData.inhouse_summary.routing_number_id = '';
    };
    $scope.addBankAndRoutes = (ev) => {
      $mdDialog
        .show({
          controller: AddBankAndRoutes,
          templateUrl: `${constants.templatesDir}individuals/innerPages/bank-and-routing-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    $scope.printIhs = () => {
      window.print();
    };

    function AddBankAndRoutes(scope, $mdDialog) {
      scope.model = {
        banks: [],
        routings: {},
        bank_name: '',
        routing_number: '',
      };

      scope.showRoutings = (b) => {
        for (const i in scope.model.banks) {
          scope.model.banks[i].status = undefined;
        }
        b.status = 1;
        scope.model.routings = b;
      };

      scope.saveNewBank = () => {
        const bank = scope.model.bank_name;
        scope.model.bank_name = '';
        AuthService.sendRequest(`${$rootScope.api05DomainPath}banks`, 'POST', { bank_name: bank }, (response) => {
          response.data.data.bank.routing = [];
          scope.model.routings = response.data.data.bank;
          loadBanksData();
        });
      };
      scope.saveNewRouting = () => {
        const routing_number = scope.model.routing_number;
        scope.model.routing_number = '';
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}banks/routing`,
          'POST',
          { routing_number: routing_number, bank_id: scope.model.routings.id },
          (_response) => {
            loadBanksData();
          },
        );
      };

      scope.deleteBank = (b) => {
        ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}banks/delete`,
              'POST',
              {
                id: b.id,
              },
              (_response) => {
                loadBanksData();
              },
            );
          },
          No: () => {},
        });
      };
      scope.deleteBankRouting = (r) => {
        ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}banks/routing/delete`,
              'POST',
              {
                id: r.id,
              },
              (_response) => {
                loadBanksData();
              },
            );
          },
          No: () => {},
        });
      };

      scope.cancel = () => {
        $mdDialog.cancel();
        loadIndividualData($rootScope.individualID);
      };
      function loadBanksData() {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}banks`, 'GET', {}, (response) => {
          scope.model.banks = response.data.data.banks;
          if (scope.model.banks.length > 0) {
            if (scope.model.routings.id) {
              for (const i in scope.model.banks) {
                if (scope.model.banks[i].id === scope.model.routings.id) {
                  scope.showRoutings(scope.model.banks[i]);
                }
              }
            } else {
              scope.showRoutings(scope.model.banks[0]);
            }
          }
        });
      }
      loadBanksData();
    }
  },
];
