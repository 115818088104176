class IndividualInfoService {
  constructor($http, AuthService, $rootScope) {
    this.$http = $http;
    this.AuthService = AuthService;
    this.$rootScope = $rootScope;
    this.info = undefined;
  }

  getInfo(individualID, callback) {
    if (typeof this.info === 'undefined') {
      this.AuthService.sendRequest(
        `${this.$rootScope.api05DomainPath}individual/get/${individualID}`,
        'POST',
        {},
        (response) => {
          const mergedIndAdd = angular.extend(response.data.individualData, response.data.individualAddress.Home);
          this.info = mergedIndAdd;
          callback(this.info);
        },
      );
    } else {
      callback(this.info);
    }
  }

  setInfo(value) {
    this.info = value;
  }
}

IndividualInfoService.$inject = ['$http', 'AuthService', '$rootScope']; // Dependency Injection

export default IndividualInfoService;
