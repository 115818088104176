export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'HelpersService',
  'IndividualInfoService',
  '$timeout',
  '$location',
  'ValidationService',
  function SupportTicketController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    HelpersService,
    IndividualInfoService,
    $timeout,
    $location,
    ValidationService,
  ) {
    $scope.first_request = true;
    $scope.individualID = $stateParams.individualID;
    $scope.agentID = $stateParams.agentID;

    let dataFactory = {};

    $scope.model = {
      dropdowns: {
        coverage_type: [],
        carrier: [],
        companies: [],
        products: [],
        phone_numbers: [],
      },
      type: $stateParams.type,
      formData: {
        users: [],
        inform_client: false,
        inform_elizabeth: false,
        search: {},
      },
      listingData: [],
      listingTotal: 0,
      listingPerPage: '',
      paginationCurrent: 1,
      sorting: {
        active: 'created_at',
        subject: '',
        individual: '',
        coverage_type: '',
        priority: '',
        status: '',
        created_at: 'desc',
      },
      //search:{},
      individualID: null,
      agentID: null,
      filterListing: 'Todo',
      is_company_enable: false,
      isEditMode: false,
      individual: null,
    };

    $scope.constants = {
      DB: {},
      dropdowns: {
        perPage: angular.copy($rootScope.constants.dropdowns.perPage),
      },
    };
    $scope.model.individualID = $scope.individualID;
    $scope.loggedInUserData = $rootScope.loggedInUserData.id;

    if (
      typeof $scope.model.individualID !== 'undefined' &&
      $scope.model.individualID !== '' &&
      $scope.model.individualID !== null &&
      $scope.model.individualID !== 0
    ) {
      //Get Individual info
      IndividualInfoService.getInfo($scope.model.individualID, (result) => {
        $scope.model.individual_info = result;
      });
    }

    $rootScope.subMenuActive = 'support-ticket';
    if ($scope.model.type === 'add_modify_st') {
      let req_url = '';
      if (
        $stateParams.supportTicketID !== '' &&
        typeof $stateParams.supportTicketID !== 'undefined' &&
        $stateParams.supportTicketID !== null &&
        $stateParams.individualID !== '' &&
        typeof $stateParams.individualID !== 'undefined' &&
        $stateParams.individualID !== null
      ) {
        $scope.model.isEditMode = true;
        $scope.model.supportTicketID = $stateParams.supportTicketID;
        req_url = `${$rootScope.api05DomainPath}support-ticket/add/${$scope.model.individualID}/${$scope.model.supportTicketID}`;
      } else if (
        $stateParams.supportTicketID !== '' &&
        typeof $stateParams.supportTicketID !== 'undefined' &&
        $stateParams.supportTicketID !== null
      ) {
        $scope.model.supportTicketID = $stateParams.supportTicketID;
        req_url = `${$rootScope.api05DomainPath}support-ticket/add/${$scope.model.supportTicketID}`;
      } else if (
        $stateParams.individualID !== '' &&
        typeof $stateParams.individualID !== 'undefined' &&
        $stateParams.individualID !== null
      ) {
        req_url = `${$rootScope.api05DomainPath}support-ticket/add/${$scope.model.individualID}`;
      } else {
        req_url = `${$rootScope.api05DomainPath}support-ticket/add/`;
      }

      AuthService.sendRequest(req_url, 'GET', {}, (response) => {
        dataFactory = response.data.carrier_info;

        $scope.constants.DB.users = response.data.users;
        $scope.constants.dropdowns.priorities = response.data.priorities;
        $scope.model.dropdowns.coverage_type = dataFactory.coverage_type_data;

        $timeout(() => {
          $scope.model.formData.individual = response.data.individual;
        });

        //Modify - Edit
        if (response.data.support_ticket.length > 0) {
          $scope.individualSelected(response.data.individual);

          $scope.model.formData = response.data.support_ticket[0];
          const supportTicketData = response.data.support_ticket[0];

          //Carrier Info Dropdown
          if (supportTicketData.product_id !== '' && supportTicketData.product_id !== null) {
            const coverageObject = HelpersService.getObjectByValue(
              supportTicketData.carrier_plan_categories_id,
              'id',
              dataFactory.coverage_type_data,
            );
            $scope.model.formData.coverage_type = coverageObject;
            $scope.populateCarriers(coverageObject);

            const carrierObject = HelpersService.getObjectByValue(
              supportTicketData.carrier_id,
              'id',
              $scope.model.dropdowns.carriers,
            );
            $scope.model.formData.carrier = carrierObject;
            $scope.populateProductsCompanies(carrierObject);

            const companyObject = HelpersService.getObjectByValue(
              supportTicketData.company_id,
              'company_id',
              $scope.model.dropdowns.companies,
            );
            if (companyObject !== '') {
              $scope.model.formData.company = companyObject;
              $scope.populateCarrierCompanyProducts(companyObject);
            }

            if (supportTicketData.company_id === '0') {
              const productObject = HelpersService.getObjectByValue(
                supportTicketData.product_id,
                'id',
                $scope.model.dropdowns.products,
              );
              $scope.model.formData.product = productObject;
            } else {
              const productObject = HelpersService.getObjectByValue(
                supportTicketData.product_id,
                'product_id',
                $scope.model.dropdowns.products,
              );
              $scope.model.formData.product = productObject;
            }
          }
        }
      });
    } else if ($scope.model.type === 'view_st_individual') {
      $scope.model.view_by = 'individual';
      get_support_ticket_results(1);
      //get_support_ticket_report();
    } else if ($scope.model.type === 'thread_st') {
      if ($stateParams.supportTicketID !== '') {
        $scope.model.supportTicketID = $stateParams.supportTicketID;
        const req_url = `${$rootScope.api05DomainPath}support-ticket/thread/${$scope.model.supportTicketID}`;

        AuthService.sendRequest(req_url, 'GET', {}, (response) => {
          if (response.data.success === true) {
            //To check if participants is assign on this task or task created by the logged in Agent
            const participants = response.data.participants;
            let participantsCount = 0;
            $scope.model.loggedInUser = false;

            const last_participants = participants[Object.keys(participants)[Object.keys(participants).length - 1]];
            for (let j = 0; j < last_participants.length; j++) {
              if (last_participants[j].user_id === $rootScope.loggedInUserData.id) {
                $scope.model.loggedInUser = true;
              }
            }

            for (const _i in participants) {
              participantsCount++;
            }
            $scope.model.participantsCount = participantsCount;

            $scope.model.support_ticket = response.data.support_ticket;
            $scope.model.support_ticket_carrier_plan = response.data.support_ticket_carrier_plan;
            $scope.model.listingData = response.data.support_ticket_messages;
            $scope.model.individual = response.data.individual;
            $scope.model.participants = response.data.participants;
            $scope.model.users = response.data.users;
            $scope.model.attached_files = response.data.attachments;
          }
        });
      }
    } else if ($scope.model.type === 'view_st_agent') {
      $scope.model.view_by = 'agent';

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}support-ticket/get-search-options`,
        'GET',
        {},
        (response) => {
          if (response.status === 200) {
            $scope.constants.dropdowns.priorities = response.data.priorities;
            $scope.constants.dropdowns.priorities.unshift({
              title: 'All',
              value: '',
            });

            $scope.constants.dropdowns.status_ticket_support = response.data.status_ticket_support;
            $scope.constants.dropdowns.status_ticket_support.unshift({
              title: 'All',
              value: '',
            });

            $scope.constants.dropdowns.coverage_type = response.data.coverage_types;
            $scope.constants.dropdowns.coverage_type.unshift({
              id: '',
              category_name: 'All',
            });
          }
        },
      );

      get_support_ticket_agent_results(1);
      get_support_ticket_agent_report();
    } else if ($scope.model.type === 'view_st_admin') {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}support-ticket/report-admin/`, 'GET', {}, (response) => {
        if (response.data.success === true) {
          $scope.model.agents_report = response.data.agents_report;
        }
      });
    }

    /*
     Submit Forms
     */
    $scope.assignSupportTicket = () => {
      if (typeof $scope.model.ticketAssigneeData.user !== 'undefined') {
        /* if($scope.model.support_ticket.user_id === $scope.model.ticketAssigneeData.user.id){
             ConfirmBoxService.open("Error","You cannot assign ticket to Reporter!",{OK:function(){}});
             }*/

        if (
          $scope.model.ticketAssigneeData.status === 'Completed' ||
          $scope.model.ticketAssigneeData.status === 'Closed'
        ) {
          ConfirmBoxService.open('Error', 'You cannot assign ticket in a status of Closed or Completed!', {
            OK: () => {},
          });
        } else {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}support-ticket/re-assign-exec`,
            'POST',
            {
              formData: $scope.model.ticketAssigneeData,
              supportTicketData: $scope.model.support_ticket,
            },
            (response) => {
              if (response.data.success === true) {
                ConfirmBoxService.open('Success', 'Successfully Assigned', {
                  OK: () => {
                    $state.reload();
                  },
                });
              } else {
                ConfirmBoxService.open('Notification', response.data.message, {
                  OK: () => {},
                });
              }
            },
          );
        }
      }
    };

    $scope.submitTicketAddForm = () => {
      if ($scope.model.type === 'add_modify_st') {
        const validated = ValidationService.validate($scope, {
          'model.formData.subject': {
            required: true,
            errorMessage: 'Subject is Required',
          },
          'model.formData.users': {
            required: true,
            errorMessage: 'Assigned To is Required',
          },
          'model.formData.priority': {
            required: true,
            errorMessage: 'Priority is Required',
          },
          'model.formData.method': {
            required: true,
            errorMessage: 'Method is Required',
          },
          'model.formData.note': {
            required: true,
            errorMessage: 'Note/Query is Required',
          },
        });

        if (validated) {
          if ($scope.model.formData.inform_client === true) {
            $scope.model.formData.inform_client = 'Yes';
          } else {
            $scope.model.formData.inform_client = 'No';
          }

          if ($scope.model.formData.inform_elizabeth === true) {
            $scope.model.formData.inform_elizabeth = 'Yes';
          } else {
            $scope.model.formData.inform_elizabeth = 'No';
          }

          $scope.model.formData.supportTicketID = $stateParams.supportTicketID;
          $scope.model.formData.individualID = $stateParams.individualID;

          const formData = new FormData();
          formData.append('formData', JSON.stringify($scope.model.formData));
          if (typeof $scope.model.attachments !== 'undefined') {
            for (const i in $scope.model.attachments) {
              formData.append('file[]', $scope.model.attachments[i]);
            }
          }
          formData.append('admin_user_id', $rootScope.loggedInUserData.id);

          AuthService.uploadFile(`${$rootScope.api05DomainPath}support-ticket/add-exec`, formData, (response) => {
            if (response.success === true) {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {
                  if ($scope.model.isEditMode) {
                    $location.path(`/support-ticket/add/${response.individual_id}/${response.support_ticket_id}`);
                  } else {
                    $location.path(`/support-ticket/add/${response.individual_id}/${response.support_ticket_id}`);
                  }
                },
              });
            } else {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {},
              });
            }
          });
        }
      }
    };

    $scope.submitTicketAddComment = () => {
      if ($scope.model.formData.message !== '') {
        $scope.model.formData.supportTicketID = $stateParams.supportTicketID;
        const formData = new FormData();
        formData.append('formData', JSON.stringify($scope.model.formData));
        if (typeof $scope.model.attachments !== 'undefined') {
          for (const i in $scope.model.attachments) {
            formData.append('file[]', $scope.model.attachments[i]);
          }
          angular.forEach(angular.element("input[type='file']"), (inputElem) => {
            angular.element(inputElem).val(null);
          });
        }
        formData.append('admin_user_id', $rootScope.loggedInUserData.id);
        AuthService.uploadFile(`${$rootScope.api05DomainPath}support-ticket/add-message`, formData, (response) => {
          if (response.success === true) {
            ConfirmBoxService.open('Notification', response.message, {
              OK: () => {
                $scope.model.listingData.push(response.support_ticket_message);
                $scope.model.formData.message = '';
              },
            });
          } else {
            ConfirmBoxService.open('Notification', response.message, {
              OK: () => {},
            });
          }
        });
      }
    };

    $scope.changeStatusSupportTicket = () => {
      if (typeof $scope.model.ticketAssigneeData.status !== 'undefined') {
        ConfirmBoxService.open('Confirmation', 'Do you really want to update status?', {
          Yes: () => {
            $scope.model.formData.status = $scope.model.ticketAssigneeData.status;
            $scope.model.formData.supportTicketID = $stateParams.supportTicketID;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}support-ticket/change-status`,
              'POST',
              $scope.model.formData,
              (response) => {
                if (response.data.success === true) {
                  ConfirmBoxService.open('Notification', response.data.message, {
                    OK: () => {
                      $state.reload();
                    },
                  });
                } else {
                  ConfirmBoxService.open('Notification', response.data.message, {
                    OK: () => {},
                  });
                }
              },
            );
          },
          No: () => {
            $state.reload();
          },
        });
      }
    };

    $scope.searchTickets = () => {
      $scope.model.paginationCurrent = 1;
      get_support_ticket_agent_results(1);
    };

    /*
     Scope Methods
     */

    $scope.populateCarriers = (d) => {
      resetAllCarrierDropdowns();
      const carrierData = dataFactory.carrier_data[d.id].carrier_data;
      $scope.model.dropdowns.carriers = carrierData;
    };

    $scope.populateProductsCompanies = (d) => {
      resetProductsCompanies();
      let _companiesData = [];
      try {
        _companiesData =
          dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company[
            $scope.model.formData.coverage_type.id
          ][d.id];
      } catch (_e) {
        _companiesData = [];
      }
      const productsData =
        dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_products[
          $scope.model.formData.coverage_type.id
        ][d.id];

      $scope.model.is_company_enable = _companiesData.length > 0;

      // Append object at index 0
      const defaultCompany = { id: 0, company_name: '--' };
      const companiesData = HelpersService.insertObjectIntoArray(_companiesData, 0, defaultCompany);

      $scope.model.dropdowns.companies = companiesData;
      $scope.model.dropdowns.products = productsData;
    };

    $scope.populateCarrierCompanyProducts = (d) => {
      resetProducts();

      if (d.id === 0) {
        $scope.populateProductsCompanies($scope.model.formData.carrier);
      } else {
        const companyProductsData =
          dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company_product[
            $scope.model.formData.coverage_type.id
          ][$scope.model.formData.carrier.id][d.id];
        $scope.model.dropdowns.products = companyProductsData;
      }
    };

    //Search Individuals in dropdown
    $scope.refreshIndividuals = (search) => {
      if (search !== '') {
        const params = { q: search, individual_id: $scope.model.individualID };
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}support-ticket/searchIndividuals`,
          'POST',
          params,
          (response) => {
            $scope.model.individuals = response.data.individuals;
          },
        );
      }
    };

    //For Pagination page change trigger
    $scope.pageChanged = (newPage, module) => {
      if (module === 'Individual') {
        if ($scope.first_request !== true) {
          get_support_ticket_results(newPage);
        } else {
          $scope.first_request = false;
        }
      } else if (module === 'Agent') {
        if ($scope.first_request !== true) {
          get_support_ticket_agent_results(newPage);
        } else {
          $scope.first_request = false;
        }
      }
    };

    //For Listing by Status
    $scope.filter_listing_by_status = (status) => {
      $scope.selectedTab = status;
      $scope.model.filterListing = status;
      $scope.model.listingPerPage = '';

      //Reset Sorting
      $scope.model.sorting = {
        active: 'created_at',
        subject: '',
        individual: '',
        coverage_type: '',
        priority: '',
        status: '',
        created_at: 'desc',
      };

      //Reset Search Dropdowns
      $scope.model.formData.search.subject = '';
      $scope.model.formData.search.category_name = '';
      $scope.model.formData.search.coverage_type = {};
      $scope.model.formData.search.coverage_type = {};
      $scope.model.formData.search.priority = {};
      $scope.model.formData.search.status = {};
      //$scope.model.formData.search = {};

      $scope.model.paginationCurrent = 1;
      get_support_ticket_agent_results(1);
    };

    //For Remove Assignee
    $scope.removeAssignee = (id) => {
      if (id === '') {
        ConfirmBoxService.open('Error', 'Missing Parameters', {
          OK: () => {},
        });
      } else {
        const participants = $scope.model.participants;
        const last_participants = participants[Object.keys(participants)[Object.keys(participants).length - 1]].length;

        //If only 1 Assignee Left then Restrict to remove it.
        if (last_participants < 2) {
          ConfirmBoxService.open('Error', 'You cannot remove the last Assignee!', {
            OK: () => {},
          });
        } else {
          ConfirmBoxService.open('Confirmation', 'Do you really want to remove this Assignee?', {
            Yes: () => {
              AuthService.sendRequest(
                `${$rootScope.api05DomainPath}support-ticket/remove-assignee`,
                'POST',
                {
                  st_participant_id: id,
                },
                (response) => {
                  if (response.data.success === true) {
                    ConfirmBoxService.open('Notification', response.data.message, {
                      OK: () => {
                        $state.reload();
                      },
                    });
                  }
                },
              );
            },
            No: () => {},
          });
        }
      }
    };

    //For Pagination sorting columns
    $scope.sortListing = (a) => {
      for (const i in $scope.model.sorting) {
        if (i !== 'active' && i !== a) {
          $scope.model.sorting[i] = '';
        }
      }

      if ($scope.model.sorting.active !== a) {
        $scope.model.sorting.active = a;
        $scope.model.sorting[a] = a;
      }

      if ($scope.model.sorting[a] === 'asc') {
        $scope.model.sorting[a] = 'desc';
      } else {
        $scope.model.sorting[a] = 'asc';
      }

      if ($scope.model.type === 'view_st_individual') {
        $scope.model.paginationCurrent = 1;
        get_support_ticket_results(1);
      } else if ($scope.model.type === 'view_st_agent') {
        $scope.model.paginationCurrent = 1;
        get_support_ticket_agent_results(1);
      }
    };

    //For Listing Per page
    $scope.changePerPage = (perPageObj) => {
      $scope.model.listingPerPage = perPageObj.value;
      $scope.model.paginationCurrent = 1;
      get_support_ticket_agent_results(1);
    };

    //Make dropdowns of phone numbers
    $scope.individualSelected = (d) => {
      const phoneListing = [];
      if (d.phone_home) {
        phoneListing.push({
          value: d.phone_home,
          title: `${d.phone_home} (Home)`,
          type: 'Home',
        });
      }

      if (d.phone_business) {
        phoneListing.push({
          value: d.phone_business,
          title: `${d.phone_business} (Business)`,
          type: 'Business',
        });
      }
      if (d.phone_cell) {
        phoneListing.push({
          value: d.phone_cell,
          title: `${d.phone_cell} (Cell)`,
          type: 'Cell',
        });
      }
      if (d.phone_other) {
        phoneListing.push({
          value: d.phone_other,
          title: `${d.phone_other} (Other)`,
          type: 'Other',
        });
      }

      $scope.model.formData.individual_phone_number = '';
      $scope.model.dropdowns.phone_numbers = phoneListing;
    };

    //Remove Attachment
    $scope.removeAttachment = (attachment_file_id) => {
      ConfirmBoxService.prompt('Confirmation', 'Do you really want to remove this attachment?', {
        Yes: () => {
          const params = { attachment_file_id: attachment_file_id };
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}support-ticket/delete-attachment/`,
            'POST',
            params,
            (response) => {
              ConfirmBoxService.open('Notification', response.data.message, {
                OK: () => {
                  $state.reload();
                },
              });
            },
          );
        },
        No: () => {},
      });
    };

    /*
     Private Methods
     */
    function get_support_ticket_results(pageNumber) {
      const params = {
        page: pageNumber,
        individualID: $stateParams.individualID,
        view_by: $scope.model.view_by,
      };

      //If Sorting
      const activeColumn = $scope.model.sorting.active;
      const ascDesc = $scope.model.sorting[$scope.model.sorting.active];
      params.column = activeColumn;
      params.columnOrder = ascDesc;

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}support-ticket/view-individual/`,
        'GET',
        params,
        (response) => {
          if (response.data.success === true) {
            $scope.model.listingData = response.data.support_tickets;
            $scope.model.listingTotal = response.data.support_tickets_count;
            $scope.model.listingPerPage = response.data.per_page;
          }
        },
      );
    }

    // function get_support_ticket_report() {
    //   const params = {
    //     individualID: $stateParams.individualID,
    //     view_by: $scope.model.view_by,
    //   };
    //   AuthService.sendRequest(
    //     `${$rootScope.api05DomainPath}support-ticket/report-individual/`,
    //     'GET',
    //     params,
    //     (response) => {
    //       if (response.data.success === true) {
    //         $scope.model.total_tickets = response.data.total_tickets;
    //         $scope.model.assigned_tickets = response.data.assigned_tickets;
    //         $scope.model.close_tickets = response.data.close_tickets;
    //         $scope.model.complete_tickets = response.data.complete_tickets;
    //         $scope.model.in_progress_tickets = response.data.in_progress_tickets;
    //         $scope.model.assigned_tickets_percentage = response.data.assigned_tickets_percentage;
    //         $scope.model.close_tickets_percentage = response.data.close_tickets_percentage;
    //         $scope.model.complete_tickets_percentage = response.data.complete_tickets_percentage;
    //         $scope.model.in_progress_tickets_percentage = response.data.in_progress_tickets_percentage;
    //         $scope.model.individual = response.data.individual;
    //       }
    //     },
    //   );
    // }

    function get_support_ticket_agent_results(pageNumber) {
      const agentID = $stateParams.agentID === '' ? AuthService.getAuthServiceData().userData.id : $stateParams.agentID;
      const params = {
        page: pageNumber,
        per_page: $scope.model.listingPerPage,
        filter: $scope.model.filterListing,
      };
      params.search = $scope.model.formData.search;

      //If Sorting
      const activeColumn = $scope.model.sorting.active;
      const ascDesc = $scope.model.sorting[$scope.model.sorting.active];
      params.column = activeColumn;
      params.columnOrder = ascDesc;

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}support-ticket/view-agent/${agentID}`,
        'GET',
        params,
        (response) => {
          if (response.data.success === true) {
            $scope.model.listingData = response.data.support_tickets;
            $scope.model.listingTotal = response.data.support_tickets_count;
            $scope.model.listingPerPage = response.data.per_page;
            $scope.model.formData.per_page = {
              title: response.data.per_page,
              value: response.data.per_page,
            };
          }
        },
      );
    }

    function get_support_ticket_agent_report() {
      const agentID = $stateParams.agentID === '' ? AuthService.getAuthServiceData().userData.id : $stateParams.agentID;
      const params = {};
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}support-ticket/report-agent/${agentID}`,
        'GET',
        params,
        (response) => {
          if (response.data.success === true) {
            $scope.model.create_tickets = response.data.create_tickets;
            $scope.model.total_tickets = response.data.total_tickets;
            $scope.model.assigned_tickets = response.data.assigned_tickets;
            $scope.model.todo_tickets = response.data.todo_tickets;
            $scope.model.complete_tickets = response.data.complete_tickets;
            $scope.model.in_progress_tickets = response.data.in_progress_tickets;
            $scope.model.assigned_tickets_percentage = response.data.assigned_tickets_percentage;
            $scope.model.todo_tickets_percentage = response.data.todo_tickets_percentage;
            $scope.model.complete_tickets_percentage = response.data.complete_tickets_percentage;
            $scope.model.in_progress_tickets_percentage = response.data.in_progress_tickets_percentage;
            $scope.model.individual = response.data.individual;
          }
        },
      );
    }

    const resetAllCarrierDropdowns = () => {
      $scope.model.formData.carrier = '';
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };

    const resetProductsCompanies = () => {
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };

    const resetProducts = () => {
      $scope.model.formData.product = '';
    };
  },
];
