export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  'LoaderService',
  'ConfirmBoxService',
  'HelpersService',
  'FocusService',
  'DTOptionsBuilder',
  function AllocationReportController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    LoaderService,
    ConfirmBoxService,
    HelpersService,
    FocusService,
    DTOptionsBuilder,
  ) {
    $scope.model = {
      graph: {
        byAccountValue: {
          activeData: 'accu_with_interest',
          graphType: 'pie',
          options: {
            legend: {
              display: true,
              position: 'right',
              fullWidth: true,
              labels: {
                generateLabels: (chart) => {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label, i) => {
                      const meta = chart.getDatasetMeta(0);
                      const ds = data.datasets[0];
                      const arc = meta.data[i];
                      const custom = arc?.custom || {};
                      const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                      const arcOpts = chart.options.elements.arc;
                      const fill = custom.backgroundColor
                        ? custom.backgroundColor
                        : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                      const stroke = custom.borderColor
                        ? custom.borderColor
                        : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                      const bw = custom.borderWidth
                        ? custom.borderWidth
                        : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                      // We get the value of the current label
                      const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                      return {
                        // Instead of `text: label,`
                        // We add the value to the string
                        text: `${label} - ${value}`,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: Number.isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
            },
            responsive: true,
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  //get the concerned dataset
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  //calculate the total of this data set
                  const total = dataset.data.reduce(
                    (previousValue, currentValue, _currentIndex, _array) => previousValue + currentValue,
                  );
                  //get the current items value
                  const currentValue = dataset.data[tooltipItem.index];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  const precentage = Math.floor((currentValue / total) * 100 + 0.5);

                  return `${currentValue} - ${data.labels[tooltipItem.index]} (${precentage}%)`;
                },
              },
            },
            pieceLabel: {
              render: 'percentage',
              fontColor: 'black',
              precision: 2,
              position: 'outside',
            },
          },
          labels: [
            '<$25,000',
            '$25,000 - $50,000',
            '$50,001 - $100,000',
            '$100,001 - $250,000',
            '$250,001 - $500,000',
            '$500,001 - $1,000,000',
            '>$1,000,000',
          ],
          colors: ['#FF4000', '#FF8000', '#FFBF00', '#2EFE9A', '#0040FF', '#FF0080', '#088A4B', '#8A0886', '#9AFE2E'],
          data: [],
          barGraphData: [],
        },
        byProduct: {
          graphType: 'pie',
          options: {
            legend: {
              display: true,
              position: 'right',
              fullWidth: true,
              labels: {
                generateLabels: (chart) => {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label, i) => {
                      const meta = chart.getDatasetMeta(0);
                      const ds = data.datasets[0];
                      const arc = meta.data[i];
                      const custom = arc?.custom || {};
                      const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                      const arcOpts = chart.options.elements.arc;
                      const fill = custom.backgroundColor
                        ? custom.backgroundColor
                        : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                      const stroke = custom.borderColor
                        ? custom.borderColor
                        : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                      const bw = custom.borderWidth
                        ? custom.borderWidth
                        : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                      // We get the value of the current label
                      const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                      return {
                        // Instead of `text: label,`
                        // We add the value to the string
                        text: `${value} Accounts - ${label}`,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: Number.isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
            },
            responsive: true,
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  //get the concerned dataset
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  //calculate the total of this data set
                  const total = dataset.data.reduce(
                    (previousValue, currentValue, _currentIndex, _array) => previousValue + currentValue,
                  );
                  //get the current items value
                  const currentValue = dataset.data[tooltipItem.index];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  const precentage = Math.floor((currentValue / total) * 100 + 0.5);

                  return `${currentValue} - ${data.labels[tooltipItem.index]} (${precentage}%)`;
                },
              },
            },
            pieceLabel: {
              render: 'percentage',
              fontColor: 'black',
              precision: 2,
              position: 'outside',
            },
          },
          labels: [],
          colors: ['#FF4000', '#FF8000', '#FFBF00', '#2EFE9A', '#0040FF', '#FF0080', '#088A4B', '#8A0886', '#9AFE2E'],
          data: [],
          barGraphData: [],
        },
        byProductValue: {
          activeData: 'accu_with_interest',
          graphType: 'pie',
          options: {
            legend: {
              display: true,
              position: 'right',
              fullWidth: true,
              labels: {
                generateLabels: (chart) => {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label, i) => {
                      const meta = chart.getDatasetMeta(0);
                      const ds = data.datasets[0];
                      const arc = meta.data[i];
                      const custom = arc?.custom || {};
                      const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                      const arcOpts = chart.options.elements.arc;
                      const fill = custom.backgroundColor
                        ? custom.backgroundColor
                        : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                      const stroke = custom.borderColor
                        ? custom.borderColor
                        : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                      const bw = custom.borderWidth
                        ? custom.borderWidth
                        : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                      // We get the value of the current label
                      const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                      return {
                        // Instead of `text: label,`
                        // We add the value to the string
                        text: `${label} - ${HelpersService.amountFormatter(value)}`,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: Number.isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
            },
            responsive: true,
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  //get the concerned dataset
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  //calculate the total of this data set
                  const total = dataset.data.reduce(
                    (previousValue, currentValue, _currentIndex, _array) => previousValue + currentValue,
                  );
                  //get the current items value
                  const currentValue = dataset.data[tooltipItem.index];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  const precentage = Math.floor((currentValue / total) * 100 + 0.5);

                  return `${currentValue} - ${data.labels[tooltipItem.index]} (${precentage}%)`;
                },
              },
            },
            pieceLabel: {
              render: 'percentage',
              fontColor: 'black',
              precision: 2,
              position: 'outside',
            },
          },
          barOptions: {
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                    label += ': ';
                  }
                  label += HelpersService.amountFormatter(tooltipItem.xLabel);
                  return label;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: (value, _index, _values) => HelpersService.amountFormatter(value),
                  },
                },
              ],
            },
          },
          labels: [
            '<$25,000',
            '$25,000 - $50,000',
            '$50,001 - $100,000',
            '$100,001 - $250,000',
            '$250,001 - $500,000',
            '$500,001 - $1,000,000',
            '>$1,000,000',
          ],
          colors: ['#FF4000', '#FF8000', '#FFBF00', '#2EFE9A', '#0040FF', '#FF0080', '#088A4B', '#8A0886', '#9AFE2E'],
          barColors: [
            '#FF4000',
            '#FF8000',
            '#FFBF00',
            '#2EFE9A',
            '#0040FF',
            '#FF0080',
            '#088A4B',
            '#8A0886',
            '#9AFE2E',
          ],
          data: [],
          barGraphData: [],
        },
      },
      tab: {
        activeTab: $stateParams.type,
      },
      filters: {
        carrier: $stateParams.type,
        search: '',
        sorting: {
          column: 'full_name',
          order: 'ASC',
        },
      },
      formData: {
        lincoln_all: false,
        lincoln: {},
      },
      data: {
        lincoln: [],
        north_american: [],
        lincoln_count: 0,
        north_american_count: 0,
      },
    };
    $scope.dtOptions = DTOptionsBuilder.newOptions().withOption('lengthMenu', [
      [10, 25, 50, 100, 150, -1],
      [10, 25, 50, 100, 150, 'All'],
    ]);

    const init = () => {
      LoaderService.startInterval(100);
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}report/allocation/get`,
        'GET',
        { per_page: -1, filters: $scope.model.filters },
        (response) => {
          $scope.model.data = response.data.data;
          $scope.createGraphData(response.data.data);
          LoaderService.stopInterval(100);
        },
      );
    };
    init();

    $scope.loadMoreData = (r) => {
      r.per_page = r.perpage;
      r.page = r.activePage;
      r.filters = $scope.model.filters;
      AuthService.sendRequest(`${$rootScope.api05DomainPath}report/allocation/get`, 'GET', r, (response) => {
        for (const i in response.data.data[r.additional]) {
          $scope.model.data[r.additional].push(response.data.data[r.additional][i]);
          $scope.model.formData[r.additional][response.data.data[r.additional][i].allocation_id] =
            $scope.model.formData.lincoln_all;
        }
      });
    };

    $scope.selectAll = (v, _t, e) => {
      e.stopPropagation();
      for (const i in $scope.model.formData.lincoln) {
        $scope.model.formData.lincoln[i] = v;
      }
    };

    $scope.loadData = () => {
      init();
    };
    $scope.filterResult = (v) => {
      if (typeof v !== 'undefined' && v !== '') {
        init();
      }
    };

    $scope.sendAllocationsViaEmail = (_t) => {
      ConfirmBoxService.prompt('Confirmation', 'Do you really want to send allocation(s) to client(s).', {
        Yes: () => {
          const data = $scope.model.formData;
          AuthService.sendRequest(`${$rootScope.api05DomainPath}report/allocation/send`, 'POST', data, (_response) => {
            ConfirmBoxService.open(
              'Alloctions Cron',
              'Alloctions Cron has been triggered, System will send you an email when its done.',
              {
                OK: () => {},
              },
            );
          });
        },
        No: () => {},
      });
    };

    $scope.sendTestAllocationsViaEmail = (_t) => {
      ConfirmBoxService.prompt(
        'Confirmation',
        'Please enter an email address.',
        {
          SEND: (model) => {
            if (typeof model.emailAddress === 'undefined') {
              FocusService('emailAddress');
              return false;
            }
            const data = $scope.model.formData;
            data.testEmailAddress = model.emailAddress;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}report/allocation/send`,
              'POST',
              data,
              (_response) => {
                ConfirmBoxService.open(
                  'Alloctions Cron',
                  'Alloctions Cron has been triggered, System will send you an email when its done.',
                  {
                    OK: () => {},
                  },
                );
              },
            );
          },
          CANCEL: () => {},
        },
        [
          {
            title: 'Email',
            placeholder: 'Email',
            modelName: 'emailAddress',
            type: 'email',
          },
        ],
      );
    };

    $scope.sortData = (col) => {
      if (col === $scope.model.filters.sorting.column && $scope.model.filters.sorting.order === 'ASC') {
        $scope.model.filters.sorting.order = 'DESC';
      } else {
        $scope.model.filters.sorting.column = col;
        $scope.model.filters.sorting.order = 'ASC';
      }
      init();
    };

    $scope.swithGraphData = (graph, dataType) => {
      $scope.model.graph[graph].activeData = dataType;
      if (graph === 'byAccountValue') {
        _GraphByAccountValue($scope.model.data);
      }
    };
    $scope.swithGraph = (graph, dataType) => {
      $scope.model.graph[graph].graphType = dataType;
      if (graph === 'byAccountValue') {
        _GraphByAccountValue($scope.model.data);
      } else {
        _GraphByProductValue($scope.model.data);
      }
    };

    $scope.createGraphData = (data) => {
      _GraphByAccountValue(data);
      _GraphByProduct(data);
      _GraphByProductValue(data);
    };

    const _GraphByAccountValue = (data) => {
      const graphSeries = [0, 0, 0, 0, 0, 0, 0];
      for (const i in data.lincoln) {
        const row = data.lincoln[i];
        let accumulationValueWithInterest = 0;
        if ($scope.model.graph.byAccountValue.activeData === 'accu_with_interest') {
          accumulationValueWithInterest = HelpersService.cleanNumber(
            Number(row.allocationData.allocationSheetData.sheetReport.total_percent_earned) < 0
              ? row.allocationData.allocationSheetData.sheetReport.accumulation_without_interest
              : row.allocationData.allocationSheetData.sheetReport.indexed_accumulation,
          );
        } else {
          accumulationValueWithInterest = HelpersService.cleanNumber(
            row.allocationData.allocationSheetData.sheetReport.accumulation_without_interest,
          );
        }

        if (accumulationValueWithInterest < 25000) {
          graphSeries[0]++;
        } else if (accumulationValueWithInterest >= 25000 && accumulationValueWithInterest <= 50000) {
          graphSeries[1]++;
        } else if (accumulationValueWithInterest >= 50001 && accumulationValueWithInterest <= 100000) {
          graphSeries[2]++;
        } else if (accumulationValueWithInterest >= 100001 && accumulationValueWithInterest <= 250000) {
          graphSeries[3]++;
        } else if (accumulationValueWithInterest >= 250001 && accumulationValueWithInterest <= 500000) {
          graphSeries[4]++;
        } else if (accumulationValueWithInterest >= 500001 && accumulationValueWithInterest <= 1000000) {
          graphSeries[5]++;
        } else if (accumulationValueWithInterest >= 1000001) {
          graphSeries[6]++;
        }
      }
      $scope.model.graph.byAccountValue.data = graphSeries;
      $scope.model.graph.byAccountValue.barGraphData = [graphSeries];
    };

    const _GraphByProduct = (data) => {
      const labels = [];
      const graphSeries = [];
      const products = {};
      for (const i in data.lincoln) {
        const row = data.lincoln[i];
        if (typeof products[row.product_id] === 'undefined') {
          products[row.product_id] = {
            count: 0,
            product_id: row.product_id,
            product_title: row.product_name,
          };
        }
        products[row.product_id].count++;
      }

      for (const i in products) {
        graphSeries.push(products[i].count);
        labels.push(products[i].product_title);
      }
      $scope.model.graph.byProduct.data = graphSeries;
      $scope.model.graph.byProduct.barGraphData = [graphSeries];
      $scope.model.graph.byProduct.labels = labels;
    };

    const _GraphByProductValue = (data) => {
      const labels = [];
      const graphSeries = [];
      const products = {};
      for (const i in data.lincoln) {
        const row = data.lincoln[i];
        let accumulationValueWithInterest = 0;
        if ($scope.model.graph.byProductValue.activeData === 'accu_with_interest') {
          accumulationValueWithInterest = HelpersService.cleanNumber(
            Number(row.allocationData.allocationSheetData.sheetReport.total_percent_earned) < 0
              ? row.allocationData.allocationSheetData.sheetReport.accumulation_without_interest
              : row.allocationData.allocationSheetData.sheetReport.indexed_accumulation,
          );
        } else {
          accumulationValueWithInterest = HelpersService.cleanNumber(
            row.allocationData.allocationSheetData.sheetReport.accumulation_without_interest,
          );
        }
        if (typeof products[row.product_id] === 'undefined') {
          products[row.product_id] = {
            product_title: row.product_name,
            value: 0,
          };
        }
        products[row.product_id].value += accumulationValueWithInterest;
      }

      for (const i in products) {
        graphSeries.push(products[i].value);
        labels.push(products[i].product_title);
      }
      $scope.model.graph.byProductValue.data = graphSeries;
      $scope.model.graph.byProductValue.barGraphData = [graphSeries];
      $scope.model.graph.byProductValue.labels = labels;
    };
  },
];
