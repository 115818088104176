// import constants from '../utils/constants';
export const AppComponent = {
  bindings: {},
  // NOTICE!!!
  // This is the only component HTML that should be loaded via `template`
  // ALl others should use `templateUrl` with the cache-busting random number
  // The reason for this is how NGINX handles caching on builds
  template: `
  <!-- <navbar></navbar> -->
  <!-- <page-home></page-home> -->
  <main class="main-container">
    <section ng-class="{ activeAlertBox: alertActive == true }" class="alert-box alert alert-success">
      {{ alertText }}
    </section>
    <ui-view name="header"></ui-view>

    <div class="ms-relative ms-z-40 ms-flex ms-h-[calc(100vh-4.375rem)]">
      <ui-view name="navmenu"></ui-view>

      <article id="content-container"
        class="printable-content ms-h-full ms-flex-1 ms-overflow-auto [&>.page-container]:ms-h-full [&>.page-container]:ms-overflow-y-auto">
        <ui-view name="individualsheader"></ui-view>
        <ui-view name="content"></ui-view>
      </article>
    </div>
  </main>

  <div ng-show="$root.confirmBox.open" class="ui-confirm-box">
    <div class="ms-bg-base-100 ms-min-h-28 ms-w-1/4 ms-rounded-lg">
      <div class="header">{{ $root.confirmBox.heading }}</div>
      <div class="body ms-min-h-20">
        <h4 class="ms-text-lg ms-font-sans ms-font-light">{{ $root.confirmBox.description }}</h4>
        <div class="ms-my-2" ng-class="{ formSection: $root.confirmBox.form == true }"
          ng-repeat="(k,d) in $root.confirmBox.fields">
          <label class="input-field">
          <input autocomplete="off" id="{{ $root.confirmBox.fields[k].modelName }}"
            name="{{ $root.confirmBox.fields[k].modelName }}" ng-readonly="d.readonly" type="{{ d.type }}"
            class="input-control" title="{{ d.title }}" ng-model="$root.confirmBox.fields[k].modelValue"
            placeholder="{{ d.placeholder }}" />
            </label>
        </div>
      </div>
      <div class="footer">
        <div class="ms-flex ms-justify-end ms-gap-3">
          <button class="ms-dui-btn ms-dui-btn-primary"
            ng-click="$root.confirmBox.click(button);" ng-repeat="(key,button) in $root.confirmBox.actions">
            {{ key.replace("_", " ") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div ng-show="$root.appLoading" class="ms-flex ms-justify-center ms-items-center app-loader">
    <svg class="ms-h-24 ms-w-24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <radialGradient id="a8" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
        <stop offset="0" stop-color="#464646"></stop>
        <stop offset=".3" stop-color="#464646" stop-opacity=".9"></stop>
        <stop offset=".6" stop-color="#464646" stop-opacity=".6"></stop>
        <stop offset=".8" stop-color="#464646" stop-opacity=".3"></stop>
        <stop offset="1" stop-color="#464646" stop-opacity="0"></stop>
      </radialGradient>
      <circle transform-origin="center" fill="none" stroke="url(#a8)" stroke-width="15" stroke-linecap="round"
        stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
        <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1"
          keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform>
      </circle>
      <circle transform-origin="center" fill="none" opacity=".2" stroke="#464646" stroke-width="15" stroke-linecap="round"
        cx="100" cy="100" r="70"></circle>
    </svg>
  </div>

  <svg class="ms-absolute ms-h-0 ms-w-0 ms-overflow-hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 36">
    <symbol id="arrow-angle" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        d="M5.25 11.1728C5.14929 11.1694 5.05033 11.1455 4.9592 11.1025C4.86806 11.0595 4.78666 10.9984 4.72 10.9228C4.57955 10.7822 4.50066 10.5916 4.50066 10.3928C4.50066 10.1941 4.57955 10.0035 4.72 9.86283L7.72 6.86283L4.72 3.86283C4.66067 3.71882 4.64765 3.55991 4.68275 3.40816C4.71785 3.25642 4.79932 3.11936 4.91585 3.01602C5.03238 2.91268 5.17819 2.84819 5.33305 2.83149C5.4879 2.81479 5.64411 2.84671 5.78 2.92283L9.28 6.42283C9.42045 6.56346 9.49934 6.75408 9.49934 6.95283C9.49934 7.15158 9.42045 7.34221 9.28 7.48283L5.78 10.9228C5.71333 10.9984 5.63193 11.0595 5.5408 11.1025C5.44966 11.1455 5.35071 11.1694 5.25 11.1728Z"
        fill="currentColor"></path>
    </symbol>

    <symbol id="dubble-arrow-angle" viewBox="0 0 14 14" fill="none"
      xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M7.68757 11.1451C7.7791 11.1831 7.8773 11.2024 7.9764 11.2019C8.07769 11.1985 8.17721 11.1745 8.26886 11.1312C8.36052 11.088 8.44238 11.0265 8.50943 10.9505L12.0294 7.49085C12.1707 7.34942 12.25 7.15771 12.25 6.95782C12.25 6.75794 12.1707 6.56622 12.0294 6.42479L8.50943 2.90479C8.37014 2.82159 8.20774 2.78551 8.04633 2.80192C7.88491 2.81833 7.73309 2.88635 7.6134 2.99588C7.4937 3.10541 7.41252 3.25061 7.38189 3.40994C7.35126 3.56927 7.37282 3.73423 7.44337 3.88033L10.4605 6.89748L7.44337 9.91463C7.30212 10.0561 7.22278 10.2478 7.22278 10.4477C7.22278 10.6475 7.30212 10.8393 7.44337 10.9807C7.51301 11.0512 7.59603 11.1071 7.68757 11.1451ZM1.94207 10.9505C2.07037 11.0968 2.25089 11.1871 2.44493 11.2019C2.63898 11.1871 2.81949 11.0968 2.94779 10.9505L6.46779 7.49085C6.60905 7.34942 6.68839 7.15771 6.68839 6.95782C6.68839 6.75793 6.60905 6.56622 6.46779 6.42479L2.94779 2.90479C2.80704 2.83757 2.6489 2.81563 2.49517 2.84201C2.34143 2.86839 2.19965 2.94178 2.08936 3.05207C1.97906 3.16237 1.90567 3.30415 1.8793 3.45788C1.85292 3.61162 1.87485 3.76975 1.94207 3.9105L4.95922 6.92765L1.94207 9.9448C1.81838 10.0831 1.75 10.2621 1.75 10.4477C1.75 10.6332 1.81838 10.8122 1.94207 10.9505Z"
        fill="currentColor"></path>
    </symbol>

    <symbol id="history" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" x="0"
      y="0" viewBox="0 0 512 512">
      <g>
        <path
          d="M226.36 160.92c-9.67 0-17.51 7.84-17.51 17.51v95.28c0 27.17 22.1 49.27 49.27 49.27h63.52c9.67 0 17.51-7.84 17.51-17.51s-7.84-17.51-17.51-17.51h-63.52c-7.86 0-14.25-6.39-14.25-14.25v-95.28c0-9.68-7.84-17.51-17.51-17.51z"
          fill="currentColor" opacity="1" data-original="currentColor" class=""></path>
        <path
          d="M431.99 87.12c-47.01-47-109.51-72.89-175.99-72.89-36.28 0-71.78 7.84-104.29 22.85l5.16-13.19c3.52-9.01-.93-19.16-9.93-22.68-9.01-3.52-19.16.93-22.68 9.93l-21.59 55.23a24.032 24.032 0 0 0 .71 19.08 24.053 24.053 0 0 0 14.43 12.5l56.58 17.74c1.74.55 3.51.81 5.24.81 7.45 0 14.35-4.79 16.7-12.27 2.89-9.23-2.24-19.05-11.47-21.94l-27.93-8.76c30.43-15.93 64.32-24.27 99.06-24.27 57.13 0 110.83 22.25 151.23 62.64 40.39 40.4 62.64 94.1 62.64 151.23s-22.25 110.84-62.64 151.23c-40.4 40.39-94.1 62.64-151.23 62.64s-110.84-22.25-151.23-62.64c-40.4-40.4-62.64-94.1-62.64-151.23 0-33.98 7.73-66.46 22.97-96.54 4.37-8.62.92-19.16-7.7-23.53s-19.16-.92-23.53 7.7C16.11 185.78 7.11 223.58 7.11 263.11c0 66.48 25.89 128.98 72.9 175.99S189.52 512 256 512s128.98-25.89 175.99-72.9 72.9-109.51 72.9-175.99S479 134.13 431.99 87.12z"
          fill="currentColor" opacity="1" data-original="currentColor" class=""></path>
      </g>
    </symbol>

    <symbol id="plus" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" width="1.5" height="12" fill="currentColor"></rect>
      <rect x="13" y="5" width="1.5" height="13" transform="rotate(90 13 5)" fill="currentColor"></rect>
    </symbol>

    <symbol id="magnifier" viewBox="0 0 18 18" fill="none">
      <path
        d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M17.0004 17.0004L13.1338 13.1338" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round"></path>
    </symbol>

    <symbol id="close" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
        fill="currentColor"></path>
    </symbol>

    <symbol id="arrow-back" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 10.5L1 6M1 6L5.5 1.5M1 6L17 6" stroke="currentColor" stroke-width="1.8" stroke-linecap="round"
        stroke-linejoin="round"></path>
    </symbol>

    <symbol id="send" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M15.2014 21.4996L21.5 2.5L2.50001 8.551L11.5065 12.4935L15.2014 21.4996Z" stroke="currentColor"
        stroke-width="1.5" stroke-linejoin="round" />
      <path d="M11.5 12.5L15 9" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    </symbol>

    <symbol id="calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="injected-svg"
      data-src="https://cdn.hugeicons.com/icons/calendar-03-stroke-sharp.svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      role="img" color="currentColor">
      <path d="M11.9955 13.5H12.0045M11.9955 17.5H12.0045M15.991 13.5H16M8 13.5H8.00897M8 17.5H8.00897"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path>
      <path d="M17.5 2V6M6.5 2V6" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M21 4H3V22H21V4Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M3 9H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </symbol>

    <symbol id="trash" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0107 14.9981H5.13069C4.43069 14.9981 3.80069 14.4381 3.73069 13.7381L2.4707 3.09814H13.6707L12.4107 13.7381C12.2707 14.4381 11.7107 14.9981 11.0107 14.9981Z"
        stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M1 3.09814H15" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
      </path>
      <path d="M8 5.90186V12.2019" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"></path>
      <path d="M10.0996 5.90186V12.2019" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"></path>
      <path d="M5.90039 5.90186V12.2019" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"></path>
      <path d="M4.5 3.1L5.9 1H10.1L11.5 3.1" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"></path>
    </symbol>

    <symbol id="edit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
      xmlns:xlink="http://www.w3.org/1999/xlink" role="img" color="currentColor">
      <path d="M14 7L5 16L4 20L8 19L17 10M14 7L17 4L20 7L17 10M14 7L17 10" stroke="currentColor" stroke-width="1.5"
        stroke-linejoin="round"></path>
      <path d="M11 20H18" stroke="currentColor" stroke-width="1.5"></path>
    </symbol>

    <symbol id="pdf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <path d="M20.001 10.0157V2.08424C20.001 2.02909 19.9562 1.98438 19.901 1.98438H9.99881L2.99805 8.9798V21.9883H19.9946M9.99881 2.51735V8.9681L3.56766 8.97885" stroke="currentColor" stroke-width="2"></path>
      <path d="M7.00682 15.5178L6.99031 12.9677H8.411C8.98672 12.9677 9.58273 13.1885 9.84457 13.7005C10.0613 14.1244 10.0462 14.4766 9.81841 14.8619C9.54913 15.3174 8.9995 15.5031 8.4698 15.507L7.00682 15.5178ZM7.00682 15.5178L6.99023 18.4788M21 12.9912H18.1085C18.0532 12.9912 18.0085 13.0359 18.0085 13.0911L18.0087 15.4876M17.9854 18.4788L18.0087 15.4876M18.0087 15.4876H20.1759M12.4941 12.9405L12.4943 17.9834H12.9585C14.2884 17.9834 15.3999 16.9727 15.5241 15.6504C15.5425 15.4554 15.5272 15.2587 15.479 15.0688L15.4629 15.0052C15.2663 14.2301 14.7838 13.5298 14.0508 13.208C13.4241 12.9329 13.0515 12.916 12.4941 12.9405Z" stroke="currentColor" stroke-width="2"></path>
    </symbol>

    <symbol id="file" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
      <path d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 7H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 11H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </symbol>

    <symbol id="ticket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" data-src="https://cdn.hugeicons.com/icons/ticket-02-stroke-sharp.svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <path d="M17.9901 16.0128L8.0127 6.04199" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M1.00144 12.9377C0.998168 12.941 0.998226 12.9463 1.00157 12.9496L4.61604 16.4894C5.28253 15.8178 5.79822 15.3921 7.00488 15.6826C7.88457 15.8944 8.42091 16.7563 8.48628 17.5307C8.51723 17.8973 8.45705 18.2798 8.28797 18.6051C8.16523 18.8414 7.95581 19.1322 7.60997 19.4565L11.1839 22.9985C11.1873 23.0018 11.1926 23.0018 11.1958 22.9985L22.9986 11.1049C23.0018 11.1017 23.0018 11.0963 22.9985 11.0931L19.4245 7.55108C19.1028 7.89939 18.8135 8.11109 18.5782 8.23571C18.254 8.40738 17.8719 8.4707 17.5047 8.44283C16.7293 8.38396 15.8623 7.85518 15.6431 6.97787C15.3424 5.77446 15.7641 5.25559 16.4306 4.58397L12.8584 1.00152C12.8551 0.998204 12.8497 0.998191 12.8465 1.00149L1.00144 12.9377Z" stroke="currentColor" stroke-width="1.5"></path>
    </symbol>

    <symbol id="link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <path d="M8.00195 15.9994L16.0004 7.99951" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M15.125 11.625L16.5 13L22 7.5L16.5 2L11 7.5L12.375 8.875" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M11.625 15.125L13 16.5L7.5 22L2 16.5L7.5 11L8.875 12.375" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
    </symbol>

    <symbol id="external-link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M11.0988 3.00012C7.4498 3.00669 5.53898 3.09629 4.31783 4.31764C3 5.63568 3 7.75704 3 11.9997C3 16.2424 3 18.3638 4.31783 19.6818C5.63565 20.9999 7.75668 20.9999 11.9987 20.9999C16.2407 20.9999 18.3617 20.9999 19.6796 19.6818C20.9007 18.4605 20.9903 16.5493 20.9969 12.8998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.5561 3.49637L11.0488 13.0589M20.5561 3.49637C20.0622 3.00175 16.7345 3.04785 16.031 3.05786M20.5561 3.49637C21.0501 3.99098 21.0041 7.32297 20.9941 8.02738" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </symbol>

    <symbol id="floppy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M8 22V19C8 17.1144 8 16.1716 8.58579 15.5858C9.17157 15 10.1144 15 12 15C13.8856 15 14.8284 15 15.4142 15.5858C16 16.1716 16 17.1144 16 19V22" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
      <path d="M10 7H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 11.8584C3 7.28199 3 4.99376 4.38674 3.54394C4.43797 3.49038 4.49038 3.43797 4.54394 3.38674C5.99376 2 8.28199 2 12.8584 2C13.943 2 14.4655 2.00376 14.9628 2.18936C15.4417 2.3681 15.8429 2.70239 16.6452 3.37099L18.8411 5.20092C19.9027 6.08561 20.4335 6.52795 20.7168 7.13266C21 7.73737 21 8.42833 21 9.81025V13C21 16.7497 21 18.6246 20.0451 19.9389C19.7367 20.3634 19.3634 20.7367 18.9389 21.0451C17.6246 22 15.7497 22 12 22C8.25027 22 6.3754 22 5.06107 21.0451C4.6366 20.7367 4.26331 20.3634 3.95491 19.9389C3 18.6246 3 16.7497 3 13V11.8584Z" stroke="currentColor" stroke-width="1.5" />
    </symbol>

    <symbol id="son" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></symbol>

    <symbol id="moon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></symbol>

    <symbol id="print" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <path d="M17 18H22V8H2V18H7" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M17 8V2H7V8" stroke="currentColor" stroke-width="1.5"></path>
      <path d="M17 16V22H7V16H17Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M18 12H18.009" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path>
    </symbol>
    <symbol id="download" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="injected-svg" data-src="https://cdn.hugeicons.com/icons/inbox-download-stroke-sharp.svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <path d="M16 3H21V21H3V3H8" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
      <path d="M21 14H15C15 15.6569 13.6569 17 12 17C10.3431 17 9 15.6569 9 14H3" stroke="currentColor" stroke-width="1.5"></path>
      <path d="M9 8L12 11L15 8M12 3V10.5895" stroke="currentColor" stroke-width="1.5"></path>
    </symbol>
  </svg>


  <!-- <md-toolbar layout="column" layout-align="center center">Copyright © 2018-2024 Main Street</md-toolbar> -->
  `,
};
