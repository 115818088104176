import constants from '../utils/constants';
const commissionsRoutes = ($stateProvider) => {
  $stateProvider.state('/commissions', {
    url: '/commissions',
    params: {
      type: 'commision-reports',
    },
    views: {
      content: {
        templateUrl: `${constants.templatesDir}commissions/main_page.html?v=${constants.randomNumber}`,
        controller: 'CommissionsController',
      },
      navmenu: {
        templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
      },
      header: {
        templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
        controller: 'HeaderController',
      },
    },
  });
};

export default commissionsRoutes;
