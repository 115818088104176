export default class fileModelDirective {
  constructor($parse) {
    this.$parse = $parse;
  }

  directive() {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        const model = this.$parse(attrs.fileModel);
        const modelSetter = model.assign;

        element.bind('change', () => {
          scope.$apply(() => {
            // Example of setting the file to the model
            if (modelSetter) {
              modelSetter(scope, element[0].files[0]);
              // For handling all files: modelSetter(scope, element[0].files);
            }

            // Optional: call a scope function if needed, e.g., scope.uploadFile(...)
          });
        });
      },
    };
  }

  static factory($parse) {
    const directive = new fileModelDirective($parse);
    return directive.directive.bind(directive);
  }
}

// Dependency Injection
fileModelDirective.factory.$inject = ['$parse'];
