export default [
  '$scope',
  function HeaderController($scope) {
    $scope.isLightTheme = true;
    $scope.dataTheme = 'msfp';

    $scope.themeChange = () => {
      document.querySelectorAll('[data-theme]').forEach((element) => {
        element.classList.toggle('ms-light');
        element.classList.toggle('ms-dark');
      });
    };

    $scope.onDataThemeChange = (themeValue) => {
      $scope.isLightTheme = true;
      document.querySelectorAll('[data-theme]').forEach((element) => {
        element.setAttribute('class', 'ms-light');
        element.setAttribute('data-theme', themeValue);
      });
    };
  },
];
