import constants from '../utils/constants';
const allocationsRoutes = ($stateProvider) => {
  $stateProvider
    .state('/report/allocation/:type', {
      url: '/report/allocation/:type',
      params: {},
      views: {
        content: {
          templateUrl: `${constants.templatesDir}allocation/report/allocations_listing.html?v=${constants.randomNumber}`,
          controller: 'AllocationReportController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/individuals/:individualID/allocation-sheet/view', {
      url: '/individuals/:individualID/allocation-sheet/view',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}allocation/allcoationList.html?v=${constants.randomNumber}`,
          controller: 'AllocationSheetController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/allocation-sheet/add', {
      url: '/individuals/:individualID/allocation-sheet/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}allocation/allcoationAdd.html?v=${constants.randomNumber}`,
          controller: 'AllocationSheetController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/allocation-sheet/:allocationSheetID/edit', {
      url: '/individuals/:individualID/allocation-sheet/:allocationSheetID/edit',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}allocation/allcoationEdit.html?v=${constants.randomNumber}`,
          controller: 'AllocationSheetController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    });
};

export default allocationsRoutes;
