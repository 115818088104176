export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'ValidationService',
  function FaxController($rootScope, $scope, AuthService, $stateParams, $state, ConfirmBoxService, ValidationService) {
    $scope.first_request = true;
    $scope.faxID = $stateParams.faxID;

    $scope.model = {
      dropdowns: {},
      type: $stateParams.type,
      formData: {
        users: [],
      },
      listingData: [],
      listingTotal: 0,
      listingPerPage: 0,
      paginationCurrent: 1,
      individualID: null,
      is_carriers_enable: false,
    };

    $scope.model.faxID = $scope.faxID;

    $rootScope.subMenuActive = '';
    if ($scope.model.type === 'add_modify_fax') {
    } else if ($scope.model.type === 'view_fax') {
      get_all_fax_results(1);
    }

    /*
     Submit Forms
     */

    $scope.submitFaxAddForm = () => {
      if ($scope.model.type === 'add_modify_fax') {
        const validated = ValidationService.validate($scope, {
          'model.formData.fax_to_number': {
            required: true,
            errorMessage: 'The Fax to number is required.',
          },
        });

        if (validated) {
          if ($stateParams.faxID !== '' && typeof $stateParams.faxID !== 'undefined') {
            $scope.model.formData.faxID = $stateParams.faxID;
          }

          const formData = new FormData();
          formData.append('formData', JSON.stringify($scope.model.formData));
          if (typeof $scope.model.attachments !== 'undefined') {
            for (const i in $scope.model.attachments) {
              formData.append('file[]', $scope.model.attachments[i]);
            }
          }
          formData.append('admin_user_id', $rootScope.loggedInUserData.id);

          AuthService.uploadFile(`${$rootScope.api05DomainPath}fax/save`, formData, (response) => {
            if (response.success === true) {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {
                  $state.reload();
                },
              });
            } else {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {},
              });
            }
          });
        }
      }
    };

    /*
     Scope Methods
     */

    /*
     Private Methods
     */
    function get_all_fax_results(_pageNumber) {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}fax/get-all/`, 'GET', {}, (response) => {
        if (response.data.success === true) {
          $scope.model.listingData = response.data.fax;
        }
      });
    }
  },
];
