export default class ngFileModelDirective {
  constructor($parse) {
    this.restrict = 'A'; // restrict the directive to be used as an attribute
    this.$parse = $parse;
  }

  link(scope, element, attrs) {
    const model = this.$parse(attrs.ngFileModel);
    const isMultiple = attrs.multiple;
    const modelSetter = model.assign;

    element.on('change', () => {
      const values = Array.from(element[0].files).map((item) => ({
        name: item.name,
        size: item.size,
        url: URL.createObjectURL(item),
        _file: item,
      }));

      scope.$apply(() => {
        if (isMultiple) {
          modelSetter(scope, values);
        } else {
          modelSetter(scope, values[0]);
        }
      });
    });
  }

  static factory($parse) {
    const directive = new ngFileModelDirective($parse);
    return directive;
  }
}

// Dependency Injection
ngFileModelDirective.factory.$inject = ['$parse'];
