import AddressBookController from './addressBook.controller';
import AdminUsersController from './adminUsers.controller';
import AllocationReportController from './allocationReport.controller';
import AllocationSheetController from './allocationSheet.controller';
import BudgetAttributesController from './allocationSheet.controller';
import BudgetCategoriesController from './budgetCategories.controller';
import CarriersController from './carriers.controller';
import ChartsController from './charts.controller';
import CommissionsController from './commissions.controller';
import DashboardController from './dashboard.controller';
import EmailNewsletterController from './emailNewsletter.controller';
import ExportIndividualReportController from './exportIndividualReport.controller';
import FaxController from './fax.controller';
import HeaderController from './header.controller';
import IndexController from './index.controller';
import IndividualController from './individual.controller';
import IndividualBudgetSheetController from './individualBudgetSheet.controller';
import IndividualDoctorController from './individualDoctor.controller';
import IndividualIhsController from './individualIhs.controller';
import IndividualMedicareController from './individualMedicare.controller';
import IndividualNotesAttachmentsController from './individualNotesAttachments.controller';
import LeadsController from './leads.controller';
import MedicareCommissionController from './medicareCommission.controller';
import MedicarePolicyController from './medicarePolicy.controller';
import NotificationsController from './notifications.controller';
import PropertyAndCasualtyController from './propertyAndCasualty.controller';
import SnpValuesController from './snpValues.controller';
import SupportTicketController from './supportTicket.controller';
import UserController from './user.controller';

export const appControllers = {
  AddressBookController,
  AdminUsersController,
  AllocationReportController,
  AllocationSheetController,
  BudgetAttributesController,
  BudgetCategoriesController,
  CarriersController,
  ChartsController,
  CommissionsController,
  DashboardController,
  EmailNewsletterController,
  ExportIndividualReportController,
  FaxController,
  IndexController,
  IndividualController,
  IndividualBudgetSheetController,
  IndividualDoctorController,
  IndividualIhsController,
  IndividualMedicareController,
  IndividualNotesAttachmentsController,
  LeadsController,
  MedicareCommissionController,
  MedicarePolicyController,
  NotificationsController,
  PropertyAndCasualtyController,
  SnpValuesController,
  SupportTicketController,
  UserController,
  HeaderController,
};

export default appControllers;
