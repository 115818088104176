import constants from '../utils/constants';
const authRoutes = ($stateProvider) => {
  $stateProvider
    .state('login', {
      url: '/login',
      params: {
        param1: null,
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/login.html?v=${constants.randomNumber}`,
          controller: 'IndexController',
        },
      },
    })
    .state('/logout', {
      url: '/logout',
      params: {
        logout: true,
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/login.html?v=${constants.randomNumber}`,
          controller: 'IndexController',
        },
      },
    });
};

export default authRoutes;
