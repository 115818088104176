export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  function MedicareCommissionController($rootScope, $scope, AuthService, $stateParams) {
    $scope.model = {
      listingData: [],
      commissionStatuses: [],
      locations: [],
      products: [],
      listingTotal: 0,
      currentPage: 1,
      footer: {},
      filters: {
        listingPerPage: '10',
        status: 'Commission Override',
        search: '',
        product: '',
        location: '',
        policy_date: '',
      },
    };
    $scope.loadCommissions = () => {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}commissions/listing?page=${$scope.model.currentPage}`,
        'GET',
        $scope.model.filters,
        (response) => {
          $scope.model.listingData = response.data.commission.data;
          $scope.model.listingTotal = response.data.commission.total;
          $scope.model.commissionStatuses = response.data.commisionType;
          $scope.model.locations = response.data.locations;
          $scope.model.products = response.data.products;
          $scope.model.footer = response.data.total;
        },
      );
    };
    $scope.pageChanged = (newPage) => {
      $scope.model.currentPage = newPage;
      $scope.loadCommissions();
    };
    if ($stateParams.type === 'listing') {
      $scope.loadCommissions();
    }

    $scope.loc_abbr = (d) => {
      if (d.individual.location) {
        if (d.individual.location.id === 1) {
          return `${d.insured_name} (GV)`;
        }
        return `${d.insured_name} (CB)`;
      }
    };
  },
];
