export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  'LoaderService',
  function ExportIndividualReportController($rootScope, $scope, AuthService, $stateParams, LoaderService) {
    $scope.model = {
      moduleTitle: $stateParams.type === 'spouse' ? 'Spouse' : 'Clients',
      params: '',
      domainPath: $rootScope.api05DomainPath,
      filters: {
        reportType: $stateParams.type,
        pagination: {
          perPage: 100,
          active: 1,
        },
        search: '',
        age: {
          start: '',
          end: '',
        },
        gender: '0',
        location: '0',
        industry: {
          medicare: '1',
          property: '',
          financial: '',
        },
      },
      listingTotal: 0,
      listingData: [],
      locationsData: [],
    };
    const user = AuthService.getAuthServiceData();

    const loadAgencyLocationData = () => {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}agency-locations/get`, 'GET', {}, (response) => {
        $scope.model.locationsData = response.data;
      });
    };
    let searchTimeOut;
    $scope.searchData = () => {
      clearTimeout(searchTimeOut);
      searchTimeOut = setTimeout(() => {
        $scope.loadData();
      }, 400);
    };

    $scope.loadData = () => {
      $scope.model.filters.pagination.active = 1;
      getData();
    };

    $scope.pageChanged = (newPage) => {
      $scope.model.filters.pagination.active = newPage;
      getData();
    };

    function getData() {
      LoaderService.show();
      $scope.model.params = `&search=${$scope.model.filters.search}&gender=${$scope.model.filters.gender}&age[start]=${$scope.model.filters.age.start}&age[end]=${$scope.model.filters.age.end}&access_token=${user.access_token}&location=${$scope.model.filters.location}&industry[medicare]=${$scope.model.filters.industry.medicare}&industry[property]=${$scope.model.filters.industry.property}&industry[financial]=${$scope.model.filters.industry.financial}&reportType=${$scope.model.filters.reportType}`;
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}report/individual/list`,
        'POST',
        $scope.model.filters,
        (response) => {
          $scope.model.listingData = response.data.records;
          $scope.model.listingTotal = response.data.count;

          setTimeout(() => {
            LoaderService.hide();
          }, 2000);
        },
      );
    }

    const init = () => {
      loadAgencyLocationData();
      getData();
    };
    init();
  },
];
