class FocusService {
  constructor($timeout, $window) {
    this.$timeout = $timeout;
    this.$window = $window;
  }

  focus(id) {
    this.$timeout(() => {
      const element = this.$window.document.getElementById(id);
      if (element) element.focus();
    });
  }
}

FocusService.$inject = ['$timeout', '$window'];

export default FocusService;
