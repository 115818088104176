// custom-grid.service.js
class CustomGridService {
  constructor($http, $rootScope) {
    this.$http = $http;
    this.$rootScope = $rootScope;
  }

  init(cData) {
    if (typeof cData.searchFilter !== 'undefined') {
      this.searchFilter = cData.searchFilter;
    }
    if (typeof cData.pagination !== 'undefined') {
      this.pagination = cData.pagination;
    }
    if (typeof cData.url !== 'undefined') {
      this.url = cData.url;
    }
    if (typeof cData.method !== 'undefined') {
      this.method = cData.method;
    }
    if (typeof cData.sorting !== 'undefined') {
      this.sorting = cData.sorting;
    }
    if (typeof cData.dataVariables !== 'undefined') {
      this.dataVariables = cData.dataVariables;
    }
    if (typeof cData.callback !== 'undefined') {
      this.callback = cData.callback;
    } else {
      cData.callback = (d) => d;
    }
    return this;
  }
}

CustomGridService.$inject = ['$http', '$rootScope'];

export default CustomGridService;
