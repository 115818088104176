// Override angular.lowercase since it's removed in AngularJS 1.7+
// handle undefined values
angular.lowercase = (text) => {
  if (typeof text === 'string') {
    return text.toLowerCase();
  }
  // Handle non-string or undefined inputs safely
  // Returning an empty string is one option, but adjust as necessary for your application
  return '';
};
