class ValidationService {
  constructor(ConfirmBoxService) {
    this.ConfirmBoxService = ConfirmBoxService;
  }

  validate($model, object) {
    let totalFields = 0;
    let validatedFields = 0;
    const errorFields = {};

    for (const model in object) {
      totalFields++;

      const modelObjects = model.split('.');
      let modelClone = $model;
      let keyExists = true;

      if (object[model].required === true) {
        for (const i in modelObjects) {
          const mKey = modelObjects[i];
          if (typeof modelClone[mKey] !== 'undefined') {
            modelClone = modelClone[mKey];
          } else {
            keyExists = false;
            errorFields[model] = object[model];
            if (typeof object[model].errorMessage === 'undefined') {
              object[model].errorMessage = `${mKey} is required!`;
            }
            this.ConfirmBoxService.open('Error', object[model].errorMessage, {
              OK: () => {},
            });
            return false;
          }
        }
      }

      if (modelClone === '') {
        if (typeof object[model].errorMessage === 'undefined') {
          object[model].errorMessage = `${mKey} is required!`;
        }
        this.ConfirmBoxService.open('Error', object[model].errorMessage, {
          OK: () => {},
        });
        return false;
      }
      if (keyExists === true) {
        validatedFields++;
      }
    }

    // All fields are validated
    return totalFields === validatedFields;
  }
}

ValidationService.$inject = ['ConfirmBoxService'];

export default ValidationService;
