export default class numericOnly {
  constructor() {
    this.require = 'ngModel'; // Specify the directive's requirement
  }

  link(scope, element, attrs, modelCtrl) {
    modelCtrl.$parsers.push((inputValue) => {
      const transformedInput = inputValue ? inputValue.replace(/[^\d.-]/g, '') : null;

      if (transformedInput !== inputValue) {
        modelCtrl.$setViewValue(transformedInput);
        modelCtrl.$render();
      }

      return transformedInput;
    });
  }

  static factory() {
    return new numericOnly();
  }
}

// Dependency Injection
numericOnly.factory.$inject = [];
