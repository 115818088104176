export default class isPercentage {
  constructor() {
    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, element, _attrs, ngModel) {
    element.bind('change', () => {
      const val = ngModel.$viewValue.replace(/([^0-9\.\-]+)/, '');
      if (val == null || val === 'undefined' || val === 'null' || typeof val === 'undefined') {
        return '0.00%';
      }
      ngModel.$setViewValue(`${val}%`);
      ngModel.$render();
    });

    element.bind('focus', () => {
      const val = ngModel.$viewValue.replace(/([^0-9\.\-]+)/, '');
      ngModel.$setViewValue(val);
      ngModel.$render();
    });

    element.bind('blur', () => {
      let val = ngModel.$viewValue.replace(/([^0-9\.\-]+)/, '');
      if (val === '') {
        val = `${0}.00`;
      } else if (val.indexOf('.') === -1) {
        val = `${val}.00`;
      }
      ngModel.$setViewValue(`${val}%`);
      ngModel.$render();
    });

    ngModel.$formatters.push((val) => {
      if (val == null || val === 'undefined' || val === 'null' || typeof val === 'undefined') {
        return '0.00%';
      }
      return `${val}%`;
    });

    ngModel.$parsers.push((val) => {
      if (val == null || val === 'undefined' || val === 'null' || typeof val === 'undefined') {
        return '0.00';
      }
      return val.replace(/%/, '');
    });
  }

  static factory() {
    return new isPercentage();
  }
}

// Dependency Injection
isPercentage.factory.$inject = [];
