export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  function IndividualMedicareController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
  ) {
    $rootScope.medicareIndividualID = $stateParams.individualID;
    $scope.model = {
      leadDatePicker: false,
      counties: [],
      addForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: new Date(),
      },
      editForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: new Date(),
      },
      individualID: null,
      listingData: [],
    };

    $scope.loadAgencyData = () => {
      LoaderService.show();
      if ($stateParams.type === 'add') {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/getFormData`, 'POST', {}, (response) => {
          $scope.addAgencyForm(response);
        });
      } else if ($scope.model.individualID == null) {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/get`, 'POST', {}, (response) => {
          $scope.setListing(response);
        });
      } else {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/get/${$scope.model.individualID}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.addAgencyForm(response);
            $scope.editIndividual(response);
          },
        );
      }
    };
    if ($stateParams.type !== 'add') {
      /*LOAD DATA*/
      if (typeof $stateParams.individualID !== 'undefined') {
        $scope.model.individualID = $stateParams.individualID;
      }
    }
    $scope.loadAgencyData();

    $scope.addAgencyForm = (response) => {
      LoaderService.hide();
      $rootScope.constants.DB = response.data;
      $scope.model.addForm.individual_type_id = response.data.individualTypes[0];
    };
    $scope.onStateChange = (i) => {
      $scope.model.counties = $rootScope.constants.DB.counties[i.id];
    };
    $scope.openLeadDatepicker = () => {
      $scope.model.leadDatePicker = true;
    };

    $scope.setListing = (res) => {
      $scope.model.listingData = res.data.individualData;
      LoaderService.hide();
    };

    $scope.submitAddForm = () => {
      LoaderService.show();
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/add`,
        'POST',
        $scope.model.addForm,
        (_response) => {
          $state.go('/individuals');
          LoaderService.hide();
        },
      );
    };

    $scope.editIndividual = (res) => {
      $scope.model.editForm = res.data.individualData;
      $scope.model.editForm.date_of_birth = new Date(res.data.individualData.date_of_birth);
      $scope.model.editForm.lead_date = new Date(res.data.individualData.lead_date);
      $scope.onStateChange($scope.model.editForm.state_id);
      LoaderService.hide();
    };
    $scope.submitEditForm = (_res) => {
      LoaderService.show();
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/update/${$scope.model.editForm.id}`,
        'POST',
        $scope.model.editForm,
        (_response) => {
          $state.go('/individuals');
          LoaderService.hide();
        },
      );
    };

    $scope.deleteIndividual = (i) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this Individual?', {
        Yes: () => {
          const userData = $scope.model.listingData[i];
          $scope.model.listingData.splice(i, 1);
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/delete/${userData.id}`,
            'POST',
            userData,
            (_response) => {},
          );
        },
        No: () => {},
      });
    };
  },
];
