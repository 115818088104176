export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'HelpersService',
  'IndividualInfoService',
  'ValidationService',
  'CustomGridService',
  function MedicarePolicyController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    HelpersService,
    IndividualInfoService,
    ValidationService,
    CustomGridService,
  ) {
    $rootScope.subMenuActive = 'medicare-policy';
    $rootScope.individualID = $stateParams.individualID;
    $scope.individualID = $stateParams.individualID;
    let dataFactory = {};

    const _$constants = angular.copy($rootScope.constants);
    const userData = AuthService.getAuthServiceData().userData;

    $scope.model = {
      attached_files: [],
      dropdowns: {
        coverage_type: [],
        carrier: [],
        companies: [],
        products: [],
        agency_locations: [],
        status: [],
        pay_frequency: [
          { value: '', title: 'Select' },
          { value: 'Monthly', title: 'Monthly' },
          { value: 'Bi-Weekly', title: 'Bi-Weekly' },
          { value: 'Bi-Monthly', title: 'Bi-Monthly' },
          { value: 'Quarterly', title: 'Quarterly' },
          { value: 'Semi-Annually', title: 'Semi-Annually' },
          { value: 'Annually', title: 'Annually' },
          { value: 'One-Time', title: 'One-Time' },
        ],
        servicing_agents: [],
        signed_by: [
          { value: '', title: 'Select' },
          { value: '1', title: 'Snyder, Benjamin' },
          { value: '2', title: 'SNYDER, ELIZABETH' },
        ],
        contact_again: [
          { value: '', title: 'Select' },
          { value: 'Yes', title: 'Yes' },
          { value: 'No', title: 'No' },
        ],
        agents: [],
        managers: [],
      },
      listingData: [],
      formData: {
        product: '',
        policy_number: '',
        agency_locations: '',
        app_submit: '',
        effective_date: '',
        renewal_date: '',
        term_date: '',
        premium: '',
        pay_frequency: '',
        lives: '',
        servicing_agent: '',
        signed_by: '',
        account_manager: '',
        additional_agent: '',
        status: '',
        contact_again: '',
        when_to_contact: '',
        notes: '',
        payment_method: '',
        paid_to_date: '',
        premium_class: '',
        modal_premium: '',
        annual_premium: '',
        mode: '',
        agent_split_percentage: '',
        household_discount: '',
        issue_date: '',
        issue_age: '',
        paper_eapp: '',
        replacement_indicator: '',
        original_annualized_premium: '',
      },
    };

    /*$scope.gridViewData = {
    searchFilter:true,
    pagination:true,
    url:$rootScope.api05DomainPath + 'medicare-policy/get-all-data/' + $scope.individualID,
    method:'GET',
    callback: function (data) {
      return data;
    }
  };*/

    const initFunc = (params) => {
      //Get Individual info
      if (params.type !== 'policiyListing') {
        IndividualInfoService.getInfo($rootScope.individualID, (result) => {
          $scope.model.individual_info = result;
        });
      }

      if (params.type === 'listing') {
        /*POLICY LISTING PAGE*/
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-policy/get-all-data/${$scope.individualID}`,
          'GET',
          {},
          (res) => {
            if (res.data.policies) {
              $scope.model.listingData = res.data.policies;
            }
          },
        );
      } else if (params.type === 'add') {
        /*ADD POLICY PAGE*/
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-policy/get-all-data/${$scope.individualID}`,
          'GET',
          {},
          (res) => {
            if (res.data.policies) {
              $scope.model.listingData = res.data.policies;
            }
          },
        );
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-policy/get-form-data/${$scope.individualID}`,
          'GET',
          {},
          (res) => {
            dataFactory = res.data;
            $scope.model.dropdowns.coverage_type = dataFactory.coverage_type_data;
            $scope.model.dropdowns.agency_locations = dataFactory.agency_locations;
            $scope.model.dropdowns.status = dataFactory.status;
            $scope.model.dropdowns.agents = dataFactory.agents;
            $scope.model.dropdowns.managers = dataFactory.managers;
            $scope.model.dropdowns.servicing_agents = dataFactory.service_agents;

            if (userData.service_location_id !== '' && userData.service_location_id != null) {
              const serviceLocation = HelpersService.getObjectByValue(
                userData.service_location_id,
                'id',
                dataFactory.agency_locations,
              );
              $scope.model.formData.agency_locations = serviceLocation;
            }

            const agent = HelpersService.getObjectByValue(userData.id, 'id', dataFactory.agents);
            $scope.model.formData.additional_agent = agent;
          },
        );
      } else if (params.type === 'edit') {
        /*EDIT POLICY PAGE*/
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-policy/get-all-data/${$scope.individualID}`,
          'GET',
          {},
          (res) => {
            if (res.data.policies) {
              $scope.model.listingData = res.data.policies;
            }
          },
        );
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-policy/get-policy-data/${$stateParams.policy_id}`,
          'GET',
          {},
          (res) => {
            dataFactory = res.data;
            $scope.model.dropdowns.coverage_type = dataFactory.coverage_type_data;
            $scope.model.dropdowns.agency_locations = dataFactory.agency_locations;
            $scope.model.dropdowns.status = dataFactory.status;
            $scope.model.dropdowns.agents = dataFactory.agents;
            $scope.model.dropdowns.managers = dataFactory.managers;
            $scope.model.attached_files = dataFactory.attachments;
            $scope.model.dropdowns.servicing_agents = dataFactory.service_agents;

            const policyData = dataFactory.policy[0];

            /*SET DROPDOWN DATA*/
            if (policyData.product_id !== '' && policyData.product_id != null) {
              const coverageObject = HelpersService.getObjectByValue(
                policyData.plan_category_id,
                'id',
                dataFactory.coverage_type_data,
              );
              $scope.model.formData.coverage_type = coverageObject;
              $scope.populateCarriers(coverageObject);

              const carrierObject = HelpersService.getObjectByValue(
                policyData.carrier_id,
                'id',
                $scope.model.dropdowns.carriers,
              );
              $scope.model.formData.carrier = carrierObject;
              $scope.populateProductsCompanies(carrierObject);

              const companyObject = HelpersService.getObjectByValue(
                policyData.company_id,
                'company_id',
                $scope.model.dropdowns.companies,
              );
              if (companyObject !== '') {
                $scope.model.formData.company = companyObject;
                $scope.populateCarrierCompanyProducts(companyObject);
              }

              if (policyData.company_id === '0') {
                const productObject = HelpersService.getObjectByValue(
                  policyData.product_id,
                  'id',
                  $scope.model.dropdowns.products,
                );
                $scope.model.formData.product = productObject;
              } else {
                const productObject = HelpersService.getObjectByValue(
                  policyData.product_id,
                  'product_id',
                  $scope.model.dropdowns.products,
                );
                $scope.model.formData.product = productObject;
              }
            }

            $scope.model.formData.policy_number = policyData.policy_number;

            if (policyData.location_id !== '' && policyData.location_id != null) {
              const serviceLocation = HelpersService.getObjectByValue(
                policyData.location_id,
                'id',
                dataFactory.agency_locations,
              );
              $scope.model.formData.agency_locations = serviceLocation;
            }

            if (policyData.pay_frequency !== '' && policyData.pay_frequency != null) {
              const pay_frequency = HelpersService.getObjectByValue(
                policyData.pay_frequency,
                'value',
                $scope.model.dropdowns.pay_frequency,
              );
              $scope.model.formData.pay_frequency = pay_frequency;
            }

            if (policyData.servicing_agent !== '' && policyData.servicing_agent != null) {
              const servicing_agent = HelpersService.getObjectByValue(
                policyData.servicing_agent,
                'id',
                $scope.model.dropdowns.servicing_agents,
              );
              $scope.model.formData.servicing_agent = servicing_agent;
            }

            if (policyData.signed_by !== '' && policyData.signed_by != null) {
              const signed_by = HelpersService.getObjectByValue(
                policyData.signed_by,
                'value',
                $scope.model.dropdowns.signed_by,
              );
              $scope.model.formData.signed_by = signed_by;
            }

            if (policyData.account_manager !== '' && policyData.account_manager != null) {
              const account_manager = HelpersService.getObjectByValue(
                policyData.account_manager,
                'id',
                dataFactory.managers,
              );
              $scope.model.formData.account_manager = account_manager;
            }

            if (policyData.agent_id !== '' && policyData.agent_id != null) {
              const agent_id = HelpersService.getObjectByValue(policyData.agent_id, 'id', dataFactory.agents);
              $scope.model.formData.additional_agent = agent_id;
            }

            if (policyData.status_id !== '' && policyData.status_id != null) {
              const status_id = HelpersService.getObjectByValue(policyData.status_id, 'id', dataFactory.status);
              $scope.model.formData.status = status_id;
            }
            if (policyData.contact_again !== '' && policyData.contact_again != null) {
              const contact_again = HelpersService.getObjectByValue(
                policyData.contact_again,
                'value',
                $scope.model.dropdowns.contact_again,
              );
              $scope.model.formData.contact_again = contact_again;
            }
            $scope.model.formData.id = policyData.id;
            $scope.model.formData.policy_number = policyData.policy_number;
            $scope.model.formData.premium = policyData.premium;
            $scope.model.formData.lives = policyData.lives;
            $scope.model.formData.notes = policyData.notes;
            $scope.model.formData.payment_method = policyData.payment_method;
            $scope.model.formData.premium_class = policyData.premium_class;
            $scope.model.formData.modal_premium = policyData.modal_premium;
            $scope.model.formData.annual_premium = policyData.annual_premiumd;
            $scope.model.formData.mode = policyData.mode;
            $scope.model.formData.agent_split_percentage = policyData.agent_split_percentage;
            $scope.model.formData.household_discount = policyData.household_discount;
            $scope.model.formData.issue_age = policyData.issue_age;
            $scope.model.formData.paper_eapp = policyData.paper_eapp;
            $scope.model.formData.replacement_indicator = policyData.replacement_indicator;
            $scope.model.formData.original_annualized_premium = policyData.original_annualized_premium;

            /*DATE PICKERS*/
            $scope.model.formData.when_to_contact = HelpersService.convertTimeZone(policyData.when_to_contact);
            $scope.model.formData.app_submit = HelpersService.convertTimeZone(policyData.submission_date);
            $scope.model.formData.effective_date = HelpersService.convertTimeZone(policyData.effective_date);
            $scope.model.formData.renewal_date = HelpersService.convertTimeZone(policyData.renewal_date);
            $scope.model.formData.term_date = HelpersService.convertTimeZone(policyData.term_date);
            $scope.model.formData.paid_to_date = HelpersService.convertTimeZone(policyData.paid_to_date);
            $scope.model.formData.issue_date = HelpersService.convertTimeZone(policyData.issue_date);
          },
        );
      } else if (params.type === 'policiyListing') {
        /*AuthService.sendRequest($rootScope.api05DomainPath + 'medicare-policy/get-all-data', "GET", {}, function (res) {
        if(res.data.policies){
          $scope.model.listingData = res.data.policies;
        }
      });*/

        $scope.gridViewData = CustomGridService.init({
          searchFilter: true,
          pagination: true,
          url: `${$rootScope.api05DomainPath}medicare-policy/get-all-data`,
          method: 'GET',
          sorting: [
            { column: 'individual_name', order: 'asc' },
            '',
            { column: 'policy', order: '' },
            { column: 'carrier', order: '' },
          ],
          dataVariables: {
            recordsCount: 'data.records_count',
            gridData: {
              apiVariable: 'data.policies',
              scopeVariable: 'model.listingData',
            },
            perPageRecords: 'data.per_page_records',
          },
          callback: (data) => {
            console.log(data);
            return data;
          },
        });
      }
    };

    /*INITIALIZE*/
    initFunc($stateParams);

    /*SCOPE METHOD*/
    //DROPDOWNS CALLBACK
    $scope.populateCarriers = (d) => {
      resetAllCarrierDropdowns();
      const carrierData = dataFactory.carrier_data[d.id].carrier_data;
      $scope.model.dropdowns.carriers = carrierData;
    };
    $scope.populateProductsCompanies = (d) => {
      resetProductsCompanies();

      let companiesData =
        dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company[
          $scope.model.formData.coverage_type.id
        ][d.id];
      const productsData =
        dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_products[
          $scope.model.formData.coverage_type.id
        ][d.id];
      /*APPEND OBJECT ON 0 INDEX*/
      companiesData = HelpersService.insertObjectIntoArray(companiesData, 0, {
        id: 0,
        company_name: '--',
      });
      $scope.model.dropdowns.companies = companiesData;
      $scope.model.dropdowns.products = productsData;
    };
    $scope.populateCarrierCompanyProducts = (d) => {
      resetProducts();

      if (d.id === 0) {
        $scope.populateProductsCompanies($scope.model.formData.carrier);
      } else {
        const companyProductsData =
          dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company_product[
            $scope.model.formData.coverage_type.id
          ][$scope.model.formData.carrier.id][d.id];
        $scope.model.dropdowns.products = companyProductsData;
      }
    };

    //FORM SUBMISSION
    $scope.addPolicy = () => {
      /*VALIDATE REQUIRED FIELDS*/
      const validated = ValidationService.validate($scope, {
        'model.formData.policy_number': {
          required: true,
          errorMessage: 'Policy Number is Required',
        },
        'model.formData.product': {
          required: true,
          errorMessage: 'Product/Plan is Required',
        },
      });
      $scope.model.formData.app_submit = HelpersService.dateFormatYYYYMMDD($scope.model.formData.app_submit);
      $scope.model.formData.effective_date = HelpersService.dateFormatYYYYMMDD($scope.model.formData.effective_date);
      $scope.model.formData.renewal_date = HelpersService.dateFormatYYYYMMDD($scope.model.formData.renewal_date);
      $scope.model.formData.term_date = HelpersService.dateFormatYYYYMMDD($scope.model.formData.term_date);

      const formData = new FormData();
      if (typeof $scope.model.attachedFile !== 'undefined') {
        for (const file in $scope.model.attachedFile) {
          formData.append('file[]', $scope.model.attachedFile[file]);
        }
      }
      formData.append('form_data', JSON.stringify(angular.copy($scope.model.formData)));

      if (validated) {
        if ($stateParams.type === 'edit') {
          AuthService.uploadFile(
            `${$rootScope.api05DomainPath}medicare-policy/update-policy/${$scope.individualID}`,
            formData,
            (res) => {
              if (res.status === 200) {
                ConfirmBoxService.open('Notifification', 'Medicare Policy has been updated successfully!', {
                  OK: () => {
                    $state.reload();
                  },
                });
              }
            },
          );
        } else {
          AuthService.uploadFile(
            `${$rootScope.api05DomainPath}medicare-policy/add-policy/${$scope.individualID}`,
            formData,
            (res) => {
              if (res.status === 200) {
                ConfirmBoxService.open('Notifification', 'Medicare Policy has been added successfully!', {
                  OK: () => {
                    window.location = `#/individuals/${$scope.individualID}/medicare-policy/edit/${res.policy.id}`;
                  },
                });
              }
            },
          );
        }
      }
    };
    $scope.deleteAttachment = (d, i) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this file?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}medicare-policy/delete/${d.file_id}`,
            'POST',
            d,
            (_res) => {},
          );
          $scope.model.attached_files.splice(i, 1);
        },
        NO: () => {},
      });
    };
    $scope.deleteMedicarePolicy = (d, i) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this Policy?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}medicare-policy/delete-policy/${d.id}`,
            'POST',
            d,
            (_res) => {},
          );
          $scope.model.listingData.splice(i, 1);
        },
        NO: () => {},
      });
    };

    /*PRIVATE FUNCTIONS*/
    const resetAllCarrierDropdowns = () => {
      $scope.model.formData.carrier = '';
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };
    const resetProductsCompanies = () => {
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };
    const resetProducts = () => {
      $scope.model.formData.product = '';
    };
  },
];
