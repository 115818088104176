export default class highlightNavItem {
  constructor($state) {
    this.restrict = 'A';
    this.$state = $state;
  }

  link(scope, element, attrs) {
    // Watch for state changes and update the active class accordingly
    scope.$on('$stateChangeSuccess', () => {
      this.updateActiveClass(attrs.uiSref, attrs.href, element);
    });

    // Initial call to set active class
    this.updateActiveClass(attrs.uiSref, attrs.href, element);
  }

  updateActiveClass(uiSref, href, element) {
    const currentState = this.$state.current.name?.replace(/\/:\w+\??/, '/');
    const currentStateWithoutParams = currentState.replace(/\/:\w+\??/, '');

    // Check if the current item matches the current state
    const matchesCurrentState = (state) => state === currentStateWithoutParams;

    // Check if any child item matches the current state
    const matchesChildState = (childItems) => {
      for (let i = 0; i < childItems.length; i++) {
        const childState =
          childItems[i].getAttribute('ui-sref')?.replace('.', '') ||
          childItems[i].getAttribute('href')?.replace('#', '');
        if (childState === currentStateWithoutParams) {
          return true;
        }
      }
      return false;
    };

    // Remove active class from all elements
    element.removeClass('active');
    const navItemState = uiSref?.replace('.', '')?.replace(/\d+$/, ''); // Remove dot from state name if present
    const hrefState = href?.replace('#', '')?.replace(/\d+$/, ''); // Remove '#' from href if present
    // Add active class to current item or its child
    if (matchesCurrentState(navItemState) || matchesCurrentState(hrefState) || matchesChildState(element.find('a'))) {
      element.addClass('active');
    }
  }

  static factory($state) {
    return new highlightNavItem($state);
  }
}

// Dependency Injection
highlightNavItem.factory.$inject = ['$state'];
