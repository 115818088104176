class AuthService {
  constructor($http, $cookies, $rootScope, $location, $state, LoaderService, $stateParams, $timeout) {
    this.$http = $http;
    this.$cookies = $cookies;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$state = $state;
    this.LoaderService = LoaderService;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.timeOut = '';
    this.cookieName = '_token';
    this.dataCookieName = 'constantData';
    this.userdata = [];
    this.reqCount = 0;
    this.resCount = 0;
    console.log(this.$rootScope);
  }

  sendRequest(url, method, data, success, error) {
    this.reqCount++;
    if (this.isLoggedIn() === true) {
      data.access_token = this.getAuthServiceData().access_token;
      data.admin_user_id = this.getAuthServiceData().userData.id;
    }
    if (typeof this.$stateParams.authKey !== 'undefined') {
      if (typeof this.$rootScope.accessToken !== 'undefined') {
        data.access_token = this.$rootScope.accessToken;
      }
    }
    const req = {
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${this.getAuthServiceData().access_token}`,
      },
      data: data,
    };
    if (method.toLowerCase() === 'get') {
      req.params = data;
    }

    if (typeof data.hiddenMode === 'undefined') {
      this.LoaderService.show();
    }

    this.$http(req).then(
      (response) => {
        this.resCount++;
        if (this.resCount === this.reqCount) {
          this.LoaderService.hide();
        }
        if (this.$rootScope.debugModeOn === true) {
          console.log(response);
        }
        success(response);
      },
      (response) => {
        this.resCount++;
        if (this.resCount === this.reqCount) {
          this.LoaderService.hide();
        }
        if (this.$rootScope.debugModeOn === true) {
          console.log(response);
        }
        if (typeof error === 'function') {
          error(response);
        }
        if (response.data.error === 'access_denied') {
          this.logout();
          return false;
        }
      },
    );
  }

  // sendRequest(url, method, data, success, error) {
  //   this.reqCount++;
  //   const originalReqCount = this.reqCount; // Store the original request count for debugging

  //   // Enhance the data with authentication details if logged in
  //   if (this.isLoggedIn()) {
  //     const authData = this.getAuthServiceData();
  //     data.access_token = authData.access_token;
  //     data.admin_user_id = authData.userData.id;
  //   }

  //   // Override with $stateParams authKey if available
  //   if (this.$stateParams.authKey && this.$rootScope.accessToken) {
  //     data.access_token = this.$rootScope.accessToken;
  //   }

  //   // Construct the request object
  //   const req = {
  //     method: method,
  //     url: url,
  //     headers: { Authorization: `Bearer ${data.access_token}` },
  //     data: method.toLowerCase() === 'get' ? null : data, // Include data only for non-GET requests
  //     params: method.toLowerCase() === 'get' ? data : null, // Use params for GET requests
  //   };

  //   // Show loader unless hiddenMode is explicitly set
  //   if (typeof data.hiddenMode === 'undefined') {
  //     this.LoaderService.show();
  //   }

  //   // Debugging information before sending the request
  //   if (this.$rootScope.debugModeOn) {
  //     console.log(`Sending request #${originalReqCount}:`, req);
  //   }

  //   // Perform the HTTP request
  //   this.$http(req).then(
  //     (response) => {
  //       // Debugging information on response
  //       if (this.$rootScope.debugModeOn) {
  //         console.log(`Response for request #${originalReqCount}:`, response);
  //       }

  //       // Handle loader visibility and call success callback
  //       this.handleResponse(originalReqCount, success, response);
  //     },
  //     (response) => {
  //       // Debugging information on error response
  //       if (this.$rootScope.debugModeOn) {
  //         console.error(`Error response for request #${originalReqCount}:`, response);
  //       }

  //       // Specific error handling for access denied
  //       if (response.data.error === 'access_denied') {
  //         this.logout();
  //       } else if (typeof error === 'function') {
  //         // Call error callback if provided
  //         error(response);
  //       }

  //       // Handle loader visibility
  //       this.handleResponse(originalReqCount, null, response);
  //     },
  //   );
  // }

  handleResponse(reqCount, callback, response) {
    this.resCount++;
    if (this.resCount === reqCount) {
      this.LoaderService.hide();
    }
    if (callback) {
      callback(response);
    }
  }

  XMLHttpRequest(url, method, data, success, error) {
    const req = {
      method: method,
      url: url,
      data: data,
    };
    if (method.toLowerCase() === 'get') {
      req.params = data;
    }
    this.LoaderService.show();
    $http(req).then(
      (response) => {
        this.LoaderService.hide();
        if ($rootScope.debugModeOn === true) {
          console.log(response);
        }
        success(response);
      },
      (response) => {
        this.LoaderService.hide();
        if ($rootScope.debugModeOn === true) {
          console.log(response);
        }
        if (typeof error === 'function') {
          error(response);
        }
      },
    );
  }

  uploadFile(url, data, success, error) {
    if (this.isLoggedIn() === true) {
      data.append('access_token', this.getAuthServiceData().access_token);
      data.append('admin_user_id', this.getAuthServiceData().userData.id);
    }
    this.LoaderService.show();

    this.$http
      .post(url, data, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined },
      })
      .then((response) => {
        // Handle success
        this.LoaderService.hide();
        if (this.$rootScope.debugModeOn === true) {
          console.log(response);
        }
        success(response);
      })
      .catch((response) => {
        // Handle error
        this.LoaderService.hide();
        if (this.$rootScope.debugModeOn === true) {
          console.log(response);
        }
        if (response.data.error === 'access_denied') {
          this.logout();
        }
        if (typeof error === 'function') {
          error(response);
        }
      });
  }

  getAccessToken(data, callback) {
    data.grant_type = 'password';
    data.client_id = '1';
    data.client_secret = 'usama';
    this.userdata = data;
    this.sendRequest(
      `${this.$rootScope.api05DomainPath}oauth/access_token`,
      'POST',
      data,
      (response) => {
        callback(response);
        if (response.status === 200) {
          this.setToken(response.data);
          this.isLoggedIn();

          if (this.$rootScope.redirectBackUrl !== undefined && this.$rootScope.redirectBackUrl !== '') {
            window.location = `#${this.$rootScope.redirectBackUrl}`;
          } else {
            this.$state.go(this.$rootScope.defaultRoute);
          }
        }
      },
      callback,
    );
  }

  getAuthServiceData() {
    if (typeof this.$cookies.get(this.cookieName) !== 'undefined' && this.$cookies.get(this.cookieName) !== '') {
      this.$rootScope.constants.DB = JSON.parse(this.$cookies.get(this.cookieName));
      return JSON.parse(this.$cookies.get(this.cookieName));
    }
    return '';
  }

  setToken(res) {
    if (res.userData.user_type === 'super_admin') {
      this.$rootScope.isAdminUser = true;
    } else {
      this.$rootScope.isAdminUser = false;
    }
    this.$rootScope.access_token = res.access_token;
    this.$cookies.put(this.cookieName, JSON.stringify(res));
  }

  updateUserData(userData) {
    const data = this.getAuthServiceData();
    data.userData.name = userData.name;
    data.userData.email = userData.email;
    data.userData.first_name = userData.first_name;
    data.userData.last_name = userData.last_name;
    data.userData.service_location_id = userData.service_location_id;

    if (typeof userData.avatar !== 'undefined') {
      data.userData.avatar = userData.avatar;
      this.$rootScope.loggedInUserData.avatar = data.userData.avatar;
    }

    this.$cookies.put(this.cookieName, JSON.stringify(data));
  }

  isLoggedIn() {
    // Early return if authKey is present in $stateParams
    if (this.$stateParams.authKey) {
      // if isDev then log the authKey
      if (this.$rootScope.isDev) {
        console.log('authKey is present in $stateParams');
      }
      this.$rootScope.accessToken = this.$stateParams.authKey;
      return true;
    }

    if (this.$rootScope.isDev) {
      console.log('authKey is not present in $stateParams');
    }

    const authData = this.getAuthServiceData();
    // Check if authData is truthy and not just an empty string
    if (authData && authData !== '') {
      this.$rootScope.accessToken = authData.access_token;
      // Use || operator to fallback to the existing avatar if needed
      this.$rootScope.loggedInUserData = {
        ...authData.userData,
        avatar: authData.userData.avatar || this.$rootScope.loggedInUserData.avatar,
        timeZone: this.$rootScope.timeZone,
      };
      // Set isAdminUser based on user_type
      this.$rootScope.isAdminUser = authData.userData.user_type === 'super_admin';

      return true;
    }

    return false;
  }

  logout() {
    //clearTimeout(obj.timeOut);
    const accessToken = this.getAuthServiceData().access_token;
    this.$cookies.remove(this.cookieName);

    this.$state.reload();
    this.sendRequest(
      `${this.$rootScope.api05DomainPath}logout`,
      'POST',
      { access_token: accessToken },
      () => {
        //window.location.reload();
      },
      () => {
        //window.location.reload();
      },
    );
    window.location.reload();
  }
}

AuthService.$inject = [
  '$http',
  '$cookies',
  '$rootScope',
  '$location',
  '$state',
  'LoaderService',
  '$stateParams',
  '$timeout',
];

export default AuthService;
