import constants from '../utils/constants';
const adminsRoutes = ($stateProvider) => {
  $stateProvider
    .state('/admin-users', {
      url: '/admin-users',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/users-listing.html?v=${constants.randomNumber}`,
          controller: 'AdminUsersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/admin-users/edit/:adminID', {
      url: '/admin-users/edit/:adminID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/users-add-edit.html?v=${constants.randomNumber}`,
          controller: 'AdminUsersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/admin-users/add', {
      url: '/admin-users/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}auth/users-add-edit.html?v=${constants.randomNumber}`,
          controller: 'AdminUsersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    });
};

export default adminsRoutes;
