export default [
  '$rootScope',
  '$scope',
  '$sce',
  'AuthService',
  '$stateParams',
  'ConfirmBoxService',
  function EmailNewsletterController($rootScope, $scope, $sce, AuthService, $stateParams, ConfirmBoxService) {
    $scope.model = {
      selectedClientList: [],
      formAction: 1,
      create: {},
      campaignsData: [],
      businessTypes: [],
      clients: [],
      selectAll: false,
      search: '',
      emails: [{ email: '', client_name: '' }],
    };

    $scope.loadListingData = () => {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}email-newsletter/campaigns`, 'GET', {}, (response) => {
        $scope.model.campaignsData = response.data.data.campaigns;
      });
    };

    $scope.createEmailCampaign = function () {
      $scope.model.create = {
        loadTemplateView: () => {
          const content = $scope.model.create.templates.find(
            (a) => a.id === $scope.model.create.template_id.id,
          ).content;
          $scope.model.create.content = content;
          $scope.model.create.renderPreview();
        },
        renderPreview: () => {
          const content = $scope.model.create.content;
          $scope.model.create.selectedTemplate = $sce.trustAsHtml(content.replace(/\{client_name}/g, 'John Doe'));
        },
        selectedTemplate: '',
        templates: [],
        subject: '',
        content: '',
        template_id: '',
        campaign_id: null,
      };
      this.loadTemplates = () => {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}email-newsletter/templates/get`,
          'GET',
          { campaign_id: $stateParams.campaign_id },
          (response) => {
            $scope.model.create.templates = response.data.data.templates;
            if (response.data.data.campaign?.id) {
              $scope.model.create.campaign_id = response.data.data.campaign.id;
              $scope.model.create.template_id = $scope.model.create.templates.find(
                (a) => a.id === response.data.data.campaign.template_id,
              );
              $scope.model.create.subject = response.data.data.campaign.subject;
              $scope.model.create.content = response.data.data.campaign.content;
              $scope.model.create.renderPreview();
            }
          },
        );
      };
      this.loadTemplates();
    };

    $scope.deleteCampaign = (index, row) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}email-newsletter/campaign/delete`,
            'POST',
            {
              campaign_id: row.id,
            },
            (_response) => {
              $scope.model.campaignsData.splice(index, 1);
            },
          );
        },
        No: () => {},
      });
    };

    $scope.getCampaign = (id) => {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}email-newsletter/campaigns`,
        'GET',
        { id: id },
        (response) => {
          $scope.model.campaignsData = response.data.data.campaigns.map((a) => {
            a.content = $sce.trustAsHtml(a.content.replace(/\{client_name}/g, 'John Doe'));
            return a;
          });
          response.data.data.businessTypes.push({
            type: 'PROSPECT',
            business_type: 'Prospect Clients',
            id: 'PROSPECT',
          });
          response.data.data.businessTypes.push({
            type: 'LEAD',
            business_type: 'Leads',
            id: 'LEAD',
          });
          $scope.model.businessTypes = response.data.data.businessTypes;
        },
      );
    };

    $scope.submitCreateCampaignForm = (action) => {
      if ($scope.model.create.subject && $scope.model.create.template_id.id) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}email-newsletter/campaign`,
          'POST',
          {
            subject: $scope.model.create.subject,
            content: $scope.model.create.content,
            template_id: $scope.model.create.template_id.id,
            campaign_id: $scope.model.create.campaign_id,
          },
          (response) => {
            if (action === 1) {
              if ($scope.model.create.campaign_id) {
                window.location.reload();
              } else {
                window.location = `#/email/campaign/create/${response.data.data.id}`;
              }
            } else if (action === 2) {
              window.location = '#' + '/email/newsletter/listing';
            } else if (action === 3) {
              window.location = `#/email/campaign/send/${
                $scope.model.create.campaign_id ? $scope.model.create.campaign_id : response.data.data.id
              }`;
            }
          },
        );
      }
    };

    $scope.sendNewsletterEmail = () => {
      const clients = $scope.model.clients
        .filter((a) => a.selected === true)
        .map((a) => ({
          id: a.id,
          client_name: a.client_name,
          email: a.email,
          type: a.type,
        }));
      const emails = $scope.model.emails
        .filter((a) => a.email && a.client_name)
        .map((a) => ({ email: a.email, client_name: a.client_name }));
      if (clients.length > 0 || emails.length > 0) {
        ConfirmBoxService.open('Confirmation', 'Are you sure to send the newsletter to selected clients?', {
          Yes: () => {
            setTimeout(() => {
              ConfirmBoxService.open('Message', 'Email successfully sent to selected clients', {
                OK: () => {},
              });
            }, 0);
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}email-newsletter/send`,
              'POST',
              {
                clients: JSON.stringify(clients),
                emails: JSON.stringify(emails),
                campaign_id: $stateParams.campaign_id,
                hiddenMode: true,
              },
              (_response) => {},
            );
          },
          No: () => {},
        });
      }
    };

    $scope.populateClients = () => {
      $scope.model.clients.forEach((value) => {
        $scope.model.selectedClientList[value.id] = value.selected;
      });
      const businessTypes = $scope.model.businessTypes.filter((a) => a.selected === true).map((a) => a.id);
      if (businessTypes.length > 0) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}email-newsletter/users`,
          'GET',
          { businessTypes: businessTypes.join(',') },
          (response) => {
            $scope.model.clients = response.data.data;
          },
        );
      } else {
        $scope.model.clients = [];
      }
    };

    $scope.updateOnSelectAll = () => {
      for (const i in $scope.model.clients) {
        $scope.model.clients[i].selected = $scope.model.selectAll;
      }
    };

    $scope.filterClients = (clients) =>
      clients.filter((a) => a.client_name.toLowerCase().indexOf($scope.model.search.toLowerCase()) !== -1);

    $scope.onEmailAddressAdd = ($tag) => {
      let currentId = 0;
      if ($scope.model.emails.length > 1) {
        const previousTagIdx = $scope.model.emails.indexOf($tag) - 1;
        const previousTag = $scope.model.emails[previousTagIdx];
        currentId = previousTag.id + 1;
      }
      $tag.id = currentId;
    };

    $scope.addMoreEmails = () => {
      $scope.model.emails.push({
        email: '',
        client_name: '',
      });
    };
    $scope.removeEmailAddress = (index) => {
      $scope.model.emails.splice(index, 1);
    };

    if ($stateParams.page_type === 'listing') {
      $scope.loadListingData();
    } else if ($stateParams.page_type === 'create') {
      $scope.createEmailCampaign();
    } else if ($stateParams.page_type === 'send') {
      $scope.getCampaign($stateParams.campaign_id);
    }
  },
];
