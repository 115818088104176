export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  function BudgetCategoriesController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
  ) {
    if ($rootScope.isAdminUser === false) {
      ConfirmBoxService.open('Error', 'Only admin user can access this module!', {
        OK: () => {
          $state.go($rootScope.defaultRoute);
        },
      });
      return false;
    }
    $scope.model = {
      dropdowns: {
        category_types: [],
      },
      addForm: {},
      formData: {},
      categoryID: null,
      listingData: [],
      type: $stateParams.type,
    };
    $scope.loadCategoryData = () => {
      LoaderService.show();
      if ($scope.model.categoryID == null) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}budget/getBudgetCategories`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.setListing(response);
          },
        );
      } else {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}budget/getBudgetCategory/${$scope.model.categoryID}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.editCategory(response);
          },
        );
      }
    };
    if ($stateParams.type !== 'add') {
      /*LOAD DATA*/
      if (typeof $stateParams.categoryID !== 'undefined') {
        $scope.model.categoryID = $stateParams.categoryID;
      }
      $scope.loadCategoryData();
    } else if ($stateParams.type === 'add') {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}budget/getBudgetCategoriesTypes`,
        'POST',
        $scope.model.addForm,
        (response) => {
          $scope.model.dropdowns.category_types = response.data.categoriesTypesData;
          $scope.model.formData.category_type = $scope.model.dropdowns.category_types[0];
          LoaderService.hide();
        },
      );
    }
    $scope.submitAddForm = () => {
      LoaderService.show();

      if ($scope.model.type === 'add') {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}budget/addBudgetCategories`,
          'POST',
          $scope.model.formData,
          (_response) => {
            $state.go('/budget-categories');
            LoaderService.hide();
          },
        );
      } else if ($scope.model.type === 'edit') {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}budget/updateBudgetCategories/${$scope.model.formData.budget_category_id}`,
          'POST',
          $scope.model.formData,
          (_response) => {
            $state.go('/budget-categories');
            LoaderService.hide();
          },
        );
      }
    };
    $scope.setListing = (res) => {
      $scope.model.listingData = res.data.categoriesData;
      LoaderService.hide();
    };

    $scope.editCategory = (res) => {
      const category_data = res.data.result.category[0];

      $scope.model.dropdowns.category_types = res.data.result.categories_types;
      $scope.model.formData = category_data;
      $scope.model.formData.category_type = {
        title: category_data.budget_category_type,
        value: category_data.budget_category_type_id,
      };

      LoaderService.hide();
    };

    $scope.deleteAgency = (i) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this agancy?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}budget/deleteBudgetCategory/${$scope.model.listingData[i].budget_category_id}`,
            'POST',
            $scope.model.listingData[i],
            (response) => {
              if (response.data.success === true) {
                $scope.model.listingData.splice(i, 1);
              } else {
                alert('An error occured. Please try again later!');
              }
            },
          );
        },
        No: () => {},
      });
    };
  },
];
