class LoaderService {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
    this.intVal = null;
  }

  startInterval(time) {
    this.intVal = setInterval(() => {
      this.$rootScope.appLoading = true;
      this.$rootScope.$apply();
    }, time);
  }

  stopInterval() {
    clearInterval(this.intVal);
    this.$rootScope.appLoading = false;
  }

  show() {
    this.$rootScope.appLoading = true;
  }

  hide() {
    this.$rootScope.appLoading = false;
  }
}

// Mark dependencies for injection
LoaderService.$inject = ['$rootScope'];

export default LoaderService;
