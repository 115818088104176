import moment from 'https://cdn.jsdelivr.net/npm/moment@2.30.1/+esm';

class MomentService {
  // Formats a date according to the given format
  formatDate(date, format) {
    return moment(date).format(format);
  }

  // Adds a specified number of days to a date
  addDays(date, days) {
    return moment(date).add(days, 'days').toDate();
  }

  // Adds a specified number of months to a date
  addMonths(date, months) {
    return moment(date).add(months, 'months').toDate();
  }

  // Checks if a date is before another date
  isBefore(date1, date2) {
    return moment(date1).isBefore(date2);
  }

  // Gets the current time in a specified format
  getCurrentTime(format = 'HH:mm:ss') {
    return moment().format(format);
  }

  // Difference between two dates in days
  differenceInDays(date1, date2) {
    return moment(date2).diff(moment(date1), 'days');
  }

  // Check if a date is valid
  isValid(date) {
    return moment(date).isValid();
  }

  // Calculates age difference in milliseconds from a given birthday to now
  getAgeDiffMs(birthday) {
    const today = moment(); // Get today's date
    const birthDate = moment(birthday); // Convert the birthday to a moment object
    const yearsDiffMs = today.diff(birthDate, 'milliseconds'); // Calculate the difference in years
    return yearsDiffMs; // Return the age difference in years
  }

  // Calculates age difference from a given birthday to now
  getAgeDiff(birthday) {
    const today = moment(); // Get today's date
    const birthDate = moment(birthday); // Convert the birthday to a moment object
    const yearsDiff = today.diff(birthDate, 'years'); // Calculate the difference in years
    return yearsDiff; // Return the age difference in years
  }

  // Converts a date string to a Date object, considering time zone
  convertTimeZone(date) {
    if (
      date === '' ||
      typeof date === 'undefined' ||
      date == null ||
      date === '0000-00-00' ||
      date === '0000-00-00 00:00:00'
    ) {
      return '';
    }
    const convertedDate = moment(date).toDate();
    return convertedDate;
  }
}

// Make sure to export the service class
export default MomentService;
