export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  'HelpersService',
  '$location',
  'MomentDateService',
  'MomentService',
  'IndividualInfoService',
  'ValidationService',
  function IndividualNotesAttachmentsController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
    HelpersService,
    $location,
    MomentDateService,
    MomentService,
    IndividualInfoService,
    ValidationService,
  ) {
    if (typeof $stateParams.individualID === 'undefined') {
      $state.go('/individuals');
      return false;
    }
    $rootScope.subMenuActive = 'notes-attachments';
    $scope.individualID = $stateParams.individualID;
    $rootScope.individualID = $stateParams.individualID;
    let dataFactory = {};

    $scope.model = {
      attachments: [],
      attachmentsGenericSearch: '',
      showSpouseSection: false,
      currentPrescriptionData: {},
      dropdown: {
        emailProviders: {},
        specialistSpeciality: {},
        physicianSuffix: {},
        drugNames: {},
        drugDosage: {},
        states: [],
        coverage_type: [],
        carrier: [],
        companies: [],
        products: [],
      },
      additionalPrescriptionsTakeData: [],
      prescriptionPacenetPA: false,
      prescriptionsTaken: false,
      spouseCurrentClient: false,
      primaryPhysiciansCount: 1,
      primaryPhysicians: [{ id: 1, value: '' }],
      additionalprescriptionsFields: [{ id: 1, value: '' }],
      approvedDateTime: '',
      aarpNumber: false,
      approvedInd: false,
      prescriptionsTakeFields: [],
      prescriptionsTakeData: [],
      specialists: [],
      filesData: {},
      individualRawData: '',
      formData: {
        moduleID: '1',
        category_type_id: { value: '', title: '' },
      },
      intakeDatePicker: false,
      appointmentDTPicker: false,
      leadDatePicker: false,
      spouseDOBPicker: false,
      effADatePicker: false,
      effBDatePicker: false,
      spouseEffADatePicker: false,
      spouseEffBDatePicker: false,
      disabilityPicker: false,
      spouseDisabilityPicker: false,
      counties: [],
      intakeSheetAdd: {},
      primaryPhysician: {},
      addForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
      },
      editForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
      },
      individualID: null,
      listingData: [],
      receipt_data: [],
      individualData: {},
      terminationLetterID: null,
      max_numbers: 7,
      decimals: 2,
      min: 0,
      max: 99999.99,
      sorting: {
        active: 'created_at',
        created_at: 'desc',
      },
    };
    $scope.model.individualID = $scope.individualID;
    //Get Individual info
    IndividualInfoService.getInfo($scope.model.individualID, (result) => {
      $scope.model.individual_info = result;
    });

    const dateFormatterYMD = (d) => {
      if (d === 'null' || d == null || d === '' || d === '0000-00-00') {
        return '';
      }
      const date = MomentDateService(d);
      if (d !== '' && d) {
        return `${Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
          Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()
        }-${date.getFullYear()}`;
      }
      return '';
    };
    const dateFormatterYMD_DOB = (date) => {
      if (date === 'null' || date == null || date === '' || date === '0000-00-00') {
        return false;
      }
      date = MomentDateService(date);
      return `${date.getFullYear()}-${
        Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }-${Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()}`;
    };

    const fd = new FormData();
    $scope.uploadFile = (f) => {
      fd.append('file', f);
      $scope.model.newFormData = fd;
    };
    $scope.uploadFileMulti = (f, _i) => {
      for (const i in f) {
        fd.append('file[]', f[i]);
      }
      $scope.model.newFormData = fd;
    };
    $scope.files = [];
    $scope.saveFormData = () => {
      let fileAttached = false;

      if (typeof $scope.model.newFormData === 'undefined') {
        const fd = new FormData();
        fd.append('file', {});
        fd.append('moduleID', $scope.model.formData.moduleID);
        fd.append('individualID', $scope.individualID);
        fd.append('category_type_id', $scope.model.formData.category_type_id.value);
        fd.append('subject', $scope.model.formData.subject);
        fd.append('notes', $scope.model.formData.notes);
        $scope.model.newFormData = fd;
      } else {
        fileAttached = true;
        $scope.model.newFormData.append('moduleID', $scope.model.formData.moduleID);
        $scope.model.newFormData.append('individualID', $scope.individualID);
        $scope.model.newFormData.append('category_type_id', $scope.model.formData.category_type_id.value);
        $scope.model.newFormData.append('subject', $scope.model.formData.subject);
        $scope.model.newFormData.append('notes', $scope.model.formData.notes);
      }
      if (fileAttached === false) {
        ConfirmBoxService.open('Confirmation', 'Do you really want to save without adding file(s)?', {
          Yes: () => {
            AuthService.uploadFile(
              `${$rootScope.api05DomainPath}individual/${$scope.individualID}/notes-attachments/add`,
              $scope.model.newFormData,
              (_response) => {
                ConfirmBoxService.open('Notification', 'Successfully Uploaded!', {
                  OK: () => {
                    window.history.back(-1);
                  },
                });
              },
            );
          },
          NO: () => {
            $scope.model.newFormData = undefined;
          },
        });
      } else {
        AuthService.uploadFile(
          `${$rootScope.api05DomainPath}individual/${$scope.individualID}/notes-attachments/add`,
          $scope.model.newFormData,
          (_response) => {
            ConfirmBoxService.open('Notification', 'Successfully Uploaded!', {
              OK: () => {
                window.history.back(-1);
              },
            });
          },
        );
      }
    };
    $scope.onChangeDOB = (a, b) => {
      const birthday = new Date(b);
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      const c = Math.abs(ageDate.getUTCFullYear() - 1970);

      if (a) {
        $scope.model.addForm.age = c;
        $scope.model.editForm.age = c;
      } else {
        $scope.model.intakeSheetAdd.spouse_age = c;
      }
    };
    $scope.showHideSpouseSection = (v) => {
      if (v?.value === 'Married') {
        $scope.model.showSpouseSection = true;
      } else {
        $scope.model.showSpouseSection = false;
      }
    };

    $scope.loadIntakeSheetData = (res) => {
      $rootScope.constants.DB = res.data;
      $scope.model.attachments = res.data.attachments;

      $scope.model.addForm.individual_type_id = res?.data?.individualTypes[0];
      $scope.model.editForm = res.data.individualData;
      $scope.model.individualAddress = res.data.individualAddress;

      $scope.showHideSpouseSection($scope?.model?.editForm?.martial_status);

      $scope.model.editForm.date_of_birth =
        res.data.individualData.date_of_birth === '0000-00-00' || res.data.individualData.date_of_birth == null
          ? '--'
          : MomentService.formatDate(res.data.individualData.date_of_birth, 'MM-DD-YYYY');
      $scope.model.editForm.lead_date =
        res.data.individualData.lead_date === '0000-00-00' || res.data.individualData.lead_date == null
          ? ''
          : MomentService.formatDate(res.data.individualData.lead_date, 'MM-DD-YYYY');

      if ($scope.model.editForm.date_of_birth !== '') {
        const birthday = new Date($scope.model.editForm.date_of_birth);
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        const c = Math.abs(ageDate.getUTCFullYear() - 1970);
        $scope.model.editForm.age = c;
      }

      $scope.model.intakeSheetAdd = res.data.individual_intake;
      $scope.model.intakeSheetAdd.intake_date =
        $scope.model.intakeSheetAdd.intake_date === '0000-00-00' || $scope.model.intakeSheetAdd.intake_date == null
          ? '--'
          : MomentService.formatDate($scope.model.intakeSheetAdd.intake_date, 'MM-DD-YYYY');
      $scope.model.intakeSheetAdd.part_a_eff_date = MomentService.formatDate(
        $scope.model.intakeSheetAdd.part_a_eff_date === '0000-00-00' ||
          $scope.model.intakeSheetAdd.part_a_eff_date == null,
        'MM-DD-YYYY',
      )
        ? '--'
        : MomentService.formatDate($scope.model.intakeSheetAdd.part_a_eff_date, 'MM-DD-YYYY');
      $scope.model.intakeSheetAdd.part_b_eff_date = MomentService.formatDate(
        $scope.model.intakeSheetAdd.part_b_eff_date === '0000-00-00' ||
          $scope.model.intakeSheetAdd.part_b_eff_date == null,
        'MM-DD-YYYY',
      )
        ? '--'
        : MomentService.formatDate($scope.model.intakeSheetAdd.part_b_eff_date, 'MM-DD-YYYY');
      $scope.model.intakeSheetAdd.ss_disability_date = MomentService.formatDate(
        $scope.model.intakeSheetAdd.ss_disability_date === '0000-00-00' ||
          $scope.model.intakeSheetAdd.ss_disability_date == null,
        'MM-DD-YYYY',
      )
        ? '--'
        : MomentService.formatDate($scope.model.intakeSheetAdd.ss_disability_date, 'MM-DD-YYYY');

      if (
        $scope.model.intakeSheetAdd.appointment_date_time !== '' &&
        $scope.model.intakeSheetAdd.appointment_date_time != null
      ) {
        const dt = new Date($scope.model.intakeSheetAdd.appointment_date_time);
        const date = dt;
        let newDate = MomentService.formatDate(dt, 'MM-DD-YYYY');

        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        newDate = `${newDate} ${strTime}`;
        //return strTime;
        $scope.model.intakeSheetAdd.appointment_date_time = newDate; //($scope.model.intakeSheetAdd.appointment_date_time == 'null'|| $scope.model.intakeSheetAdd.appointment_date_time == null || $scope.model.intakeSheetAdd.appointment_date_time == '')?'': $scope.model.intakeSheetAdd.appointment_date_time;
      }

      if ($scope.model.intakeSheetAdd.current_ins_co != null) {
        for (const i in $rootScope.constants.dropdowns.currentInsurance) {
          if ($rootScope.constants.dropdowns.currentInsurance[i].value === $scope.model.intakeSheetAdd.current_ins_co) {
            $scope.model.intakeSheetAdd.current_ins_co = $rootScope.constants.dropdowns.currentInsurance[i];
            break;
          }
        }
      }

      if ($scope.model.intakeSheetAdd.approved_by_manager != null) {
        if (
          $scope.model.intakeSheetAdd.manager_approval_date !== '' &&
          $scope.model.intakeSheetAdd.approved_by_manager.value === 'yes'
        ) {
          $scope.model.approvedDateTime = $scope.model.intakeSheetAdd.manager_approval_date;
        }
      }

      if ($scope.model.intakeSheetAdd.current_prescription_drug_plan != null) {
        const _organization = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'organization',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const _code = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'code',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const _monthly_premium = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'monthly_premium',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const title = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'title',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const id = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'id',
          $rootScope.constants.DB.prescription_drug_plan,
        );

        $scope.model.intakeSheetAdd.current_prescription_drug_plan = {
          value: Number(id),
          title: title,
        };
        populateCurrentPrescriptionData($scope.model.intakeSheetAdd.current_prescription_drug_plan);
      }

      if (typeof res.data.spouseData !== 'undefined') {
        $scope.model.spouseData = res.data.spouseData;
        $scope.model.spouseData.date_of_birth =
          res.data.spouseData.date_of_birth === '0000-00-00' || res.data.spouseData.date_of_birth == null
            ? ''
            : MomentService.formatDate(res.data.spouseData.date_of_birth, 'MM-DD-YYYY');
        if ($scope.model.spouseData.date_of_birth) {
          const birthday = new Date($scope.model.spouseData.date_of_birth);
          const ageDifMs = Date.now() - birthday.getTime();
          const ageDate = new Date(ageDifMs); // miliseconds from epoch
          const c = Math.abs(ageDate.getUTCFullYear() - 1970);
          $scope.model.spouseData.age = c;
        }

        $scope.model.spouseData.part_a_eff_date =
          $scope.model.spouseData.part_a_eff_date === '0000-00-00' || $scope.model.spouseData.part_a_eff_date == null
            ? ''
            : MomentService.formatDate($scope.model.spouseData.part_a_eff_date, 'MM-DD-YYYY');
        $scope.model.spouseData.part_b_eff_date =
          $scope.model.spouseData.part_b_eff_date === '0000-00-00' || $scope.model.spouseData.part_b_eff_date == null
            ? ''
            : MomentService.formatDate($scope.model.spouseData.part_b_eff_date, 'MM-DD-YYYY');
        $scope.model.spouseData.ss_disability_date =
          $scope.model.spouseData.ss_disability_date === '0000-00-00' ||
          $scope.model.spouseData.ss_disability_date == null
            ? ''
            : MomentService.formatDate($scope.model.spouseData.ss_disability_date, 'MM-DD-YYYY');
      }

      if (res.data.primary_physicians.length !== 0) {
        $scope.model.primaryPhysicians = res.data.primary_physicians;
      }

      if (res.data.specialists.length !== 0) {
        $scope.model.specialistsData = res.data.specialists;
      }
      if (res.data.medical_specialties.length !== 0) {
        $scope.model.specialists = res.data.medical_specialties;
      }
      if (res.data.prescriptions.length !== 0) {
        let presIndex = 0;
        let additionalPresIndex = 0;
        for (const i in res.data.prescriptions) {
          const p = res.data.prescriptions[i];

          if (p.is_additional_prescription === 'yes') {
            if (typeof $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] === 'undefined') {
              $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] = {};
            }
            if (additionalPresIndex === 0) {
              $scope.model.additionalprescriptionsFields = [];
            }
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].drug_name = p.drug_name;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].dosage = p.dosage;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].frequency = p.frequency;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].quantity = p.quantity;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].prescribing_doctor = {
              value: p.prescribing_doctor,
              title: p.prescribing_doctor,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            additionalPresIndex++;
            $scope.model.additionalprescriptionsFields.push({
              id: additionalPresIndex,
              value: additionalPresIndex,
            });
          } else {
            if (typeof $scope.model.prescriptionsTakeData[presIndex] === 'undefined') {
              $scope.model.prescriptionsTakeData[presIndex] = {};
            }
            $scope.model.prescriptionsTakeData[presIndex].drug_name = p.drug_name;
            $scope.model.prescriptionsTakeData[presIndex].dosage = p.dosage;
            $scope.model.prescriptionsTakeData[presIndex].frequency = p.frequency;
            $scope.model.prescriptionsTakeData[presIndex].quantity = p.quantity;
            $scope.model.prescriptionsTakeData[presIndex].prescribing_doctor = {
              value: p.prescribing_doctor,
              title: p.prescribing_doctor,
            };
            $scope.model.prescriptionsTakeData[presIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.prescriptionsTakeData[presIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            presIndex++;
          }
        }
      }

      const o = HelpersService.mergeData([$scope.model.intakeSheetAdd, $scope.model.editForm]);
      o.individual_type_id = $scope.model.editForm.individual_type_id.individual_types;
      o.individual_category_id = $scope.model.editForm.individual_category_id;
      //o.state_id = $scope.model.editForm.state_id.state;
      //o.county_id = $scope.model.editForm.county_id.county;
      $scope.model.individualRawData = o;

      if ($stateParams.contentType === 'print-intake-sheet') {
        setTimeout(() => {
          window.print();
          //window.close();
        }, 1000);
      }

      LoaderService.hide();
    };
    const populateCurrentPrescriptionData = (d) => {
      const organization = HelpersService.getValueByKey(
        d.value,
        'id',
        'organization',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const code = HelpersService.getValueByKey(d.value, 'id', 'code', $rootScope.constants.DB.prescription_drug_plan);
      const monthly_premium = HelpersService.getValueByKey(
        d.value,
        'id',
        'monthly_premium',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const _title = HelpersService.getValueByKey(
        d.value,
        'id',
        'title',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const _id = HelpersService.getValueByKey(d.value, 'id', 'id', $rootScope.constants.DB.prescription_drug_plan);
      $scope.model.currentPrescriptionData.organization = organization;
      $scope.model.currentPrescriptionData.code = code;
      $scope.model.currentPrescriptionData.monthly_premium = monthly_premium;
    };
    $scope.downloadIntakeSheet = (_d) => {
      LoaderService.show();
      JSZipUtils.getBinaryContent($rootScope.constants.intakeSheetDoc, (err, content) => {
        if (err) {
          throw e;
        }
        const doc = new Docxtemplater(content);
        $scope.model.individualRawData = HelpersService.nullToEmptyValue($scope.model.individualRawData);
        doc.setData($scope.model.individualRawData);
        doc.render();
        const out = doc.getZip().generate({ type: 'blob' });
        saveAs(out, `${$scope.model.individualRawData.first_name}-intake-sheet.docx`);
        const lod = document.getElementsByClassName('app-loader');
        lod[0].className = 'app-loader ng-hide';
      });
    };
    $scope.printContent = () => {
      window.print();
    };

    $scope.exportPDF = (t) => {
      HelpersService.exportToPDF(t);
    };
    $scope.printIntakeSheet = (t) => {
      const _printWindow = window.open(
        `${$rootScope.domainPath}/print.html#/individuals/${$scope.model.individualID}/notes-attachment/view/${t}`,
      );
    };
    $scope.editDocument = (t) => {
      const _printWindow = window.open(
        `${$rootScope.domainPath}#/individuals/${$scope.model.individualID}/notes-attachment/documents/${t}`,
      );
    };
    $scope.printAttachments = (t, id) => {
      const _printWindow = window.open(
        `${$rootScope.domainPath}/print.html#/individuals/${$scope.model.individualID}/notes-attachment/view/${t}/${id}`,
      );
    };

    if ($stateParams.fileType && ($stateParams.type === 'listing' || $stateParams.type === 'view')) {
      getIndividualAttachemntsListing(false);

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/get-termination-letters/${$scope.model.individualID}`,
        'POST',
        {},
        (response) => {
          if (response.data.success === true) {
            $scope.model.terminationLetters = response.data.termination_letters;
            $scope.model.individualReceipts = response.data.individual_receipts;
          }
        },
      );

      //LoaderService.show();
      /*$scope.model.addForm.module_id = 1;
         $scope.model.addForm.fileType = $stateParams.fileType;
         $scope.model.addForm.individual_id = $rootScope.individualID;
         if ($scope.model.addForm.fileType == 'documents') {
         $scope.model.addForm.fileType = 'document';
         }

         if ($stateParams.authKey != 'undefined') {
         $scope.model.addForm.authKey = $stateParams.authKey;
         $scope.model.addForm.token = $stateParams.token;
         }
         AuthService.sendRequest($rootScope.api05DomainPath + 'individual/get/' + $scope.model.individualID + '/files', "POST", $scope.model.addForm, function (response) {
         $scope.loadIntakeSheetData(response);
         });

         AuthService.sendRequest($rootScope.api05DomainPath + 'individual/get-termination-letters/' + $scope.model.individualID, "POST", {}, function (response) {
         if(response.data.success == true){
         $scope.model.terminationLetters = response.data.termination_letters;
         $scope.model.individualReceipts = response.data.individual_receipts;
         }
         });*/
    }

    if ($stateParams.type === 'add-modify-termination-letter') {
      let req_url = '';
      if ($stateParams.terminationLetterID !== '') {
        $scope.model.terminationLetterID = $stateParams.terminationLetterID;
        req_url = `${$rootScope.api05DomainPath}individual/termination-letter/${$scope.model.individualID}/${$scope.model.terminationLetterID}`;
      } else {
        req_url = `${$rootScope.api05DomainPath}individual/termination-letter/${$scope.model.individualID}`;
      }

      $scope.date = MomentService.formatDate(new Date(), 'MM-DD-YYYY');
      AuthService.sendRequest(req_url, 'POST', {}, (response) => {
        $scope.model.states = response.data.states;
        $scope.model.carriers = response.data.carriers;
        $scope.model.companies = response.data.companies;
        $scope.model.carrier_plans = response.data.carrier_plans;
        $scope.model.individualData = response.data.individual[0];

        //Edit Mode
        if (response.data.termination_letter_data.length > 0) {
          $scope.model.addForm = response.data.termination_letter_data[0];

          //If print page
          if ($stateParams.param === 'print') {
            $scope.model.addForm.effective_date = MomentService.formatDate(
              $scope.model.addForm.effective_date,
              'MM-DD-YYYY',
            );
          } else {
            $scope.model.addForm.effective_date = MomentDateService($scope.model.addForm.effective_date);
          }

          if ($scope.model.addForm.address_type === 'primary') {
            $scope.model.addForm.address_type = {
              value: 'primary',
              title: 'Primary Address',
            };
            $scope.model.individualData.address_v = $scope.model.individualData.address;
          } else if ($scope.model.addForm.address_type === 'office') {
            $scope.model.addForm.address_type = {
              value: 'office',
              title: 'Office Address',
            };
            $scope.model.individualData.address_v = $scope.model.individualData.address_2;
          }

          $scope.populatePlanDataTerminationLetter($scope.model.addForm.carrier_id, 'MEDICAL_SUPPLIMENT');
        }

        if ($scope.model.individualData.phone_cell !== '') {
          $scope.model.individualData.phone_number = $scope.model.individualData.phone_cell;
        } else if ($scope.model.individualData.phone_home !== '') {
          $scope.model.individualData.phone_number = $scope.model.individualData.phone_home;
        } else if ($scope.model.individualData.phone_business !== '') {
          $scope.model.individualData.phone_number = $scope.model.individualData.phone_business;
        } else if ($scope.model.individualData.phone_other !== '') {
          $scope.model.individualData.phone_number = $scope.model.individualData.phone_other;
        } else {
          $scope.model.individualData.phone_number = '-';
        }

        if (response.data.termination_letter_data.length === 0) {
          if ($scope.model.individualData.address !== '') {
            $scope.model.addForm.address_type = {
              value: 'primary',
              title: 'Primary Address',
            };
            $scope.model.individualData.address_v = $scope.model.individualData.address;
          } else if ($scope.model.individualData.address_2 !== '') {
            $scope.model.addForm.address_type = {
              value: 'office',
              title: 'Office Address',
            };
            $scope.model.individualData.address_v = $scope.model.individualData.address_2;
          }
        }

        if ($stateParams.param === 'print') {
          setTimeout(() => {
            window.print();
            //window.close();
          }, 1000);
        }
      });
    } else if ($stateParams.type === 'add-modify-receipt-individual') {
      let req_url = '';
      if ($stateParams.receiptIndividualID !== '') {
        $scope.model.receiptIndividualID = $stateParams.receiptIndividualID;
        req_url = `${$rootScope.api05DomainPath}individual/receipt-individual/${$scope.model.individualID}/${$scope.model.receiptIndividualID}`;
      } else {
        req_url = `${$rootScope.api05DomainPath}individual/receipt-individual/${$scope.model.individualID}`;
      }

      AuthService.sendRequest(req_url, 'GET', {}, (response) => {
        dataFactory = response.data.carrier_info;
        $scope.model.dropdown.coverage_type = dataFactory.coverage_type_data;

        $scope.model.listingData = response.data.receipt_data_c;
        $scope.model.receipt_data = response.data.receipt_data[0];
        $scope.model.individual_data = response.data.individual[0];
        $scope.model.individual_name = `${$scope.model.individual_data.first_name} ${$scope.model.individual_data.last_name}`;

        const _receipt_data = response.data.receipt_data[0];

        //Edit Mode
        if (response.data.receipt_data.length > 0) {
          //If print page
          if ($stateParams.param === 'print') {
            $scope.model.receipt_data.agent_date = MomentService.formatDate(
              $scope.model.receipt_data.agent_date,
              'MM-DD-YYYY',
            );
            $scope.model.receipt_data.client_date = MomentService.formatDate(
              $scope.model.receipt_data.client_date,
              'MM-DD-YYYY',
            );

            //convert all date picker values to moment format
            $scope.moment_date_format($scope.model.listingData, 'print');
          } else {
            $scope.model.receipt_data.agent_date = MomentDateService($scope.model.receipt_data.agent_date);
            $scope.model.receipt_data.client_date = MomentDateService($scope.model.receipt_data.client_date);

            //convert all date picker values to moment format
            $scope.moment_date_format($scope.model.listingData, 'edit');

            /*SET DROPDOWN DATA*/
            for (const i in $scope.model.listingData) {
              if ($scope.model.listingData[i].length > 0) {
                for (const k in $scope.model.listingData[i]) {
                  const objListing = $scope.model.listingData[i][k];
                  const coverage_type = i;
                  objListing.type_tp = coverage_type;

                  const coverageObject = HelpersService.getObjectByValue(
                    objListing.plan_category_id,
                    'id',
                    dataFactory.coverage_type_data,
                  );
                  objListing.carriers = dataFactory.carrier_data[coverageObject.id].carrier_data;
                  objListing.coverage_type = coverageObject;

                  const carrierObject = HelpersService.getObjectByValue(
                    objListing.carrier_id,
                    'id',
                    objListing.carriers,
                  );
                  objListing.carrier = carrierObject;
                  $scope.populateProductsCompanies(carrierObject, coverage_type, k);

                  const companyObject = HelpersService.getObjectByValue(
                    objListing.company_id,
                    'company_id',
                    objListing.companies,
                  );
                  if (companyObject !== '') {
                    objListing.insurance_company = companyObject;
                    $scope.populateCarrierCompanyProducts(companyObject, coverage_type, k);
                  }

                  if (objListing.company_id === '0') {
                    const productObject = HelpersService.getObjectByValue(
                      objListing.product_id,
                      'id',
                      objListing.products,
                    );
                    objListing.plan = productObject;
                  } else {
                    const productObject = HelpersService.getObjectByValue(
                      objListing.product_id,
                      'product_id',
                      objListing.products,
                    );
                    objListing.plan = productObject;
                  }
                }
              }
            }
          }
        }

        //Add first Index By default
        if ($scope.model.listingData.Final_Expense.length === 0) {
          $scope.add_field_receipt('Final_Expense');
        }

        if ($stateParams.param === 'print') {
          setTimeout(() => {
            window.print();
            //window.close();
          }, 2000);
        }
      });
    }

    $scope.showDatePickerCalendar = (t) => {
      $scope.model[t] = true;
    };

    $scope.change_address = (o) => {
      if (o.value === 'primary') {
        $scope.model.individualData.address_v = $scope.model.individualData.address;
      } else if (o.value === 'office') {
        $scope.model.individualData.address_v = $scope.model.individualData.address_2;
      }
    };

    $scope.saveTerminationFormData = () => {
      /*VALIDATE REQUIRED FIELDS*/
      const validated = ValidationService.validate($scope, {
        'model.addForm.street': {
          required: true,
          errorMessage: 'Please enter street',
        },
      });

      if (validated) {
        //check to make sure the form is completely valid
        $scope.model.addForm.effective_date = MomentService.formatDate(
          $scope.model.addForm.effective_date,
          'YYYY-MM-DD',
        );
        $scope.model.addForm.individual_id = $scope.model.individualID;
        $scope.model.addForm.terminationLetterID = $scope.model.terminationLetterID;

        if (typeof $scope.model.newFormData === 'undefined') {
          const fd = new FormData();
          fd.append('file', {});
          $scope.model.newFormData = fd;
        }
        $scope.model.newFormData.append('formData', JSON.stringify($scope.model.addForm));

        AuthService.uploadFile(
          `${$rootScope.api05DomainPath}individual/add-termination-letter`,
          $scope.model.newFormData,
          (response) => {
            if (response.success === true) {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {
                  $location.path(`/individuals/${$scope.model.individualID}/notes-attachment/documents`);
                },
              });
            } else {
              ConfirmBoxService.open('Notification', response.message, {
                OK: () => {},
              });
            }
          },
        );
      }
    };

    $scope.saveReceiptFormData = () => {
      $scope.model.receipt_data.agent_date = MomentService.formatDate(
        $scope.model.receipt_data.agent_date,
        'YYYY-MM-DD',
      );
      $scope.model.receipt_data.client_date = MomentService.formatDate(
        $scope.model.receipt_data.client_date,
        'YYYY-MM-DD',
      );

      //convert all date picker values to moment format -- Receipt_C Data
      $scope.moment_date_format($scope.model.listingData, 'add');

      $scope.model.addForm.individual_id = $scope.model.individualID;
      $scope.model.addForm.receiptIndividualID = $scope.model.receiptIndividualID;

      if (typeof $scope.model.newFormData === 'undefined') {
        const fd = new FormData();
        fd.append('agent_signature', {});
        fd.append('client_signature', {});
        $scope.model.newFormData = fd;
      }

      $scope.model.newFormData.append('formData', JSON.stringify($scope.model.addForm));
      $scope.model.newFormData.append('listingData', JSON.stringify($scope.model.listingData));
      $scope.model.newFormData.append('receiptData', JSON.stringify($scope.model.receipt_data));

      AuthService.uploadFile(
        `${$rootScope.api05DomainPath}individual/add-receipt-individual`,
        $scope.model.newFormData,
        (response) => {
          if (response.success === true) {
            ConfirmBoxService.open('Notification', response.message, {
              OK: () => {
                //$state.reload();
                if (response.req_result === 'add') {
                  $location.path(
                    `/individuals/${$scope.model.individualID}/notes-attachment/documents/receipt-individual/${response.receipt_individual_id}`,
                  );
                } else {
                  $state.reload();
                }
              },
            });
          } else {
            let htmlError = '';
            for (const i in response.error_messages) {
              htmlError += `${response.error_messages[i]}.`;
            }

            ConfirmBoxService.open('Notification', htmlError, {
              OK: () => {
                //$state.reload();
              },
            });
          }
        },
      );
    };

    $scope.add_field_receipt = (type_tp) => {
      const obj = {};
      const target = $scope.model.listingData[type_tp];
      const coverage_type = getCoverageIdByType(type_tp);
      const coverage_id = coverage_type.id;

      obj.date_dt = '';
      obj.effective_date = '';
      obj.initial_payment = '';
      obj.insurance_company = '';
      obj.monthly_eft_payment = '';
      obj.name = '';
      obj.plan = '';
      obj.type_tp = type_tp;
      obj.is_company_enable = false;

      if (coverage_id !== '') {
        const carrierData = dataFactory.carrier_data[coverage_id].carrier_data;
        obj.carriers = carrierData;
        obj.coverage_type = coverage_type;
      }

      target.push(obj);
    };

    $scope.remove_field_receipt = (type_tp, index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          const target = $scope.model.listingData[type_tp];

          target.splice(index, 1);
        },
        No: () => {},
      });
    };

    $scope.moment_date_format = (data, type) => {
      for (const i in data) {
        if (data[i].length > 0) {
          for (const k in data[i]) {
            for (const m in data[i][k]) {
              if (m === 'date_dt' || m === 'effective_date') {
                if (type === 'add') {
                  if (data[i][k][m] instanceof Date === true) {
                    data[i][k][m] = MomentService.formatDate(data[i][k][m], 'YYYY-MM-DD');
                  }
                } else if (type === 'edit') {
                  if (MomentService.isValid(data[i][k][m])) {
                    data[i][k][m] = MomentDateService(data[i][k][m]);
                  }
                } else if (type === 'print') {
                  if (MomentService.isValid(data[i][k][m])) {
                    data[i][k][m] = MomentService.formatDate(data[i][k][m], 'MM-DD-YYYY');
                  }
                }
              }
            }
          }
        }
      }
    };

    $scope.uploadFileByName = (files, name) => {
      if (typeof $scope.model.newFormData === 'undefined') {
        $scope.model.newFormData = new FormData();
      }
      $scope.model.newFormData.append(name, files[0]);
    };

    $scope.formatDate = (date) => MomentDateService(date);

    $scope.populatePlanDataTerminationLetter = (item, type_tp, carr_com_type) => {
      const carrier_id = item.carrier_id;
      const company_id = item.company_id;
      const plans = $scope.model.carrier_plans[type_tp][carrier_id];

      if (
        typeof $scope.model.companies[type_tp] !== 'undefined' &&
        typeof $scope.model.companies[type_tp][carrier_id] !== 'undefined'
      ) {
        const _companies = $scope.model.companies[type_tp][carrier_id];
      } else {
        const _companies = [];
      }

      //Assign Plans
      if (carr_com_type === 'carrier') {
        //Reset Values
        $scope.model.companiesIn = [];
        $scope.model.addForm.insurance_company = {};
        $scope.model.products = [];
        $scope.model.addForm.plan = {};

        $scope.model.products = plans;
      } else if (carr_com_type === 'company') {
        //Reset Values
        $scope.model.products = [];
        $scope.model.addForm.plan = {};

        const plans_array = [];
        for (let i = 0; i < plans.length; i++) {
          if (plans[i].company_id === company_id) {
            plans_array.push(plans[i]);
          }
        }

        $scope.model.products = plans_array;
      }

      //Assign Companies
      if (typeof companies !== 'undefined') {
        $scope.model.companiesIn = companies;
      }
    };

    $scope.populateProductsCompanies = (d, type_tp, index) => {
      resetProductsCompanies(type_tp, index);
      const coverage_type = getCoverageIdByType(type_tp);
      const coverage_type_id = coverage_type.id;

      try {
        const _companiesData = dataFactory.carrier_data[coverage_type_id].carrier_company[coverage_type_id][d.id];
      } catch (_e) {
        const _companiesData = [];
      }
      const productsData = dataFactory.carrier_data[coverage_type_id].carrier_products[coverage_type_id][d.id];

      if (companiesData.length > 0) {
        $scope.model.listingData[type_tp][index].is_company_enable = true;
      } else {
        $scope.model.listingData[type_tp][index].is_company_enable = false;
      }

      /*APPEND OBJECT ON 0 INDEX*/
      companiesData = HelpersService.insertObjectIntoArray(companiesData, 0, {
        id: 0,
        company_name: '--',
      });
      $scope.model.listingData[type_tp][index].companies = companiesData;
      $scope.model.listingData[type_tp][index].products = productsData;
    };

    $scope.populateCarrierCompanyProducts = (d, type_tp, index) => {
      resetProducts(type_tp, index);
      const coverage_type = getCoverageIdByType(type_tp);
      const coverage_type_id = coverage_type.id;

      if (d.id === 0) {
        $scope.populateProductsCompanies($scope.model.listingData[type_tp][index].carrier, type_tp, index);
      } else {
        const companyProductsData =
          dataFactory.carrier_data[coverage_type_id].carrier_company_product[coverage_type_id][d.carrier_id][d.id];
        $scope.model.listingData[type_tp][index].products = companyProductsData;
      }
    };

    $scope.populatePlanPaymentData = (d, type_tp, index) => {
      $scope.model.listingData[type_tp][index].monthly_eft_payment = d.monthly_eft_amount;
    };

    /*EDIT ATTACHMENT WORK*/
    if ($stateParams.type === 'edit_attachment') {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/getAttachment/${$stateParams.attachmentID}`,
        'GET',
        $scope.model.addForm,
        (response) => {
          $scope.model.fileData = response.data.attachments[0];
          $scope.model.attachedFiles = response.data.attached_files;
        },
      );
    }
    $scope.deleteAttachedFile = (f, t, i) => {
      f.file_type = t;
      ConfirmBoxService.open('Confirmation', 'Do you want to Delete this file?', {
        YES: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/deleteAttachment/${f.id}`,
            'POST',
            f,
            (_response) => {
              if (typeof i !== 'undefined') {
                $scope.model.attachedFiles.splice(i, 1);
              } else {
                $scope.model.fileData.file_name = '';
              }
            },
          );
        },
        NO: () => {},
      });
    };

    $scope.updateFormData = () => {
      if (typeof $scope.model.newFormData === 'undefined') {
        const fd = new FormData();
        fd.append('file[]', {});
        fd.append('category_type', $scope.model.fileData.category_type);
        fd.append('subject', $scope.model.fileData.subject);
        fd.append('notes', $scope.model.fileData.notes);
        fd.append('individual_id', $scope.model.fileData.ref_id);
        $scope.model.newFormData = fd;
      } else {
        $scope.model.newFormData.append('category_type', $scope.model.fileData.category_type);
        $scope.model.newFormData.append('subject', $scope.model.fileData.subject);
        $scope.model.newFormData.append('notes', $scope.model.fileData.notes);
        $scope.model.newFormData.append('individual_id', $scope.model.fileData.ref_id);
      }
      $scope.model.newFormData.append('id', $scope.model.fileData.id);
      AuthService.uploadFile(
        `${$rootScope.api05DomainPath}individual/updateAttachment/${$stateParams.attachmentID}`,
        $scope.model.newFormData,
        (_response) => {
          $state.reload();
        },
      );
    };

    $scope.removeAttachedFile = (d) => {
      const data = angular.copy(d);
      if (data.file_name === '') {
        data.file_type = 'multiple';
        data.id = data.file_id;
      }
      data.deleteParentRecord = 1;
      ConfirmBoxService.open('Error', 'Do you really want to delete this attachment?!', {
        YES: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/deleteAttachment/${d.id}`,
            'POST',
            data,
            (_response) => {
              $state.reload();
            },
          );
        },
        NO: () => {},
      });
    };

    $scope.sortListing = (a) => {
      for (const i in $scope.model.sorting) {
        if (i !== 'active' && i !== a) {
          $scope.model.sorting[i] = '';
        }
      }

      if ($scope.model.sorting.active !== a) {
        $scope.model.sorting.active = a;
        $scope.model.sorting[a] = a;
      }

      if ($scope.model.sorting[a] === 'asc') {
        $scope.model.sorting[a] = 'desc';
      } else {
        $scope.model.sorting[a] = 'asc';
      }

      getIndividualAttachemntsListing(true);
    };

    function getIndividualAttachemntsListing(_sorting) {
      $scope.model.addForm.module_id = 1;
      $scope.model.addForm.fileType = $stateParams.fileType;
      $scope.model.addForm.individual_id = $rootScope.individualID;
      if ($scope.model.addForm.fileType === 'documents') {
        $scope.model.addForm.fileType = 'document';
      }

      if ($stateParams.authKey !== 'undefined') {
        $scope.model.addForm.authKey = $stateParams.authKey;
        $scope.model.addForm.token = $stateParams.token;
      }

      const activeColumn = $scope.model.sorting.active;
      const ascDesc = $scope.model.sorting[$scope.model.sorting.active];
      $scope.model.addForm.column = activeColumn;
      $scope.model.addForm.columnOrder = ascDesc;
      let $url = '';
      if ($stateParams.contentType === 'print-intake-sheet') {
        $url = `${$rootScope.api05DomainPath}individual/get/${$scope.model.individualID}/files`;
      } else {
        $url = `${$rootScope.api05DomainPath}individual/get/${$scope.model.individualID}/attachments`;
      }
      AuthService.sendRequest($url, 'POST', $scope.model.addForm, (response) => {
        $scope.loadIntakeSheetData(response);
      });
    }

    const resetProductsCompanies = (type_tp, index) => {
      $scope.model.listingData[type_tp][index].plan = '';
      $scope.model.listingData[type_tp][index].insurance_company = '';
    };

    const resetProducts = (type_tp, index) => {
      $scope.model.listingData[type_tp][index].plan = '';
    };

    const getCoverageIdByType = (type_tp) => {
      const coverage_type = $scope.model.dropdown.coverage_type;
      let coverage_type_selected = '';

      for (const i in coverage_type) {
        if (coverage_type[i].category_slug === type_tp) {
          coverage_type_selected = coverage_type[i];
          break;
        }
      }

      return coverage_type_selected;
    };

    $scope.filterAttachments = (data) => {
      data = $scope.model.attachments.filter(
        (a) => JSON.stringify(a).toLowerCase().indexOf($scope.model.attachmentsGenericSearch.toLowerCase()) !== -1,
      );
      return data;
    };

    $scope.updateFilesTitle = (file) => {
      if (file.model) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/attachment/update/${file.id}`,
          'GET',
          file,
          (_response) => {
            file.original_name = file.model;
            file.editMode = false;
          },
        );
      }
    };
  },
];
