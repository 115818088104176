class IndividualRecentActivityService {
  constructor($http, AuthService, $rootScope) {
    this.$http = $http;
    this.AuthService = AuthService;
    this.$rootScope = $rootScope;
    this.info = null;
  }

  getInfo(callback) {
    if (typeof this.info === 'undefined') {
      // Assuming AuthService.sendRequest is a method that performs an HTTP request.
      this.AuthService.sendRequest(
        `${this.$rootScope.api05DomainPath}activity-log/view-individual-activity-agent`,
        'GET',
        {},
        (response) => {
          this.info = response; // Adjust according to actual response structure
          callback(this.info);
        },
      );
    } else {
      callback(this.info);
    }
  }

  setInfo(value) {
    this.info = value;
  }
}

IndividualRecentActivityService.$inject = ['$http', 'AuthService', '$rootScope'];

export default IndividualRecentActivityService;
