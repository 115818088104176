import { fileCategories } from './utils/constants';
import helpers from './utils/helpers';

const runtimeConfig = [
  '$rootScope',
  'AuthService',
  '$cookies',
  ($rootScope, AuthService, $cookies) => {
    $rootScope.access_token = '';
    $rootScope.domain = helpers.getCurrentDomain();
    $rootScope.isDevEnv = helpers.isDevEnv($rootScope.domain);
    $rootScope.debugModeOn = $rootScope.isDevEnv;
    $rootScope.printOpenedWindow = false;
    $rootScope.domainPath = `//${$rootScope.domain}/`;
    $rootScope.appAssetsPath = `//${$rootScope.domain}/assets/`;
    $rootScope.api05DomainPath = `//${helpers.getApi05Domain()}/`;
    $rootScope.api11DomainPath = `//${helpers.getApi11Domain()}/api/`;
    $rootScope.defaultRoute = '/individuals';
    $rootScope.appLoading = false;
    $rootScope.templatesDir = 'views/';

    $rootScope.confirmBox = {
      models: {},
      open: false,
      heading: 'Are You Sure?',
      description: '',
      form: false,
      click: (callback) => {
        const models = {};
        for (const i in $rootScope.confirmBox.fields) {
          const d = $rootScope.confirmBox.fields[i];
          models[d.modelName] = d.modelValue;
        }
        if (typeof callback === 'function') {
          if (callback(models) === false) {
            return false;
          }
        }
        $rootScope.confirmBox.open = false;
        $rootScope.confirmBox.form = false;
      },
      actions: {
        Yes: () => {},
        No: () => {},
      },
      fields: [],
    };

    $rootScope.loggedInUserData = {
      avatar: './avatar9.jpg',
    };

    $rootScope.logout = () => {
      AuthService.logout();
    };

    $rootScope.fileToDownload = '';
    $rootScope.downloadFile = (url) => {
      $rootScope.fileToDownload = url;
    };

    if ($rootScope.debugModeOn === true) {
      console.log("%c Debugging is ON because we're on a DEV server.", 'color: red;font-size:1em;font-weight:bold;');
    }

    $rootScope.constants = {
      intakeSheetDoc: `${$rootScope.domainPath}templates/docs/intake-sheet.docx`,
      DB: {},
      fileCategories: fileCategories,
      modules: [
        {
          id: 1,
          name: 'Individuals',
        },
      ],
      datePickerShown: false,
      dropdowns: {
        addressType: [
          {
            value: 'Home',
            title: 'Home',
          },
          {
            value: 'Business',
            title: 'Business',
          },
        ],
        isAppointmentForClient: [
          {
            value: 'Individual',
            title: 'Individual',
          },
          {
            value: 'Couple',
            title: 'Couple',
          },
          {
            value: '3+',
            title: '3+',
          },
        ],
        prescription_reason: [
          {
            value: '-',
            title: '-',
          },
          {
            value: 'Allergies',
            title: 'Allergies',
          },
          {
            value: "Alzheimer's Disease",
            title: "Alzheimer's Disease",
          },
          {
            value: 'Anxiety',
            title: 'Anxiety',
          },
          {
            value: 'Arthritis',
            title: 'Arthritis',
          },
          {
            value: 'Asthma',
            title: 'Asthma',
          },
          {
            value: 'Atrial Fibrillation (A-Fib)',
            title: 'Atrial Fibrillation (A-Fib)',
          },
          {
            value: 'Blood Disorder',
            title: 'Diarrhea',
          },
          {
            value: 'Blood Pressure',
            title: 'Blood Pressure',
          },
          {
            value: 'Blood Thinner',
            title: 'Blood Thinner',
          },
          {
            value: 'Bone Density ',
            title: 'Bone Density ',
          },
          {
            value: 'Cancer',
            title: 'Cancer',
          },
          {
            value: 'Cerebral Palsy',
            title: 'Cerebral Palsy',
          },
          {
            value: 'Cholesterol',
            title: 'Cholesterol',
          },
          {
            value: 'Chronic Obstructive Pulmonary Disease (COPD)',
            title: 'Chronic Obstructive Pulmonary Disease (COPD)',
          },
          {
            value: 'COPD',
            title: 'COPD',
          },
          {
            value: 'Depression',
            title: 'Depression',
          },
          {
            value: 'Diabetes',
            title: 'Diabetes',
          },
          {
            value: 'Dimentia',
            title: 'Dimentia',
          },
          {
            value: 'Disorder of the Pancreas',
            title: 'Disorder of the Pancreas',
          },
          {
            value: 'Diuretic',
            title: 'Diuretic',
          },
          {
            value: 'End Stage Renal Disease',
            title: 'End Stage Renal Disease',
          },
          {
            value: 'Enlarged Prostate',
            title: 'Enlarged Prostate',
          },
          {
            value: 'Erectile Dysfunction',
            title: 'Erectile Dysfunction',
          },
          {
            value: 'Glaucoma',
            title: 'Glaucoma',
          },
          {
            value: 'Heartburn / Acid Reflux / GERD',
            title: 'Heartburn / Acid Reflux / GERD',
          },
          {
            value: 'Hepatitis',
            title: 'Hepatitis',
          },
          {
            value: 'HIV',
            title: 'HIV',
          },
          {
            value: "Hodgkin's Disease",
            title: "Hodgkin's Disease",
          },
          {
            value: 'Hyper Thyroid (Overactive)',
            title: 'Hyper Thyroid (Overactive)',
          },
          {
            value: 'Hypo Thyroid (Underactive)',
            title: 'Hypo Thyroid (Underactive)',
          },
          {
            value: 'Inflammation',
            title: 'Inflammation',
          },
          {
            value: 'Kidney Disease',
            title: 'Kidney Disease',
          },
          {
            value: 'Leukemia',
            title: 'Leukemia',
          },
          {
            value: "Lou Gehrig's Disease",
            title: "Lou Gehrig's Disease",
          },
          {
            value: 'Macular Degeneration',
            title: 'Macular Degeneration',
          },
          {
            value: 'Menopause',
            title: 'Menopause',
          },
          {
            value: 'Multiple Sclerosis',
            title: 'Multiple Sclerosis',
          },
          {
            value: 'Muscular Dystrophy',
            title: 'Muscular Dystrophy',
          },
          {
            value: 'Narcolepsy',
            title: 'Narcolepsy',
          },
          {
            value: 'Nausea',
            title: 'Nausea',
          },
          {
            value: 'Osteoprosis',
            title: 'Osteoprosis',
          },
          {
            value: 'Overactive Bladder',
            title: 'Overactive Bladder',
          },
          {
            value: 'Pain',
            title: 'Pain',
          },
          {
            value: "Parkinson's",
            title: "Parkinson's",
          },
          {
            value: 'Prevent Blood Clots',
            title: 'Prevent Blood Clots',
          },
          {
            value: 'Prevent Osteoporosis',
            title: 'Prevent Osteoporosis',
          },
          {
            value: 'Prevents Transplant Rejection',
            title: 'Prevents Transplant Rejection',
          },
          {
            value: 'Restless leg syndrone',
            title: 'Restless leg syndrone',
          },
          {
            value: 'Rheumatoid Arthritis',
            title: 'Rheumatoid Arthritis',
          },
          {
            value: 'Seizures',
            title: 'Seizures',
          },
          {
            value: 'Sleeping Aid / Difficulty Sleeping',
            title: 'Sleeping Aid / Difficulty Sleeping',
          },
          {
            value: 'Type I Diabetes',
            title: 'Type I Diabetes',
          },
          {
            value: 'Type II Diabetes',
            title: 'Type II Diabetes',
          },
          {
            value: 'Ulcerative Colitis',
            title: 'Ulcerative Colitis',
          },
          {
            value: 'Ulcers',
            title: 'Ulcers',
          },
          {
            value: 'Vaginal Dryness ',
            title: 'Vaginal Dryness ',
          },
        ],
        appointment_reference: [
          {
            value: 'Life Insurance',
            title: 'Life Insurance',
          },
          {
            value: 'Medicare Supp',
            title: 'Medicare Supp',
          },
          {
            value: 'Financial',
            title: 'Financial',
          },
          {
            value: 'PC',
            title: 'PC',
          },
        ],
        currentInsuranceOf: [
          {
            value: 'self',
            title: 'Self',
          },
          {
            value: 'husband',
            title: 'Husband',
          },
          {
            value: 'wife',
            title: 'Wife',
          },
          {
            value: 'not applicable',
            title: 'Not Applicable',
          },
        ],
        adminUsers: [
          {
            value: 'christy',
            title: 'Christy',
          },
          {
            value: 'christina',
            title: 'Christina',
          },
          {
            value: 'tina',
            title: 'Tina',
          },
        ],
        currentInsurance: [
          {
            value: 'Medicare',
            title: 'Medicare',
          },
          {
            value: 'Employer / Group',
            title: 'Employer / Group',
          },
          {
            value: 'Individual FFM',
            title: 'Individual FFM',
          },
          {
            value: 'Individual Medicare Advantage',
            title: 'Individual Medicare Advantage',
          },
          {
            value: 'Individual Medical Supplement',
            title: 'Individual Medical Supplement',
          },
          {
            value: 'Cobra',
            title: 'Cobra',
          },
        ],
        planType: [
          {
            value: 'PPO',
            title: 'PPO',
          },
          {
            value: 'HMO',
            title: 'HMO',
          },
          {
            value: 'POS',
            title: 'POS',
          },
          {
            value: 'HSA',
            title: 'HSA',
          },
        ],
        carriers: [
          {
            value: 'Testing Carrier 1',
            title: 'Testing Carrier 1',
          },
          {
            value: 'Testing Carrier 2',
            title: 'Testing Carrier 2',
          },
        ],
        drug_company: [
          {
            value: 'A&Z Pharmaceutical, Inc.',
            title: 'A&Z Pharmaceutical, Inc.',
          },
          {
            value: 'AbbVie Inc.',
            title: 'AbbVie Inc.',
          },
          {
            value: 'Adapt Pharma, Inc.',
            title: 'Adapt Pharma, Inc.',
          },
          {
            value: 'Akorn, Inc.',
            title: 'Akorn, Inc.',
          },
          {
            value: 'Medicure Pharma, Inc.',
            title: 'Medicure Pharma, Inc.',
          },
        ],
        arp_member: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        locations: [
          {
            value: '1',
            title: 'Gilbertsville - 19525',
          },
          {
            value: '2',
            title: 'Bethlehem - 18018',
          },
        ],
        elizabethApproval: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        gender: [
          {
            value: 'male',
            title: 'Male',
          },
          {
            value: 'female',
            title: 'Female',
          },
        ],
        prefix: [
          {
            value: 'Mr.',
            title: 'Mr.',
          },
          {
            value: 'Mrs.',
            title: 'Mrs.',
          },
          {
            value: 'Ms.',
            title: 'Ms.',
          },
          {
            value: 'Dr.',
            title: 'Dr.',
          },
        ],
        suffix: [
          {
            value: 'N/A',
            title: 'N/A',
          },
          {
            value: 'Jr.',
            title: 'Jr.',
          },
          {
            value: 'Sr.',
            title: 'Sr.',
          },
          {
            value: 'II',
            title: 'II',
          },
          {
            value: 'III',
            title: 'III',
          },
          {
            value: 'IV',
            title: 'IV',
          },
        ],
        maritalStatus: [
          {
            value: 'Single',
            title: 'Single',
          },
          {
            value: 'Married',
            title: 'Married',
          },
          {
            value: 'Widowed',
            title: 'Widowed',
          },
        ],
        seminarAttended: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        smoker: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        spouseCurrentClient: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        veteran: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
        specialistSuffix: [
          {
            value: 'Dr.',
            title: 'Dr.',
          },
          {
            value: 'Ms.',
            title: 'Ms.',
          },
          {
            value: 'Mr.',
            title: 'Mr.',
          },
          {
            value: 'Mrs.',
            title: 'Mrs.',
          },
        ],
        physicianSpeciality: [
          {
            value: 'MD | Doctor of Medicine',
            title: 'MD | Doctor of Medicine',
          },
          {
            value: 'DO | Doctor of Osteopathic Medicine',
            title: 'DO | Doctor of Osteopathic Medicine',
          },
          {
            value: 'PA-C | Physician Assistant',
            title: 'PA-C | Physician Assistant',
          },
          {
            value: 'NP | Nurse Practitioner',
            title: 'NP | Nurse Practitioner',
          },
        ],
        specialistSpeciality: [
          {
            value: 'MD | Doctor of Medicine',
            title: 'MD | Doctor of Medicine',
          },
          {
            value: 'DO | Doctor of Osteopathic Medicine',
            title: 'DO | Doctor of Osteopathic Medicine',
          },
          {
            value: 'PA-C | Physician Assistant',
            title: 'PA-C | Physician Assistant',
          },
          {
            value: 'NP | Nurse Practitioner',
            title: 'NP | Nurse Practitioner',
          },
        ],
        additionalPrescriptionDrugHelp: [
          {
            value: 'Extra help',
            title: 'Extra help',
          },
          {
            value: 'PACE of PA',
            title: 'PACE of PA',
          },
          {
            value: 'PACENET of PA',
            title: 'PACENET of PA',
          },
          {
            value: 'VETERAN drug plan',
            title: 'VETERAN drug plan',
          },
        ],
        prescription_taken: (() => {
          const data = [];
          data.push({
            value: '',
            title: 'Not Applicable...',
          });
          for (let i = 1; i <= 20; i++) {
            data.push({
              value: i,
              title: i,
            });
          }
          return data;
        })(),
        drugsInfo: {
          drugs: [],
          dosage: {
            0: [
              {
                value: 'Abacavir TAB 300MG',
                title: 'Abacavir TAB 300MG',
              },
            ],
            1: [
              {
                value: 'Abelcet INJ 5MG/ML',
                title: 'Abelcet INJ 5MG/ML',
              },
            ],
            2: [
              {
                value: 'Baciim INJ 50000UNT',
                title: 'Baciim INJ 50000UNT',
              },
            ],
            3: [
              {
                value: 'Bacitracin INJ 50000UNT',
                title: 'Bacitracin INJ 50000UNT',
              },
            ],
            4: [
              {
                value: 'Cabergoline TAB 0.5MG',
                title: 'Cabergoline TAB 0.5MG',
              },
            ],
            5: [
              {
                value: 'Cafergot TAB 1-100MG',
                title: 'Cafergot TAB 1-100MG',
              },
            ],
            6: [
              {
                value: 'Dacarbazine INJ 200MG',
                title: 'Dacarbazine INJ 200MG',
              },
            ],
            7: [
              {
                value: 'Dacogen INJ 50MG',
                title: 'Dacogen INJ 50MG',
              },
            ],
            8: [
              {
                value: 'E.E.S. 400 TAB 400MG',
                title: 'E.E.S. 400 TAB 400MG',
              },
            ],
            9: [
              {
                value: 'Ec-Naprosyn TAB 375MG',
                title: 'Ec-Naprosyn TAB 375MG',
              },
              {
                value: 'Ec-Naprosyn TAB 375MG',
                title: 'Ec-Naprosyn TAB 375MG',
              },
            ],
            10: [
              {
                value: 'Falmina TAB',
                title: 'Falmina TAB',
              },
            ],
            11: [
              {
                value: 'Famciclovir TAB 125MG',
                title: 'Famciclovir TAB 125MG',
              },
              {
                value: 'Famciclovir TAB 250MG',
                title: 'Famciclovir TAB 250MG',
              },
              {
                value: 'Famciclovir TAB 500MG',
                title: 'Famciclovir TAB 500MG',
              },
            ],
            12: [
              {
                value: 'Ganciclovir (Injection) INJ 500MG',
                title: 'Ganciclovir (Injection) INJ 500MG',
              },
            ],

            13: [
              {
                value: 'Gentamicin Sulfate Topical CRE 0.1%',
                title: 'Gentamicin Sulfate Topical CRE 0.1%',
              },
              {
                value: 'Gentamicin Sulfate Topical OIN 0.1%',
                title: 'Gentamicin Sulfate Topical OIN 0.1%',
              },
            ],

            14: [
              {
                value: 'Halobetasol Propionate CRE 0.05%',
                title: 'Halobetasol Propionate CRE 0.05 % ',
              },
              {
                value: 'Halobetasol Propionate OIN 0.05%',
                title: 'Halobetasol Propionate OIN 0.05%',
              },
            ],

            15: [
              {
                value: 'Haloperidol Tablets TAB 0.5MG',
                title: 'Haloperidol Tablets TAB 0.5MG',
              },
              {
                value: 'Haloperidol Tablets TAB 1MG',
                title: 'Haloperidol Tablets TAB 1MG',
              },
              {
                value: 'Haloperidol Tablets TAB 2MG',
                title: 'Haloperidol Tablets TAB 2MG',
              },
              {
                value: 'Haloperidol Tablets TAB 5MG',
                title: 'Haloperidol Tablets TAB 5MG',
              },
              {
                value: 'Haloperidol Tablets TAB 10MG',
                title: 'Haloperidol Tablets TAB 10MG',
              },
              {
                value: 'Haloperidol Tablets TAB 20MG',
                title: 'Haloperidol Tablets TAB 20MG',
              },
            ],

            16: [
              {
                value: 'Ibandronate Sodium INJ 3MG/3ML',
                title: 'Ibandronate Sodium INJ 3MG / 3ML',
              },
              {
                value: 'Ibandronate Sodium TAB 150MG',
                title: 'Ibandronate Sodium TAB 150MG',
              },
            ],

            17: [
              {
                value: 'Idarubicin Hcl INJ 10MG/10M',
                title: 'Idarubicin Hcl INJ 10MG/10M',
              },
            ],

            18: [
              {
                value: 'Jinteli TAB 1MG-5MCG',
                title: 'Jinteli TAB 1MG-5MCG',
              },
            ],

            19: [
              {
                value: 'Juleber TAB',
                title: 'Juleber TAB',
              },
            ],

            20: [
              {
                value: 'Kaitlib FE CHW',
                title: 'Kaitlib FE CHW',
              },
            ],

            21: [
              {
                value: 'Kariva TAB 28 DAY',
                title: 'Kariva TAB 28 DAY',
              },
            ],

            22: [
              {
                value: 'Labetalol Hcl INJ 5MG/ML',
                title: 'Labetalol Hcl INJ 5MG/ML',
              },
              {
                value: 'Labetalol Hcl TAB 100MG',
                title: 'Labetalol Hcl TAB 100MG',
              },
              {
                value: 'Labetalol Hcl TAB 200MG',
                title: 'Labetalol Hcl TAB 200MG',
              },
              {
                value: 'Labetalol Hcl TAB 300MG',
                title: 'Labetalol Hcl TAB 300MG',
              },
            ],

            23: [
              {
                value: 'Letrozole TAB 2.5MG',
                title: 'Letrozole TAB 2.5MG',
              },
            ],

            24: [
              {
                value: 'Macrobid CAP 100MG',
                title: 'Macrobid CAP 100MG',
              },
            ],

            25: [
              {
                value: 'Macrodantin CAP 25MG',
                title: 'Macrodantin CAP 25MG',
              },
              {
                value: 'Macrodantin CAP 50MG',
                title: 'Macrodantin CAP 50MG',
              },
              {
                value: 'Macrodantin CAP 100MG',
                title: 'Macrodantin CAP 100MG',
              },
            ],

            26: [
              {
                value: 'Nabumetone TAB 500MG',
                title: 'Nabumetone TAB 500MG',
              },
              {
                value: 'Nabumetone TAB 750MG',
                title: 'Nabumetone TAB 750MG',
              },
            ],

            27: [
              {
                value: 'Naloxone Hcl INJ 0.4MG/ML',
                title: 'Naloxone Hcl INJ 0.4MG/ML',
              },
              {
                value: 'Naloxone Hcl INJ 1MG/ML',
                title: 'Naloxone Hcl INJ 1MG/ML',
              },
            ],

            28: [
              {
                value: 'Octreotide Acetate INJ 50MCG/ML',
                title: 'Octreotide Acetate INJ 50MCG/ML',
              },
              {
                value: 'Octreotide Acetate INJ 100MCG/ML',
                title: 'Octreotide Acetate INJ 100MCG/ML',
              },
              {
                value: 'Octreotide Acetate INJ 200MCG/ML',
                title: 'Octreotide Acetate INJ 200MCG/ML',
              },
              {
                value: 'Octreotide Acetate INJ 500MCG/ML',
                title: 'Octreotide Acetate INJ 500MCG/ML',
              },
              {
                value: 'Octreotide Acetate INJ 1000MCG/M',
                title: 'Octreotide Acetate INJ 1000MCG/M',
              },
            ],

            29: [
              {
                value: 'Omega-3-Acid Ethyl Esters CAP 1GM',
                title: 'Omega-3-Acid Ethyl Esters CAP 1GM',
              },
            ],

            30: [
              {
                value: 'Pacerone TAB 100MG',
                title: 'Pacerone TAB 100MG',
              },
              {
                value: 'Pacerone TAB 200MG',
                title: 'Pacerone TAB 200MG',
              },
              {
                value: 'Pacerone TAB 400MG',
                title: 'Pacerone TAB 400MG',
              },
            ],

            31: [
              {
                value: 'Paromomycin Sulfate CAP 250MG',
                title: 'Paromomycin Sulfate CAP 250MG',
              },
            ],

            32: [
              {
                value: 'Questran POW 4GM (Packet)',
                title: 'Questran POW 4GM (Packet)',
              },
            ],

            33: [
              {
                value: 'Quinidine Gluconate INJ 80MG/ML',
                title: 'Quinidine Gluconate INJ 80MG/ML',
              },
              {
                value: 'Quinidine Gluconate TAB 324MG CR',
                title: 'Quinidine Gluconate TAB 324MG CR',
              },
            ],

            34: [
              {
                value: 'Rabeprazole Sodium TAB 20MG',
                title: 'Rabeprazole Sodium TAB 20MG',
              },
            ],

            35: [
              {
                value: 'Rapaflo CAP 4MG',
                title: 'Rapaflo CAP 4MG',
              },
              {
                value: 'Rapaflo CAP 8MG',
                title: 'Rapaflo CAP 8MG',
              },
            ],

            36: [
              {
                value: 'Sabril POW 500MG',
                title: 'Sabril POW 500MG',
              },
              {
                value: 'Sabril TAB 500MG',
                title: 'Sabril TAB 500MG',
              },
            ],

            37: [
              {
                value: 'Salagen TAB 5MG',
                title: 'Salagen TAB 5MG',
              },
              {
                value: 'Salagen TAB 7.5MG',
                title: 'Salagen TAB 7.5MG',
              },
            ],

            38: [
              {
                value: 'Tacrolimus CAP 0.5MG',
                title: 'Tacrolimus CAP 0.5MG',
              },
              {
                value: 'Tacrolimus CAP 1MG',
                title: 'Tacrolimus CAP 1MG',
              },
              {
                value: 'Tacrolimus CAP 5MG',
                title: 'Tacrolimus CAP 5MG',
              },
            ],

            39: [
              {
                value: 'Tacrolimus (Topical) OIN 0.03%',
                title: 'Tacrolimus (Topical) OIN 0.03%',
              },
              {
                value: 'Tacrolimus (Topical) OIN 0.1%',
                title: 'Tacrolimus (Topical) OIN 0.1%',
              },
            ],

            40: [
              {
                value: 'Ultracet TAB 37.5-325',
                title: 'Ultracet TAB 37.5-325',
              },
            ],

            41: [
              {
                value: 'Ultram TAB 50MG',
                title: 'Ultram TAB 50MG',
              },
            ],

            42: [
              {
                value: 'Valium TAB 2MG',
                title: 'Valium TAB 2MG',
              },
              {
                value: 'Valium TAB 5MG',
                title: 'Valium TAB 5MG',
              },
              {
                value: 'Valium TAB 10MG',
                title: 'Valium TAB 10MG',
              },
            ],

            43: [
              {
                value: 'Varizig INJ 125UNIT (Solution)',
                title: 'Varizig INJ 125UNIT (Solution)',
              },
            ],

            44: [
              {
                value: 'Welchol PAK 3.75GM',
                title: 'Welchol PAK 3.75GM',
              },
              {
                value: 'Welchol TAB 625MG',
                title: 'Welchol TAB 625MG',
              },
            ],

            45: [
              {
                value: 'WYMZYA FE CHW 0.4MG-35',
                title: 'WYMZYA FE CHW 0.4MG-35',
              },
            ],

            46: [
              {
                value: 'Xifaxan TAB 200MG',
                title: 'Xifaxan TAB 200MG',
              },
              {
                value: 'Xifaxan TAB 550MG',
                title: 'Xifaxan TAB 550MG',
              },
            ],

            47: [
              {
                value: 'Xopenex HFA AER',
                title: 'Xopenex HFA AER',
              },
            ],

            48: [
              {
                value: 'Yasmin 28 TAB 3-0.03MG',
                title: 'Yasmin 28 TAB 3-0.03MG',
              },
            ],
            49: [
              {
                value: 'Yervoy INJ 50MG',
                title: 'Yervoy INJ 50MG',
              },
            ],
            50: [
              {
                value: 'Zafirlukast TAB 10MG',
                title: 'Zafirlukast TAB 10MG',
              },
              {
                value: 'Zafirlukast TAB 20MG',
                title: 'Zafirlukast TAB 20MG',
              },
            ],
            51: [
              {
                value: 'Zarontin CAP 250MG',
                title: 'Zarontin CAP 250MG',
              },
              {
                value: 'Zarontin SOL 250/5ML',
                title: 'Zarontin SOL 250/5ML',
              },
            ],
          },
          frequency: [
            {
              title: '-',
              value: '-',
            },
            {
              title: 'Every 1 Month',
              value: 'Every 1 Month',
            },
            {
              title: 'Every 2 Month',
              value: 'Every 2 Month',
            },
            {
              title: 'Every 3 Month',
              value: 'Every 3 Month',
            },
            {
              title: 'Every 12 Month',
              value: 'Every 12 Month',
            },
          ],
          dosageSeleted: [],
          physicians: [
            {
              value: 'Dr. Smith Andrison',
              title: 'Dr. Smith Andrison',
            },
            {
              value: 'Ms. Aliza Peterson',
              title: 'Ms. Aliza Peterson',
            },
            {
              value: 'Mr. Shawn Cruise',
              title: 'Mr. Shawn Cruise',
            },
            {
              value: 'Mr. Mike John',
              title: 'Mr. Mike John',
            },
            {
              value: 'Dr. Shane Mark',
              title: 'Dr. Shane Mark',
            },
          ],
          drugLongevity: [
            {
              value: '-',
              title: '-',
            },
            {
              value: '1 - 2 years',
              title: '1 - 2 years',
            },
            {
              value: '2 - 3 years',
              title: '2 - 3 years',
            },
            {
              value: 'More then 3 years',
              title: 'More then 3 years',
            },
          ],
        },
        bicCode: [
          {
            value: 'A - Wage Earner',
            title: 'A - Wage Earner',
          },
          {
            value: 'B - Wife drawing on a husband’s record ',
            title: 'B - Wife drawing on a husband’s record ',
          },
          {
            value: 'B1 - Husband drawing on a wife’s record ',
            title: 'B1 - Husband drawing on a wife’s record ',
          },
          {
            value: 'B2 - Wife drawing benefits under age 62 ',
            title: 'B2 - Wife drawing benefits under age 62 ',
          },
          {
            value: 'B3 - Second wife',
            title: 'B3 - Second wife',
          },
          {
            value: 'B4 - Second husband ',
            title: 'B4 - Second husband ',
          },
          {
            value: 'B5 - Second wife under age 62 ',
            title: 'B5 - Second wife under age 62 ',
          },
          {
            value: 'B6 - Divorced wife',
            title: 'B6 - Divorced wife',
          },
          {
            value: 'B7 - Third wife under age 62',
            title: 'B7 - Third wife under age 62',
          },
          {
            value: 'B8 - Third wife',
            title: 'B8 - Third wife',
          },
          {
            value: 'B9 - Second divorced wife',
            title: 'B9 - Second divorced wife',
          },
          {
            value: 'BA - Fourth wife',
            title: 'BA - Fourth wife',
          },
          {
            value: 'BD - Fifth wife',
            title: 'BD - Fifth wife',
          },
          {
            value: 'BG - Third husband',
            title: 'BG - Third husband',
          },
          {
            value: 'BH - Fourth husband',
            title: 'BH - Fourth husband',
          },
          {
            value: 'BJ - Fifth husband',
            title: 'BJ - Fifth husband',
          },
          {
            value: 'BK - Fourth wife under age 62',
            title: 'BK - Fourth wife under age 62',
          },
          {
            value: 'BL - Fifth wife under age 62',
            title: 'BL - Fifth wife under age 62',
          },
          {
            value: 'BN - Third divorced wife',
            title: 'BN - Third divorced wife',
          },
          {
            value: 'BP - Fourth divorced wife',
            title: 'BP - Fourth divorced wife',
          },
          {
            value: 'BQ - Fifth divorced husband',
            title: 'BQ - Fifth divorced husband',
          },
          {
            value: 'BR - Divorced husband',
            title: 'BR - Divorced husband',
          },
          {
            value: 'BT - Second divorced husband',
            title: 'BT - Second divorced husband',
          },
          {
            value: 'BW - Husband drawing father’s benefits',
            title: 'BW - Husband drawing father’s benefits',
          },
          {
            value: 'BY - Second husband drawing father’s benefits',
            title: 'BY - Second husband drawing father’s benefits',
          },
          {
            value: 'C1 - Youngest disabled child',
            title: 'C1 - Youngest disabled child',
          },
          {
            value: 'C2 – C9 Disabled child, number denotes which child',
            title: 'C2 – C9 Disabled child, number denotes which child',
          },
          {
            value: 'CA - Tenth disabled child, CB-eleventh, etc.',
            title: 'CA -Tenth disabled child, CB-eleventh, etc.',
          },
          {
            value: 'D  - Wife drawing on a deceased husband’s record',
            title: 'D  - Wife drawing on a deceased husband’s record',
          },
          {
            value: 'D1 - Husband drawing on a deceased wife’s record',
            title: 'D1 - Husband drawing on a deceased wife’s record',
          },
          {
            value: 'D2 - Second widow',
            title: 'D2 - Second widow',
          },
          {
            value: 'D3 - Second widower',
            title: 'D3 - Second widower',
          },
          {
            value: 'D4 - Widow remarried',
            title: 'D4 - Widow remarried',
          },
          {
            value: 'D5 - Widower remarried',
            title: 'D5 - Widower remarried',
          },
          {
            value: 'D6 - Surviving divorced wife',
            title: 'D6 - Surviving divorced wife',
          },
          {
            value: 'D7 - Second surviving divorced wife',
            title: 'D7 - Second surviving divorced wife',
          },
          {
            value: 'D8 - Third widow',
            title: 'D8 - Third widow',
          },
          {
            value: 'D9 - Second wife remarried',
            title: 'D9 - Second wife remarried',
          },
          {
            value: 'DA - Third remarried widow',
            title: 'DA - Third remarried widow',
          },
          {
            value: 'DC - Surviving divorced husband',
            title: 'DC - Surviving divorced husband',
          },
          {
            value: 'DD - Fourth widow',
            title: 'DD - Fourth widow',
          },
          {
            value: 'DG - Fifth Widow',
            title: 'DG -Fifth Widow',
          },
          {
            value: 'DH - Third widower',
            title: 'DH - Third widower',
          },
          {
            value: 'DJ - Fourth widower',
            title: 'DJ - Fourth widower',
          },
          {
            value: 'DK - Fifth widower',
            title: 'DK - Fifth widower',
          },
          {
            value: 'DL - Fourth remarried widow',
            title: 'DL - Fourth remarried widow',
          },
          {
            value: 'DM - Second surviving divorced husband',
            title: 'DM - Second surviving divorced husband',
          },
          {
            value: 'DN - Fifth remarried widow',
            title: 'DN - Fifth remarried widow',
          },
          {
            value: 'DP - Second remarried widower',
            title: 'DP - Second remarried widower',
          },
          {
            value: 'DQ - Third remarried widower',
            title: 'DQ - Third remarried widower',
          },
          {
            value: 'DR - Fourth remarried widower',
            title: 'DR - Fourth remarried widower',
          },
          {
            value: 'DS - Third surviving divorced husband',
            title: 'DS - Third surviving divorced husband',
          },
          {
            value: 'DT - Fifth remarried widower',
            title: 'DT - Fifth remarried widower',
          },
          {
            value: 'DV - Third surviving divorced husband',
            title: 'DV - Third surviving divorced husband',
          },
          {
            value: 'DW - Fourth surviving divorced wife',
            title: 'DW - Fourth surviving divorced wife',
          },
          {
            value: 'DX - Fourth surviving divorced husband',
            title: 'DX - Fourth surviving divorced husband',
          },
          {
            value: 'DY - Fifth surviving divorced wife',
            title: 'DY - Fifth surviving divorced wife',
          },
          {
            value: 'DZ - Fifth surviving divorced husband',
            title: 'DZ - Fifth surviving divorced husband',
          },
          {
            value: 'E  - Mother (widow)',
            title: 'E  - Mother (widow)',
          },
          {
            value: 'E1 - Surviving widowed mother',
            title: 'E1 - Surviving widowed mother',
          },
          {
            value: 'E2 - Second widowed mother',
            title: 'E2 - Second widowed mother',
          },
          {
            value: 'E3 - Second surviving divorced mother',
            title: 'E3 - Second surviving divorced mother',
          },
          {
            value: 'E4 - Widowed father',
            title: 'E4 - Widowed father',
          },
          {
            value: 'E5 - Surviving divorced father',
            title: 'E5 - Surviving divorced father',
          },
          {
            value: 'E6 - Second widowed father',
            title: 'E6 - Second widowed father',
          },
          {
            value: 'E7 - Third widowed mother',
            title: 'E7 - Third widowed mother',
          },
          {
            value: 'E8 - Fourth widowed mother',
            title: 'E8 - Fourth widowed mother',
          },
          {
            value: 'E9 -  Second surviving divorced father',
            title: 'E9 -  Second surviving divorced father',
          },
          {
            value: 'EA - Fifth widowed mother',
            title: 'EA - Fifth widowed mother',
          },
          {
            value: 'EB - Third surviving divorced mother',
            title: 'EB - Third surviving divorced mother',
          },
          {
            value: 'EC - Fourth surviving divorced mother',
            title: 'EC - Fourth surviving divorced mother',
          },
          {
            value: 'ED - Fifth surviving divorced mother',
            title: 'ED - Fifth surviving divorced mother',
          },
          {
            value: 'EF - Third widowed father',
            title: 'EF - Third widowed father',
          },
          {
            value: 'EG - Fourth widowed father',
            title: 'EG - Fourth widowed father',
          },
          {
            value: 'EH - Fifth widowed father',
            title: 'EH - Fifth widowed father',
          },
          {
            value: 'EJ - Third surviving divorced father',
            title: 'EJ - Third surviving divorced father',
          },
          {
            value: 'EK - Fourth surviving divorced father',
            title: 'EK - Fourth surviving divorced father',
          },
          {
            value: 'EM - Fifth surviving divorced father',
            title: 'EM - Fifth surviving divorced father',
          },
          {
            value: 'F1 - Father',
            title: 'F1 - Father',
          },
          {
            value: 'F2 - Mother',
            title: 'F2 - Mother',
          },
          {
            value: 'F3 - Stepfather',
            title: 'F3 - Stepfather',
          },
          {
            value: 'F4 - Stepmother',
            title: 'F4 - Stepmother',
          },
          {
            value: 'F5 - Adopting Father',
            title: 'F5 - Adopting Father',
          },
          {
            value: 'F6 - Adopting Mother',
            title: 'F6 - Adopting Mother',
          },
          {
            value: 'F7 Second alleged father',
            title: 'F7 Second alleged father',
          },
          {
            value: 'F8 Second alleged father',
            title: 'F8 Second alleged father',
          },
          {
            value:
              'J1 – J4 - At age 72, the beneficiary under T suffix becomes D9 Second wife remarried eligible for special benefits, the suffix becomes J1–J4.',
            title:
              'J1 – J4 - At age 72, the beneficiary under T suffix becomes D9 Second wife remarried eligible for special benefits, the suffix becomes J1–J4.',
          },
          {
            value: 'K1-KM - Wife of J1-J4',
            title: 'K1-KM - Wife of J1-J4',
          },
          {
            value: 'M - SMI entitlement only',
            title: 'M - SMI entitlement only',
          },
          {
            value: 'M1 - SMI entitlement only – refused Part A',
            title: 'M1 - SMI entitlement only – refused Part A',
          },
          {
            value: 'T - Drawing no Social Security Benefits',
            title: 'T - Drawing no Social Security Benefits',
          },
          {
            value: 'W - Disabled Widow',
            title: 'W - Disabled Widow',
          },
          {
            value: 'W1 - Disabled Widower',
            title: 'W1 - Disabled Widower',
          },
          {
            value: 'W2 - Second disabled widow',
            title: 'W2 - Second disabled widow',
          },
          {
            value: 'W3 - Second disabled widower',
            title: 'W3 - Second disabled widower',
          },
          {
            value: 'W4 - Third disabled widow',
            title: 'W4 - Third disabled widow',
          },
          {
            value: 'W5 - Third disabled widower',
            title: 'W5 - Third disabled widower',
          },
          {
            value: 'W6 - Disabled surviving divorced wife',
            title: 'W6 - Disabled surviving divorced wife',
          },
          {
            value: 'WR - Disabled surviving divorced husband',
            title: 'WR - Disabled surviving divorced husband',
          },
          {
            value: 'WT - Second disabled surviving divorced husband',
            title: 'WT - Second disabled surviving divorced husband',
          },
        ],
        emailProviders: [
          {
            value: 'gmail.com',
            title: 'gmail.com',
          },
          {
            value: 'hotmail.com',
            title: 'hotmail.com',
          },
          {
            value: 'aol.com',
            title: 'aol.com',
          },
          {
            value: 'yahoo.com',
            title: 'yahoo.com',
          },
          {
            value: 'snydertechnologies.com',
            title: 'snydertechnologies.com',
          },
          {
            value: 'outlook.com',
            title: 'outlook.com',
          },
          {
            value: 'live.com',
            title: 'live.com',
          },
        ],
        selectedPhysicians: [
          {
            value: 'Primary Physician',
            title: 'Primary Physician',
          },
          {
            value: 'Specialist',
            title: 'Specialist',
          },
        ],
        status_individuals: [
          {
            value: 'Active',
            title: 'Active',
          },
          {
            value: 'Archived',
            title: 'Archived',
          },
        ],
        address_types: [
          {
            value: 'primary',
            title: 'Primary Address',
          },
          {
            value: 'office',
            title: 'Office Address',
          },
        ],
        perPage: [
          {
            value: '10',
            title: '10',
          },
          {
            value: '25',
            title: '25',
          },
          {
            value: '50',
            title: '50',
          },
        ],
        dataThemes: [
          {
            value: 'msfp',
            title: 'Main Street',
          },
          {
            value: 'roaring20s',
            title: 'Roaring 20s',
          },
        ],
      },
    };
    $rootScope.openDatepicker = () => {
      $rootScope.constants.datePickerShown = true;
    };

    if ($cookies.get('_token') !== '' && typeof $cookies.get('_token') !== 'undefined') {
      $rootScope.constants.DB = JSON.parse($cookies.get('_token'));
      $rootScope.access_token = JSON.parse($cookies.get('_token')).access_token;
    }

    $rootScope.$on('$stateChangeStart', (_even, next, current) => {
      if (AuthService.isLoggedIn() === true) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}activity-log/view-individual-activity-agent`,
          'GET',
          {},
          (response) => {
            $rootScope.individual_recent_activities = response.data.activity_logs;
          },
        );
      }

      if (AuthService.isLoggedIn() === false && next.name !== 'login') {
        if (typeof next !== 'undefined') {
          let url = next.url;
          for (const i in current) {
            url = url.replace(`:${i}`, current[i]);
          }
          $rootScope.redirectBackUrl = url;

          if (next.params.authenticated !== true) {
            window.location = '#/login';
          }
        }
      }
    });
  },
];

export default runtimeConfig;
