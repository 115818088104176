import accountRoutes from './account.routes';
import adminsRoutes from './admins.routes';
import allocationsRoutes from './allocations.routes';
import authRoutes from './auth.routes';
import budgetsRoutes from './budgets.routes';
import carrierRoutes from './carrier.routes';
import commissionsRoutes from './commissions.routes';
import individualsRoutes from './individuals.routes';
import leadsRoutes from './leads.routes';
import medicareRoutes from './medicare.routes';
import otherRoutes from './other.routes';
import supportRoutes from './support.routes';
import testRoutes from './test.routes';

const routesConfig = [
  '$stateProvider',
  '$urlRouterProvider',
  ($stateProvider, $urlRouterProvider) => {
    accountRoutes($stateProvider);
    adminsRoutes($stateProvider);
    allocationsRoutes($stateProvider);
    authRoutes($stateProvider);
    budgetsRoutes($stateProvider);
    carrierRoutes($stateProvider);
    commissionsRoutes($stateProvider);
    individualsRoutes($stateProvider);
    leadsRoutes($stateProvider);
    medicareRoutes($stateProvider);
    otherRoutes($stateProvider);
    supportRoutes($stateProvider);
    testRoutes($stateProvider);
    $urlRouterProvider.otherwise('/login');
  },
];

export default routesConfig;
