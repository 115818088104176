class MomentDateService {
  constructor(moment) {
    this.moment = moment;
  }

  convertToDate(d) {
    if (d === '' || typeof d === 'undefined' || d === null || d === '0000-00-00' || d === '0000-00-00 00:00:00') {
      return '';
    }
    return this.moment(d).toDate();
  }
}

MomentDateService.$inject = ['moment']; // Inject moment library

export default MomentDateService;
