export default [
  '$rootScope',
  '$scope',
  'AuthService',
  'MomentService',
  'HelpersService',
  function SnpValuesController($rootScope, $scope, AuthService, MomentService, HelpersService) {
    $scope.model = {
      formData: {
        startDate: MomentService.addMonths(MomentService.convertTimeZone(new Date()), -6),
        endDate: MomentService.convertTimeZone(new Date()),
        investment_amount: 0,
        monthly_cap: 0,

        snpData: {},
        mainGraph: [],
      },
    };
    $scope.submitSearchSNP = () => {
      const startDate = HelpersService.dateFormatYYYYMMDD($scope.model.formData.startDate);
      const endDate = HelpersService.dateFormatYYYYMMDD($scope.model.formData.endDate);

      const monthlyCap = $scope.model.formData.monthly_cap;

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}snp-values/get`,
        'GET',
        {
          startDate: startDate,
          endDate: endDate,
          investment_maount: $scope.model.formData.investment_amount,
          monthlyCap: monthlyCap,
        },
        (response) => {
          $scope.model.formData.snpData = response.data;
          /*SET GRAPH DATA*/
          $scope.setGraphData();
        },
      );
    };

    $scope.setGraphData = () => {
      const labelsData = [];
      const graphData = [];
      const interestData = {};
      const accumulationData = [];
      const snpData = {};

      const interestEarnedMonthly = {};
      const caped_percent = {};

      for (const i in $scope.model.formData.snpData.snp_data) {
        if (i === 0) {
          continue;
        }
        const sd = $scope.model.formData.snpData.snp_data[i];
        labelsData.push(sd.month);
        for (const d in $scope.model.formData.snpData.dates.date) {
          const date = $scope.model.formData.snpData.dates.date[d];
          if (typeof snpData[date] === 'undefined') {
            snpData[date] = [];
            interestData[date] = [];
          }
          snpData[date].push(Number(sd.snp_values[date]));
          if (typeof sd.interest_earned[date] !== 'undefined') {
            interestData[date].push(Number(sd.interest_earned[date]));
          } else {
            interestData[date].push(0);
          }

          if (typeof accumulationData[date] === 'undefined') {
            accumulationData[date] = [];
          }

          if (typeof sd.amountEarnedUnFormated[date] !== 'undefined') {
            accumulationData[date].push(Number(sd.amountEarnedUnFormated[date]));
          } else {
            accumulationData[date].push(0);
          }

          if (typeof interestEarnedMonthly[date] === 'undefined') {
            interestEarnedMonthly[date] = [];
          }

          if (typeof sd.caped_amount[date] !== 'undefined') {
            interestEarnedMonthly[date].push(Number(sd.caped_amount[date].amount));
          } else {
            interestEarnedMonthly[date].push(0);
          }

          if (typeof caped_percent[date] === 'undefined') {
            caped_percent[date] = [];
          }

          if (typeof sd.caped_percent[date] !== 'undefined') {
            caped_percent[date].push(Number(sd.caped_percent[date]));
          } else {
            caped_percent[date].push(0);
          }
        }
      }

      const interestGraphData = [];
      const snpGraphData = [];
      const accumulationGraphData = [];
      const interestEarnedMonthlyGraph = [];
      const interestCapedPercentMonthlyGraph = [];

      for (const d in $scope.model.formData.snpData.dates.date) {
        graphData.push(snpData[$scope.model.formData.snpData.dates.date[d]]);
        snpGraphData.push(snpData[$scope.model.formData.snpData.dates.date[d]]);
      }

      for (const d in $scope.model.formData.snpData.dates.date) {
        graphData.push(interestData[$scope.model.formData.snpData.dates.date[d]]);
        interestGraphData.push(interestData[$scope.model.formData.snpData.dates.date[d]]);
        accumulationGraphData.push(accumulationData[$scope.model.formData.snpData.dates.date[d]]);
        interestEarnedMonthlyGraph.push(interestEarnedMonthly[$scope.model.formData.snpData.dates.date[d]]);
        interestCapedPercentMonthlyGraph.push(caped_percent[$scope.model.formData.snpData.dates.date[d]]);
      }

      $scope.colors = ['#33FF00', '#FF0000', '#8E24AA', '#29B6F6'];
      Chart.defaults.global.legend.display = true;
      Chart.defaults.global.legend.position = 'top';

      $scope.labels = labelsData;
      $scope.series = [
        'S&P-1ST',
        'S&P-8TH',
        'S&P-15TH',
        'S&P-22ND',
        'INTEREST-1ST',
        'INTEREST-8TH',
        'INTEREST-15TH',
        'INTEREST-22ND',
      ];
      $scope.series1 = ['S&P-1ST', 'S&P-8TH', 'S&P-15TH', 'S&P-22ND'];
      $scope.series2 = ['INTEREST-1ST', 'INTEREST-8TH', 'INTEREST-15TH', 'INTEREST-22ND'];
      $scope.series3 = ['1ST', '8TH', '15TH', '22ND'];

      $scope.data = graphData;
      $scope.data1 = snpGraphData;
      $scope.data2 = interestGraphData;
      $scope.data3 = accumulationGraphData;

      //$scope.data5 = accumulationGraphData;

      $scope.series5 = ['1ST', '8TH', '15TH', '22ND'];
      $scope.data5 = interestCapedPercentMonthlyGraph;
      $scope.series6 = ['1ST', '8TH', '15TH', '22ND'];
      $scope.data6 = interestEarnedMonthlyGraph;

      $scope.datasetOverride = [{ yAxisID: 'y-axis-1' }];
      $scope.options = {
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, _data) => tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          },
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return value;
                },
              },
            },
            {
              id: 'y-axis-2',
              type: 'linear',
              display: false,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return value;
                },
              },
            },
          ],
        },
      };
      $scope.options3 = {
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, _data) => tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          },
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                  }
                  return `$${value}`;
                },
              },
            },
            {
              id: 'y-axis-2',
              type: 'linear',
              display: false,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return value;
                },
              },
            },
          ],
        },
      };
    };

    $scope.enlarge = () => {};

    /*Initialize*/
    $scope.submitSearchSNP();
  },
];
