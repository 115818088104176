import constants from '../utils/constants';

export default [
  '$rootScope',
  '$scope',
  'AuthService',
  'ConfirmBoxService',
  'HelpersService',
  function CommissionsController($rootScope, $scope, AuthService, ConfirmBoxService, HelpersService) {
    $scope.commissionsTemplate = `${$rootScope.templatesDir}commissions/partials/commissions.html?v=${constants.randomNumber}`;
    $scope.policiesTemplate = `${$rootScope.templatesDir}commissions/partials/policies.html?v=${constants.randomNumber}`;

    const date = new Date();
    const startYear = 2017;
    const currentMonth = `${date.getMonth()}`.length > 1 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;
    const years = [];

    let prevMonth = Number(currentMonth) === 1 ? 12 : Number(currentMonth) - 1;
    prevMonth = `${prevMonth}`.length > 1 ? `${prevMonth}` : `0${prevMonth}`;

    for (let i = startYear; i <= date.getFullYear(); i++) {
      years.push(`${i}`);
    }
    const months = [];
    for (let i = 1; i <= 12; i++) {
      if (i <= 9) {
        months.push(`0${i}`);
      } else {
        months.push(`${i}`);
      }
    }

    $scope.model = {
      activeTab: 'policies_not_exists',
      contentLoaded: {},
      listing: {
        activeTab: 'commissions',
        data: {
          carriers: [],
          totalCommission: 0,
          commissionByCompany: [],
          commissionByProduct: [],
          commissionByLocation: [],
          filters: {
            agency_location_records: {
              type: 'policies_missing',
            },
            above_7_years: {
              duration: '1',
            },
          },
          policiesData: {
            policies_not_exists: [],
            policies_not_exists_count: 0,
            premium_not_updated: [],
          },
        },
        filter: {
          carriersModel: {},
          yearsData: years,
          monthsData: months,
          from: {
            month: `${prevMonth}`,
            year: `${date.getFullYear()}`,
          },
          to: {
            month: `${currentMonth}`,
            year: `${date.getFullYear()}`,
          },
        },
      },
      importCommissions: {
        fileValidated: false,
        records: {},
        date: new Date(),
        location: '1',
        fileName: '',
      },
      file: {},
      fileReaderCallback: (data, name) => {
        $scope.model.importCommissions.fileName = name;
        $scope.model.importCommissions.records = { data: data };
        $scope.model.importCommissions.fileValidated = true;
      },
    };
    function hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: Number.parseInt(result[1], 16),
            g: Number.parseInt(result[2], 16),
            b: Number.parseInt(result[3], 16),
          }
        : null;
    }
    $scope.options = {
      legend: { display: true },
      responsive: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            //get the concerned dataset
            const dataset = data.datasets[tooltipItem.datasetIndex];
            //calculate the total of this data set
            const total = dataset.data.reduce(
              (previousValue, currentValue, _currentIndex, _array) => previousValue + currentValue,
            );
            //get the current items value
            const currentValue = dataset.data[tooltipItem.index];
            //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            const precentage = Math.floor((currentValue / total) * 100 + 0.5);

            return `${data.labels[tooltipItem.index]} (${precentage}%)`;
          },
        },
      },
      pieceLabel: {
        render: 'percentage',
        fontColor: 'black',
        precision: 2,
        position: 'outside',
      },

      /*animation: {
      duration: 500,
      easing: "easeOutQuart",
      onComplete: function () {
        var ctx = this.chart.ctx;
        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function (dataset) {

          for (var i = 0; i < dataset.data.length; i++) {
            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
              total = dataset._meta[Object.keys(dataset._meta)[0]].total,
              mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
              start_angle = model.startAngle,
              end_angle = model.endAngle,
              mid_angle = start_angle + (end_angle - start_angle) / 2;

            var x = mid_radius * Math.cos(mid_angle);
            var y = mid_radius * Math.sin(mid_angle);

            ctx.fillStyle = '#fff';
            if (i == 3) { // Darker text color for lighter background
              ctx.fillStyle = '#444';
            }
            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
            ctx.fillText(HelpersService.amountFormatter(dataset.data[i],'$'), model.x + x, model.y + y);
            // Display percent in another line, line break doesn't work for fillText
            ctx.fillText(percent, model.x + x, model.y + y + 15);
          }
        });
      }
    }*/
    };

    $scope.CompanyCommissionChartLabels = [];
    $scope.CompanyCommissionChartData = [];
    $scope.commissionByLocationChartLabels = [];
    $scope.commissionByLocationChartData = [];
    $scope.commissionByProductChartLabels = [];
    $scope.commissionByProductChartData = [];

    const resetGraphs = () => {
      $scope.CompanyCommissionChartLabels = [];
      $scope.CompanyCommissionChartData = [];
      $scope.CompanyCommissionChartColors = ['#E74C3C', '#27AE60', '#F1C40F'];

      $scope.commissionByLocationChartLabels = [];
      $scope.commissionByLocationChartData = [];
      $scope.commissionByLocationChartColors = ['#0040FF', '#FF8000', '#74DF00'];

      $scope.commissionByProductChartLabels = [];
      $scope.commissionByProductChartData = [];
      $scope.commissionByProductChartColors = [
        '#FF4000',
        '#FF8000',
        '#FFBF00',
        '#2EFE9A',
        '#0040FF',
        '#FF0080',
        '#088A4B',
        '#8A0886',
        '#9AFE2E',
      ];
    };

    $scope.importCommissions = () => {
      ConfirmBoxService.open(
        'Confirmation',
        `Do you really want to import '${$scope.model.importCommissions.fileName}' file?`,
        {
          Yes: () => {
            $scope.model.importCommissions.date = HelpersService.dateFormatYYYYMMDD(
              $scope.model.importCommissions.date,
            );
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}commissions/aetna/import-excel`,
              'POST',
              $scope.model.importCommissions,
              (response, _status) => {
                ConfirmBoxService.open('Info', response.data.message, {
                  OK: () => {
                    $scope.model.importCommissions.fileValidated = false;
                    init();
                  },
                });
              },
            );
          },
          No: () => {},
        },
      );
    };

    const init = () => {
      $scope.model.listing.filter.contentType = $scope.model.listing.activeTab;
      $scope.model.contentLoaded[$scope.model.listing.activeTab] = true;
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}commissions/aetna/${$scope.model.listing.filter.contentType}/get`,
        'GET',
        $scope.model.listing.filter,
        (response) => {
          if ($scope.model.listing.filter.contentType === 'commissions') {
            setCommissionsContent(response);
          } else {
            setPoliciesContent(response);
          }
        },
      );
    };

    const setCommissionsContent = (response) => {
      /*CARRIERS FILTER*/
      if ($scope.model.listing.filter.carriersModel) {
        let flag = false;
        for (const i in $scope.model.listing.filter.carriersModel) {
          const d = $scope.model.listing.filter.carriersModel[i];
          if (d === true) {
            flag = true;
          }
        }
        if (flag === false) {
          $scope.model.listing.data.carriers = [];
        }
      }

      if ($scope.model.listing.data.carriers.length === 0) {
        $scope.model.listing.data.carriers = response.data.data.carriers;
      }
      /*CARRIERS FILTER*/

      $scope.model.listing.data.totalCommission = response.data.data.totalCommission;
      $scope.model.listing.data.commissionByCompany = response.data.data.commissionByCompany;
      $scope.model.listing.data.commissionByProduct = response.data.data.commissionByProduct;
      $scope.model.listing.data.commissionByLocation = response.data.data.commissionByLocation;

      resetGraphs();
      for (const i in $scope.model.listing.data.commissionByCompany) {
        const d = $scope.model.listing.data.commissionByCompany[i];
        $scope.CompanyCommissionChartLabels.push(d.company_abbr);
        $scope.CompanyCommissionChartData.push(Number(d.commission));
        d.chartLabels = [];
        d.chartData = [];
        for (const ii in d.productsData) {
          const pD = d.productsData[ii];
          const pname = pD.product === '' ? pD.commission_category : pD.product;
          d.chartLabels.push(pname);
          d.chartData.push(Number(pD.commission));
        }
      }

      for (const i in response.data.data.commissionByLocation) {
        const d = response.data.data.commissionByLocation[i];
        $scope.commissionByLocationChartLabels.push(d.location);
        $scope.commissionByLocationChartData.push(Number(d.commission));
      }

      for (const i in response.data.data.commissionByProduct) {
        const d = response.data.data.commissionByProduct[i];
        const pname = d.product === '' ? d.commission_category : d.product;
        $scope.commissionByProductChartLabels.push(pname);
        $scope.commissionByProductChartData.push(Number(d.commission));
      }
    };

    const setPoliciesContent = (response) => {
      $scope.model.listing.data.policiesData = response.data.data;
    };

    init();
    $scope.applyFilter = () => {
      init();
    };
    $scope.loadTabData = (type) => {
      $scope.model.listing.activeTab = type;
      init();
    };

    $scope.loadPoliciesData = (data, filters, type) => {
      if (type && data === '') {
        data = {};
        data.additional = type;
        data.activePage = 1;
        data.perpage = 10;
        data.filters = $scope.model.listing.data.filters;
      } else {
        data.filters = $scope.model.listing.data.filters;
      }
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}commissions/aetna/more-data/get`,
        'GET',
        data,
        (response) => {
          $scope.model.listing.data.policiesData[data.additional] = response.data.data[data.additional];
          if (filters) {
            $scope.model.listing.data.policiesData[`${data.additional}_count`] =
              response.data.data[`${data.additional}_count`];
          }
        },
      );
    };

    $scope.showAgencyLocationData = (_t) => {
      $scope.loadTabData('policies');
      $scope.model.listing.activeTab = 'policies';
      $scope.model.activeTab = 'agency_location_records';
    };
  },
];
