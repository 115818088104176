import constants from '../utils/constants';
const budgetsRoutes = ($stateProvider) => {
  $stateProvider
    .state('/budget-categories', {
      url: '/budget-categories',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_categories/budgetCategoryListing.html?v=${constants.randomNumber}`,
          controller: 'BudgetCategoriesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/budget-categories/add', {
      url: '/budget-categories/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_categories/budgetCategoryAdd.html?v=${constants.randomNumber}`,
          controller: 'BudgetCategoriesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/budget-categories/edit/:categoryID', {
      url: '/budget-categories/edit/:categoryID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_categories/budgetCategoryAdd.html?v=${constants.randomNumber}`,
          controller: 'BudgetCategoriesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/budget-attributes', {
      url: '/budget-attributes',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_attributes/budgetAttributeListing.html?v=${constants.randomNumber}`,
          controller: 'BudgetAttributesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/budget-attributes/add', {
      url: '/budget-attributes/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_attributes/budgetAttributeAdd.html?v=${constants.randomNumber}`,
          controller: 'BudgetAttributesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/budget-attributes/edit/:attributeID', {
      url: '/budget-attributes/edit/:attributeID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_attributes/budgetAttributeAdd.html?v=${constants.randomNumber}`,
          controller: 'BudgetAttributesController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/client/budget-sheet/:authKey', {
      url: '/client/budget-sheet/:authKey',
      params: {
        type: 'add_client',
        authenticated: true,
      },
      navmenu: {
        templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}budget_attributes/budgetAttributeAdd.html?v=${constants.randomNumber}`,
          controller: 'IndividualBudgetSheetController',
        },
      },
    })
    .state('/individuals/:individualID/budget-sheet/add', {
      url: '/individuals/:individualID/budget-sheet/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/budgetSheetAdd.html?v=${constants.randomNumber}`,
          controller: 'IndividualBudgetSheetController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/budget-sheet/budget-sheet-print', {
      url: '/individuals/:individualID/budget-sheet/budget-sheet-print',
      params: {
        type: 'print',
        param: 'print',
        contentType: 'print-budget-sheet',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/budgetSheetPrint.html?v=${constants.randomNumber}`,
          controller: 'IndividualBudgetSheetController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/landscape-header.html?v=${constants.randomNumber}`,
        },
      },
    });
};

export default budgetsRoutes;
