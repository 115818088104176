export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  'ConfirmBoxService',
  '$timeout',
  'FocusService',
  function AdminUsersController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    ConfirmBoxService,
    $timeout,
    FocusService,
  ) {
    $scope.model = {
      userTypes: [],
      userData: {
        status: 'Active',
        user_type: 'frontdesk_controller',
      },
      formAction: '',
      formData: new FormData(),
      listingData: [],
    };
    $scope.adminID = '';

    function getRoles(callback) {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}admin-user/get-roles`, 'GET', {}, (response) => {
        $scope.model.userTypes = response.data.data;
        if (callback) {
          callback();
        }
      });
    }

    if ($stateParams.type === 'listing') {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}admin-users/getAll`, 'POST', {}, (response) => {
        $scope.model.listingData = response.data.users;
      });
    } else if ($stateParams.type === 'edit') {
      $scope.adminID = $stateParams.adminID;

      getRoles(() => {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}admin-user/get/${$scope.adminID}`,
          'POST',
          { user_id: $scope.adminID },
          (response) => {
            if (response.data.userData) {
              $scope.model.formAction = 'edit';
              $scope.model.userData = response.data.userData;
              $scope.model.userData.password = '';
              $scope.model.userData.passwordAgain = '';

              for (const i in $scope.model.userData.types) {
                const type = $scope.model.userData.types[i];
                $scope.model.userTypes = $scope.model.userTypes.map((a) => {
                  if (a.id === type.role_id) {
                    a.checked = true;
                  }
                  return a;
                });
              }
            }
          },
        );
      });
    } else {
      $scope.model.formAction = $stateParams.type;
      $scope.model.passwordReqiured = true;
      getRoles();
    }

    $scope.deleteAdminUser = (i) => {
      const userData = AuthService.getAuthServiceData().userData;
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this individual!',
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = {};
            postData.password = model.password;
            postData.user_id = $scope.model.listingData[i].id;
            postData.email = userData.email;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}admin-user/markAsDeleteded/${userData.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  $scope.model.listingData.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = 'Invalid Password!';
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    if ($rootScope.loggedInUserData.avatar === '' || $rootScope.loggedInUserData.avatar == null) {
      $scope.model.userData.avatar = `${$rootScope.domainPath}/avatar9.jpg`;
    }

    $scope.uploadFile = (f) => {
      $scope.model.formData.append('file', f);
    };

    $scope.saveAdminFormData = () => {
      $scope.model.formData.append('userRoles', JSON.stringify($scope.model.userTypes));
      if ($scope.model.formAction === 'edit') {
        if (
          typeof $scope.model.userData.password !== 'undefined' &&
          typeof $scope.model.userData.passwordAgain !== 'undefined'
        ) {
          if ($scope.model.userData.password !== $scope.model.userData.passwordAgain) {
            if ($scope.model.userData.password.length <= 5) {
              ConfirmBoxService.open('Error', 'password must have atleast 6 characters', {
                OK: () => {},
              });
              return false;
            }
            ConfirmBoxService.open('Error', "'Password' & 'Password Again' fields did not matched!", {
              OK: () => {},
            });
            return false;
          }
        }
        ConfirmBoxService.prompt(
          'Confirmation',
          'Administrative password is required to update admin info!',
          {
            Confirm: (model) => {
              if (model.password === '' || typeof model.password === 'undefined') {
                FocusService('password');
                return false;
              }
              /*var postData = angular.copy($scope.model.userData);
                     postData.adminPassword = model.password;
                     postData.admin_id = $scope.adminID;*/

              $scope.model.formData.append('email', $scope.model.userData.email);
              $scope.model.formData.append('first_name', $scope.model.userData.first_name);
              $scope.model.formData.append('last_name', $scope.model.userData.last_name);
              $scope.model.formData.append('user_id', $scope.model.userData.id);
              $scope.model.formData.append('id', $scope.model.userData.id);
              $scope.model.formData.append('user_type', $scope.model.userData.user_type);
              $scope.model.formData.append('status', $scope.model.userData.status);
              $scope.model.formData.append('password', $scope.model.userData.password);
              $scope.model.formData.append('passwordAgain', $scope.model.userData.passwordAgain);

              $scope.model.formData.append('adminPassword', model.password);
              $scope.model.formData.append('admin_id', $scope.adminID);

              AuthService.uploadFile(
                `${$rootScope.api05DomainPath}admin-user/update/${$scope.adminID}`,
                $scope.model.formData,
                (response) => {
                  if (response.status === '200') {
                    ConfirmBoxService.close();
                    $timeout(() => {
                      ConfirmBoxService.open('Alert', 'Information has been updated successfully!', {
                        OK: () => {
                          window.location = '#/admin-users';
                        },
                      });
                    }, 100);
                  } else if (response.status === '401') {
                    ConfirmBoxService.close();
                    $timeout(() => {
                      ConfirmBoxService.open('Error', 'Email address already exists!', {
                        OK: () => {
                          FocusService('emailAddress');
                        },
                      });
                    }, 100);
                  } else {
                    $rootScope.confirmBox.description = 'Invalid Password!';
                  }
                },
              );
              return false;
            },
            Cancel: () => {},
          },
          [
            {
              title: 'Password',
              placeholder: 'Password',
              modelName: 'password',
              type: 'password',
            },
          ],
        );
      } else if ($scope.model.formAction === 'add') {
        if (
          typeof $scope.model.userData.password !== 'undefined' &&
          typeof $scope.model.userData.passwordAgain !== 'undefined'
        ) {
          if ($scope.model.userData.password !== $scope.model.userData.passwordAgain) {
            if ($scope.model.userData.password.length <= 5) {
              ConfirmBoxService.open('Error', 'password must have atleast 6 characters', {
                OK: () => {},
              });
              return false;
            }
            ConfirmBoxService.open('Error', "'Password' & 'Password Again' fields did not matched!", {
              OK: () => {},
            });
            return false;
          }
        }
        ConfirmBoxService.prompt(
          'Confirmation',
          'Administrative password is required to update admin info!',
          {
            Confirm: (model) => {
              if (model.password === '' || typeof model.password === 'undefined') {
                FocusService('password');
                return false;
              }
              /*var postData = angular.copy($scope.model.userData);
                    postData.adminPassword = model.password;*/

              $scope.model.formData.append('first_name', $scope.model.userData.first_name);
              $scope.model.formData.append('last_name', $scope.model.userData.last_name);
              $scope.model.formData.append('email', $scope.model.userData.email);
              $scope.model.formData.append('user_type', $scope.model.userData.user_type);
              $scope.model.formData.append('status', $scope.model.userData.status);
              $scope.model.formData.append('password', $scope.model.userData.password);
              $scope.model.formData.append('passwordAgain', $scope.model.userData.passwordAgain);

              $scope.model.formData.append('adminPassword', model.password);

              AuthService.uploadFile(
                `${$rootScope.api05DomainPath}admin-user/add`,
                $scope.model.formData,
                (response) => {
                  if (response.status === '200') {
                    ConfirmBoxService.close();
                    $timeout(() => {
                      ConfirmBoxService.open('Alert', 'User has been added successfully!', {
                        OK: () => {
                          window.location = '#/admin-users';
                        },
                      });
                    }, 100);
                  } else if (response.status === '401') {
                    ConfirmBoxService.close();
                    $timeout(() => {
                      ConfirmBoxService.open('Error', 'Email address already exists!', {
                        OK: () => {
                          FocusService('emailAddress');
                        },
                      });
                    }, 100);
                  } else {
                    $rootScope.confirmBox.description = 'Invalid Password!';
                  }
                },
              );
              return false;
            },
            Cancel: () => {},
          },
          [
            {
              title: 'Password',
              placeholder: 'Password',
              modelName: 'password',
              type: 'password',
            },
          ],
        );
      }
    };
  },
];
