export default class fileModelMultiDirective {
  constructor($parse) {
    this.restrict = 'A'; // restrict the directive to be used as an attribute
    this.$parse = $parse;
  }

  link(scope, element, attrs) {
    const model = this.$parse(attrs.fileModelMulti);
    element.on('change', () => {
      scope.uploadFileMulti(element[0].files);
    });
  }

  static factory($parse) {
    const directive = new fileModelMultiDirective($parse);
    return directive;
  }
}

// Dependency Injection
fileModelMultiDirective.factory.$inject = ['$parse'];
