export default [
  '$rootScope',
  'AuthService',
  function NotificationsController($rootScope, AuthService) {
    if (typeof $rootScope.notifications === 'undefined') {
      $rootScope.notifications = [];
    }

    const _checkNewNotifications = () => {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}notifications/get/all`,
        'get',
        { hiddenMode: true, syncedData: $rootScope.notifications },
        (response) => {
          setNotificationData(response);
          //$timeout(function(){checkNewNotifications();},600000);
        },
      );
    };

    //checkNewNotifications();
    const setNotificationData = (response) => {
      if (response.data.notifications.length !== 0) {
        $rootScope.notifications = [];
      }
      for (const i in response.data.notifications) {
        const messageData = response.data.notifications[i];
        $rootScope.notifications.push(messageData);
      }
    };
  },
];
