export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'FocusService',
  'MomentService',
  'ValidationService',
  '$location',
  function LeadsController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    FocusService,
    MomentService,
    ValidationService,
    $location,
  ) {
    $scope.leadID = $stateParams.leadID;
    $scope.model = {
      constants: {
        dropdowns: {
          gender: [
            { value: 'Male', title: 'Male' },
            { value: 'Female', title: 'Female' },
          ],
          lead_status: [],
          lead_source: [],
          lead_affiliate: [],
          lead_purchasing: [],
          msfp_business_line: [],
        },
      },
      formData: {},
      listingData: [],
      comments: {
        comment: '',
      },
    };
    $scope.model.leadID = $scope.leadID;

    if ($stateParams.type === 'add_lead') {
      let req_url = '';
      if ($stateParams.leadID !== '' && typeof $stateParams.leadID !== 'undefined') {
        $scope.model.leadID = $stateParams.leadID;
        req_url = `${$rootScope.api05DomainPath}medicare-lead/get/${$scope.model.leadID}`;
      } else {
        req_url = `${$rootScope.api05DomainPath}medicare-lead/get/`;
      }

      AuthService.sendRequest(req_url, 'GET', {}, (response) => {
        $scope.model.constants.dropdowns.lead_status = response.data.lead_status;
        $scope.model.constants.dropdowns.seller = response.data.seller;
        $scope.model.constants.dropdowns.lead_account_status = response.data.lead_account_status;
        $scope.model.constants.dropdowns.msfp_business_line = response.data.msfp_business_line;
        $scope.model.constants.dropdowns.referrer_type = response.data.referrer_type;
        $scope.model.constants.dropdowns.lead_exclusivity = response.data.lead_exclusivity;
        $scope.model.constants.dropdowns.seller_status = response.data.seller_status;
        $scope.model.constants.dropdowns.lead_sex = response.data.lead_sex;
        $scope.model.constants.dropdowns.confirmation = response.data.confirmation;

        //Modify - Edit
        if (typeof response.data.lead_data.lead_name_first !== 'undefined') {
          $scope.model.formData = response.data.lead_data;
          $scope.model.listingData = response.data.lead_data.comments;

          if ($scope.model.formData.lead_date_of_birth === '0000-00-00') {
            $scope.model.formData.lead_date_of_birth = '';
          } else {
            $scope.model.formData.lead_date_of_birth = MomentService.formatDate(
              $scope.model.formData.lead_date_of_birth,
              'MM-DD-YYYY',
            );
          }
        }
      });
    } else if ($stateParams.type === 'listingPage') {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}medicare-lead/get-all/`, 'GET', {}, (response) => {
        for (const i in response.data.leads) {
          response.data.leads[i].lead_status = {
            value: response.data.leads[i].lead_status,
            title: response.data.leads[i].lead_status,
          };
        }

        $scope.model.listingData = response.data.leads;
        $scope.model.constants.dropdowns.lead_status = response.data.lead_status;
      });
    }

    const convertTimeZone = (d) => {
      if (d === '' || typeof d === 'undefined' || d === null || d === '0000-00-00' || d === '0000-00-00 00:00:00') {
        return '';
      }
      d = MomentService.convertTimeZone(d);
      return d;
    };
    $scope.onChangeDOB = (a, b, _ele) => {
      if (b === null || b === '' || typeof b === 'undefined' || b === 'Invalid Date') {
        return false;
      }
      b = b.replace(/-/g, '/'); // replaces all occurances of "-" with "/"
      const birthday = convertTimeZone(b);
      const ageDifMs = MomentService.getAgeDiffMs(birthday);
      const ageDate = convertTimeZone(ageDifMs); // miliseconds from epoch
      const c = Math.abs(ageDate.getUTCFullYear() - 1970);

      if (typeof b !== 'undefined' && b !== null && b !== '' && b !== 'Invalid Date') {
        const splittedVal = b.split('/');
        const mm = splittedVal[0];
        const dd = splittedVal[1];
        const yyyy = splittedVal[2];
        if (Number(mm) > 12) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.formData.date_of_birth = '';
            $scope.model.formData.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
        if (Number(dd) > 31) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.formData.date_of_birth = '';
            $scope.model.formData.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
        if (Number(yyyy) > birthday.getFullYear()) {
          ConfirmBoxService.open('Error', `Invald Date of birth(${b})!\n Date format must be MM-DD-YYYY (12-31-2017)`, {
            OK: () => {},
          });
          if (a) {
            $scope.model.formData.date_of_birth = '';
            $scope.model.formData.date_of_birth = '';
          } else {
            $scope.model.spouseData.date_of_birth = '';
          }
          return false;
        }
      }

      if (a) {
        $scope.model.formData.lead_age = c;
        $scope.model.formData.lead_age = c;
      } else {
        $scope.model.spouseData.lead_age = c;
      }
    };
    /*$scope.onChangeAge = function (age) {
    if(age !== ''){
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let bYear = currentYear-Number(age);
      let dob = bYear+'/'+(currentDate.getMonth()+1)+'/'+currentDate.getDate();
      dob = moment(dob).format("MM-DD-YYYY");
      $scope.model.formData.date_of_birth = dob;
    }
  };*/

    $scope.submitAddleadForm = () => {
      const validated = ValidationService.validate($scope, {
        'model.formData.lead_name_first': {
          required: true,
          errorMessage: 'First name is required',
        },
        'model.formData.lead_name_last': {
          required: true,
          errorMessage: 'last name is required',
        },
      });
      if (validated) {
        if ($stateParams.leadID !== '' && typeof $stateParams.leadID !== 'undefined') {
          $scope.model.formData.leadID = $stateParams.leadID;
        }
        $scope.model.formData.lead_date_of_birth = dateFormatterYMD($scope.model.formData.lead_date_of_birth);
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-lead/save`,
          'POST',
          $scope.model.formData,
          (response) => {
            if (response.data.success === true) {
              ConfirmBoxService.open('Success', response.data.message, {
                OK: () => {
                  if ($stateParams.leadID !== '' && typeof $stateParams.leadID !== 'undefined') {
                    $state.reload();
                  } else {
                    $location.path(`/leads/add/${response.data.lead_id}`);
                  }
                },
              });
            } else {
              ConfirmBoxService.open('Notification', response.data.message, {
                OK: () => {},
              });
            }
          },
        );
      }
    };

    $scope.updateLeadStatus = (d, leadData) => {
      const params = { lead_status: d, lead_id: leadData.id };
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}medicare-lead/update-lead-status`,
        'POST',
        params,
        (response) => {
          ConfirmBoxService.open('Notification', response.data.message, {
            OK: () => {},
          });
        },
      );
    };
    $scope.deleteLead = (lead, index) => {
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this LEAD!',
        {
          Confirm: (model) => {
            if (model.confirmPassword === '' || typeof model.confirmPassword === 'undefined') {
              FocusService('confirmPassword');
              return false;
            }
            const postData = {};
            postData.password = model.confirmPassword;
            postData.lead_id = lead.id;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}medicare-leads/delete/${lead.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  $scope.model.listingData.splice(index, 1);
                } else {
                  $rootScope.confirmBox.description = response.data.message;
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'confirmPassword',
            type: 'password',
          },
        ],
      );
    };
    $scope.addComment = () => {
      const validated = ValidationService.validate($scope, {
        'model.comments.comment': {
          required: true,
          errorMessage: 'Comment is required',
        },
      });
      if (validated) {
        if ($stateParams.leadID !== '' && typeof $stateParams.leadID !== 'undefined') {
          $scope.model.comments.leadID = $stateParams.leadID;
        }
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}medicare-lead/add-comment`,
          'POST',
          $scope.model.comments,
          (response) => {
            $scope.model.comments.comment = '';
            $scope.model.listingData = response.data.comments;
          },
        );
      }
    };

    const dateFormatterYMD = (date) => {
      if (date === '' || typeof date === 'undefined') {
        return '';
      }
      if (date === 'null' || date === null || date === '') {
        return false;
      }
      date = convertTimeZone(date);

      return `${date.getFullYear()}-${
        Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }-${Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()}`;
    };
  },
];
