export default class expandCollapseFeatureDirective {
  constructor($parse) {
    this.restrict = 'A'; // restrict the directive to be used as an attribute
    this.$parse = $parse;
  }

  link(scope, element, attrs) {
    const parent = angular.element(`[${attrs.expandCollapseFeatureDirective}]`);
    parent.css('height', parent.attr(attrs.expandCollapseFeatureDirective)).css('overflow', 'hidden');
    element.data('expanded', 0);

    element.on('click', () => {
      if (element.data('expanded') === 0) {
        element.data('expanded', 1);
        parent.css('height', 'auto');
        element.text('Hide');
      } else {
        element.text('View More');
        element.data('expanded', 0);
        parent.css('height', parent.attr(attrs.expandCollapseFeatureDirective)).css('overflow', 'hidden');
      }
    });
  }

  static factory($parse) {
    const directive = new expandCollapseFeatureDirective($parse);
    return directive;
  }
}

// Dependency Injection
expandCollapseFeatureDirective.factory.$inject = ['$parse'];
