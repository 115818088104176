export default class customPaginationDirective {
  constructor(LoaderService) {
    this.restrict = 'E'; // restrict the directive to be used as an element
    this.replace = true; // replace the custom-pagination-dir element with the directive template
    this.LoaderService = LoaderService;
  }

  link(scope, element, attrs) {
    const $this = this;
    let interval;
    let lastState = 0;
    const $attrs = attrs;
    const _options = {};
    interval = setInterval(() => {
      if (Number(attrs.recordsCount) !== Number($this.recordsCount)) {
        pagination();
        lastState = 0;
      } else if (element[0].isConnected === false) {
        clearInterval(interval);
        lastState = 1;
      } else if (!element.is(':visible')) {
        if (lastState !== 2) {
          pagination();
        }
        lastState = 2;
      }
    }, 500);

    const pagination = () => {
      $this.options = { type: 'pagination' };
      if (typeof $attrs.options !== 'undefined') {
        $this.options = JSON.parse($attrs.options);
        if ($this.options.type === 'scroll') {
          if (angular.element($this.options.element).length <= 0) {
            $this.options.element = $(window);
          }
        } else {
          $this.options = { type: 'pagination' };
        }
      }

      $this.scope = scope;
      $this.onClick = attrs.onClick;
      $this.recordsCount = attrs.recordsCount;
      $this.recordsPerPage = attrs.recordsPerPage;
      $this.additionalData = attrs.additionalData;

      $this.perpage = $this.recordsPerPage;
      $this.records = $this.recordsCount;
      $this.pages = Math.ceil($this.records / $this.perpage);

      $this.element = element;

      /*SCROLL FEATURE VARS*/
      $this.scrollTimeout = null;
      $this.scrollPage = 1;

      $this.initPagination = function (activePage) {
        $this.activepage = activePage;
        this.pagesRemaining = $this.pages - $this.activepage;
        let startPage = $this.activepage;

        if (startPage === 2) {
          startPage = 1;
        } else if (startPage === 2) {
          startPage = 1;
        } else if (this.pagesRemaining === 0) {
          startPage = $this.pages - 9;
        } else if (this.pagesRemaining === 1) {
          startPage = $this.pages - 8;
        } else if (this.pagesRemaining === 2) {
          startPage = $this.pages - 7;
        } else {
          startPage = startPage - 4;
        }

        let html = '';
        let pageNoCreated = 0;
        const pageBtns = Number(this.perpage);
        if ($this.activepage !== 1) {
          html += '<li title="First Page" data-ele-click class=""><a data-page="1">First</a></li>';
          html += `<li title="Previous Page" data-ele-click class=""><a data-page="${
            $this.activepage - 1
          }">Prev</a></li>`;
        }
        for (let i = 1; i <= $this.pages; i++) {
          if (startPage <= i) {
            pageNoCreated++;
            html += `<li class="${$this.activepage === i ? 'active' : ''}"><a data-page="${i}">${i}</a></li>`;
          }
          if (Number(pageNoCreated) === Number(pageBtns)) {
            break;
          }
        }
        if ($this.activepage !== this.pages) {
          html += `<li title="Next Page" data-ele-click class=""><a data-page="${$this.activepage + 1}">Next</a></li>`;
          html += `<li title="Last Page" data-ele-click class=""><a data-page="${$this.pages}">Last</a></li>`;
        }

        const aHtml = angular.element(
          `<ul class="pagination" data-custom-pagination="">${html}</ul><p>Total Pages: ${$this.pages} | Total Records: ${$this.records}</p>`,
        );
        $this.element.html(aHtml);
        $this.element.find('li').bind('click', function (e) {
          e.preventDefault();
          const page = angular.element(this).find('a').attr('data-page');
          $this.loadPageData(page);
        });
      };

      $this.initContinuousScroll = (activePage) => {
        $this.loadPageData(activePage);
        let recordsShown = Number($this.scrollPage) * Number($this.perpage);
        if (recordsShown > Number($this.records)) {
          recordsShown = $this.records;
        }
        const aHtml = angular
          .element(`<p>Total Records: ${recordsShown} / ${$this.records}</p>`)
          .css('padding', '20px');
        $this.element.html(aHtml);
        scope.$apply();
      };

      $this.fixedTableHeadOnScroll = () => {
        if (angular.element($this.options.element).find('thead').length > 0) {
          angular.element($this.options.element).find('thead').find('tr').css('position', 'absolute');
          angular.element($this.options.element).find('thead').find('th').css('background-color', '#fff');

          angular
            .element(angular.element($this.options.element).find('tbody').find('tr')[0])
            .find('td')
            .each((a, b) => {
              angular.element(b).css('padding-top', '80px');
              angular
                .element(angular.element($this.options.element).find('thead').find('th')[a])
                .width(angular.element(b).width());
            });

          let recordsShown = Number($this.scrollPage) * Number($this.perpage);
          if (recordsShown > Number($this.records)) {
            recordsShown = $this.records;
          }
          const aHtml = angular
            .element(`<p>Total Records: ${recordsShown} / ${$this.records}</p>`)
            .css('padding', '20px');
          $this.element.html(aHtml);
        }
      };

      if ($this.options.type === 'pagination') {
        $this.initPagination(1);
      } else {
        let interval = null;
        interval = setInterval(() => {
          clearInterval(interval);
          if (angular.element($this.options.element).find('tbody').find('tr').length > 0) {
            //$this.fixedTableHeadOnScroll();
          }
        }, 800);

        angular.element($this.options.element).on('scroll', (e) => {
          const elem = angular.element(e.currentTarget);
          clearTimeout($this.scrollTimeout);
          const totalHeight =
            typeof elem[0].scrollHeight === 'undefined' ? document.documentElement.scrollHeight : elem[0].scrollHeight;
          if (elem.scrollTop() + elem.height() + 100 > totalHeight) {
            $this.scrollTimeout = setTimeout(() => {
              $this.scrollPage++;
              if ($this.scrollPage <= $this.pages) {
                $this.LoaderService.show();
                $this.initContinuousScroll($this.scrollPage);
              }
            }, 200);
          }
        });
      }

      $this.loadPageData = (aP) => {
        if ($this.options.type === 'pagination') {
          $this.initPagination(Number(aP));
        }
        if (typeof $this.scope[$this.onClick] === 'function') {
          const data = {
            perpage: $this.recordsPerPage,
            activePage: aP,
            totalRecords: $this.recordsCount,
            additional: $this.additionalData,
          };
          $this.scope[$this.onClick](data);
        } else {
          alert('Custom Pagination - Callback(on-click) method not found in the scope');
        }
      };
    };

    pagination();
  }

  static factory(LoaderService) {
    return new customPaginationDirective(LoaderService);
  }
}

// Dependency Injection
customPaginationDirective.factory.$inject = ['LoaderService'];
