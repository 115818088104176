class DateFormattingService {
  constructor(moment) {
    this.moment = moment;
  }

  format(date, format) {
    if (typeof date !== 'undefined' && date !== '' && date !== '1969-12-31') {
      return this.moment(date).format(format);
    }
    return '';
  }
}

DateFormattingService.$inject = ['moment']; // Dependency Injection

export default DateFormattingService;
