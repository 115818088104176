class MaxNumbersService {
  createMaxInteger(val, allowedIntegerLength, allowedDecimalLength) {
    return new MaxInteger(val, allowedIntegerLength, allowedDecimalLength);
  }
}

// The MaxInteger class encapsulates the logic previously contained in the factory.
class MaxInteger {
  constructor(val, allowedIntegerLength, allowedDecimalLength) {
    this.val = val;
    this.pointIndex = val.indexOf('.');
    this.integersLength = this.pointIndex !== -1 ? val.substring(0, this.pointIndex).length : val.length;
    this.decimalsLength = this.pointIndex !== -1 ? val.substring(this.pointIndex + 1).length : 0;
    this.allowedIntegerLength = allowedIntegerLength;
    this.allowedDecimalLength = allowedDecimalLength;
    this.hasChanged = false;
  }

  isNotANumber() {
    return Number.isNaN(Number(this.val));
  }

  handleIsNaN() {
    while (true) {
      if (Number.isNaN(Number(this.val))) {
        this.val = this.val.substr(0, this.val.length - 1);
      } else {
        break;
      }
    }
    this.hasChanged = true;
  }

  throwDecimals() {
    const incrementer = this.allowedDecimalLength ? this.allowedDecimalLength + 1 : 0;
    this.val = this.val.substring(0, this.pointIndex + incrementer);
    this.hasChanged = true;
  }

  hasDecimals() {
    return this.val.indexOf('.') !== -1;
  }

  hasTooMuchDecimals() {
    return this.decimalsLength > this.allowedDecimalLength;
  }

  hasTooMuchIntegers() {
    return this.integersLength > this.allowedIntegerLength;
  }

  throwIntegers() {
    let integersLength = this.allowedIntegerLength;
    if (this.pointIndex !== -1) {
      integersLength = this.pointIndex <= this.allowedIntegerLength ? this.pointIndex : this.allowedIntegerLength;
      this.val = this.val.substr(0, integersLength);
    } else {
      this.val = this.val.substr(0, integersLength);
    }

    this.hasChanged = true;
  }

  didItChanged() {
    return this.hasChanged;
  }
}

export default MaxNumbersService;
