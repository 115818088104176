export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'IndividualInfoService',
  function IndividualBudgetSheetController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    IndividualInfoService,
  ) {
    let _originatorEv;

    $rootScope.subMenuActive = 'budget-sheet';
    $rootScope.individualID = $stateParams.individualID;
    let params;
    $scope.model = {
      editableFields: [],
      dropdowns: {
        categories: [],
      },
      formData: {},
      listingData: [],
      individualData: {},
      listingDataExist: true,
      typesData: [],
      adminData: [],
      type: $stateParams.type,
      individualID: $stateParams.individualID,
      expense_total_present: 0,
      expense_total_future: 0,
      income_total_present: 0,
      income_total_future: 0,
      retirement_total_present: 0,
      retirement_total_future: 0,
      max_numbers: 7,
      decimals: 2,
      min: 0,
      max: 99999.99,
    };
    //Get Individual info
    IndividualInfoService.getInfo($scope.model.individualID, (result) => {
      $scope.model.individual_info = result;
    });

    if ($scope.model.type === 'add') {
      params = { individualID: $scope.model.individualID };
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}budget/getBudgetAttributesByIndividual/${$scope.model.individualID}`,
        'GET',
        params,
        (response) => {
          $scope.model.listingData = response.data.results.attributes_data;
          $scope.model.typesData = response.data.results.types_data;
          $scope.model.individualData = response.data.results.individual_data[0];
          $scope.model.listingDataExist = response.data.results.attributes_data_exist;
          $scope.model.adminData = response.data.results.admin_data;
          $scope.update_expense_total();
        },
      );
    } else if ($scope.model.type === 'print') {
      params = { individualID: $scope.model.individualID };
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}budget/getBudgetAttributesByIndividual/${+$scope.model.individualID}`,
        'GET',
        params,
        (response) => {
          $scope.model.listingData = response.data.results.attributes_data;
          $scope.model.typesData = response.data.results.types_data;
          $scope.model.individualData = response.data.results.individual_data[0];
          $scope.model.listingDataExist = response.data.results.attributes_data_exist;
          $scope.model.adminData = response.data.results.admin_data;
          $scope.update_expense_total();

          setTimeout(() => {
            let expenseAreaClass = false;
            let incomeAreaClass = false;
            let summaryAreaClass = false;

            const elementExpenseArea = angular.element(document.querySelector('#expenseArea'));
            const heightExpenseArea = elementExpenseArea[0].offsetHeight;

            const elementIncomeArea = angular.element(document.querySelector('#incomeArea'));
            const heightIncomeArea = elementIncomeArea[0].offsetHeight;

            const elementSummaryArea = angular.element(document.querySelector('#graphArea'));
            const _heightSummaryArea = elementSummaryArea[0].offsetHeight;

            if (heightExpenseArea >= 825) {
              expenseAreaClass = true;
            }

            if (heightIncomeArea >= 825) {
              incomeAreaClass = true;
              expenseAreaClass = true;
            }

            if (heightExpenseArea <= 410 && heightIncomeArea <= 410) {
              incomeAreaClass = true;
              summaryAreaClass = true;
            }

            if (heightExpenseArea <= 410 && heightIncomeArea > 410) {
              incomeAreaClass = true;
              expenseAreaClass = true;
            }

            if (heightExpenseArea >= 410 && heightIncomeArea > 410) {
              incomeAreaClass = true;
              expenseAreaClass = true;
            }

            if (expenseAreaClass === true) {
              elementExpenseArea.addClass('break-after');
            }
            if (incomeAreaClass === true) {
              elementIncomeArea.addClass('break-after');
            }
            if (summaryAreaClass === true) {
              elementSummaryArea.addClass('break-before');
            }

            window.print();
            //window.close();
          }, 1000);
        },
      );
    }

    $scope.add_field = (attr) => {
      const obj = angular.copy(attr);
      const target = $scope.model.listingData[attr.budget_category_type][attr.budget_category];

      obj.attr_id = 0;
      obj.attribute = '';
      obj.present = '';
      obj.future = '';
      target.push(obj);

      setTimeout(() => {
        angular
          .element('.tbody')
          .find(`[data-category='${attr.budget_category}'] div.action:last .edit`)
          .trigger('click');
      }, 100);
    };

    $scope.remove_attribute = (attr, index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this attribute?', {
        Yes: () => {
          const target = $scope.model.listingData[attr.budget_category_type][attr.budget_category];

          //Check if Listing data have last object then copy that object to dummy object
          if (target.length === 1) {
            alert('Last attribute can not be removed. Please remove whole category!');
          } else {
            target.splice(index, 1);
            $scope.update_expense_total();
          }
        },
        No: () => {},
      });
    };

    $scope.remove_category = (attr, _index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this attribute?', {
        Yes: () => {
          const target = $scope.model.listingData[attr.budget_category_type];
          delete target[attr.budget_category];
          $scope.update_expense_total();
        },
        No: () => {},
      });
    };

    $scope.add_category = (category_type) => {
      const category_name = prompt('Please enter category name', '');
      if (category_name != null) {
        let cat_type_id = 0;
        angular.forEach($scope.model.typesData, (value, _key) => {
          if (value.budget_category_type === category_type) {
            cat_type_id = value.id;
          }
        });

        const obj = {
          attr_id: 0,
          attribute: '',
          budget_category: category_name,
          budget_category_type: category_type,
          cat_id: 0,
          cat_type_id: cat_type_id,
        };

        //If no category is defined
        if (typeof $scope.model.listingData[category_type] === 'undefined') {
          $scope.model.listingData[category_type] = {};
          $scope.model.listingData[category_type][category_name] = [];
        } else {
          $scope.model.listingData[category_type][category_name] = [];
        }

        $scope.model.listingData[category_type][category_name].push(obj);

        setTimeout(() => {
          angular.element('.tbody').find(`[data-category='${category_name}'] div.action:last .edit`).trigger('click');
        }, 100);
      }
    };

    $scope.add_budget_sheet = () => {
      if ($scope.model.type === 'add') {
        $scope.model.formData.attributes_data = $scope.model.listingData;
        //console.log(JSON.stringify($scope.model.formData));
        $scope.model.formData.individualID = $scope.model.individualID;
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}budget/addBudgetSheet`,
          'POST',
          $scope.model.formData,
          (response) => {
            if (response.data.results.success === true) {
              alert('Budget Sheet has been updated.');
              $state.reload();
              //$location.path("individuals/"+$scope.model.individualID+"/budget-sheet/add");
            } else {
              alert(response.data.result.message);
            }
          },
        );
      }
    };

    $scope.update_expense_total = () => {
      const expenses = $scope.model.listingData.Expenses;
      const income = $scope.model.listingData.Income;
      let expense_total_present = 0;
      let expense_total_future = 0;
      let income_total_present = 0;
      let income_total_future = 0;

      //For Expenses
      for (const k in expenses) {
        for (let i = 0; i < expenses[k].length; i++) {
          if (typeof expenses[k][i].present !== 'undefined' && expenses[k][i].present !== '') {
            expense_total_present += Number.parseFloat(expenses[k][i].present);
          }

          if (typeof expenses[k][i].future !== 'undefined' && expenses[k][i].future !== '') {
            expense_total_future += Number.parseFloat(expenses[k][i].future);
          }
        }
      }

      //For Income
      for (const k in income) {
        for (let i = 0; i < income[k].length; i++) {
          if (typeof income[k][i].present !== 'undefined' && income[k][i].present !== '') {
            income_total_present += Number.parseFloat(income[k][i].present);
          }

          if (typeof income[k][i].future !== 'undefined' && income[k][i].future !== '') {
            income_total_future += Number.parseFloat(income[k][i].future);
          }
        }
      }

      $scope.model.expense_total_present = expense_total_present;
      $scope.model.expense_total_future = expense_total_future;
      $scope.model.income_total_present = income_total_present;
      $scope.model.income_total_future = income_total_future;

      //Summary
      $scope.model.retirement_total_present = income_total_present - expense_total_present;
      $scope.model.retirement_total_future = income_total_future - expense_total_future;

      //Highchart
      Highcharts.setOptions({
        colors: ['#32c7ff', '#e30001', '#feec44', '#3ebe36'],
      });

      Highcharts.chart('container', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Expense Budget Summary Chart',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: Highcharts.theme?.contrastTextColor || 'black',
              },
            },
          },
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [
              {
                name: 'Current Expense',
                y: $scope.model.expense_total_present,
              },
              {
                name: 'Future Expense',
                y: $scope.model.expense_total_future,
              },
              {
                name: 'Current Income',
                y: $scope.model.income_total_present,
              },
              {
                name: 'Future Income',
                y: $scope.model.income_total_future,
              },
            ],
          },
        ],
        credits: {
          enabled: false,
        },
      });
    };

    $scope.email_to_client = () => {
      $scope.model.formData.individualID = $scope.model.individualID;
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}budget/emailBudgetSheetToClient`,
        'POST',
        $scope.model.formData,
        (response) => {
          alert(response.data.results.message);
        },
      );
    };

    $scope.openMenu = ($mdOpenMenu, ev) => {
      _originatorEv = ev;
      $mdOpenMenu(ev);
    };
  },
];
