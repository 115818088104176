import constants from '../utils/constants';

export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'HelpersService',
  'FocusService',
  '$mdDialog',
  'MomentService',
  'IndividualInfoService',
  function AllocationSheetController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    HelpersService,
    FocusService,
    $mdDialog,
    MomentService,
    IndividualInfoService,
  ) {
    $rootScope.subMenuActive = 'allocation-sheet';
    $rootScope.individualID = $stateParams.individualID;
    $scope.individualID = $stateParams.individualID;
    $rootScope.allocationSheetID = $stateParams.allocationSheetID;

    $scope.model = {
      policyType: '99-9999999',
      helpers: {
        flags: {
          viewType: 'msfp',
          accountTypeData: 0,
          issueDate: false,
          reissueDate: false,
        },
        makeEditable: (d) => {
          d.isEditable = true;
        },
        showDropDown: (t) => {
          $scope.model.helpers.flags[t] = true;
        },
        populateProducts: (i) => {
          if (i.carrier_name === 'North American') {
            $scope.model.policyType = '9999999999';
          } else {
            $scope.model.policyType = '99-9999999';
          }

          $scope.model.dropdowns.products = i.productsData;
        },
        updateInitialPremium: (t, t1, t2) => {
          let bonusAmount = '';
          if (
            typeof $scope.model.formData.total_amount !== 'undefined' &&
            $scope.model.formData.total_amount !== '' &&
            $scope.model.formData.total_amount !== '$' &&
            $scope.model.formData.monthly_cap !== ''
          ) {
            let amount = angular.copy($scope.model.formData.total_amount);
            amount = amount.replace(/([a-zA-Z\%\$\,]+)/, '');
            amount = amount.replace(/,/g, '');

            const percentage = (Number(amount) / 100) * Number($scope.model.formData.monthly_cap);
            $scope.model.formData.initial_premium = HelpersService.roundNumber(percentage, 2).toFixed(2);

            if ($scope.model.formData.fixed_account !== '') {
              const percentage2 = (Number(amount) / 100) * Number($scope.model.formData.fixed_account);
              $scope.model.formData.fixed_account_amount = HelpersService.roundNumber(percentage2, 2).toFixed(2);
            }
            if (t2) {
              if (Number($scope.model.formData.initial_premium) > 100000) {
                bonusAmount = angular.copy($scope.model.formData.product.max_bonus_rate);
              } else {
                bonusAmount = '3.00';
              }
              $scope.model.formData.product.bonus_rate = bonusAmount;
              $scope.model.formData.product.bonus_rate_fixed = bonusAmount;
            }

            if ($scope.model.formData.product.bonus_rate !== '') {
              const _bonusAmount = $scope.model.formData.product.bonus_rate;

              if (
                $scope.model.formData.product.bonus_amount_total !== '0.00' &&
                t &&
                !t1 &&
                typeof $scope.model.formData.product.bonus_amount_total !== 'undefined'
              ) {
                $scope.model.formData.product.bonus_amount = HelpersService.roundNumber(
                  $scope.model.formData.product.bonus_amount_total,
                  2,
                ).toFixed(2);
                $scope.model.formData.initial_accumulation = HelpersService.roundNumber(
                  Number($scope.model.formData.product.bonus_amount) + Number($scope.model.formData.initial_premium),
                  2,
                ).toFixed(2);
              } else {
                const percentage3 =
                  (Number($scope.model.formData.initial_premium) / 100) *
                  Number($scope.model.formData.product.bonus_rate);
                $scope.model.formData.product.bonus_amount = HelpersService.roundNumber(percentage3, 2).toFixed(2);
                $scope.model.formData.initial_accumulation = HelpersService.roundNumber(
                  Number($scope.model.formData.product.bonus_amount) + Number($scope.model.formData.initial_premium),
                  2,
                ).toFixed(2);
              }
              if ($scope.model.formData.fixed_account !== '') {
                const percentage4 =
                  (Number($scope.model.formData.fixed_account_amount) / 100) *
                  Number($scope.model.formData.product.bonus_rate_fixed);
                $scope.model.formData.product.bonus_amount_fixed_account = HelpersService.roundNumber(
                  percentage4,
                  2,
                ).toFixed(2);
              }
            }
            $scope.model.formData.product.bonus_rate_fixed = bonusAmount;
          }
        },
        updateBonusAmount: () => {
          let amount = $scope.model.formData.product.bonus_amount.replace(/([a-zA-Z\%\$\,,]+)/, '');
          amount = amount.replace(/,/g, '');
          $scope.model.formData.initial_accumulation = HelpersService.roundNumber(
            Number(amount) + Number($scope.model.formData.initial_premium),
            2,
          ).toFixed(2);
        },
        changedWithdrawals: (_i, d) => {
          d.updated = true;
        },
      },
      dropdowns: { carriers: [], products: [] },
      allocationSheets: [],
      individualData: {},
      allocationData: {
        calculatedData: [],
        sheetData: {},
        footerData: {
          overwrite_flag: false,
        },
      },
      formData: {
        last_withdrawal_deduction: '',
        monthly_cap: 0,
        fixed_account: 0,
        fixed_account_rate: '1.00',
        product: {
          bonus_rate: 0,
          monthly_cap: 0,
          fee: 0,
          max_bonus_rate: '4.00',
        },
      },
    };
    $scope.model.individualID = $scope.individualID;
    //Get Individual info
    IndividualInfoService.getInfo($scope.model.individualID, (result) => {
      $scope.model.individual_info = result;
    });

    if ($stateParams.type === 'listing') {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/allocationsSheets/${$rootScope.individualID}`,
        'POST',
        { individual_id: $rootScope.individualID },
        (response) => {
          initListingPage(response);
        },
      );
    } else if ($stateParams.type === 'add') {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/allocationsSheet/add/${$rootScope.individualID}`,
        'POST',
        { individual_id: $rootScope.individualID },
        (response) => {
          initAddPage(response);
        },
      );
    } else if ($stateParams.type === 'edit') {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/allocationsSheet/get/${$rootScope.individualID}`,
        'POST',
        {
          individual_id: $rootScope.individualID,
          allocation_sheet_id: $stateParams.allocationSheetID,
        },
        (response) => {
          initEditPage(response);
        },
      );
    }

    const initListingPage = (response) => {
      $scope.model.individualData = response.data.individualData[0];
      $scope.model.allocationSheets = response.data.allocationsSheets;
    };
    const initAddPage = (response) => {
      $scope.model.individualData = response.data.individualData[0];
      $scope.model.dropdowns.carriers = response.data.carriers;
    };
    const initEditPage = (response) => {
      if (response.data.status === 200) {
        $scope.model.allocationData.calculatedData = response.data.allocationSheetData.allocationData;
        $scope.model.allocationData.footerData = response.data.allocationSheetData.sheetReport;

        $scope.model.allocationData.sheetData = response.data.allocationData;

        $scope.model.allocationData.sheetDataFixedAccount = response.data.allocationDataFixedAccount;
        setAllocationFormData();
      } else if (response.data.status === 404) {
        ConfirmBoxService.open('Error', response.data.message, {
          OK: () => {},
        });
      }
    };
    const setAllocationFormData = () => {
      $scope.model.formData = $scope.model.allocationData.sheetData[0];
      $scope.model.formData.fixed_account = 0;
      $scope.model.formData.total_amount = $scope.model.formData.investment_amount;
      $scope.model.formData.monthly_cap = $scope.model.formData.monthly_cap_amount_percentage;
      $scope.model.formData.fixed_account = $scope.model.formData.fixed_account_amount_percentage;
      $scope.model.formData.issue_date = MomentService.convertTimeZone($scope.model.formData.issue_date);

      if ($scope.model.formData.initial_issue_date) {
        $scope.model.formData.initial_issue_date = MomentService.convertTimeZone(
          $scope.model.formData.initial_issue_date,
        );
      }

      $scope.model.formData.carrier = {
        carrier_name: $scope.model.allocationData.sheetData[0].carrier_name,
        id: $scope.model.allocationData.sheetData[0].carrier_id,
      };

      if ($scope.model.allocationData.sheetData[0].carrier_name === 'North American') {
        $scope.model.policyType = '9999999999';
      } else {
        $scope.model.policyType = '99-9999999';
      }

      $scope.model.formData.product = $scope.model.allocationData.sheetData[0];
      $scope.model.formData.product.bonus_amount_total = $scope.model.formData.product.bonus_amount;
      $scope.model.formData.product.bonus_rate_fixed = $scope.model.allocationData.sheetDataFixedAccount[0].bonus_rate;
      $scope.model.formData.fixed_account_rate = $scope.model.allocationData.sheetDataFixedAccount[0].max_monthly_cap;
      $scope.model.formData.product.max_bonus_rate = '4.00';

      $scope.model.helpers.updateInitialPremium(true);

      if ($rootScope.activeAllocation === 2) {
        $scope.loadAllocationData(2);
      }
    };

    function datenum(v, date1904) {
      if (date1904) v += 1462;
      const epoch = Date.parse(v);
      return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    }

    function sheet_from_array_of_arrays(data, _opts) {
      const ws = {};
      const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
      for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
          if (range.s.r > R) range.s.r = R;
          if (range.s.c > C) range.s.c = C;
          if (range.e.r < R) range.e.r = R;
          if (range.e.c < C) range.e.c = C;
          const cell = { v: data[R][C] };
          if (cell.v == null) continue;
          const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

          if (typeof cell.v === 'number') cell.t = 'n';
          else if (typeof cell.v === 'boolean') cell.t = 'b';
          else if (cell.v instanceof Date) {
            cell.t = 'n';
            cell.z = XLSX.SSF._table[14];
            cell.v = datenum(cell.v);
          } else cell.t = 's';

          ws[cell_ref] = cell;
        }
      }
      if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
      return ws;
    }

    function Workbook() {
      if (!(this instanceof Workbook)) return new Workbook();
      this.SheetNames = [];
      this.Sheets = {};
    }

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    $scope.exportCSV = () => {
      const xlsxData = [];
      xlsxData.push([]);
      let ws_name = '';
      if ($scope.model.helpers.flags.accountTypeData === 2) {
        ws_name = `Fixed Account - ${$scope.model.formData.full_name}`;
        xlsxData.push([
          'Client:',
          $scope.model.formData.full_name,
          '',
          'Fixed Account Rate:',
          `${$scope.model.formData.fixed_account_rate}%`,
        ]);
        xlsxData.push([
          'Product Name:',
          $scope.model.formData.carrier.carrier_name,
          '',
          'Initial Premium (Rollover):',
          `$${$scope.model.formData.fixed_account_amount}`,
        ]);
        xlsxData.push([
          'Company:',
          $scope.model.formData.product_name,
          '',
          'Bonus:',
          `$${$scope.model.formData.bonus_amount_fixed_account}`,
        ]);
        xlsxData.push([
          'Policy:',
          $scope.model.formData.policy_number,
          '',
          'Initial Allocation Value:',
          `$${
            Number($scope.model.formData.bonus_amount_fixed_account) +
            Number($scope.model.formData.fixed_account_amount)
          }`,
        ]);
        xlsxData.push(['Market Type:', 'Fixed Account', '', 'Bonus %:', `$${$scope.model.formData.bonus_rate_fixed}`]);
        xlsxData.push([
          'Account Type:',
          $scope.model.formData.account_type_text,
          '',
          'Fee %:',
          `$${$scope.model.formData.fee}`,
        ]);
        xlsxData.push(['Issue Date:', MomentService.formatDate($scope.model.formData.issue_date, 'MM-DD-YYYY')]);
        xlsxData.push([]);
        xlsxData.push([
          'DATE',
          'MONTH',
          'FIXED ACCOUNT VALUE',
          'INTEREST EARNED',
          'PAYMENTS',
          'BONUS',
          'WITHDRAWALS',
          'FEES',
        ]);
        for (const i in $scope.model.allocationData.calculatedData) {
          const d = $scope.model.allocationData.calculatedData[i];
          xlsxData.push([
            d.date_formatted,
            d.month_name,
            d.accumulation_value_with_interest_formatted,
            d.interest_earned_formatted,
            d.deposit,
            d.bonus,
            d.withdrawals,
            d.deducted_fee,
          ]);
        }
        xlsxData.push([
          '',
          '',
          'TOTAL:',
          $scope.model.allocationData.footerData.total_interest_earned,
          '-',
          '-',
          $scope.model.allocationData.footerData.withdrawals,
          $scope.model.allocationData.footerData.total_fee,
        ]);
      } else {
        ws_name = `2-Year Monthly Cap - ${$scope.model.formData.full_name}`;
        xlsxData.push([
          'Client:',
          $scope.model.formData.full_name,
          '',
          '2-Year Monthly Cap:',
          `${$scope.model.formData.max_monthly_cap}%`,
        ]);
        xlsxData.push([
          'Product Name:',
          $scope.model.formData.carrier.carrier_name,
          '',
          'Initial Premium (Rollover):',
          `$${$scope.model.formData.investment_amount}`,
        ]);
        xlsxData.push([
          'Company:',
          $scope.model.formData.product_name,
          '',
          'Bonus:',
          `$${$scope.model.formData.bonus_amount}`,
        ]);
        xlsxData.push([
          'Policy:',
          $scope.model.formData.policy_number,
          '',
          'Initial Allocation Value:',
          `$${$scope.model.formData.initial_accumulation}`,
        ]);
        xlsxData.push([
          '2-Year Monthly Cap:',
          $scope.model.formData.market_type,
          '',
          'Bonus %:',
          `$${$scope.model.formData.bonus_rate}`,
        ]);
        xlsxData.push([
          'Account Type:',
          $scope.model.formData.account_type_text,
          '',
          'Fee %:',
          `$${$scope.model.formData.fee}`,
        ]);
        xlsxData.push(['Issue Date:', MomentService.formatDate($scope.model.formData.issue_date, 'MM-DD-YYYY')]);
        xlsxData.push([]);
        xlsxData.push([
          'Date',
          'Month',
          'S&P Values',
          'Accumulation Value with Interest Earned applied',
          'Value as appears on statement (w/o Interest)',
          'Percent Earned',
          'Withdrawals',
          'Fees',
          'Accumulated Interest Earned',
        ]);
        for (const i in $scope.model.allocationData.calculatedData) {
          const d = $scope.model.allocationData.calculatedData[i];
          xlsxData.push([
            d.date_formatted,
            d.month_name,
            d.snp_value_formatted,
            d.accumulation_value_with_interest_formatted,
            d.accumulation_value_without_interest_formatted,
            d.percent_earned,
            d.withdrawals,
            d.deducted_fee,
            d.interest_earned_formatted,
          ]);
        }
        xlsxData.push([
          '',
          '',
          'TOTAL:',
          $scope.model.allocationData.footerData.indexed_accumulation,
          $scope.model.allocationData.footerData.accumulation_without_interest,
          $scope.model.allocationData.footerData.total_percent_earned,
          $scope.model.allocationData.footerData.withdrawals,
          $scope.model.allocationData.footerData.total_fee,
          $scope.model.allocationData.footerData.percent_earned,
        ]);
      }

      const data = xlsxData;
      const wb = new Workbook();
      const ws = sheet_from_array_of_arrays(data);

      wb.SheetNames.push(ws_name);
      wb.Sheets[ws_name] = ws;
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
      });
      saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${ws_name}.xlsx`);
    };

    /*ADD ALLOCATION SHEET ACTION*/
    $scope.submitAddAllocationForm = () => {
      if (typeof $scope.model.formData.issue_date === 'undefined') {
        ConfirmBoxService.open('Error', 'Issue Date is required!', {
          OK: () => {
            FocusService('issueDate');
          },
        });
        return false;
      }

      if (typeof $scope.model.formData.reissue_date !== 'undefined' && $scope.model.formData.reissue_date !== '') {
        $scope.model.formData.reissue_date = HelpersService.dateFormatYYYYMMDD($scope.model.formData.reissue_date);
      }

      $scope.model.formData.issue_date = HelpersService.dateFormatYYYYMMDD($scope.model.formData.issue_date);
      const fData = angular.copy($scope.model.formData);
      fData.individual_id = $rootScope.individualID;
      fData.total_amount = fData.total_amount.replace(/([^0-9\.])/g, '');
      if (typeof fData.product === 'undefined' || typeof fData.product.id === 'undefined') {
        ConfirmBoxService.open('Error', 'Product is required!', {
          OK: () => {},
        });
        return false;
      }
      if (typeof fData.total_amount === 'undefined' || fData.total_amount.id === '') {
        ConfirmBoxService.open('Error', 'Investment amount is required!', {
          OK: () => {},
        });
        return false;
      }
      if (typeof fData.monthly_cap === 'undefined' || fData.monthly_cap === '0' || fData.monthly_cap === 0) {
        ConfirmBoxService.open('Error', '2-Year Monthly Cap(%) is required!', {
          OK: () => {},
        });
        return false;
      }
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/allocationsSheet/addProcess/${$rootScope.individualID}`,
        'POST',
        fData,
        (response) => {
          if (response.data.status === 200) {
            ConfirmBoxService.open('Alert', response.data.message, {
              OK: () => {
                window.location = `#/individuals/${$rootScope.individualID}/allocation-sheet/${response.data.data.allocation_id}/edit`;
              },
            });
          }
        },
      );
    };

    $scope.updateAllocationSheetData = () => {
      const calculatedData = angular.copy($scope.model.allocationData.calculatedData);
      const sheetData = {
        id: $scope.model.formData.id,
        policy_number: $scope.model.formData.policy_number,
        total_amount: $scope.model.formData.total_amount,
        bonus_rate: $scope.model.formData.product.bonus_rate,
        bonus_amount: $scope.model.formData.product.bonus_amount,
        monthly_cap_amount_percentage: $scope.model.formData.monthly_cap,
        fixed_account_amount_percentage: $scope.model.formData.fixed_account,
        issue_date: HelpersService.dateFormatYYYYMMDD($scope.model.formData.issue_date),
        max_monthly_cap: $scope.model.formData.max_monthly_cap,
        fee: $scope.model.formData.product.fee,
        overwriteInterestEarned: $scope.model.allocationData.footerData.total_percent_earned,
        overwritePercentEarned: $scope.model.allocationData.footerData.percent_earned,
        overwriteTotalFlag: $scope.model.allocationData.footerData.overwrite_flag,
        deduct_: $scope.model.allocationData.footerData.overwrite_flag,
        deduct_last_month_withdrawals: $scope.model.formData.last_withdrawal_deduction,
        account_type_text: $scope.model.formData.account_type_text,
        fixed_id: $scope.model.allocationData.sheetDataFixedAccount[0].id,
        fixed_account_bonus: $scope.model.formData.product.bonus_rate_fixed,
        fixed_account_monthly_cap: $scope.model.formData.product.fixed_account_rate,
      };

      if ($scope.model.allocationData.footerData.overwrite_flag !== true) {
        sheetData.overwriteInterestEarned = 0;
        sheetData.overwritePercentEarned = 0;
      }

      const alllocationData = {
        allocationData: calculatedData,
        sheetData: sheetData,
      };
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/allocationsSheet/updateProcess/${$rootScope.individualID}`,
        'POST',
        alllocationData,
        (_response) => {
          $state.reload();
        },
      );
    };
    /*DELETE ALLOCATION SHEET ACTION*/
    $scope.deleteAllocationSheet = (i, data) => {
      ConfirmBoxService.prompt(
        'Confirmation',
        "Administrative password is required to delete 'llocation Sheet!'",
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = data;
            postData.password = model.password;

            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/delete/${$rootScope.individualID}`,
              'POST',
              data,
              (response) => {
                if (response.data.status === 200) {
                  ConfirmBoxService.close();
                  $scope.model.allocationSheets.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = response.data.message;
                }
              },
            );

            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    $scope.loadAllocationData = (accountType) => {
      $scope.model.helpers.flags.viewType = 'msfp';
      if (accountType === 1 && $scope.model.helpers.flags.accountTypeData !== 0) {
        $scope.model.helpers.flags.accountTypeData = 1;
        $rootScope.activeAllocation = 1;
        $state.reload();
      } else if (accountType === 2) {
        $scope.model.helpers.flags.accountTypeData = 2;
        $rootScope.activeAllocation = 2;
        const fixedAccountData = $scope.model.allocationData.sheetDataFixedAccount[0];
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/getAllocationsSheetData/${fixedAccountData.id}`,
          'POST',
          fixedAccountData,
          (response) => {
            $scope.model.allocationData.calculatedData = response.data.allocationSheetData.allocationData;
            $scope.model.allocationData.footerData = response.data.allocationSheetData.sheetReport;
          },
        );
      }
    };

    $scope.sendAllocationViaEmail = () => {
      const formData = $scope.model.formData;
      ConfirmBoxService.open('Confirmation', 'Do you really want to send Allocation Sheet to Client?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/allocations-sheet/send-via-email`,
            'POST',
            {
              allocation_id: formData.allocation_id,
              individual_id: $scope.individualID,
            },
            (response) => {
              ConfirmBoxService.open(response.data.status, response.data.message, {
                OK: () => {},
              });
            },
            () => {},
          );
        },
        No: () => {},
      });
    };
    $scope.sendAllocationViaEmailFromListing = (id, individual_id) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to send Allocation Sheet to Client?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/allocations-sheet/send-via-email`,
            'POST',
            { allocation_id: id, individual_id: individual_id },
            (response) => {
              ConfirmBoxService.open(response.data.status, response.data.message, {
                OK: () => {},
              });
            },
            () => {},
          );
        },
        No: () => {},
      });
    };

    /*MODALS*/
    $scope.addPayments = () => {
      $mdDialog.show({
        controller: addPaymentsController,
        templateUrl: `${constants.templatesDir}allocation/allocationAddPaymentsModal.html?v=${constants.randomNumber}`,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
      });
    };
    $scope.addEditPayments = (d) => {
      $scope.paymentsData = d;
      $mdDialog.show({
        controller: addEditPaymentsController,
        templateUrl: `${constants.templatesDir}allocation/allocationPaymentsModal.html?v=${constants.randomNumber}`,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
      });
    };
    $scope.loadGraphModal = (ev) => {
      $mdDialog
        .show({
          controller: graphController,
          templateUrl: `${constants.templatesDir}allocation/allocationGraphModal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          fullscreen: true, // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addEditWithdrawals = (d) => {
      $scope.withdrawalsData = d;
      $mdDialog
        .show({
          controller: withdrawalsController,
          templateUrl: `${constants.templatesDir}allocation/allocationWithdrawalsModal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: d,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addEditFees = (d) => {
      $scope.feesData = d;
      $mdDialog
        .show({
          controller: feesController,
          templateUrl: `${constants.templatesDir}allocation/allocationFeesModal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          clickOutsideToClose: true,
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    $scope.updateSNP = (d) => {
      $scope.snpData = d;
      $mdDialog
        .show({
          controller: snpController,
          templateUrl: `${constants.templatesDir}allocation/allocationSNPModal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          clickOutsideToClose: true,
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    function graphController(scope, $mdDialog) {
      scope.colors = ['#33FF00', '#FF0000', '#8E24AA', '#29B6F6'];
      scope.model = {
        formData: {
          full_name: $scope.model.formData.full_name,
          issue_date: MomentService.formatDate($scope.model.formData.issue_date, 'MM-DD-YYYY'),
        },
      };

      Chart.defaults.global.legend.display = true;
      Chart.defaults.global.legend.position = 'top';

      const labelsData = [];
      const InterestData = [];
      const valuesData = [];
      const percentEarnedData = [];
      const snpData = [];
      for (const i in $scope.model.allocationData.calculatedData) {
        const cData = angular.copy($scope.model.allocationData.calculatedData[i]);
        labelsData.push(`${cData.month_str}-${cData.day}-${cData.year}`);
        valuesData.push(HelpersService.roundNumber(cData.accumulation_value_with_interest, 2));
        InterestData.push(HelpersService.roundNumber(cData.interest_earned, 2));
        percentEarnedData.push(HelpersService.roundNumber(cData.percent_earned, 5));
        snpData.push(HelpersService.roundNumber(cData.snp_value, 6));
      }
      scope.labels = labelsData;
      scope.series = ['ACCUMULATION VALUE (WITH INTEREST)', 'INTEREST EARNED', 'MONTHLY CAP', 'S&P VALUES'];

      scope.data = [valuesData, InterestData, percentEarnedData, snpData];

      scope.datasetOverride = [{ yAxisID: 'y-axis-1' }];
      scope.options = {
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, _data) => tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          },
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                  }
                  return `$${value}`;
                },
              },
            },
            {
              id: 'y-axis-2',
              type: 'linear',
              display: false,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                  }
                  return `$${value}`;
                },
              },
            },
            {
              id: 'y-axis-3',
              type: 'linear',
              display: false,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return `${value}%`;
                },
              },
            },
            {
              id: 'y-axis-4',
              type: 'linear',
              display: false,
              position: 'left',
              ticks: {
                callback: (value, _index, _values) => {
                  if (Number.parseInt(value) >= 1000) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return value;
                },
              },
            },
          ],
        },
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }

    function withdrawalsController(scope, $mdDialog) {
      scope.withdrawalsData = angular.copy($scope.withdrawalsData);
      if (
        typeof scope.withdrawalsData.withdrawals_records === 'undefined' ||
        scope.withdrawalsData.withdrawals_records.length === 0
      ) {
        scope.withdrawalsData.withdrawals_records = [];
        scope.withdrawalsData.withdrawals_records.push({
          id: 0,
          date: scope.withdrawalsData.day,
        });
      }
      scope.addWithdrawalsRecord = () => {
        scope.withdrawalsData.withdrawals_records.push({
          id: 0,
        });
      };
      scope.save = () => {
        ConfirmBoxService.open('Confirmation', "Do you really want to Update 'Withdrawals'?", {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/withdrawals/${$rootScope.individualID}`,
              'POST',
              scope.withdrawalsData,
              (_response) => {
                $mdDialog.hide();
                $state.reload();
              },
            );
          },
          No: () => {},
        });
      };
      scope.delete = (i, _d) => {
        ConfirmBoxService.open('Confirmation', "Do you really want to delete this 'Withdrawals'?", {
          Yes: () => {
            scope.withdrawalsData.withdrawals_records.splice(i, 1);
          },
          No: () => {},
        });
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }

    function addPaymentsController(scope, $mdDialog) {
      scope.paymentsData = [];
      scope.addPaymentsData = () => {
        scope.paymentsData.push({
          id: 0,
        });
      };
      scope.save = () => {
        ConfirmBoxService.open('Confirmation', "Do you really want to Add 'Payments'?", {
          Yes: () => {
            const paymentD = [];
            for (const i in scope.paymentsData) {
              const p = {};
              p.allocation_id = $scope.model.allocationData.id;
              p.amount = scope.paymentsData[i].amount;
              p.date = HelpersService.dateFormatYYYYMMDD(MomentService.convertTimeZone(scope.paymentsData[i].date));
              paymentD.push(p);
            }
            const data = {
              payments: paymentD,
              sheetData: $scope.model.allocationData.sheetDataFixedAccount[0],
            };
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/addPayments/${$rootScope.individualID}`,
              'POST',
              data,
              (_response) => {
                $mdDialog.hide();
                $state.reload();
              },
            );
          },
          No: () => {},
        });
      };
      scope.delete = (i, _d) => {
        ConfirmBoxService.open('Confirmation', "Do you really want to delete this 'Payment'?", {
          Yes: () => {
            scope.paymentsData.splice(i, 1);
          },
          No: () => {},
        });
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }

    function addEditPaymentsController(scope, $mdDialog) {
      scope.paymentsData = angular.copy($scope.paymentsData);
      scope.addPaymentsData = () => {
        scope.paymentsData.payment_records.push({
          id: 0,
        });
      };
      scope.save = () => {
        ConfirmBoxService.open('Confirmation', "Do you really want to Add 'Payments'?", {
          Yes: () => {
            const paymentD = [];
            for (const i in scope.paymentsData.payment_records) {
              const p = {};
              p.id = scope.paymentsData.payment_records[i].id;
              p.amount = scope.paymentsData.payment_records[i].amount;
              p.date = scope.paymentsData.payment_records[i].date;
              paymentD.push(p);
            }
            const data = {
              payments: paymentD,
              sheetData: scope.paymentsData,
            };
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/addEditPayments/${$rootScope.individualID}`,
              'POST',
              data,
              (_response) => {
                $mdDialog.hide();
                $state.reload();
              },
            );
          },
          No: () => {},
        });
      };
      scope.delete = (i, _d) => {
        ConfirmBoxService.open('Confirmation', "Do you really want to delete this 'Payment'?", {
          Yes: () => {
            scope.paymentsData.splice(i, 1);
          },
          No: () => {},
        });
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }

    function feesController(scope, $mdDialog) {
      scope.feesData = angular.copy($scope.feesData);
      if (typeof scope.feesData.fees_records === 'undefined') {
        scope.feesData.fees_records = [];
        scope.feesData.fees_records.push({
          id: 0,
        });
      }
      scope.addFeesRecord = () => {
        scope.feesData.fees_records.push({
          id: 0,
        });
      };
      scope.save = () => {
        ConfirmBoxService.open('Confirmation', "Do you really want to Update 'Fees'?", {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/fees/${$rootScope.individualID}`,
              'POST',
              scope.feesData,
              (_response) => {
                $mdDialog.hide();
                $state.reload();
              },
            );
          },
          No: () => {},
        });
      };
      scope.delete = (i, _d) => {
        ConfirmBoxService.open('Confirmation', "Do you really want to delete this 'Fees'?", {
          Yes: () => {
            scope.feesData.fees_records.splice(i, 1);
          },
          No: () => {},
        });
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }

    function snpController(scope, $mdDialog) {
      scope.d = angular.copy($scope.snpData);
      scope.saveSNP = (_d) => {
        ConfirmBoxService.open('Confirmation', "Do you really want to Update 'SNP'?", {
          Yes: () => {
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/allocationsSheet/snp/${$rootScope.individualID}`,
              'POST',
              scope.d,
              (_response) => {
                $mdDialog.hide();
                $state.reload();
              },
            );
          },
          No: () => {},
        });
      };
      scope.hide = () => {
        $mdDialog.hide();
      };
    }
  },
];
