import constants from '../utils/constants';

export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  '$timeout',
  '$q',
  'HelpersService',
  'FocusService',
  'FocusHighlightService',
  '$mdDialog',
  function IndividualDoctorController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
    $timeout,
    $q,
    HelpersService,
    FocusService,
    FocusHighlightService,
    $mdDialog,
  ) {
    $scope.first_request = true;
    $rootScope.subMenuActive = 'basic-info';
    $scope.addPhysicians = () => {
      $scope.model.primaryPhysiciansCount++;
      $scope.model.primaryPhysicians.push({
        id: $scope.model.primaryPhysiciansCount,
        value: '',
        suffix: {},
        first_name: '',
        last_name: '',
      });
    };
    $scope.deletePhysicians = (i) => {
      $scope.model.primaryPhysicians.splice(i, 1);
      $scope.model.primaryPhysiciansCount++;
    };

    $scope.model = {
      spouseEditable: true,
      sorting: {
        active: 'updated_at',
        name: '',
        email: '',
        updated_at: 'desc',
      },
      wizard: {
        activeTabClass: 'basic',
        back: () => {
          $scope.model.wizard.activeTabClass = 'basic';
          $timeout(() => {
            $rootScope.gotoNextStep = false;
            $state.reload();
          }, 500);
        },
      },
      currentPrescriptionData: {},
      dropdown: {
        emailProviders: {},
        specialistSpeciality: {},
        physicianSuffix: {},
        drugNames: {},
        drugDosage: {
          dosages: {
            dataArray: [],
          },
        },
      },
      additionalPrescriptionsTakeData: [],
      prescriptionPacenetPA: false,
      prescriptionsTaken: false,
      spouseCurrentClient: false,
      primaryPhysiciansCount: 1,
      primaryPhysicians: [{ id: 1, value: '', suffix: {}, first_name: '', last_name: '' }],
      additionalprescriptionsFields: [{ id: 1, value: '' }],
      approvedDateTime: '',
      aarpNumber: false,
      approvedInd: false,
      intakeDatePicker: false,
      appointmentDTPicker: false,
      leadDatePicker: false,
      spouseDOBPicker: false,
      effADatePicker: false,
      effBDatePicker: false,
      spouseEffADatePicker: false,
      spouseEffBDatePicker: false,
      disabilityPicker: false,
      deceased_date: false,
      spouseDisabilityPicker: false,
      counties: [],

      prescriptionsTakeFields: [],
      prescriptionsTakeData: [],

      primaryPhysician: {
        suffix: { title: '' },
        first_name: '',
        last_name: '',
      },
      specialists: [],

      intakeSheetAdd: {
        arp_member: 'no',
      },
      addForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
      },
      editForm: {
        gender: $rootScope.constants.dropdowns.gender[0],
        date_of_birth: null,
      },
      spouseData: {},
      individualID: null,
      listingData: [],
      doctorsData: [],
      listingPerPage: 0,
      listingTotal: 0,
      paginationCurrent: 1,
      searchText: '',
      searchDoctor: '',
      listingFilter: 0,
    };

    $scope.spouseEditable = (i) => {
      $scope.model.spouseData = {};
      if (i.value === 'yes') {
        $scope.model.spouseEditable = true;
        if (typeof $scope.model.intakeSheetAdd.spouse_current_client_id !== 'undefined') {
          if (
            $scope.model.intakeSheetAdd.spouse_current_client_id.value !== 0 &&
            $scope.model.intakeSheetAdd.spouse_current_client_id.value !== '0'
          ) {
            $scope.populateSpouseData($scope.model.intakeSheetAdd.spouse_current_client_id);
          }
        }
      } else {
        $scope.model.spouseEditable = false;
      }
    };

    $scope.sortListing = (a) => {
      for (const i in $scope.model.sorting) {
        if (i !== 'active' && i !== a) {
          $scope.model.sorting[i] = '';
        }
      }

      if ($scope.model.sorting.active !== a) {
        $scope.model.sorting.active = a;
        $scope.model.sorting[a] = a;
      }

      if ($scope.model.sorting[a] === 'asc') {
        $scope.model.sorting[a] = 'desc';
      } else {
        $scope.model.sorting[a] = 'asc';
      }

      getIndividualResults(1, true);
    };
    /*SPOUSE SELECTION*/
    $scope.ssnCheckAndLoadData = (ssn, t) => {
      if (typeof ssn !== 'undefined' && ssn !== '') {
        const data = {};
        data.ssn = ssn;
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/check-ssn`, 'POST', data, (response) => {
          if (response.data.status !== 200) {
            ConfirmBoxService.open(
              `Client(${response.data.individualData.social_security_no}) is already a member!`,
              `Do you want to edit this individual(${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name})?`,
              {
                Yes: () => {
                  window.location = `#/individuals/edit/${response.data.individualData.id}`;
                },
                No: () => {
                  $scope.model.addForm.social_security_no = '';
                  if (t) {
                    $scope.model.editForm.social_security_no = angular.copy(
                      $scope.model.clonedData.individualData.social_security_no,
                    );
                  }
                },
              },
            );
          }
        });
      }
    };
    $scope.checkSpouseSSNExistence = (ssn) => {
      if (typeof ssn !== 'undefined' && ssn !== '') {
        const data = {};
        data.ssn = ssn;
        data.notIn = $scope.model.editForm.id;
        if (data.ssn === $scope.model.editForm.social_security_no) {
          ConfirmBoxService.open('Error', "individual's SSN & Spouse SSN are same!", {
            OK: () => {
              $scope.model.spouseData.social_security_no = '';
            },
          });
        } else if ($scope.model.intakeSheetAdd.spouse_current_client !== 'yes') {
          AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/check-ssn`, 'POST', data, (response) => {
            if (response.data.status !== 200) {
              ConfirmBoxService.open(
                `Client(${response.data.individualData.social_security_no}) is already a member!`,
                `Do you want to populate this individual's information(${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name})?`,
                {
                  Yes: () => {
                    $scope.populateSpouseData({
                      value: response.data.individualData.id,
                    });
                    $scope.model.intakeSheetAdd.spouse_current_client_id = {
                      value: response.data.individualData.id,
                      title: `${response.data.individualData.last_name}, ${response.data.individualData.first_name} ${response.data.individualData.middle_name}`,
                    };
                    $scope.model.intakeSheetAdd.spouse_current_client = {
                      value: 'yes',
                      title: 'Yes',
                    };
                    $scope.onSelectSpouseCurrentClient({
                      value: 'yes',
                      title: 'Yes',
                    });
                  },
                  No: () => {
                    $scope.model.spouseData.social_security_no = '';
                  },
                },
              );
            }
          });
        }
      }
    };
    $scope.populateSpouseData = (v) => {
      if (v.value !== '') {
        const data = {};
        data.spouse_id = v.value;
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/load-spouse-data/${data.spouse_id}`,
          'POST',
          data,
          (res) => {
            //$scope.model.spouseData = response.data.spouseData;
            if (typeof res.data.spouseData !== 'undefined') {
              $scope.model.spouseData = res.data.spouseData;
              $scope.model.spouseData.date_of_birth =
                res.data.spouseData.date_of_birth === 'null' || res.data.spouseData.date_of_birth == null
                  ? ''
                  : new Date(res.data.spouseData.date_of_birth);
              if ($scope.model.spouseData.date_of_birth) {
                const birthday = $scope.model.spouseData.date_of_birth;
                const ageDifMs = Date.now() - birthday.getTime();
                const ageDate = new Date(ageDifMs); // miliseconds from epoch
                const c = Math.abs(ageDate.getUTCFullYear() - 1970);
                $scope.model.spouseData.age = c;
              }
              $scope.model.spouseData.part_a_eff_date =
                $scope.model.spouseData.part_a_eff_date === 'null' || $scope.model.spouseData.part_a_eff_date == null
                  ? ''
                  : new Date($scope.model.spouseData.part_a_eff_date);
              $scope.model.spouseData.part_b_eff_date =
                $scope.model.spouseData.part_b_eff_date === 'null' || $scope.model.spouseData.part_b_eff_date == null
                  ? ''
                  : new Date($scope.model.spouseData.part_b_eff_date);
              $scope.model.spouseData.ss_disability_date =
                $scope.model.spouseData.ss_disability_date === 'null' ||
                $scope.model.spouseData.ss_disability_date == null
                  ? ''
                  : new Date($scope.model.spouseData.ss_disability_date);
              $scope.model.spouseData.date_of_birth =
                $scope.model.spouseData.date_of_birth === 'null' || $scope.model.spouseData.date_of_birth == null
                  ? ''
                  : new Date($scope.model.spouseData.date_of_birth);
            } else {
              $scope.model.spouseData = {};
            }
          },
        );
      }
    };

    if ($rootScope.gotoNextStep === true) {
      $scope.model.wizard.activeTabClass = 'intake';
      $rootScope.gotoNextStep = false;
    }

    $scope.editPhoneNumber = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/phone-number-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addPhoneNumber = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/phone-number-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.addAddress = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/address-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.editDocters = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/docters-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };
    $scope.editAddress = (ev) => {
      $mdDialog
        .show({
          controller: DialogController,
          templateUrl: `${constants.templatesDir}individuals/innerPages/address-modal.html?v=${constants.randomNumber}`,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(
          (answer) => {
            $scope.status = `You said the information was "${answer}".`;
          },
          () => {
            $scope.status = 'You cancelled the dialog.';
          },
        );
    };

    function DialogController(scope, $mdDialog) {
      scope.is_primary_assigned = false;
      scope.getStateCountyCity = (t) => {
        if (t === true) {
          $scope.model.editForm.zip_code = $scope.model.addForm.zip_code;
        }
        if (typeof $scope.model.editForm.zip_code === 'undefined') {
          return false;
        }
        if ($scope.model.editForm.zip_code.length <= 4) {
          return false;
        }
        const data = {};
        data.zip = angular.copy($scope.model.editForm.zip_code);

        AuthService.sendRequest(`${$rootScope.api05DomainPath}getAddressInfo`, 'POST', data, (response) => {
          if (response.data.status === 200) {
            const d = response.data.addressData[0];
            const state = {
              id: d.state_id,
              state: `${d.stateabbr} - ${d.state}`,
            };
            const county = { id: d.id, county: d.county };
            $scope.model.addForm.state_id = state;
            $scope.model.editForm.state_id = state;
            scope.onStateChange(state);

            $scope.model.addForm.county_id = county;
            $scope.model.editForm.county_id = county;

            $scope.model.addForm.city = d.city;
            $scope.model.editForm.city = d.city;

            scope.model.counties = response.data.addressData;
            $scope.model.counties = response.data.addressData;
          } else {
            $scope.model.addForm.county_id = {};
            $scope.model.editForm.county_id = {};

            $scope.model.addForm.city = '';
            $scope.model.editForm.city = '';

            $scope.model.addForm.state_id = {};
            $scope.model.editForm.state_id = {};
          }
        });

        return false;
      };
      scope.constants = {
        DB: angular.copy($rootScope.constants.DB),
      };

      scope.multipleAddress = {};
      const addressTypes = ['home_address', 'business_address', 'billing_address', 'mailing_address', 'poa_address'];
      for (const i in addressTypes) {
        scope.multipleAddress[addressTypes[i]] = {
          title: addressTypes[i].replace('_address', ''),
          type: addressTypes[i],
          state_id: '',
          county_id: '',
          county_fips: '',
          address_type: '',
          address1: '',
          address2: '',
          zip: '',
          city: '',
        };
      }

      scope.openConfirmBox = (doctor) => {
        const params = {
          individual_id: $scope.model.individualID,
          hipaaspace_npi: doctor.npi,
        };
        ConfirmBoxService.open('confirmation', 'Add this doctor as a Primary Pysician or Specialist', {
          Add_Physician: () => {
            if (scope.is_primary_assigned === false) {
              params.is_primary = 'yes';
              AuthService.sendRequest(
                `${$rootScope.api05DomainPath}individual/add-doctor`,
                'POST',
                params,
                (response) => {
                  if (response.data.result.success === true) {
                    $scope.model.primaryPhysicians.push(doctor);
                  }
                  alert(response.data.result.message);
                },
              );
            } else {
              alert('Primary Pysician is already added.');
            }
          },
          Add_Specialist: () => {
            params.is_primary = 'no';
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/add-doctor`,
              'POST',
              params,
              (response) => {
                if (response.data.result.success === true) {
                  $scope.model.specialists.push(doctor);
                }
                alert(response.data.result.message);
              },
            );
          },
          Cancel: () => {},
        });
      };

      scope.pastePhone = (e, m) => {
        let phoneNumber = e.originalEvent.clipboardData.getData('text/plain');
        phoneNumber = phoneNumber ? phoneNumber.replace(/[^\d]/g, '') : null;
        if (phoneNumber.length !== 10 && phoneNumber.length !== 11) {
          scope.model.addForm[m] = '';
          alert('Invalid Phone Number');
          return false;
        }
        let ph = '';
        if (phoneNumber.length === 11) {
          ph = phoneNumber;
          ph = ph.substring(1, ph.length);
        }
        scope.model.addForm[m] = ph;
      };

      if ($stateParams.type === 'add') {
        scope.model = $scope.model;
        scope.model.addForm = $scope.model.addForm;
      } else {
        scope.model = $scope.model;
        scope.model.addForm = $scope.model.editForm;
      }

      scope.model.oldValues = angular.copy(scope.model.addForm);
      scope.constants = angular.copy($rootScope.constants);

      scope.hide = () => {
        $mdDialog.hide();
      };
      scope.cancel = () => {
        scope.model.addForm = angular.copy(scope.model.oldValues);
        $scope.model.addForm = angular.copy(scope.model.oldValues);
        $scope.model.editForm = angular.copy(scope.model.oldValues);
        $mdDialog.cancel();
      };
      scope.answer = (answer) => {
        $mdDialog.hide(answer);
      };
      scope.onStateChange = (i) => {
        scope.model.addForm.county_id = {};
        scope.model.addForm.city = '';
        if (typeof $rootScope.constants.DB.counties === 'undefined') {
          scope.model.counties = angular.copy(scope.constants.DB.counties[i.id]);
          $scope.model.counties = angular.copy(scope.constants.DB.counties[i.id]);
        } else {
          $scope.model.counties = angular.copy($rootScope.constants.DB.counties[i.id]);
        }
      };

      //New Physician/Specialist work
      scope.searchDoctors = () => {
        const params = {
          search: $scope.model.searchDoctor,
          individual_id: $scope.model.individualID,
        };
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/search-doctor`, 'POST', params, (response) => {
          scope.doctorsData = response.data.doctors_physicians;
          scope.is_primary_assigned = response.data.is_primary_assigned;
        });
      };
    }

    let _setTimeoutAddress;
    $scope.permanentDeleteIndividual = (i) => {
      const userData = AuthService.getAuthServiceData().userData;
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this individual!',
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = {};
            postData.password = model.password;
            postData.individualID = $scope.model.listingData[i].id;
            postData.email = userData.email;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/permanentDelete/${userData.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  $scope.model.listingData.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = 'Invalid Password!';
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    $scope.showDatePickerCalendar = (t) => {
      $scope.model[t] = true;
    };
    $scope.updateAdditionalPrescriptions = (i, t) => {
      if (t === 'add') {
        $scope.model.additionalprescriptionsFields.push({
          id: $scope.model.additionalprescriptionsFields.length + 1,
        });
      } else {
        $scope.model.additionalprescriptionsFields.splice(i, 1);
        $scope.model.additionalPrescriptionsTakeData.splice(i, 1);
      }
    };

    $scope.onChangeDOB = (a, b) => {
      const birthday = new Date(b);
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      const c = Math.abs(ageDate.getUTCFullYear() - 1970);

      if (a) {
        const _monthNumber = birthday.getMonth() + 1;
        $scope.model.intakeSheetAdd.drug_id_password = `${
          (birthday.getMonth() + 1).toString().length === 1 ? `0${birthday.getMonth() + 1}` : birthday.getMonth() + 1
        }${birthday.getDate() > 9 ? birthday.getDate() : `0${birthday.getDate()}`}${birthday.getFullYear()}`;
        $scope.model.addForm.age = c;
        $scope.model.editForm.age = c;
      } else {
        $scope.model.spouseData.age = c;
      }
    };
    $scope.changeAARPMember = (v) => {
      if (v.value === 'yes') {
        $scope.model.aarpNumber = true;
      } else {
        $scope.model.aarpNumber = false;
      }
    };
    $scope.onSelectSpouseCurrentClient = (i) => {
      if (i.value !== '') {
        if (i.value === 'yes') {
          $scope.model.spouseCurrentClient = true;
        } else {
          $scope.model.spouseCurrentClient = false;
        }

        $scope.spouseEditable(i);
      }
    };
    $scope.onSelectPacenet = (i) => {
      if (i.value === 'PACENET of PA') {
        $scope.model.prescriptionPacenetPA = true;
      } else {
        $scope.model.prescriptionPacenetPA = false;
      }
    };
    $scope.onSelectPrescription = (i) => {
      populateCurrentPrescriptionData(i);
    };

    $scope.onPrescriptionSelected = (d) => {
      const count = d.value;
      if ($scope.model.intakeSheetAdd.prescriptions_taken.value === '') {
        $scope.model.prescriptionsTaken = false;
      } else {
        $scope.model.prescriptionsTakeFields = [];
        for (let i = 1; i <= count; i++) {
          $scope.model.prescriptionsTakeFields.push({ id: i });
        }
        $scope.model.prescriptionsTaken = true;
      }
    };
    $scope.onManagerApproval = (i) => {
      if (i.value === 'yes') {
        $scope.model.approvedInd = true;
      } else {
        $scope.model.approvedInd = false;
      }
    };

    $scope.loadAgencyData = () => {
      LoaderService.show();
      if ($stateParams.type === 'add') {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/getFormData`, 'POST', {}, (response) => {
          $scope.addAgencyForm(response);
          $scope.$watch('model.intakeSheetAdd.approved_by_manager', () => {
            if (typeof $scope.model.intakeSheetAdd.approved_by_manager !== 'undefined') {
              if ($scope.model.intakeSheetAdd.approved_by_manager.value === 'yes') {
                $scope.model.approvedInd = true;
              } else {
                $scope.model.approvedInd = false;
              }
            }
          });
        });
      } else if ($scope.model.individualID == null) {
        if ($stateParams.type === 'listingArchive') {
          getIndividualResults(1);
        } else {
          AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/getFormData`, 'POST', {}, (response) => {
            $scope.addAgencyForm(response);
          });
          getIndividualResults(1);
        }
      } else {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/get/${$scope.model.individualID}`,
          'POST',
          $scope.model.addForm,
          (response) => {
            $scope.addAgencyForm(response);
            $scope.editIndividual(response);
          },
        );
      }
    };

    if ($stateParams.type !== 'add') {
      /*LOAD DATA*/
      if (typeof $stateParams.individualID !== 'undefined') {
        $scope.model.individualID = $stateParams.individualID;
        $scope.individualID = $stateParams.individualID;
        $rootScope.individualID = $stateParams.individualID;
      }
    }

    $scope.loadAgencyData();

    $scope.addAgencyForm = (response) => {
      LoaderService.hide();
      $rootScope.constants.DB = response.data;
      $scope.model.addForm.individual_type_id = response.data.individualTypes[0];
    };
    $scope.onStateChange = (i) => {
      $scope.model.counties = $rootScope.constants.DB.counties[i.id];
    };
    $scope.openLeadDatepicker = () => {
      $scope.model.leadDatePicker = true;
    };
    $scope.setListing = (res) => {
      $scope.model.listingData = res.data.individualData;
      LoaderService.hide();
    };

    $scope.showHideSpouseSection = (v) => {
      if (v.value === 'Married') {
        $scope.model.showSpouseSection = true;
      } else {
        $scope.model.showSpouseSection = false;
      }
    };

    $scope.checkCostObjectExists = (o) => {
      if (typeof o !== 'undefined' && o !== '') {
        o = o.toString();
        o = o.replace(/(\$)/, '');
        o = o.replace(/(\,)/, '');
        o = o.replace(/(\,)/, '');
        o = o.replace(/(\,)/, '');
        o = o.replace(/(\,)/, '');
        o = o.replace(/(\,)/, '');
        return o;
      }
      return '';
    };
    $scope.submitAddForm = (closeForm, nextStep) => {
      if (!$scope.formValidated($scope.model.addForm)) {
        ConfirmBoxService.open('Error', 'Highlighted Input Fields Are Required!', {
          OK: () => {},
        });
        return false;
      }
      const dataObject = {
        userData: $scope.model.addForm,
        intakeData: $scope.model.intakeSheetAdd,
        physicians: [],
        prescriptions: [],
        prescriptionsData: $scope.model.prescriptionsTakeData,
        additionalPrescriptionsData: $scope.model.additionalPrescriptionsTakeData,
      };
      dataObject.userData.date_of_birth = dateFormatterYMD(dataObject.userData.date_of_birth);
      dataObject.userData.lead_date = dateFormatterYMD(dataObject.userData.lead_date);
      if (dataObject.userData.email === '' || $scope.model.dropdown.emailProviders.searchText === '') {
        dataObject.userData.email = '';
      } else {
        dataObject.userData.email = `${dataObject.userData.email}@${$scope.model.dropdown.emailProviders.searchText}`;
      }
      dataObject.userData = HelpersService.assignDropDownValues(dataObject.userData);

      dataObject.intakeData.current_cost = $scope.checkCostObjectExists(dataObject.intakeData.current_cost);
      dataObject.intakeData.spouse_current_cost = $scope.checkCostObjectExists(
        dataObject.intakeData.spouse_current_cost,
      );

      dataObject.intakeData.deceased_date = dateFormatterYMD(dataObject.intakeData.deceased_date);
      dataObject.intakeData.intake_date = dateFormatterYMD(dataObject.intakeData.intake_date);
      dataObject.intakeData.part_a_eff_date = dateFormatterYMD(dataObject.intakeData.part_a_eff_date);
      dataObject.intakeData.part_b_eff_date = dateFormatterYMD(dataObject.intakeData.part_b_eff_date);
      dataObject.intakeData.spouse_date_of_birth = dateFormatterYMD(dataObject.intakeData.spouse_date_of_birth);
      dataObject.intakeData.spouse_part_a_eff_date = dateFormatterYMD(dataObject.intakeData.spouse_part_a_eff_date);
      dataObject.intakeData.spouse_part_b_eff_date = dateFormatterYMD(dataObject.intakeData.spouse_part_b_eff_date);
      dataObject.intakeData.spouse_ss_disability_date = dateFormatterYMD(
        dataObject.intakeData.spouse_ss_disability_date,
      );
      dataObject.intakeData.ss_disability_date = dateFormatterYMD(dataObject.intakeData.ss_disability_date);
      if (dataObject.intakeData.current_prescription_drug_plan != null) {
        if (typeof dataObject.intakeData.current_prescription_drug_plan.value !== 'undefined') {
          dataObject.intakeData.current_prescription_drug_plan =
            dataObject.intakeData.current_prescription_drug_plan.value;
        }
      }
      dataObject.intakeData = HelpersService.assignDropDownValues(dataObject.intakeData);

      /*PHYSICIANS*/
      if ($scope.model.primaryPhysician != null && typeof $scope.model.primaryPhysician.first_name !== 'undefined') {
        if (typeof $scope.model.primaryPhysician === 'undefined') {
        } else if (
          typeof $scope.model.primaryPhysician.suffix === 'undefined' ||
          $scope.model.primaryPhysician.suffix !== ''
        ) {
          //return false;
        } else {
          dataObject.physicians.push(HelpersService.assignDropDownValues($scope.model.primaryPhysician, 'value'));
          if (dataObject.physicians[0].suffix !== '') {
            dataObject.physicians[0].suffix = HelpersService.ucfirst(dataObject.physicians[0].suffix);
          }
          dataObject.physicians[0].is_primary = 'yes';
          dataObject.physicians[0].individual_id = dataObject.userData.id;
        }
      }

      /*SPECIALISTS*/
      if ($scope.model.primaryPhysicians != null) {
        for (const i in $scope.model.primaryPhysicians) {
          if (
            typeof $scope.model.primaryPhysicians[i].first_name !== '' &&
            typeof $scope.model.primaryPhysicians[i].first_name !== 'undefined'
          ) {
            const d = HelpersService.assignDropDownValues($scope.model.primaryPhysicians[i], 'value');
            if (d.suffix === '' || typeof d.suffix === 'undefined') {
              //return false;
            } else {
              if (d.suffix !== '') {
                d.suffix = HelpersService.ucfirst(d.suffix);
              }
              d.is_primary = 'no';
              d.individual_id = dataObject.userData.id;
              dataObject.physicians.push(d);
            }
          }
        }
      }
      /*prescriptionsData*/
      if (dataObject.prescriptionsData != null) {
        for (const i in dataObject.prescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.prescriptionsData[i]);
          if (d.suffix === '' && typeof d.suffix === 'undefined') {
            break;
          }
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'no',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }
      if (dataObject.additionalPrescriptionsData != null) {
        for (const i in dataObject.additionalPrescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.additionalPrescriptionsData[i]);
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'yes',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }

      if (
        dataObject.intakeData.appointment_date_time !== '' &&
        typeof dataObject.intakeData.appointment_date_time !== 'undefined'
      ) {
        dataObject.intakeData.appointment_date_time = new Date(dataObject.intakeData.appointment_date_time);
        const dt = dataObject.intakeData.appointment_date_time;
        const newDateTime = `${dateFormatterYMD(dt)} ${dt.getHours()}:${dt.getMinutes()}:00`;
        dataObject.intakeData.appointment_date_time = newDateTime;
      }
      LoaderService.show();

      if (dataObject.userData.newsletter_subscription !== true) {
        dataObject.userData.newsletter_subscription = false;
      }

      if (dataObject.userData.primary_address !== true) {
        dataObject.userData.primary_address = false;
      }

      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/add`, 'POST', dataObject, (response) => {
        if (closeForm === true) {
          $state.go('/individuals');
        } else {
          if (nextStep === true) {
            $rootScope.gotoNextStep = true;
            window.location = `#/individuals/edit/${response.data.individual_id}`;
          } else {
            ConfirmBoxService.open('Alert', 'Information has been Addedd successfully!', {
              OK: () => {
                window.location = `#/individuals/edit/${response.data.individual_id}`;
              },
            });
          }
        }

        LoaderService.hide();
      });
    };

    $scope.editIndividual = (res) => {
      $rootScope.constants.DB.carriers = res.data.carriers;
      $rootScope.constants.DB.medical_specialties = res.data.medical_specialties;
      $rootScope.constants.dropdowns.drugsInfo.drugs = res.data.ndc_drugs;

      $scope.model.editForm = res.data.individualData;
      $scope.model.clonedData = angular.copy(res.data);

      if ($scope.model.editForm.newsletter_subscription === 'true') {
        $scope.model.editForm.newsletter_subscription = true;
      }
      $scope.model.editForm.date_of_birth =
        res.data.individualData.date_of_birth === 'null' || res.data.individualData.date_of_birth == null
          ? ''
          : new Date(res.data.individualData.date_of_birth);
      $scope.model.editForm.lead_date =
        res.data.individualData.lead_date === 'null' || res.data.individualData.lead_date == null
          ? ''
          : new Date(res.data.individualData.lead_date);

      $scope.showHideSpouseSection($scope.model.editForm.martial_status);

      if ($scope.model.editForm.date_of_birth) {
        const birthday = $scope.model.editForm.date_of_birth;
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        const c = Math.abs(ageDate.getUTCFullYear() - 1970);
        $scope.model.editForm.age = c;
      }
      if ($scope.model.editForm.state_id) {
        $scope.onStateChange($scope.model.editForm.state_id);
      }
      $scope.model.intakeSheetAdd = res.data.individual_intake;

      /*EMAIL FORMATTING*/
      if ($scope.model.editForm.email !== '') {
        const emailAddress = $scope.model.editForm.email;
        const attrs = emailAddress.split('@');
        $scope.model.dropdown.emailProviders.searchText = attrs[1];
        $scope.model.editForm.email = attrs[0];
      }

      $scope.model.intakeSheetAdd.appointment_date_time =
        $scope.model.intakeSheetAdd.appointment_date_time === 'null' ||
        $scope.model.intakeSheetAdd.appointment_date_time == null ||
        $scope.model.intakeSheetAdd.appointment_date_time === ''
          ? ''
          : new Date($scope.model.intakeSheetAdd.appointment_date_time);

      $scope.model.intakeSheetAdd.deceased_date =
        $scope.model.intakeSheetAdd.deceased_date === 'null' ||
        $scope.model.intakeSheetAdd.deceased_date == null ||
        $scope.model.intakeSheetAdd.deceased_date === '0000-00-00'
          ? ''
          : new Date($scope.model.intakeSheetAdd.deceased_date);
      $scope.model.intakeSheetAdd.intake_date =
        $scope.model.intakeSheetAdd.intake_date === 'null' ||
        $scope.model.intakeSheetAdd.intake_date == null ||
        $scope.model.intakeSheetAdd.intake_date === ''
          ? ''
          : new Date($scope.model.intakeSheetAdd.intake_date);
      $scope.model.intakeSheetAdd.part_a_eff_date =
        $scope.model.intakeSheetAdd.part_a_eff_date === 'null' || $scope.model.intakeSheetAdd.part_a_eff_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.part_a_eff_date);
      $scope.model.intakeSheetAdd.part_b_eff_date =
        $scope.model.intakeSheetAdd.part_b_eff_date === 'null' || $scope.model.intakeSheetAdd.part_b_eff_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.part_b_eff_date);
      $scope.model.intakeSheetAdd.ss_disability_date =
        $scope.model.intakeSheetAdd.ss_disability_date === 'null' ||
        $scope.model.intakeSheetAdd.ss_disability_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.ss_disability_date);

      $scope.model.intakeSheetAdd.spouse_part_a_eff_date =
        $scope.model.intakeSheetAdd.spouse_part_a_eff_date === 'null' ||
        $scope.model.intakeSheetAdd.spouse_part_a_eff_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.spouse_part_a_eff_date);
      $scope.model.intakeSheetAdd.spouse_part_b_eff_date =
        $scope.model.intakeSheetAdd.spouse_part_b_eff_date === 'null' ||
        $scope.model.intakeSheetAdd.spouse_part_b_eff_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.spouse_part_b_eff_date);
      $scope.model.intakeSheetAdd.spouse_ss_disability_date =
        $scope.model.intakeSheetAdd.spouse_ss_disability_date === 'null' ||
        $scope.model.intakeSheetAdd.spouse_ss_disability_date == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.spouse_ss_disability_date);
      $scope.model.intakeSheetAdd.spouse_date_of_birth =
        $scope.model.intakeSheetAdd.spouse_date_of_birth === 'null' ||
        $scope.model.intakeSheetAdd.spouse_date_of_birth == null
          ? ''
          : new Date($scope.model.intakeSheetAdd.spouse_date_of_birth);

      $scope.model.intakeSheetAdd.spouse_age = $scope.onChangeDOB(
        false,
        $scope.model.intakeSheetAdd.spouse_date_of_birth,
      );

      if (typeof res.data.spouseData !== 'undefined') {
        $scope.model.spouseData = res.data.spouseData;
        $scope.model.spouseData.date_of_birth =
          res.data.spouseData.date_of_birth === 'null' || res.data.spouseData.date_of_birth == null
            ? ''
            : new Date(res.data.spouseData.date_of_birth);
        if ($scope.model.spouseData.date_of_birth) {
          const birthday = $scope.model.spouseData.date_of_birth;
          const ageDifMs = Date.now() - birthday.getTime();
          const ageDate = new Date(ageDifMs); // miliseconds from epoch
          const c = Math.abs(ageDate.getUTCFullYear() - 1970);
          $scope.model.spouseData.age = c;
        }

        $scope.model.spouseData.part_a_eff_date =
          $scope.model.spouseData.part_a_eff_date === 'null' || $scope.model.spouseData.part_a_eff_date == null
            ? ''
            : new Date($scope.model.spouseData.part_a_eff_date);
        $scope.model.spouseData.part_b_eff_date =
          $scope.model.spouseData.part_b_eff_date === 'null' || $scope.model.spouseData.part_b_eff_date == null
            ? ''
            : new Date($scope.model.spouseData.part_b_eff_date);
        $scope.model.spouseData.ss_disability_date =
          $scope.model.spouseData.ss_disability_date === 'null' || $scope.model.spouseData.ss_disability_date == null
            ? ''
            : new Date($scope.model.spouseData.ss_disability_date);
        $scope.model.spouseData.date_of_birth =
          $scope.model.spouseData.date_of_birth === 'null' || $scope.model.spouseData.date_of_birth == null
            ? ''
            : new Date($scope.model.spouseData.date_of_birth);
      }

      if ($scope.model.intakeSheetAdd.spouse_current_client != null) {
        if (typeof $scope.model.intakeSheetAdd.spouse_current_client.value !== 'undefined') {
          if ($scope.model.intakeSheetAdd.spouse_current_client.value === 'yes') {
            $scope.model.spouseCurrentClient = true;
          }
        }
      }

      if ($scope.model.intakeSheetAdd.arp_member != null) {
        $scope.changeAARPMember($scope.model.intakeSheetAdd.arp_member);
      }
      if ($scope.model.intakeSheetAdd.additional_drug_help != null) {
        $scope.onSelectPacenet($scope.model.intakeSheetAdd.additional_drug_help);
      }
      if ($scope.model.intakeSheetAdd.prescriptions_taken != null) {
        $scope.onPrescriptionSelected($scope.model.intakeSheetAdd.prescriptions_taken);
      }
      if ($scope.model.intakeSheetAdd.spouse_date_of_birth) {
        const birthday = $scope.model.intakeSheetAdd.spouse_date_of_birth;
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        const c = Math.abs(ageDate.getUTCFullYear() - 1970);
        $scope.model.intakeSheetAdd.spouse_age = c;
      }

      if ($scope.model.intakeSheetAdd.approved_by_manager != null) {
        if (
          $scope.model.approvedInd.manager_approval_date !== '' &&
          $scope.model.intakeSheetAdd.approved_by_manager.value === 'yes'
        ) {
          $scope.model.approvedDateTime = $scope.model.intakeSheetAdd.manager_approval_date;
          $scope.model.approvedInd = true;
        }
      }
      if ($scope.model.intakeSheetAdd.current_ins_co != null) {
        for (const i in $rootScope.constants.dropdowns.currentInsurance) {
          if ($rootScope.constants.dropdowns.currentInsurance[i].value === $scope.model.intakeSheetAdd.current_ins_co) {
            $scope.model.intakeSheetAdd.current_ins_co = $rootScope.constants.dropdowns.currentInsurance[i];
            break;
          }
        }
      }
      if ($scope.model.intakeSheetAdd.spouse_current_ins_co != null) {
        for (const i in $rootScope.constants.dropdowns.currentInsurance) {
          if (
            $rootScope.constants.dropdowns.currentInsurance[i].value ===
            $scope.model.intakeSheetAdd.spouse_current_ins_co
          ) {
            $scope.model.intakeSheetAdd.spouse_current_ins_co = $rootScope.constants.dropdowns.currentInsurance[i];
            break;
          }
        }
      }

      if ($scope.model.intakeSheetAdd.arp_member === 'yes') {
        $scope.model.aarpNumber = true;
      }

      if ($scope.model.intakeSheetAdd.current_prescription_drug_plan != null) {
        const _organization = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'organization',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const _code = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'code',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const _monthly_premium = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'monthly_premium',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const title = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'title',
          $rootScope.constants.DB.prescription_drug_plan,
        );
        const id = HelpersService.getValueByKey(
          Number($scope.model.intakeSheetAdd.current_prescription_drug_plan),
          'id',
          'id',
          $rootScope.constants.DB.prescription_drug_plan,
        );

        $scope.model.intakeSheetAdd.current_prescription_drug_plan = {
          value: Number(id),
          title: title,
        };
        populateCurrentPrescriptionData($scope.model.intakeSheetAdd.current_prescription_drug_plan);
      }

      if (res.data.primary_physicians.length !== 0) {
        let count = 0;
        for (const i in res.data.primary_physicians) {
          const phy = res.data.primary_physicians[i];
          if (phy.is_primary === 'yes') {
            $scope.model.primaryPhysician.suffix = {
              value: phy.suffix,
              title: phy.suffix,
            };
            $scope.model.primaryPhysician.first_name = phy.first_name;
            $scope.model.primaryPhysician.last_name = phy.last_name;
            $scope.model.primaryPhysician.speciality = {
              value: phy.speciality,
              title: phy.speciality,
            };
          } else {
            if (count === 0) {
              $scope.model.primaryPhysicians = [];
              $scope.model.primaryPhysiciansCount = 0;
            }
            $scope.model.primaryPhysiciansCount++;
            const speciality = HelpersService.getValueByKey(
              Number(phy.speciality),
              'value',
              'title',
              $rootScope.constants.DB.medical_specialties,
            );
            const pData = {
              suffix: { value: phy.suffix, title: phy.suffix },
              first_name: phy.first_name,
              last_name: phy.last_name,
              speciality: { value: phy.speciality, title: speciality },
            };
            $scope.model.primaryPhysicians.push(pData);
            count++;
          }
        }

        $scope.model.primaryPhysicians = res.data.primary_physicians;
      }
      if (res.data.specialists.length !== 0) {
        $scope.model.specialists = res.data.specialists;
      }

      if (res.data.prescriptions.length !== 0) {
        let presIndex = 0;
        let additionalPresIndex = 0;
        for (const i in res.data.prescriptions) {
          const p = res.data.prescriptions[i];

          if (p.is_additional_prescription === 'yes') {
            if (typeof $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] === 'undefined') {
              $scope.model.additionalPrescriptionsTakeData[additionalPresIndex] = {};
            }
            if (additionalPresIndex === 0) {
              $scope.model.additionalprescriptionsFields = [];
            }
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].drug_name = p.drug_name;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].dosage = p.dosage;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].frequency = p.frequency;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].quantity = p.quantity;
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].prescribing_doctor = {
              value: p.prescribing_doctor,
              title: p.prescribing_doctor,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.additionalPrescriptionsTakeData[additionalPresIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            additionalPresIndex++;
            $scope.model.additionalprescriptionsFields.push({
              id: additionalPresIndex,
              value: additionalPresIndex,
            });
          } else {
            if (typeof $scope.model.prescriptionsTakeData[presIndex] === 'undefined') {
              $scope.model.prescriptionsTakeData[presIndex] = {};
            }
            $scope.model.prescriptionsTakeData[presIndex].drug_name = p.drug_name;
            $scope.model.prescriptionsTakeData[presIndex].dosage = {
              title: p.dosage,
              value: p.dosage,
            };

            if (typeof $scope.model.dropdown.drugDosage.dosages.dataArray === 'undefined') {
              $scope.model.dropdown.drugDosage.dosages.dataArray = [];
            }
            $scope.model.dropdown.drugDosage.dosages.dataArray[presIndex] = p.dosageOptions;

            $scope.model.prescriptionsTakeData[presIndex].frequency = p.frequency;
            $scope.model.prescriptionsTakeData[presIndex].quantity = p.quantity;
            $scope.model.prescriptionsTakeData[presIndex].prescribing_doctor = {
              value: p.prescribing_doctor,
              title: p.prescribing_doctor,
            };
            $scope.model.prescriptionsTakeData[presIndex].reason = {
              value: p.reason,
              title: p.reason,
            };
            $scope.model.prescriptionsTakeData[presIndex].taking_for_how_long = {
              value: p.taking_for_how_long,
              title: p.taking_for_how_long,
            };
            presIndex++;
          }
        }
      }

      LoaderService.hide();
    };

    const populateCurrentPrescriptionData = (d) => {
      const organization = HelpersService.getValueByKey(
        d.value,
        'id',
        'organization',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const code = HelpersService.getValueByKey(d.value, 'id', 'code', $rootScope.constants.DB.prescription_drug_plan);
      const monthly_premium = HelpersService.getValueByKey(
        d.value,
        'id',
        'monthly_premium',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const _title = HelpersService.getValueByKey(
        d.value,
        'id',
        'title',
        $rootScope.constants.DB.prescription_drug_plan,
      );
      const _id = HelpersService.getValueByKey(d.value, 'id', 'id', $rootScope.constants.DB.prescription_drug_plan);
      $scope.model.currentPrescriptionData.organization = organization;
      $scope.model.currentPrescriptionData.code = code;
      $scope.model.currentPrescriptionData.monthly_premium = monthly_premium;
    };

    const dateFormatterYMD = (date) => {
      if (date === 'null' || date == null || date === '') {
        return false;
      }
      date = new Date(date);
      return `${date.getFullYear()}-${
        Number(date.getMonth()) + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }-${Number(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate()}`;
    };

    $scope.fieldValidated = (v) => {
      let validated = true;
      if (v === '' || typeof v === 'undefined' || v == null) {
        validated = false;
      }
      return validated;
    };

    $scope.formValidated = (formData) => {
      let validated = true;
      const _intakeData = $scope.model.intakeSheetAdd;
      if (!$scope.fieldValidated(formData.first_name)) {
        FocusHighlightService.show('first_name');
        validated = false;
      } else {
        FocusHighlightService.hide('first_name');
      }

      if (!$scope.fieldValidated(formData.last_name)) {
        FocusHighlightService.show('last_name');
        validated = false;
      } else {
        FocusHighlightService.hide('last_name');
      }

      if (!$scope.fieldValidated(formData.date_of_birth)) {
        FocusHighlightService.show('date_of_birth');
        validated = false;
      } else {
        FocusHighlightService.hide('date_of_birth');
      }

      if (
        !$scope.fieldValidated(formData.home_phone) &&
        !$scope.fieldValidated(formData.business_phone) &&
        !$scope.fieldValidated(formData.cell_phone)
      ) {
        FocusHighlightService.show('phone-section');
        validated = false;
      } else {
        FocusHighlightService.hide('phone-section');
      }

      /*if(!$scope.fieldValidated(formData.email)){
     FocusHighlightService.show('email');
     validated = false;
     } else {
     FocusHighlightService.hide('email');
     }*/

      /*if ($scope.model.showSpouseSection === true) {

     if (!$scope.fieldValidated(intakeData.spouse_first_name)) {
     FocusHighlightService.show('spouse_first_name');
     validated = false;
     } else {
     FocusHighlightService.hide('spouse_first_name');
     }

     if (!$scope.fieldValidated(intakeData.spouse_last_name)) {
     FocusHighlightService.show('spouse_last_name');
     validated = false;
     } else {
     FocusHighlightService.hide('spouse_last_name');
     }

     if (!$scope.fieldValidated(intakeData.spouse_date_of_birth)) {
     FocusHighlightService.show('spouse_date_of_birth');
     validated = false;
     } else {
     FocusHighlightService.hide('spouse_date_of_birth');
     }
     }*/

      return validated;
    };

    $scope.submitEditForm = (closeForm, nextStep) => {
      if (!$scope.formValidated($scope.model.editForm)) {
        ConfirmBoxService.open('Error', 'Highlighted Input Fields Are Required!', {
          OK: () => {},
        });
        return false;
      }

      const dataObject = {
        userData: $scope.model.editForm,
        spouseData: $scope.model.spouseData,
        intakeData: $scope.model.intakeSheetAdd,
        physicians: [],
        prescriptions: [],
        prescriptionsData: $scope.model.prescriptionsTakeData,
        additionalPrescriptionsData: $scope.model.additionalPrescriptionsTakeData,
      };

      dataObject.intakeData.current_cost = $scope.checkCostObjectExists(dataObject.intakeData.current_cost);
      dataObject.intakeData.spouse_current_cost = $scope.checkCostObjectExists(
        dataObject.intakeData.spouse_current_cost,
      );

      dataObject.userData.date_of_birth = dateFormatterYMD(dataObject.userData.date_of_birth);
      dataObject.userData.lead_date = dateFormatterYMD(dataObject.userData.lead_date);
      if (dataObject.userData.email === '' || $scope.model.dropdown.emailProviders.searchText === '') {
        dataObject.userData.email = '';
      } else {
        dataObject.userData.email = `${dataObject.userData.email}@${$scope.model.dropdown.emailProviders.searchText}`;
      }
      dataObject.userData = HelpersService.assignDropDownValues(dataObject.userData);

      dataObject.intakeData.deceased_date = dateFormatterYMD(dataObject.intakeData.deceased_date);
      dataObject.intakeData.intake_date = dateFormatterYMD(dataObject.intakeData.intake_date);
      dataObject.intakeData.part_a_eff_date = dateFormatterYMD(dataObject.intakeData.part_a_eff_date);
      dataObject.intakeData.part_b_eff_date = dateFormatterYMD(dataObject.intakeData.part_b_eff_date);
      dataObject.intakeData.ss_disability_date = dateFormatterYMD(dataObject.intakeData.ss_disability_date);

      /*SPOUSE*/
      dataObject.spouseData.date_of_birth = dateFormatterYMD(dataObject.spouseData.date_of_birth);
      dataObject.spouseData.part_a_eff_date = dateFormatterYMD(dataObject.spouseData.part_a_eff_date);
      dataObject.spouseData.part_b_eff_date = dateFormatterYMD(dataObject.spouseData.part_b_eff_date);
      dataObject.spouseData.ss_disability_date = dateFormatterYMD(dataObject.spouseData.ss_disability_date);
      dataObject.spouseData = HelpersService.assignDropDownValues(dataObject.spouseData);
      dataObject.spouseData.current_cost = $scope.checkCostObjectExists(dataObject.spouseData.current_cost);
      if (dataObject.intakeData.current_prescription_drug_plan != null) {
        if (typeof dataObject.intakeData.current_prescription_drug_plan.value !== 'undefined') {
          dataObject.intakeData.current_prescription_drug_plan =
            dataObject.intakeData.current_prescription_drug_plan.value;
        }
      }
      dataObject.intakeData = HelpersService.assignDropDownValues(dataObject.intakeData);

      /*PHYSICIANS*/
      if ($scope.model.primaryPhysician != null && typeof $scope.model.primaryPhysician.first_name !== 'undefined') {
        if (typeof $scope.model.primaryPhysician === 'undefined') {
        } else if (typeof $scope.model.primaryPhysician === 'undefined') {
          //return false;
        } else {
          dataObject.physicians.push(HelpersService.assignDropDownValues($scope.model.primaryPhysician, 'value'));
          dataObject.physicians[0].suffix = HelpersService.ucfirst(dataObject.physicians[0].suffix);
          dataObject.physicians[0].is_primary = 'yes';
          dataObject.physicians[0].individual_id = dataObject.userData.id;
        }
      }

      /*SPECIALISTS*/
      if ($scope.model.primaryPhysicians != null) {
        for (const i in $scope.model.primaryPhysicians) {
          if (
            typeof $scope.model.primaryPhysicians[i].first_name !== '' &&
            typeof $scope.model.primaryPhysicians[i].first_name !== 'undefined'
          ) {
            const d = HelpersService.assignDropDownValues($scope.model.primaryPhysicians[i], 'value');
            if (d.suffix === '' || typeof d.suffix === 'undefined') {
              //return false;
            } else {
              d.suffix = HelpersService.ucfirst(d.suffix);
              d.is_primary = 'no';
              d.individual_id = dataObject.userData.id;
              dataObject.physicians.push(d);
            }
          }
        }
      }
      /*prescriptionsData*/
      if (dataObject.prescriptionsData != null) {
        for (const i in dataObject.prescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.prescriptionsData[i]);
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'no',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }
      if (dataObject.additionalPrescriptionsData != null) {
        for (const i in dataObject.additionalPrescriptionsData) {
          const d = HelpersService.assignDropDownValues(dataObject.additionalPrescriptionsData[i]);
          dataObject.prescriptions.push({
            individual_id: dataObject.userData.id,
            is_additional_prescription: 'yes',
            drug_name: d.drug_name,
            dosage: d.dosage,
            quantity: d.quantity,
            frequency: d.frequency,
            prescribing_doctor: d.prescribing_doctor,
            reason: d.reason,
            taking_for_how_long: d.taking_for_how_long,
          });
        }
      }

      LoaderService.show();
      if (
        dataObject.intakeData.appointment_date_time !== '' &&
        typeof dataObject.intakeData.appointment_date_time !== 'undefined'
      ) {
        dataObject.intakeData.appointment_date_time = new Date(dataObject.intakeData.appointment_date_time);
        const dt = dataObject.intakeData.appointment_date_time;
        const newDateTime = `${dateFormatterYMD(dt)} ${dt.getHours()}:${dt.getMinutes()}:00`;
        dataObject.intakeData.appointment_date_time = newDateTime;
      }

      if (dataObject.userData.newsletter_subscription !== true) {
        dataObject.userData.newsletter_subscription = false;
      }

      if (dataObject.userData.primary_address !== true) {
        dataObject.userData.primary_address = false;
      }

      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}individual/update/${$scope.model.editForm.id}`,
        'POST',
        dataObject,
        (_response) => {
          if (closeForm === true) {
            $state.go('/individuals');
          } else {
            if (nextStep === true) {
              $scope.model.wizard.activeTabClass = 'intake';
              $timeout(() => {
                $rootScope.gotoNextStep = true;
                $state.reload();
              }, 500);
            } else {
              if ($scope.model.wizard.activeTabClass === 'intake') {
                $rootScope.gotoNextStep = true;
              }
              $state.reload();
              ConfirmBoxService.open('Alert', 'Information has been Updated successfully!', {
                OK: () => {},
              });
            }
          }
          LoaderService.hide();
        },
        () => {
          $state.go('/individuals');
        },
      );
    };
    $scope.deleteIndividual = (i) => {
      const userData = AuthService.getAuthServiceData().userData;
      ConfirmBoxService.prompt(
        'Confirmation',
        'Administrative password is required to delete this individual!',
        {
          Confirm: (model) => {
            if (model.password === '' || typeof model.password === 'undefined') {
              FocusService('password');
              return false;
            }
            const postData = {};
            postData.password = model.password;
            postData.individualID = $scope.model.listingData[i].id;
            postData.email = userData.email;
            AuthService.sendRequest(
              `${$rootScope.api05DomainPath}individual/delete/${userData.id}`,
              'POST',
              postData,
              (response) => {
                if (response.data.status === '200') {
                  ConfirmBoxService.close();
                  $scope.model.listingData.splice(i, 1);
                } else {
                  $rootScope.confirmBox.description = 'Invalid Password!';
                }
              },
            );
            return false;
          },
          Cancel: () => {},
        },
        [
          {
            title: 'Password',
            placeholder: 'Password',
            modelName: 'password',
            type: 'password',
          },
        ],
      );
    };

    const physiciansCheck = (c) => {
      let a = [];
      a = (() => $scope.model.primaryPhysicians.slice())();
      a.push($scope.model.primaryPhysician);

      for (const i in $scope.model.prescriptionsTakeData) {
        let notMatched = false;
        const selectedPhysicians = $scope.model.prescriptionsTakeData[i];
        const selectedPhysicianName = selectedPhysicians.prescribing_doctor.title;
        for (const k in a) {
          const physicianData = a[k];
          const physicianName = `${physicianData.suffix.title} ${physicianData.first_name} ${physicianData.last_name}`;
          if (physicianName === selectedPhysicianName) {
            notMatched = true;
          }
        }
        if (notMatched === false) {
          $scope.model.prescriptionsTakeData[i].prescribing_doctor = {
            title: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
            value: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
          };
        }
      }
      for (const i in $scope.model.additionalPrescriptionsTakeData) {
        let notMatched = false;
        const selectedPhysicians = $scope.model.additionalPrescriptionsTakeData[i];
        const selectedPhysicianName = selectedPhysicians.prescribing_doctor.title;
        for (const k in a) {
          const physicianData = a[k];
          const physicianName = `${physicianData.suffix.title} ${physicianData.first_name} ${physicianData.last_name}`;
          if (physicianName === selectedPhysicianName) {
            notMatched = true;
          }
        }
        if (notMatched === false) {
          $scope.model.additionalPrescriptionsTakeData[i].prescribing_doctor = {
            title: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
            value: `${c.suffix.title} ${c.first_name} ${c.last_name}`,
          };
        }
      }
    };
    $scope.changePrimaryPhysiciansCallBack = (a, _b, _c) => {
      physiciansCheck(a);
    };

    /*AUTO COMPLETE*/
    const selfObj = $scope;

    /*emailProviders*/
    selfObj.model.dropdown.emailProviders.simulateQuery = false;
    selfObj.model.dropdown.emailProviders.isDisabled = false;
    selfObj.model.dropdown.emailProviders.repos = (() => {
      const repos = $rootScope.constants.dropdowns.emailProviders;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.emailProviders.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.emailProviders.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.emailProviders.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.emailProviders.selectedItemChange = () => {};
    selfObj.model.dropdown.emailProviders.searchTextChange = (_a, _k) => {};

    /*specialistSpeciality*/
    selfObj.model.dropdown.specialistSpeciality.simulateQuery = false;
    selfObj.model.dropdown.specialistSpeciality.isDisabled = false;
    selfObj.model.dropdown.specialistSpeciality.repos = (() => {
      const repos = $rootScope.constants.dropdowns.specialistSpeciality;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.specialistSpeciality.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.specialistSpeciality.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.specialistSpeciality.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.specialistSpeciality.selectedItemChange = () => {};
    selfObj.model.dropdown.specialistSpeciality.searchTextChange = () => {};

    /*physicianSuffix*/
    selfObj.model.dropdown.physicianSuffix.simulateQuery = false;
    selfObj.model.dropdown.physicianSuffix.isDisabled = false;
    selfObj.model.dropdown.physicianSuffix.repos = (() => {
      const repos = $rootScope.constants.dropdowns.specialistSuffix;
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    })();
    selfObj.model.dropdown.physicianSuffix.querySearch = (query) => {
      const results = query
        ? selfObj.model.dropdown.physicianSuffix.repos.filter(createFilterFor(query))
        : selfObj.model.dropdown.physicianSuffix.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.physicianSuffix.selectedItemChange = () => {};
    selfObj.model.dropdown.physicianSuffix.searchTextChange = () => {};

    /*DRUG NAMES*/
    selfObj.model.dropdown.drugNames.simulateQuery = false;
    selfObj.model.dropdown.drugNames.isDisabled = false;
    selfObj.model.dropdown.drugNames.repos = loadAll();
    selfObj.model.dropdown.drugNames.querySearch = querySearch;
    selfObj.model.dropdown.drugNames.selectedItemChange = selectedItemChange;
    selfObj.model.dropdown.drugNames.searchTextChange = (_a, k) => {
      if (typeof $scope.model.prescriptionsTakeData[k] === 'undefined') {
        $scope.model.prescriptionsTakeData[k] = { dosage: '' };
      } else if (typeof $scope.model.prescriptionsTakeData[k].dosage === 'undefined') {
        $scope.model.prescriptionsTakeData[k].dosage = '';
      } else {
        $scope.model.prescriptionsTakeData[k].dosage = '';
      }
    };
    const self = selfObj.model.dropdown.drugNames;

    /*DRUG DOSAGE*/
    selfObj.model.dropdown.drugDosage.simulateQuery = false;
    selfObj.model.dropdown.drugDosage.isDisabled = false;
    selfObj.model.dropdown.drugDosage.querySearch = (query, k) => {
      const results = query
        ? selfDrugDosage.dosages.dataArray[k].filter(createFilterFor(query))
        : selfDrugDosage.dosages.dataArray[k];
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    };
    selfObj.model.dropdown.drugDosage.selectedItemChange = () => {};
    selfObj.model.dropdown.drugDosage.searchTextChange = searchTextChange;
    let selfDrugDosage = selfObj.model.dropdown.drugDosage;
    selfObj.model.dropdown.drugDosage.dosages = [];
    selfDrugDosage = selfObj.model.dropdown.drugDosage;

    function querySearch(query) {
      const results = query ? self.repos.filter(createFilterFor(query)) : self.repos;
      let deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(
          () => {
            deferred.resolve(results);
          },
          Math.random() * 1000,
          false,
        );
        return deferred.promise;
      }
      return results;
    }

    function searchTextChange(_text) {
      //$log.info('Text changed to ' + text);
    }

    function selectedItemChange(d, k) {
      if (typeof d !== 'undefined') {
        AuthService.sendRequest(`${$rootScope.api05DomainPath}getDosagesDetails`, 'POST', d, (response) => {
          if (response.data.status === 200) {
            if (typeof selfObj.model.dropdown.drugDosage.dosages.dataArray === 'undefined') {
              selfObj.model.dropdown.drugDosage.dosages.dataArray = [];
            }
            selfObj.model.dropdown.drugDosage.dosages.dataArray[k] = response.data.ndc_dosages;
            $scope.model.prescriptionsTakeData[k].dosage = selfObj.model.dropdown.drugDosage.dosages.dataArray[k][0];
          }
          //$scope.model.prescriptionsTakeData[k].frequency = response.data.ndc_dosages;
        });
      }

      return false;

      selfObj.model.dropdown.drugDosage.dosages.dataArray[k] = loadAllDosage(d);
      $scope.model.prescriptionsTakeData[k].frequency = $rootScope.constants.dropdowns.drugsInfo.frequency[d.id];
    }

    function loadAll() {
      const repos = (() => {
        const d = $rootScope.constants.dropdowns.drugsInfo.drugs;
        return d;
      })();
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    }

    function createFilterFor(query) {
      const lowercaseQuery = angular.lowercase(query);
      return function filterFn(item) {
        return item.value.indexOf(lowercaseQuery) === 0;
      };
    }

    function loadAllDosage(a) {
      const repos = ((data) => {
        const d = $rootScope.constants.dropdowns.drugsInfo.dosage[data.id];
        const dArray = [];
        for (const k in d) {
          dArray.push({ title: d[k].title, id: d[k].value });
        }
        return dArray;
      })(a);
      return repos.map((repo) => {
        repo.value = repo.title.toLowerCase();
        return repo;
      });
    }

    /*UPDATE SPECIALIST UNDER PRESCRIPTION SECTION*/
    for (const i in $scope.model.primaryPhysician) {
      ((index) => {
        $scope.$watch(`model.primaryPhysician.${index}`, (_a, _b, _c) => {
          const _flag = false;
          if (
            $scope.model.primaryPhysician.suffix === '' ||
            typeof $scope.model.primaryPhysician.suffix === 'undefined'
          ) {
            //return false;
          } else {
            $rootScope.constants.dropdowns.selectedPhysicians[0] = {
              value: `${$scope.model.primaryPhysician.suffix.title} ${$scope.model.primaryPhysician.first_name} ${$scope.model.primaryPhysician.last_name}`,
              title: `${$scope.model.primaryPhysician.suffix.title} ${$scope.model.primaryPhysician.first_name} ${$scope.model.primaryPhysician.last_name}`,
            };
          }
        });
      })(i);
    }

    $scope.$watch('model.primaryPhysiciansCount', () => {
      let index = 0;
      for (const i in $scope.model.primaryPhysicians) {
        index++;
        for (const k in $scope.model.primaryPhysicians[i]) {
          ((index, k, i) => {
            $scope.$watch(`model.primaryPhysicians[${i}].${k}`, (_a, _b, _c) => {
              const _flag = false;
              if (
                $scope.model.primaryPhysicians[i].suffix.title === '' ||
                typeof $scope.model.primaryPhysicians[i].suffix.title === 'undefined'
              ) {
                //return false;
              } else {
                $rootScope.constants.dropdowns.selectedPhysicians[index] = {
                  value: `${$scope.model.primaryPhysicians[i].suffix.title} ${$scope.model.primaryPhysicians[i].first_name} ${$scope.model.primaryPhysicians[i].last_name}`,
                  title: `${$scope.model.primaryPhysicians[i].suffix.title} ${$scope.model.primaryPhysicians[i].first_name} ${$scope.model.primaryPhysicians[i].last_name}`,
                };
              }
            });
          })(index, k, i);
        }
      }
    });

    const _tim = setTimeout(() => {
      $scope.model.primaryPhysiciansCount++;
    }, 500);
    /*$scope.$watch('model.primaryPhysicians', function() {
   console.log($scope.model.primaryPhysicians);
   });*/

    //Search Individuals in dropdown
    $scope.refreshIndividuals = (search) => {
      if (search !== '') {
        const params = { q: search, individual_id: $scope.model.individualID };
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}individual/searchIndividuals`,
          'POST',
          params,
          (response) => {
            $rootScope.constants.DB.individuals = response.data.individuals;
          },
        );
      }
    };

    //Pagination work for Individial Active and Archive
    $scope.pageChanged = (newPage) => {
      if ($scope.first_request !== true) {
        getIndividualResults(newPage);
      } else {
        $scope.first_request = false;
      }
    };

    //Search Individuals in Listing Page
    $scope.searchIndividual = () => {
      getIndividualResults(1);
      $scope.model.paginationCurrent = 1;
    };

    //Filter Individuals in Listing page
    $scope.change_filter = () => {
      getIndividualResults(1);
    };

    //Delete doctor
    $scope.deleteDoctor = (item, type) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this doctor?', {
        Yes: () => {
          const params = {
            npi: item.npi,
            individual_id: $scope.model.individualID,
          };
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}individual/delete-doctor`,
            'POST',
            params,
            (response) => {
              if (response.data.result.success === true) {
                if (type === 'specialist') {
                  const index = $scope.model.specialists.indexOf(item);
                  $scope.model.specialists.splice(index, 1);
                } else if (type === 'primary_physicians') {
                  const index = $scope.model.primaryPhysicians.indexOf(item);
                  $scope.model.primaryPhysicians.splice(index, 1);
                }
              }
              alert(response.data.result.message);
            },
          );
        },
        No: () => {},
      });
    };

    function getIndividualResults(pageNumber, _sorting) {
      const params = { page: pageNumber, filter: $scope.model.listingFilter };
      if ($stateParams.type === 'listingArchive') {
        params.status = 'Deleted';
      }
      if ($scope.model.searchText !== '') {
        params.search = $scope.model.searchText;
      }
      //if(sorting === true){
      const activeColumn = $scope.model.sorting.active;
      const ascDesc = $scope.model.sorting[$scope.model.sorting.active];
      params.column = activeColumn;
      params.columnOrder = ascDesc;
      //}

      AuthService.sendRequest(`${$rootScope.api05DomainPath}individual/get`, 'POST', params, (response) => {
        $scope.model.listingData = response.data.individualData;
        $scope.model.listingTotal = response.data.individualCount;
        $scope.model.listingPerPage = response.data.individualPerPage;
      });
    }
  },
];
