// helpers.js

import { api05Subdomain, api11Subdomain, devApi11Domain } from './constants.js';

/**
 * Determines if a given domain name indicates a development environment.
 *
 * The function checks if the domain is exactly "localhost" or if the last segment
 * of the domain (after splitting by ".") is "dev" or "local". This is intended to
 * identify common patterns in domain names used for development environments.
 *
 * @param {any} domain - The domain name to check, which will be treated as a string.
 * @returns {boolean} - Returns true if the domain is associated with a development
 *                      environment, false otherwise.
 */
function isDevEnv(domain) {
  // Convert domain to a string to ensure compatibility with string methods
  const domainStr = String(domain);

  // Check for "localhost" immediately
  if (domainStr === 'localhost') {
    return true;
  }

  // Proceed with checks if the domain contains a period
  if (domainStr.includes('.')) {
    const domainArray = domainStr.split('.');
    const domainArrayLength = domainArray.length;

    // Check the last segment for "dev" or "local"
    if (domainArray[domainArrayLength - 1] === 'dev' || domainArray[domainArrayLength - 1] === 'local') {
      return true;
    }
  }

  // If none of the conditions above match, the environment is not development
  return false;
}

/**
 * Retrieves the current domain from the browser's address bar.
 *
 * @returns {string} The domain of the current website.
 */
function getCurrentDomain() {
  return window.location.hostname;
}

/**
 * Extracts the domain (including subdomains) from a given URL. This function handles URLs with or without protocols
 * and port numbers efficiently, using the URL API for parsing when possible. For environments where the URL might
 * not start with a protocol (e.g., lacking 'http://' or 'https://'), it prepends a dummy protocol to ensure
 * successful parsing. This approach ensures accurate domain extraction across a wide range of URL formats.
 *
 * @param {string} url - The URL from which to extract the domain, which can include protocols, subdomains, and port numbers.
 * @returns {string} The domain, including any subdomains, extracted from the URL. Protocols and port numbers are stripped out.
 *
 * @example
 * // Returns 'www.example.com'
 * extractDomain('http://www.example.com/page');
 *
 * @example
 * // Returns 'localhost'
 * extractDomain('localhost:8080/page');
 *
 * @example
 * // Returns 'subdomain.example.com'
 * extractDomain('https://subdomain.example.com:3000');
 */
function extractDomain(url) {
  try {
    // Attempt to use the URL API for parsing
    return new URL(url).hostname;
  } catch (e) {
    // Fallback for cases where URL does not start with a protocol
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return extractDomain(`http://${url}`);
    }
    throw e; // Re-throw unexpected errors
  }
}

/**
 * Extracts the domain from a given hostname. If the hostname is 'localhost',
 * it directly returns 'localhost'. Otherwise, it splits the hostname by dots
 * and returns the last two segments as the domain. This function is useful
 * for operations that require the domain portion of a URL or hostname,
 * especially handling both development (localhost) and production environments.
 *
 * @param {string} hostname - The full hostname from which to extract the domain.
 * This can be a simple domain, a domain with subdomains, or 'localhost' for
 * local development scenarios.
 * @returns {string} The extracted domain as a string. Returns 'localhost' if
 * the input hostname is 'localhost', otherwise returns the last two segments
 * of the hostname joined by a dot, which represents the domain.
 *
 * @example
 * // Returns 'example.com'
 * extractDomainTopLevel('www.example.com');
 *
 * @example
 * // Returns 'localhost'
 * extractDomainTopLevel('localhost');
 *
 * @example
 * // Returns 'example.com'
 * extractDomainTopLevel('sub.domain.example.com');
 */
function extractDomainTopLevel(hostname) {
  // Checks if the hostname is 'localhost', otherwise extracts the domain
  return hostname.includes('localhost') ? 'localhost' : hostname.split('.').slice(-2).join('.');
}

/**
 * Replaces the lowest subdomain in a given domain with a specified subdomain.
 * If the provided domain has no subdomain, the function returns false.
 *
 * @param {string} domain - The full domain name where the lowest subdomain will be replaced.
 * @param {string} newSubdomain - The subdomain to replace the lowest subdomain with.
 * @returns {string|boolean} The modified domain with the new subdomain, or false if the provided domain has no subdomain.
 *
 * @example
 * // Returns 'api05.dev.msds.dev'
 * swapLowestSubdomain('crm.dev.msds.dev', 'api05');
 *
 * @example
 * // Returns false
 * swapLowestSubdomain('msds.dev', 'api05');
 */
function swapLowestSubdomain(domain, newSubdomain) {
  const parts = domain.split('.');

  // Check if the domain has more than two parts (i.e., has a subdomain)
  if (parts.length > 2) {
    // Replace the lowest subdomain (first part) with the new subdomain
    parts[0] = newSubdomain;
    return parts.join('.');
  }

  // Return false if there is no subdomain
  return false;
}

function getApi05Domain() {
  const currentDomain = getCurrentDomain();
  // check if the environment is development
  if (extractDomain(currentDomain) === 'localhost') {
    // normally we'd use devApi05Domain but CORS Preflight throws a fit if not using the same domain
    return 'localhost:3000/api';
  }
  // use the swap function to replace the lowest subdomain
  return swapLowestSubdomain(currentDomain, api05Subdomain);
}

function getApi11Domain() {
  const currentDomain = getCurrentDomain();
  // check if the environment is development
  if (extractDomain(currentDomain) === 'localhost') {
    return devApi11Domain;
  }
  // use the swap function to replace the lowest subdomain
  return swapLowestSubdomain(currentDomain, api11Subdomain);
}

// Creating an object to hold the function(s) to be exported
const helpers = {
  isDevEnv: isDevEnv,
  getCurrentDomain: getCurrentDomain,
  extractDomain: extractDomain,
  extractDomainTopLevel: extractDomainTopLevel,
  swapLowestSubdomain: swapLowestSubdomain,
  getApi05Domain: getApi05Domain,
  getApi11Domain: getApi11Domain,
};

// Default export of the helpers object
export default helpers;
