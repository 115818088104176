export default class numberTextColor {
  constructor() {
    this.restrict = 'A';
    this.require = '?ngModel'; // Make ngModel controller optional
  }

  link(scope, element, _attrs, ngModel) {
    // Define a function to update the color based on the value
    const updateColor = (val) => {
      // Check if the value is a number
      if (!isNaN(Number.parseFloat(val)) && isFinite(val)) {
        // Change text color based on positivity or negativity
        if (Number.parseFloat(val) >= 0) {
          element.css('color', 'green');
        } else {
          element.css('color', 'red');
        }
      } else {
        // Reset text color if not a number
        element.css('color', '');
      }
    };

    // Watch for changes in the ngModel value
    scope.$watch(
      () => ngModel.$viewValue,
      (newValue) => {
        updateColor(newValue);
      },
    );

    // Initialize color based on initial value
    updateColor(ngModel.$viewValue);
  }

  static factory() {
    return new numberTextColor();
  }
}

// Dependency Injection
numberTextColor.factory.$inject = [];
