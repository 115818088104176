import angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-chart.js';
import 'angular-chosen-localytics';
import 'angular-cookies';
import 'angular-datatables';
import 'angular-material';
import 'angular-messages';
import 'angular-moment';
import 'angular-route';
import 'angular-touch';
import 'angular-ui-bootstrap';
import 'angular-ui-mask';
import 'angular-ui-router';
import 'angular-utils-pagination';
import moment from 'moment-timezone';
import 'ng-img-crop/compile/minified/ng-img-crop';
import 'ng-material-datetimepicker';
import 'ui-select';

// import 'https://cdn.jsdelivr.net/npm/angular-animate@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-aria@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-chart.js@1.1/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-chosen-localytics@1.9/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-cookies@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-datatables@0.6/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-material@1.2/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-messages@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-moment@0.10/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-route@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-touch@1.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-ui-bootstrap@2.5/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-ui-mask@1.8/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-ui-router@0.3/+esm';
// import 'https://cdn.jsdelivr.net/npm/angular-utils-pagination@0.11/+esm';
// import 'https://cdn.jsdelivr.net/npm/ng-material-datetimepicker@1.20.0/+esm';
// import 'https://cdn.jsdelivr.net/npm/ui-select@0.20.0/+esm'
// import 'ng-img-crop/compile/minified/ng-img-crop';

import { AppComponent } from './app/app.component';
// import ComponentsModule from './app/components/components';
import appControllers from './controllers/_index';
import appDirectives from './directives/_index';
import appFilters from './filters/_index';
// import PagesModule from './pages/pages.js';
import routesConfig from './routes/_routes.config';
import runtimeConfig from './runtime.config';
import ServicesModule from './services/_services.module';

// // Define the authInterceptor
// function authInterceptor(_$rootScope, $q, $window) {
//   return {
//     request: (config) => {
//       config.headers = config.headers || {};
//       const token = $window.localStorage.getItem('authToken');
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//       return config;
//     },
//     responseError: (response) => {
//       if (response.status === 401) {
//         // Redirect to login page, etc.
//         // $state.go('login'); // Inject $state or use $window.location.href to redirect
//       }
//       return $q.reject(response);
//     },
//   };
// }

// authInterceptor.$inject = ['$rootScope', '$q', '$window']; // Dependency injection for authInterceptor

const appModule = angular
  .module('appModule', [
    'angularUtils.directives.dirPagination',
    'ngRoute',
    'ngCookies', // removed, replaced by localStorage
    'ui.router',
    // 'oc.lazyLoad',
    'ui.bootstrap',
    'angularMoment',
    'chart.js',
    'ui.mask',
    'ui.select',
    'localytics.directives',
    'ngAnimate',
    'ngTouch',
    'ngAria',
    'ngMessages',
    'ngMaterial',
    'ngImgCrop',
    'ngMaterialDatePicker',
    'datatables',
    // PagesModule,
    // ComponentsModule,
    ServicesModule,
  ])
  // these get added in the future if/when we move away to a more modern approach
  // .factory('authInterceptor', authInterceptor)
  // .config([
  //   '$httpProvider',
  //   ($httpProvider) => {
  //     $httpProvider.interceptors.push('authInterceptor');
  //   },
  // ])
  .constant('moment', moment)
  .config(routesConfig)
  .run(runtimeConfig)
  .component('app', AppComponent);

// Register each controller
for (const controllerName of Object.keys(appControllers)) {
  appModule.controller(controllerName, appControllers[controllerName]);
}
// Register each filter
for (const filterName of Object.keys(appFilters)) {
  appModule.filter(filterName, appFilters[filterName]);
}

// Register each directive
for (const directiveName of Object.keys(appDirectives)) {
  appModule.directive(directiveName, appDirectives[directiveName].factory);
}

export default appModule;
