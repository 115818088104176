export default class excelFileReader {
  constructor($parse, LoaderService, ConfirmBoxService) {
    this.restrict = 'A'; // restrict the directive to be used as an attribute
    this.$parse = $parse;
    this.LoaderService = LoaderService;
    this.ConfirmBoxService = ConfirmBoxService;
  }

  link(scope, element, attrs) {
    this.LoaderService.show();

    const _model = this.$parse(attrs.excelFileReader);

    function keysToLowerCase(obj) {
      Object.keys(obj).forEach((key) => {
        const k = key
          .replace(/([^a-z0-9A-Z ]+)/g, '')
          .replace(/ /g, '_')
          .toLowerCase();
        if (k !== key) {
          obj[k] = obj[key];
          delete obj[key];
        }
      });
      return obj;
    }

    const ExcelToJSON = (event) => {
      const input = event.target;
      const reader = new FileReader();
      let sheetsCount = 0;
      let sheetsIterate = 0;
      const data = {};
      reader.onload = () => {
        const fileData = reader.result;
        const wb = XLSX.read(fileData, { type: 'binary' });

        sheetsCount = wb.SheetNames.length;
        for (const sheetName of wb.SheetNames) {
          sheetsIterate++;
          const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);

          for (const i in rowObj) {
            rowObj[i] = keysToLowerCase(rowObj[i]);
          }

          data[sheetName.replace(' ', '_').toLowerCase()] = rowObj;
          if (sheetsIterate === sheetsCount) {
            this.LoaderService.hide();
            scope.$apply(() => {
              scope.$eval(attrs.excelFileReader, { data, fileName: input.files[0].name });
            });
            event.target.value = null;
          }
        }
      };
      reader.readAsBinaryString(input.files[0]);
    };

    element.bind('change', (evt) => {
      let ext = evt.target.files[0].name.split('.');
      ext = ext[ext.length - 1];
      if (ext !== 'xls' && ext !== 'xlsx') {
        this.ConfirmBoxService.open('Error', 'Invalid File Format.', {
          OK: () => {},
        });
        evt.target.value = null;
        scope.$apply();
        return false;
      }
      this.LoaderService.show();
      const _file = evt.currentTarget.files;
      scope.$apply(() => {
        scope.$eval(attrs.ngModel + ' = $file', { $file: _file });
      });
      ExcelToJSON(evt);
    });
  }

  static factory($parse, LoaderService, ConfirmBoxService) {
    return new excelFileReader($parse, LoaderService, ConfirmBoxService);
  }
}

// Dependency Injection
excelFileReader.factory.$inject = ['$parse', 'LoaderService', 'ConfirmBoxService'];
