class HelpersService {
  constructor($rootScope, moment) {
    this.$rootScope = $rootScope;
    this.moment = moment;
  }

  convertTimeZone(d) {
    if (d === '' || typeof d === 'undefined' || d == null || d === '0000-00-00' || d === '0000-00-00 00:00:00') {
      return '';
    }
    return this.moment(d).toDate();
  }

  insertObjectIntoArray(array, index, objectToBeAdded) {
    const dataArray = [];
    array.forEach((item, i) => {
      if (i === index) dataArray.push(objectToBeAdded);
      dataArray.push(item);
    });
    return dataArray;
  }

  emptyElement(e) {
    $(e).html('');
  }

  assignDropDownValues(obj, t) {
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (typeof t !== 'undefined') {
          obj[key] = obj[key][t];
        } else if (typeof obj[key].value !== 'undefined') {
          obj[key] = obj[key].value;
        } else if (typeof obj[key].id !== 'undefined') {
          obj[key] = obj[key].id;
        }
      }
    }
    return obj;
  }

  mergeData(objArray) {
    const d = {};
    for (const o of objArray) {
      for (const b of Object.keys(o)) {
        if (typeof o[b] === 'object' && o[b] !== null) {
          if (typeof o[b].title !== 'undefined') {
            d[b] = o[b].title;
          } else {
            const mydate = new Date(o[b]);
            d[b] = `${mydate.getMonth() + 1}/${mydate.getDate()}/${mydate.getFullYear()}`;
          }
        } else {
          d[b] = o[b];
        }
      }
    }
    return d;
  }

  getValueByKey(valueToSearch, keyToSerch, keyToGetValue, object) {
    let d = '';
    for (const item of object) {
      if (item[keyToSerch] === valueToSearch) {
        d = item[keyToGetValue];
      }
    }
    return d;
  }

  nullToEmptyValue(object) {
    for (const key of Object.keys(object)) {
      if (object[key] == null) {
        object[key] = '';
      }
    }
    return object;
  }

  ucfirst(str) {
    if (!str) return '';
    return str
      .split(' ')
      .map((part) => part[0].toUpperCase() + part.slice(1))
      .join(' ');
  }

  dateTimeFormatYYYYMMDD(d) {
    return this.moment(d).format('YYYY-MM-DD HH:mm:ss');
  }

  dateFormatYYYYMMDD(d) {
    return this.moment(d).format('YYYY-MM-DD');
  }

  dateFormatMMDDYYYY(d) {
    return this.moment(d).format('MM-DD-YYYY');
  }

  getObjectByValue(valueToSearch, keyToSerch, array) {
    return array.find((item) => item[keyToSerch] === valueToSearch) || '';
  }

  roundNumber(value, exp) {
    return Number(`${Math.round(`${value}e${exp}`)}e-${exp}`);
  }

  mergeObject(object1, Object2) {
    return { ...object1, ...Object2 };
  }

  amountFormatter(amount, curr) {
    if (typeof amount !== 'undefined' && amount !== '' && amount != null) {
      let val = amount
        .toString()
        .replace(/([a-zA-Z]+)/, '')
        .replace(/^(\$)/, '');
      val = Number.isNaN(Number.parseFloat(val)) ? '0.00' : Number.parseFloat(val).toFixed(2);
      return curr + val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return `${curr}0.00`;
  }

  cleanNumber(v) {
    return Number.parseFloat(v.toString().replace(/([^0-9-.]+)/g, '')) || 0;
  }
}

HelpersService.$inject = ['$rootScope', 'moment'];

export default HelpersService;
