import constants from '../utils/constants';
const carrierRoutes = ($stateProvider) => {
  $stateProvider
    .state('/carriers', {
      url: '/carriers',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}carrier/carriersListing.html?v=${constants.randomNumber}`,
          controller: 'CarriersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/carrier/add', {
      url: '/carrier/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}carrier/carrierAdd.html?v=${constants.randomNumber}`,
          controller: 'CarriersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/carrier/add-coverage', {
      url: '/carrier/add-coverage',
      params: {
        type: 'add-coverage',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}carrier/carrierAddCoverage.html?v=${constants.randomNumber}`,
          controller: 'CarriersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/carrier/:carrierID/edit', {
      url: '/carrier/:carrierID/edit',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}carrier/carrierEdit.html?v=${constants.randomNumber}`,
          controller: 'CarriersController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    });
};

export default carrierRoutes;
