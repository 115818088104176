import AuthService from './auth.service';
import ConfirmBoxService from './confirm-box.service';
import ConvertStrDateToObjectService from './convert-str-date-to-object.service';
import CustomGridService from './custom-grid.service';
import DateFormattingService from './date-formatting.service';
import ErrorReadableService from './error-readable.service';
import FocusHighlightService from './focus-highlights.service';
import FocusService from './focus.service';
import HelpersService from './helpers.service';
import IndividualInfoService from './individual-info.service';
import IndividualRecentActivityService from './individual-recent-activity.service';
import LoaderService from './loader.service';
import MaxNumbersService from './max-numbers.service';
import MomentDateService from './moment-date.service';
import MomentService from './moment.service';
import NavigationService from './navigation.service';
import ValidationService from './validation.service';

const ServicesModule = angular
  .module('services', [])
  .service('NavigationService', NavigationService)
  .service('HelpersService', HelpersService)
  .service('LoaderService', LoaderService)
  .service('FocusService', FocusService)
  .service('FocusHighlightService', FocusHighlightService)
  .service('ConfirmBoxService', ConfirmBoxService)
  .service('MaxNumbersService', MaxNumbersService)
  .service('MomentDateService', MomentDateService)
  .service('MomentService', MomentService)
  .service('ErrorReadableService', ErrorReadableService)
  .service('DateFormattingService', DateFormattingService)
  .service('IndividualInfoService', IndividualInfoService)
  .service('ConvertStrDateToObjectService', ConvertStrDateToObjectService)
  .service('ValidationService', ValidationService)
  .service('IndividualRecentActivityService', IndividualRecentActivityService)
  .service('CustomGridService', CustomGridService)
  .service('AuthService', AuthService).name;

export default ServicesModule;
