export default class highlightParentNavItem {
  constructor() {
    this.restrict = 'A';
  }

  link(scope, element, _attrs) {
    scope.$watch(
      () => {
        const children = element.children().children().children();
        return Array.from(children).some((child) => child.querySelector('.active'));
      },
      (newValue) => {
        const parent = element.children().children()[0];
        angular.element(parent).toggleClass('active', newValue);
      },
    );
  }

  static factory() {
    return new highlightParentNavItem();
  }
}

// Dependency Injection
highlightParentNavItem.factory.$inject = [];
