class ErrorReadableService {
  constructor($sce) {
    this.$sce = $sce;
  }

  toHtml(messages) {
    let messageHtml = '';
    for (let i = 0; i < messages.length; i++) {
      messageHtml += `${messages[i]}.<br>`;
    }
    return this.$sce.trustAsHtml(messageHtml);
  }
}

ErrorReadableService.$inject = ['$sce']; // Dependency Injection

export default ErrorReadableService;
