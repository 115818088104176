class ConvertStrDateToObjectService {
  constructor(moment) {
    this.moment = moment;
  }

  setDateFieldsDate(data, isArray) {
    const dataaa = isArray ? [] : {};
    for (const i in data) {
      if (typeof data[i] === 'object') {
        if (Array.isArray(data[i])) {
          dataaa[i] = this.setDateFieldsDate(data[i], true);
        } else {
          dataaa[i] = this.setDateFieldsDate(data[i], false);
        }
      } else {
        const str = data[i].toString();
        const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})/g;
        if (regex.test(str)) {
          if (str === '0000-00-00 00:00:00' || str === '0000-00-00') {
            dataaa[i] = null;
          } else {
            dataaa[i] = this.moment(str).toDate();
          }
        } else {
          dataaa[i] = data[i];
        }
      }
    }
    return dataaa;
  }

  init(d) {
    return this.setDateFieldsDate(d, false);
  }
}

ConvertStrDateToObjectService.$inject = ['moment'];

export default ConvertStrDateToObjectService;
