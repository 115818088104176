import moment from 'https://cdn.jsdelivr.net/npm/moment@2.30.1/+esm';

export default function msfpDateFormatter() {
  return (input, format) => {
    // if (!input) return input;

    // Replace custom format strings with moment.js equivalents
    format = format.replace(/y{1,4}|M{1,4}|d{1,2}|E{1,4}|H{1,2}|h{1,2}|m{1,2}|s{1,3}|a|Z|w{1,2}|G{1,4}/g, (match) => {
      switch (match) {
        case 'yyyy':
          return 'YYYY';
        case 'yy':
          return 'YY';
        case 'y':
          return 'YYYY'; // Moment does not support single-digit year; custom handling may be needed
        case 'MMMM':
          return 'MMMM';
        case 'MMM':
          return 'MMM';
        case 'MM':
          return 'MM';
        case 'M':
          return 'M';
        case 'LLLL':
          return 'MMMM'; // Stand-alone month; Moment treats it similar to 'MMMM'
        case 'dd':
          return 'DD';
        case 'd':
          return 'D';
        case 'EEEE':
          return 'dddd';
        case 'EEE':
          return 'ddd';
        case 'HH':
          return 'HH';
        case 'H':
          return 'H';
        case 'hh':
          return 'hh';
        case 'h':
          return 'h';
        case 'mm':
          return 'mm';
        case 'm':
          return 'm';
        case 'ss':
          return 'ss';
        case 's':
          return 's';
        case 'sss':
          return 'SSS';
        case 'a':
          return 'A';
        case 'Z':
          return 'ZZ';
        case 'ww':
          return 'WW';
        case 'w':
          return 'W';
        case 'G':
        case 'GG':
        case 'GGG':
        case 'GGGG':
          return 'GGGG'; // Moment uses 'GGGG' for the extended era format
        default:
          return match;
      }
    });

    // Handle predefined localizable formats
    switch (format) {
      case 'medium':
        format = 'MMM D, YYYY h:mm:ss A';
        break;
      case 'short':
        format = 'M/D/YY h:mm A';
        break;
      case 'fullDate':
        format = 'dddd, MMMM D, YYYY';
        break;
      case 'longDate':
        format = 'MMMM D, YYYY';
        break;
      case 'mediumDate':
        format = 'MMM D, YYYY';
        break;
      case 'shortDate':
        format = 'M/D/YY';
        break;
      case 'mediumTime':
        format = 'h:mm:ss A';
        break;
      case 'shortTime':
        format = 'h:mm A';
        break;
    }

    // Create moment object from input and format it
    return moment(input).format(format);
  };
}
