import constants from '../utils/constants';
const supportRoutes = ($stateProvider) => {
  $stateProvider
    .state('/support-ticket/add/:individualID/:supportTicketID', {
      url: '/support-ticket/add/:individualID/:supportTicketID',
      params: {
        type: 'add_modify_st',
        individualID: { squash: true, value: null },
        supportTicketID: { squash: true, value: null },
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}support_ticket/supportTicketAdd.html?v=${constants.randomNumber}`,
          controller: 'SupportTicketController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/individuals/:individualID/support-ticket/view', {
      url: '/individuals/:individualID/support-ticket/view',
      params: {
        type: 'view_st_individual',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}support_ticket/supportTicketListing.html?v=${constants.randomNumber}`,
          controller: 'SupportTicketController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/support-ticket/view/agent/:agentID?', {
      url: '/support-ticket/view/agent/:agentID?',
      params: {
        type: 'view_st_agent',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}support_ticket/supportTicketListingAgent.html?v=${constants.randomNumber}`,
          controller: 'SupportTicketController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/support-ticket/thread/:supportTicketID/:agentID', {
      url: '/support-ticket/thread/:supportTicketID/:agentID',
      params: {
        type: 'thread_st',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}support_ticket/supportTicketThread.html?v=${constants.randomNumber}`,
          controller: 'SupportTicketController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/support-ticket/view/admin/', {
      url: '/support-ticket/view/admin/',
      params: {
        type: 'view_st_admin',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}support_ticket/supportTicketListingAdmin.html?v=${constants.randomNumber}`,
          controller: 'SupportTicketController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    });
};

export default supportRoutes;
