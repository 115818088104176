import constants from '../utils/constants';
const leadsRoutes = ($stateProvider) => {
  $stateProvider
    .state('/leads', {
      url: '/leads',
      params: {
        type: 'listingPage',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}leads/leadsListing.html?v=${constants.randomNumber}`,
          controller: 'LeadsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/leads/add/:leadID?', {
      url: '/leads/add/:leadID?',
      params: {
        type: 'add_lead',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}leads/leadsAdd.html?v=${constants.randomNumber}`,
          controller: 'LeadsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    });
};

export default leadsRoutes;
