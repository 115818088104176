class FocusHighlightService {
  constructor($timeout, $window) {
    this.$timeout = $timeout;
    this.$window = $window;
  }

  show(id) {
    this.$timeout(() => {
      const element = this.$window.document.getElementById(id);
      if (element) {
        $(element).addClass('has-error');
        element.focus();
      }
    });
  }

  hide(id) {
    this.$timeout(() => {
      const element = this.$window.document.getElementById(id);
      if (element) {
        $(element).removeClass('has-error');
      }
    });
  }
}

FocusHighlightService.$inject = ['$timeout', '$window'];

export default FocusHighlightService;
