export default class ngRequired {
  constructor() {
    this.restrict = 'A';
    this.require = '^form';
  }

  link(scope, _el, _attrs, formCtrl) {
    scope.$on('show-errors-check-validity', () => {
      console.log(formCtrl);
      /*el.toggleClass('has-error', formCtrl[inputName].$invalid);*/
    });
  }

  static factory() {
    return new ngRequired();
  }
}

// Dependency Injection
ngRequired.factory.$inject = [];
