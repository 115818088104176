class ConfirmBoxService {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
  }

  open(heading, description, actions) {
    // this.$rootScope.confirmBox = {
    //   heading: heading,
    //   description: description,
    //   actions: actions,
    //   open: true,
    //   fields: [],
    // };
    this.$rootScope.confirmBox.heading = heading;
    this.$rootScope.confirmBox.description = description;
    this.$rootScope.confirmBox.actions = actions;
    this.$rootScope.confirmBox.open = true;
    this.$rootScope.confirmBox.fields = [];
  }

  prompt(heading, description, actions, fields) {
    //  this.$rootScope.confirmBox = {
    //    heading: heading,
    //    description: description,
    //    actions: actions,
    //    open: true,
    //    fields: fields,
    //    click: () => {}
    //  };
    this.$rootScope.confirmBox.heading = heading;
    this.$rootScope.confirmBox.description = description;
    this.$rootScope.confirmBox.actions = actions;
    this.$rootScope.confirmBox.open = true;
    this.$rootScope.confirmBox.fields = fields;
  }

  form(heading, description, actions, fields) {
    // this.$rootScope.confirmBox = {
    //   heading: heading,
    //   description: description,
    //   actions: actions,
    //   open: true,
    //   fields: fields,
    //   form: true,
    // };
    this.$rootScope.confirmBox.description = description;
    this.$rootScope.confirmBox.actions = actions;
    this.$rootScope.confirmBox.open = true;
    this.$rootScope.confirmBox.fields = fields;
    this.$rootScope.confirmBox.form = true;
    this.$rootScope.confirmBox.heading = heading;
  }

  close() {
    if (this.$rootScope.confirmBox) {
      this.$rootScope.confirmBox.fields = [];
      this.$rootScope.confirmBox.open = false;
    }
  }
}

// Mark dependencies for injection
ConfirmBoxService.$inject = ['$rootScope'];

export default ConfirmBoxService;
