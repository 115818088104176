export default function amountFormatter() {
  return (amount, currencySymbol = '$') => {
    // Check if the amount is defined and not empty
    if (amount !== undefined && amount !== '' && amount !== null) {
      // Convert amount to string to safely use string methods
      let stringValue = String(amount);

      // Remove any alphabetic characters
      stringValue = stringValue.replace(/([a-zA-Z]+)/, '');

      // Ensure leading currency symbols are removed
      stringValue = stringValue.replace(/^\$/, '');

      // Return formatted currency if stringValue is empty after replacements
      if (!stringValue) {
        return `${currencySymbol}0.00`;
      }

      // Ensure two decimal places
      if (stringValue.indexOf('.') === -1) {
        stringValue += '.00';
      }

      // Split the integer and decimal parts
      let [integerPart, decimalPart] = stringValue.split('.');

      // Format the integer part with commas
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Ensure the decimal part is not longer than two digits
      decimalPart = decimalPart ? `.${decimalPart.substring(0, 2)}` : '.00';

      // Return the formatted amount prefixed with the currency symbol
      return `${currencySymbol}${integerPart}${decimalPart}`;
    }

    // Return default formatted currency if amount is not defined or invalid
    return `${currencySymbol}0.00`;
  };
}
