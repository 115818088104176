export const api05Subdomain = 'api05';
export const api11Subdomain = 'api11';

const templatesDir = 'views/';
const randomNumber = Math.floor(Math.random() * (Math.floor(99999999) - Math.ceil(100000))) + Math.ceil(100000);

export const devApi05Domain = `${api05Subdomain}.dev.msds.dev`;
export const devApi11Domain = `${api11Subdomain}.dev.msds.dev`;

export const fileCategories = [
  {
    value: '',
    title: 'None',
  },
  {
    value: 'document',
    title: 'Documents',
  },
  {
    value: 'medicareCard',
    title: 'Medicare Card',
  },
  {
    value: 'bankingInformation',
    title: 'Banking Information',
  },
  {
    value: 'idCardLicense',
    title: "ID Card & Driver's License",
  },
  {
    value: 'ritterImPlan',
    title: 'Ritter IM Plan',
  },
  {
    value: 'prescriptions',
    title: 'Prescriptions',
  },
  {
    value: 'medicarePolicy',
    title: 'Medicare Policy',
  },
  {
    value: 'others',
    title: 'Others',
  },
];

// Creating an object to hold the function(s) to be exported
const constants = {
  api05Subdomain: api05Subdomain,
  api11Subdomain: api11Subdomain,
  devApi05Domain: devApi05Domain,
  devApi11Domain: devApi11Domain,
  templatesDir: templatesDir,
  randomNumber: randomNumber,
  fileCategories: fileCategories,
};

// Default export of the helpers object
export default constants;
