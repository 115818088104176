import constants from '../utils/constants';
const medicareRoutes = ($stateProvider) => {
  $stateProvider
    .state('/individuals/:individualID/medicare-policy/view', {
      url: '/individuals/:individualID/medicare-policy/view',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}medicarePolicy/medicarePolicyListing.html?v=${constants.randomNumber}`,
          controller: 'MedicarePolicyController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/medicare-policy/add', {
      url: '/individuals/:individualID/medicare-policy/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}medicarePolicy/medicarePolicyAddEdit.html?v=${constants.randomNumber}`,
          controller: 'MedicarePolicyController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/commissions/listing', {
      url: '/commissions/listing',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}medicarePolicy/commissionsListing.html?v=${constants.randomNumber}`,
          controller: 'MedicareCommissionController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/individuals/:individualID/medicare-policy/edit/:policy_id', {
      url: '/individuals/:individualID/medicare-policy/edit/:policy_id',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}medicarePolicy/medicarePolicyAddEdit.html?v=${constants.randomNumber}`,
          controller: 'MedicarePolicyController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/policies', {
      url: '/policies',
      params: {
        type: 'policiyListing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}medicarePolicy/policyListing.html?v=${constants.randomNumber}`,
          controller: 'MedicarePolicyController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    });
};

export default medicareRoutes;
