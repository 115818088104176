export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$state',
  'ConfirmBoxService',
  'FocusService',
  function UserController($rootScope, $scope, AuthService, $state, ConfirmBoxService, FocusService) {
    $scope.model = {
      userData: $rootScope.loggedInUserData,
      formData: new FormData(),
    };

    $scope.model.userData.username = $scope.model.userData.name;

    $scope.model.userData.currentPassword = '';
    $scope.model.userData.newPassword = '';
    $scope.model.userData.newPasswordAgain = '';

    if ($scope.model.userData.service_location_id != null && $scope.model.userData.service_location_id !== '') {
      for (const i in $rootScope.constants.dropdowns.locations) {
        const d = $rootScope.constants.dropdowns.locations[i];
        if ($scope.model.userData.service_location_id === d.value) {
          $scope.model.userData.service_location_id = {
            value: $scope.model.userData.service_location_id,
            title: d.title,
          };
        }
      }
    }
    if ($rootScope.loggedInUserData.avatar === '' || $rootScope.loggedInUserData.avatar == null) {
      $scope.model.userData.avatar = `${$rootScope.domainPath}/avatar9.jpg`;
    }

    $scope.uploadFile = (f) => {
      $scope.model.formData.append('file', f);
    };
    $scope.updateFormData = () => {
      if ($scope.model.userData.name.length <= 4) {
        ConfirmBoxService.open('Error', 'Username field required atleast 5 charactres!', {
          OK: () => {
            FocusService('username');
          },
        });
      } else {
        $scope.model.formData.append(
          'username',
          `${$scope.model.userData.first_name} ${$scope.model.userData.last_name}`,
        );
        $scope.model.formData.append('email', $scope.model.userData.email);
        $scope.model.formData.append('first_name', $scope.model.userData.first_name);
        $scope.model.formData.append('last_name', $scope.model.userData.last_name);
        $scope.model.formData.append('user_id', $scope.model.userData.id);
        if ($scope.model.userData.service_location_id != null && $scope.model.userData.service_location_id !== '') {
          $scope.model.formData.append('location', $scope.model.userData.service_location_id.value);
          $rootScope.loggedInUserData.service_location_id = $scope.model.userData.service_location_id.value;
        } else {
          $scope.model.formData.append('location', '');
        }

        AuthService.uploadFile(
          `${$rootScope.api05DomainPath}admin/change-profile-info`,
          $scope.model.formData,
          (response) => {
            if (response.status === 200) {
              if (typeof response.userData.avatar !== 'undefined') {
                response.userData.avatar = $rootScope.api05DomainPath + response.userData.avatar;
              }
              $rootScope.loggedInUserData.name = `${response.userData.first_name} ${response.userData.last_name}`;
              AuthService.updateUserData(response.userData);
              ConfirmBoxService.open('Success', 'Profile has been updated successfully!', {
                OK: () => {
                  $scope.model.userData.avatarFile = '';
                },
              });
            } else {
              let errorTxt = '';
              for (const i in response.error) {
                errorTxt += `${response.error[i]}\n`;
              }
              ConfirmBoxService.open('Error', errorTxt, {
                OK: () => {
                  //FocusService('curr-pass');
                },
              });
            }
          },
        );
      }
    };

    $scope.updatePassword = () => {
      if (
        $scope.model.userData.currentPassword === '' ||
        typeof $scope.model.userData.currentPassword === 'undefined'
      ) {
        ConfirmBoxService.open('Error', 'Current password is required!', {
          OK: () => {
            FocusService('curr-pass');
          },
        });
      } else if ($scope.model.userData.newPassword === '' || typeof $scope.model.userData.newPassword === 'undefined') {
        ConfirmBoxService.open('Error', 'New password is required!', {
          OK: () => {
            FocusService('new-pass');
          },
        });
      } else if (
        $scope.model.userData.newPasswordAgain === '' ||
        typeof $scope.model.userData.newPasswordAgain === 'undefined'
      ) {
        ConfirmBoxService.open('Error', 'Please type new password again!', {
          OK: () => {
            FocusService('new-pass-again');
          },
        });
      } else if ($scope.model.userData.newPasswordAgain === $scope.model.userData.newPassword) {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}admin/change-password`,
          'POST',
          $scope.model.userData,
          (response) => {
            if (response.data.status === 200) {
              ConfirmBoxService.open('Success', 'Password has been changed successfully!', {
                LOGOUT: () => {
                  $state.go('/logout');
                },
              });
            } else {
              ConfirmBoxService.open('Error', 'Current Password is Wrong!', {
                OK: () => {
                  FocusService('curr-pass');
                },
              });
            }
          },
        );
      } else {
        ConfirmBoxService.open('Error', 'Password did not matched!', {
          OK: () => {
            FocusService('new-pass-again');
          },
        });
      }
    };
  },
];
