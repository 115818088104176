import constants from '../utils/constants';
const individualsRoutes = ($stateProvider) => {
  $stateProvider
    .state('/report/export-individuals', {
      url: '/report/export-individuals',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}reports/individuals/exportIndividuals.html?v=${constants.randomNumber}`,
          controller: 'ExportIndividualReportController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/report/export-spouse', {
      url: '/report/export-spouse',
      params: {
        type: 'spouse',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}reports/individuals/exportIndividuals.html?v=${constants.randomNumber}`,
          controller: 'ExportIndividualReportController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals', {
      url: '/individuals',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualListing.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
      },
    })
    .state('/individuals-archive', {
      url: '/individuals-archive',
      params: {
        type: 'listingArchive',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualListingArchive.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/add', {
      url: '/individuals/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualAdd.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/addNew/:ssn?', {
      url: '/individuals/addNew/:ssn?',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualAddNew.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/edit/:individualID', {
      url: '/individuals/edit/:individualID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualEdit.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/editNew/:individualID', {
      url: '/individuals/editNew/:individualID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/individualEditNew.html?v=${constants.randomNumber}`,
          controller: 'IndividualDoctorController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/documents', {
      url: '/individuals/:individualID/notes-attachment/documents',
      params: {
        type: 'listing',
        fileType: 'documents',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/notesAttachmentsListing.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/add', {
      url: '/individuals/:individualID/notes-attachment/add',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/notesAttachmentsAdd.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/edit/:attachmentID', {
      url: '/individuals/:individualID/notes-attachment/edit/:attachmentID',
      params: {
        type: 'edit_attachment',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/notesAttachmentsEdit.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/documents/termination-letter/:terminationLetterID?', {
      url: '/individuals/:individualID/notes-attachment/documents/termination-letter/:terminationLetterID?',
      params: {
        type: 'add-modify-termination-letter',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/terminationLetter.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/documents/receipt-individual/:receiptIndividualID?', {
      url: '/individuals/:individualID/notes-attachment/documents/receipt-individual/:receiptIndividualID?',
      params: {
        type: 'add-modify-receipt-individual',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/receiptIndividual.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/view/receipt-individual-print/:receiptIndividualID', {
      url: '/individuals/:individualID/notes-attachment/view/receipt-individual-print/:receiptIndividualID',
      params: {
        type: 'add-modify-receipt-individual',
        param: 'print',
        contentType: 'print-receipt-individual',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/receiptIndividualPrint.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/view/termination-letter-print/:terminationLetterID', {
      url: '/individuals/:individualID/notes-attachment/view/termination-letter-print/:terminationLetterID',
      params: {
        type: 'add-modify-termination-letter',
        param: 'print',
        contentType: 'print-termination-letter',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/terminationLetterPrint.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },

      /*DOCUMENT FILES*/
    })
    .state('/individuals/:individualID/notes-attachment/view/intake-sheet', {
      url: '/individuals/:individualID/notes-attachment/view/intake-sheet',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'print-intake-sheet',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intakeSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },

      /*PRINT THROUGH WKHTML*/
    })
    .state('/individuals/:individualID/notes-attachment/view/intake-sheet/:authKey/:token', {
      url: '/individuals/:individualID/notes-attachment/view/intake-sheet/:authKey/:token',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'export-pdf',
        authenticated: true,
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intakeSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/view/prescription-sheet', {
      url: '/individuals/:individualID/notes-attachment/view/prescription-sheet',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'print-intake-sheet',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/prescriptionSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },

      /*PRINT THROUGH WKHTML*/
    })
    .state('/individuals/:individualID/notes-attachment/view/prescription-sheet/:authKey/:token', {
      url: '/individuals/:individualID/notes-attachment/view/prescription-sheet/:authKey/:token',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'export-pdf',
        authenticated: true,
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/prescriptionSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/view/prescription-sheet-pdf', {
      url: '/individuals/:individualID/notes-attachment/view/prescription-sheet-pdf',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'export-pdf',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/prescriptionSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/notes-attachment/view/intake-sheet-pdf', {
      url: '/individuals/:individualID/notes-attachment/view/intake-sheet-pdf',
      params: {
        type: 'view',
        fileType: 'intake-sheet',
        contentType: 'export-pdf',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intakeSheet.html?v=${constants.randomNumber}`,
          controller: 'IndividualNotesAttachmentsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/intake-header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/relations-spouse/view', {
      url: '/individuals/:individualID/relations-spouse/view',
      params: {
        type: 'view-relations-spouse',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/relationsView.html?v=${constants.randomNumber}`,
          controller: 'IndividualController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/ihs/listing', {
      url: '/individuals/:individualID/ihs/listing',
      params: {
        type: 'listing',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/ihs_listing.html?v=${constants.randomNumber}`,
          controller: 'IndividualIhsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/ihs', {
      url: '/individuals/:individualID/ihs',
      params: {
        type: 'add',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/ihs.html?v=${constants.randomNumber}`,
          controller: 'IndividualIhsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    })
    .state('/individuals/:individualID/ihs/:ihsID', {
      url: '/individuals/:individualID/ihs/:ihsID',
      params: {
        type: 'edit',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}individuals/innerPages/ihs.html?v=${constants.randomNumber}`,
          controller: 'IndividualIhsController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
      },
    });
};

export default individualsRoutes;
