import constants from '../utils/constants';
const otherRoutes = ($stateProvider) => {
  $stateProvider
    .state('/snp-value-history', {
      url: '/snp-value-history',
      params: {
        type: 'view',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}snp-values.html?v=${constants.randomNumber}`,
          controller: 'SnpValuesController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    //Chart js
    .state('/charts', {
      url: '/charts',
      params: {
        type: 'view',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}charts/charts.html?v=${constants.randomNumber}`,
          controller: 'ChartsController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/property-and-casualty/:individualID/intake-form', {
      url: '/property-and-casualty/:individualID/intake-form',
      params: {
        type: 'p-and-c-intakesheet-form',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}property_and_casualty/intake-form.html?v=${constants.randomNumber}`,
          controller: 'PropertyAndCasualtyController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        individualsheader: {
          templateUrl: `${constants.templatesDir}individuals/header.html?v=${constants.randomNumber}`,
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/address-book/add/:addressBookID?', {
      url: '/address-book/add/:addressBookID?',
      params: {
        type: 'add_modify_ab',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}address_book/addressBookAdd.html?v=${constants.randomNumber}`,
          controller: 'AddressBookController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/fax/add/:faxID?', {
      url: '/fax/add/:faxID?',
      params: {
        type: 'add_modify_fax',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}fax/faxAdd.html?v=${constants.randomNumber}`,
          controller: 'FaxController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/fax/', {
      url: '/fax/',
      params: {
        type: 'view_fax',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}fax/faxListing.html?v=${constants.randomNumber}`,
          controller: 'FaxController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/address-book/view/', {
      url: '/address-book/view/',
      params: {
        type: 'view_ab',
      },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}address_book/addressBookListing.html?v=${constants.randomNumber}`,
          controller: 'AddressBookController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })

    .state('/email/newsletter/listing', {
      url: '/email/newsletter/listing',
      params: { page_type: 'listing' },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}newsletter/listing.html?v=${constants.randomNumber}`,
          controller: 'EmailNewsletterController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/email/campaign/create/:campaign_id?', {
      url: '/email/campaign/create/:campaign_id?',
      params: { page_type: 'create' },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}newsletter/create-campaign.html?v=${constants.randomNumber}`,
          controller: 'EmailNewsletterController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    })
    .state('/email/campaign/send/:campaign_id?', {
      url: '/email/campaign/send/:campaign_id?',
      params: { page_type: 'send' },
      views: {
        content: {
          templateUrl: `${constants.templatesDir}newsletter/send.html?v=${constants.randomNumber}`,
          controller: 'EmailNewsletterController',
        },
        header: {
          templateUrl: `${constants.templatesDir}layouts/header.html?v=${constants.randomNumber}`,
          controller: 'HeaderController',
        },
        navmenu: {
          templateUrl: `${constants.templatesDir}layouts/nav-menu.html`,
        },
      },
    });
};

export default otherRoutes;
