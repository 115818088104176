export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'ConfirmBoxService',
  'ValidationService',
  'IndividualInfoService',
  'HelpersService',
  'ConvertStrDateToObjectService',
  function PropertyAndCasualtyController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    ConfirmBoxService,
    ValidationService,
    IndividualInfoService,
    HelpersService,
    ConvertStrDateToObjectService,
  ) {
    $rootScope.subMenuActive = 'property-and-casualty';
    $rootScope.individualID = $stateParams.individualID;
    $scope.individualID = $stateParams.individualID;

    $scope.model = {
      attachments: [],
      formData: {
        additional_policies: {
          policies: {},
          additionalNotes: [],
        },
        automobileData: {
          automobiles: [{}],
        },
        property: {},
      },
    };

    /*BIND FORM DATA*/
    const bindIntakeSheetData = (response) => {
      const validated = ValidationService.validate(response, {
        'data.formData': {
          required: true,
          errorMessage: 'Data not found on the API Server.',
        },
        'data.modelData': {
          required: true,
          errorMessage: 'Model data not found on the API Server.',
        },
      });

      if (validated) {
        $scope.constants = response.data.formData;
        $scope.model.formData = HelpersService.mergeObject($scope.model.formData, response.data.modelData);
        $scope.model.formData = ConvertStrDateToObjectService.init($scope.model.formData);
      }
    };

    /*REQUEST ERROR HANDLER*/
    const requestErrorHandler = () => {
      ConfirmBoxService.open('API Server Error', 'Requested data not found on SERVER', {
        OK: () => {},
      });
    };

    const initialize = () => {
      //Get Individual info
      if (typeof $scope.individualID !== 'undefined' && $scope.individualID !== '') {
        IndividualInfoService.getInfo($scope.individualID, (result) => {
          $scope.model.individual_info = result;
        });
      }
      if ($stateParams.type === 'p-and-c-intakesheet-form') {
        AuthService.sendRequest(
          `${$rootScope.api05DomainPath}property-and-casualty/get/form-data`,
          'GET',
          { individual_id: $scope.individualID },
          bindIntakeSheetData,
          requestErrorHandler,
        );
      }
    };
    initialize();

    /*SCOPE METHODS*/
    $scope.addAutomobiles = () => {
      $scope.model.formData.automobileData.automobiles.push({});
    };
    $scope.populateModelsData = (d, item) => {
      if (item.make_model_id === 'Other') {
        d.addNewMake = true;
      } else {
        d.addNewMake = false;
      }
      if (typeof item.otherOption === 'undefined') {
        item.otherOption = true;
        if (typeof item.models === 'undefined') {
          item.models = [];
        }
        item.models.push({ model: 'Other', model_id: 'Other' });
      }
      d.model = '';
    };

    $scope.savePropertyAndCasualtyForm = () => {
      const validated = ValidationService.validate($scope, {
        'model.individual_info.last_name': {
          required: true,
          errorMessage: 'Please Select a Client first.',
        },
        individualID: {
          required: true,
          errorMessage: 'Please Select a Client first.',
        },
      });

      if (validated) {
        const modelData = angular.copy($scope.model.formData);
        if (modelData.additional_policies.date) {
          modelData.additional_policies.date = HelpersService.dateTimeFormatYYYYMMDD(
            modelData.additional_policies.date,
          );
        }

        if ($scope.model.formData.additional_policies.additionalNotes.length > 0) {
          for (const i in $scope.model.formData.additional_policies.additionalNotes) {
            if ($scope.model.formData.additional_policies.additionalNotes[i].notes_date) {
              modelData.additional_policies.additionalNotes[i].notes_date = HelpersService.dateTimeFormatYYYYMMDD(
                modelData.additional_policies.additionalNotes[i].notes_date,
              );
            }
          }
        }

        const formData = new FormData();
        for (const i in modelData) {
          const data = modelData[i];
          formData.append(i, JSON.stringify(data));
        }
        for (const i in $scope.model.attachments) {
          if (typeof $scope.model.attachments[i] === 'function') {
            continue;
          }
          formData.append('file[]', $scope.model.attachments[i]);
        }
        formData.append('individual_id', $scope.individualID);
        let url;
        if (typeof $scope.model.formData.pnc_main !== 'undefined') {
          url = `${$rootScope.api05DomainPath}property-and-casualty/edit-process`;
        } else {
          url = `${$rootScope.api05DomainPath}property-and-casualty/add-process`;
        }
        AuthService.uploadFile(url, formData, (response) => {
          if (response.success === true) {
            ConfirmBoxService.open('Notification', response.message, {
              OK: () => {
                $state.reload();
              },
            });
          } else {
            ConfirmBoxService.open('Error', response.message, {
              OK: () => {},
            });
          }
        });
      }
    };

    $scope.deleteAttachment = (file, index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete this file?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}property-and-casualty/file/delete/${file.id}`,
            'POST',
            file,
            (response) => {
              if (response.data.status === 'success') {
                delete $scope.model.formData.pnc_attachments[index];
              }
            },
          );
        },
        No: () => {},
      });
    };

    $scope.isNewPNC = () => {
      if (typeof $scope.model.formData.pnc_main !== 'undefined') {
        return false;
      }
      return true;
    };

    $scope.addAdditionalNotes = () => {
      $scope.model.formData.additional_policies.additionalNotes.push({
        additional_policies_notes: '',
        notes_date: null,
      });
    };
    $scope.deleteNotes = (index) => {
      ConfirmBoxService.open('Confirmation', 'Are You Sure?', {
        Yes: () => {
          $scope.model.formData.additional_policies.additionalNotes.splice(index, 1);
        },
        No: () => {},
      });
    };
  },
];
