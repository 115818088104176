export default [
  '$scope',
  'AuthService',
  '$rootScope',
  '$stateParams',
  '$state',
  function IndexController($scope, AuthService, $rootScope, $stateParams, $state) {
    if ($stateParams.logout === true) {
      AuthService.logout();
      return true;
    }
    if (AuthService.isLoggedIn()) {
      $state.go($rootScope.defaultRoute);
      return true;
    }

    $scope.model = {
      inProgress: false,
      hasError: false,
      loginInfo: {
        username: '',
        password: '',
      },
    };

    $scope.submitLoginForm = () => {
      $scope.model.inProgress = true;
      $scope.model.hasError = false;
      AuthService.getAccessToken($scope.model.loginInfo, (response) => {
        if (response.status !== 200) {
          $scope.model.inProgress = false;
          $scope.model.hasError = true;
        }
      });
    };
  },
];
