import angular from 'angular';
import './assets/css/old/global.css';
import './assets/css/styles.css';
import './core';
import './hacks';
// import './other';

const component = () => {
  // eslint-disable-next-line angular/document-service
  const element = document.createElement('div');

  element.setAttribute('ng-app', 'appModule');
  element.className = 'app';

  element.innerHTML = `
            <app></app>
      `;

  return element;
};

angular.element(() => {
  angular.bootstrap(document, ['appModule']);
});

// eslint-disable-next-line angular/document-service
document.body.appendChild(component());
