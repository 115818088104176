export default [
  '$rootScope',
  '$scope',
  'AuthService',
  'MomentService',
  'HelpersService',
  function ChartsController($rootScope, $scope, AuthService, MomentService, HelpersService) {
    let dataFactory = {};
    const _userData = AuthService.getAuthServiceData().userData;
    $scope.model = {
      charts: {
        chart1: [],
        chart2: [],
      },
      locations: [],
      products: [],
      commissionStatuses: [],
      dropdowns: {
        coverage_type: [],
        carrier: [],
        companies: [],
        products: [],
        agency_locations: [],
        status: [],
        servicing_agents: [],
        gender: [],
        agents: [],
        managers: [],
      },
      formData: {
        startDate: MomentService.addMonths(MomentService.convertTimeZone(new Date()), -6),
        endDate: MomentService.convertTimeZone(new Date()),
        product: '',
        agency_locations: '',
      },
      filters: {
        status: 'Commission Override',
        search: '',
        product: '',
        location: '',
      },
    };
    $scope.loadCommissions = () => {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}charts/get`, 'GET', $scope.model.filters, (res) => {
        dataFactory = res.data;
        $scope.model.dropdowns.coverage_type = dataFactory.coverage_type_data;
        $scope.model.dropdowns.agency_locations = dataFactory.agency_locations;
        $scope.model.dropdowns.status = dataFactory.status;
        $scope.model.dropdowns.agents = dataFactory.agents;
        $scope.model.dropdowns.managers = dataFactory.managers;
        $scope.model.dropdowns.servicing_agents = dataFactory.service_agents;
        $scope.model.dropdowns.gender = dataFactory.gender;

        const label1 = [];
        const count1 = [];
        $.each(dataFactory.locationCount, (_i, data) => {
          label1.push(data.label);
          count1.push(data.count);
        });
        $scope.model.charts.chart1.labels = label1;
        $scope.model.charts.chart1.colors = [];
        $scope.model.charts.chart1.data = count1;

        const label2 = [];
        const count2 = [];
        $.each(dataFactory.genderCount, (_i, data) => {
          label2.push(data.label);
          count2.push(data.count);
        });
        $scope.model.charts.chart2.labels = label2;
        $scope.model.charts.chart2.colors = [];
        $scope.model.charts.chart2.data = count2;
      });
    };

    //DROPDOWNS CALLBACK
    $scope.populateCarriers = (d) => {
      resetAllCarrierDropdowns();
      const carrierData = dataFactory.carrier_data[d.id].carrier_data;
      $scope.model.dropdowns.carriers = carrierData;
    };

    $scope.populateProductsCompanies = (d) => {
      resetProductsCompanies();

      let companiesData =
        dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company[
          $scope.model.formData.coverage_type.id
        ][d.id];
      const productsData =
        dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_products[
          $scope.model.formData.coverage_type.id
        ][d.id];
      /*APPEND OBJECT ON 0 INDEX*/
      companiesData = HelpersService.insertObjectIntoArray(companiesData, 0, {
        id: 0,
        company_name: '--',
      });
      $scope.model.dropdowns.companies = companiesData;
      $scope.model.dropdowns.products = productsData;
    };

    $scope.populateCarrierCompanyProducts = (d) => {
      resetProducts();

      if (d.id === 0) {
        $scope.populateProductsCompanies($scope.model.formData.carrier);
      } else {
        const companyProductsData =
          dataFactory.carrier_data[$scope.model.formData.coverage_type.id].carrier_company_product[
            $scope.model.formData.coverage_type.id
          ][$scope.model.formData.carrier.id][d.id];
        $scope.model.dropdowns.products = companyProductsData;
      }
    };

    /*PRIVATE FUNCTIONS*/
    const resetAllCarrierDropdowns = () => {
      $scope.model.formData.carrier = '';
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };
    const resetProductsCompanies = () => {
      $scope.model.formData.product = '';
      $scope.model.formData.company = '';
    };
    const resetProducts = () => {
      $scope.model.formData.product = '';
    };

    $scope.loadCommissions();

    //FORM SUBMISSION
    $scope.getResults = () => {
      console.log('asd');
    };
  },
];
