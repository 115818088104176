export default [
  '$rootScope',
  '$scope',
  'AuthService',
  '$stateParams',
  '$state',
  'LoaderService',
  'ConfirmBoxService',
  '$timeout',
  '$location',
  'ValidationService',
  function AddressBookController(
    $rootScope,
    $scope,
    AuthService,
    $stateParams,
    $state,
    LoaderService,
    ConfirmBoxService,
    $timeout,
    $location,
    ValidationService,
  ) {
    $scope.first_request = true;
    $scope.individualID = $stateParams.individualID;
    $scope.agentID = $stateParams.agentID;

    let dataFactory = {};

    $scope.model = {
      dropdowns: {
        coverage_type: [],
        carrier: [],
      },
      type: $stateParams.type,
      formData: {
        users: [],
        access_users: false,
      },
      listingData: [],
      listingWritingNumbers: [],
      listingUserPasswords: [],
      listingTotal: 0,
      listingPerPage: 0,
      paginationCurrent: 1,
      individualID: null,
      is_carriers_enable: false,
      searchText: '',
      loggedInUser: $rootScope.loggedInUserData.id,
    };

    $scope.model.addressBookID = $scope.addressBookID;

    $rootScope.subMenuActive = '';
    if ($scope.model.type === 'add_modify_ab') {
      let req_url = '';
      if ($stateParams.addressBookID !== '' && typeof $stateParams.addressBookID !== 'undefined') {
        $scope.model.addressBookID = $stateParams.addressBookID;
        req_url = `${$rootScope.api05DomainPath}address-book/get/${$scope.model.addressBookID}`;
      } else {
        req_url = `${$rootScope.api05DomainPath}address-book/get/`;
      }

      AuthService.sendRequest(req_url, 'GET', {}, (response) => {
        if (response.data.success === true) {
          dataFactory = response.data.carrier_info;

          $scope.model.dropdowns.msfp_business_type = response.data.msfp_business_type;
          $scope.model.dropdowns.cp_department = response.data.cp_department;
          $scope.model.dropdowns.agency_locations = response.data.agency_locations;
          $scope.model.dropdowns.users = response.data.users;
          $scope.model.dropdowns.coverage_type = dataFactory.coverage_type_data;

          $timeout(() => {
            $scope.model.formData.users = response.data.allowed_users;
          }, 500);

          //Modify - Edit
          if (typeof response.data.address_book.business_name !== 'undefined') {
            $timeout(() => {
              LoaderService.hide();
            }, 2000);
            $scope.populateCarriers(response.data.address_book.coverage_type);
            $scope.model.formData = response.data.address_book;
            $scope.model.listingData = response.data.address_book.contact_persons;
            $scope.model.listingUserPasswords = response.data.address_book.user_passwords;
            $scope.model.listingWritingNumbers = response.data.address_book.writing_numbers;

            //User not allowed to view password and assign users
            if (response.data.is_logged_in_user_allowed === false) {
              $scope.model.formData.disabled_access_users = true;
            }
          }
        } else {
          ConfirmBoxService.open('Notification', response.data.message, {
            OK: () => {
              $location.path('/address-book/add/');
            },
          });
        }
      });
    } else if ($scope.model.type === 'view_ab') {
      $scope.model.view_by = 'individual';
      get_address_book_results(1);
    } else if ($scope.model.type === 'thread_st') {
      if ($stateParams.supportTicketID !== '') {
        $scope.model.supportTicketID = $stateParams.supportTicketID;
        const req_url = `${$rootScope.api05DomainPath}support-ticket/thread/${$scope.model.supportTicketID}`;

        AuthService.sendRequest(req_url, 'GET', {}, (response) => {
          if (response.data.success === true) {
            //To check if participants is assign on this task or task created by the logged in Agent
            const participants = response.data.participants;
            let participantsCount = 0;
            $scope.model.loggedInUser = false;

            const last_participants = participants[Object.keys(participants)[Object.keys(participants).length - 1]];
            for (let j = 0; j < last_participants.length; j++) {
              if (last_participants[j].user_id === $rootScope.loggedInUserData.id) {
                $scope.model.loggedInUser = true;
              }
            }

            for (const _i in participants) {
              participantsCount++;
            }
            $scope.model.participantsCount = participantsCount;

            $scope.model.support_ticket = response.data.support_ticket;
            $scope.model.support_ticket_carrier_plan = response.data.support_ticket_carrier_plan;
            $scope.model.listingData = response.data.support_ticket_messages;
            $scope.model.individual = response.data.individual;
            $scope.model.participants = response.data.participants;
            $scope.model.users = response.data.users;
            $scope.model.attached_files = response.data.attachments;
          }
        });
      }
    } else if ($scope.model.type === 'view_st_agent') {
      $scope.model.view_by = 'agent';
      get_support_ticket_agent_results(1);
      get_support_ticket_agent_report();
    } else if ($scope.model.type === 'view_st_admin') {
      AuthService.sendRequest(`${$rootScope.api05DomainPath}support-ticket/report-admin/`, 'GET', {}, (response) => {
        if (response.data.success === true) {
          $scope.model.agents_report = response.data.agents_report;
        }
      });
    }

    /*
     Submit Forms
     */

    $scope.submitAdressBookAddForm = () => {
      if ($scope.model.type === 'add_modify_ab') {
        const validated = ValidationService.validate($scope, {
          'model.formData.business_name': {
            required: true,
            errorMessage: 'The Business name is required',
          },
        });

        if (validated) {
          $scope.model.formData.contact_persons = $scope.model.listingData;
          $scope.model.formData.writing_numbers = $scope.model.listingWritingNumbers;
          $scope.model.formData.user_passwords = $scope.model.listingUserPasswords;
          if ($stateParams.addressBookID !== '' && typeof $stateParams.addressBookID !== 'undefined') {
            $scope.model.formData.addressBookID = $stateParams.addressBookID;
          }

          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}address-book/save`,
            'POST',
            $scope.model.formData,
            (response) => {
              if (response.data.success === true) {
                ConfirmBoxService.open('Success', response.data.message, {
                  OK: () => {
                    if ($stateParams.addressBookID !== '' && typeof $stateParams.addressBookID !== 'undefined') {
                      $state.reload();
                    } else {
                      $location.path(`/address-book/add/${response.data.address_book_id}`);
                    }
                  },
                });
              } else {
                ConfirmBoxService.open('Notification', response.data.message, {
                  OK: () => {},
                });
              }
            },
          );
        }
      }
    };

    /*
     Scope Methods
     */

    /*$scope.populateCarriers = function (item, action) {

        action = typeof action !== 'undefined' ? action : 'add';

        if (item.value == 'CARRIER') {
            $scope.model.is_carriers_enable = true;
        } else {
            $scope.model.is_carriers_enable = false;

            //Reset Values
            if(action == 'add') {
                $scope.model.formData.business_name = '';
                $scope.model.formData.phone_primary = '';
            }
        }

    };*/

    $scope.populateCarrierInfo = (item) => {
      $scope.model.formData.business_name = item.carrier_name;
      $scope.model.formData.phone_primary = item.business_phone;
    };

    $scope.add_field_contact_person = () => {
      const obj = {};
      const target = $scope.model.listingData;

      obj.cp_name_first = '';
      obj.cp_name_last = '';
      obj.cp_phone_primary = '';
      obj.cp_phone_primary_ext = '';
      obj.cp_phone_secondary = '';
      obj.cp_phone_secondary_ext = '';
      obj.cp_department = '';

      target.push(obj);
    };

    $scope.remove_field_contact_person = (index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          const target = $scope.model.listingData;

          target.splice(index, 1);
        },
        No: () => {},
      });
    };

    $scope.add_field_writing_number = () => {
      const obj = {};
      const target = $scope.model.listingWritingNumbers;

      obj.location = '';
      obj.writing_number = '';
      obj.user = '';

      target.push(obj);
    };

    $scope.remove_field_writing_number = (index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          const target = $scope.model.listingWritingNumbers;

          target.splice(index, 1);
        },
        No: () => {},
      });
    };

    $scope.add_field_user_password = () => {
      const obj = {};
      const target = $scope.model.listingUserPasswords;

      obj.username = '';
      obj.password = '';
      obj.users = '';

      target.push(obj);
    };

    $scope.remove_field_user_password = (index) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          const target = $scope.model.listingUserPasswords;

          target.splice(index, 1);
        },
        No: () => {},
      });
    };

    $scope.showPassword = (password, id) => {
      AuthService.sendRequest(
        `${$rootScope.api05DomainPath}address-book/get-decrypted-password`,
        'POST',
        { password: password, user_password_access_id: id },
        (response) => {
          if (response.data.success === true) {
            ConfirmBoxService.open('Success', response.data.message, {
              OK: () => {},
            });
          } else {
            ConfirmBoxService.open('Notification', response.data.message, {
              OK: () => {},
            });
          }
        },
      );
    };

    $scope.deleteAddressBook = (index, addressBookID) => {
      ConfirmBoxService.open('Confirmation', 'Do you really want to delete?', {
        Yes: () => {
          AuthService.sendRequest(
            `${$rootScope.api05DomainPath}address-book/delete`,
            'POST',
            { address_book_id: addressBookID },
            (response) => {
              if (response.data.success === true) {
                ConfirmBoxService.open('Success', response.data.message, {
                  OK: () => {
                    $scope.model.listingData.splice(index, 1);
                  },
                });
              } else {
                ConfirmBoxService.open('Notification', response.data.message, {
                  OK: () => {},
                });
              }
            },
          );
        },
        No: () => {
          //$state.reload();
        },
      });
    };

    //For Pagination page change trigger
    $scope.pageChanged = (newPage) => {
      if ($scope.first_request !== true) {
        get_address_book_results(newPage);
      } else {
        $scope.first_request = false;
      }
    };

    //Search Address Book in Listing Page
    $scope.searchAddressBook = () => {
      get_address_book_results(1);
      $scope.model.paginationCurrent = 1;
    };

    //Get Carriers by Coverage Type
    $scope.populateCarriers = (d) => {
      $scope.model.formData.carrier = '';
      $scope.model.formData.business_name = '';
      const carrierData = angular.copy(dataFactory.carrier_data[d.id].carrier_data);
      carrierData.push({
        id: 0,
        carrier_name: 'Other',
        created_at: '',
        updated_at: '',
      });
      $scope.model.dropdowns.carriers = carrierData;
    };

    /*
     Private Methods
     */

    function get_address_book_results(pageNumber) {
      const params = { page: pageNumber };

      if ($scope.model.searchText !== '') {
        params.search = $scope.model.searchText;
      }

      AuthService.sendRequest(`${$rootScope.api05DomainPath}address-book/get-all/`, 'GET', params, (response) => {
        if (response.data.success === true) {
          $scope.model.listingData = response.data.address_book;
          $scope.model.listingTotal = response.data.address_book_count;
          $scope.model.listingPerPage = response.data.per_page;
        }
      });
    }
  },
];
